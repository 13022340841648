import React, { useState } from "react";
import NoEventUI from '../UserDashboard/noEventUI';
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, A11y, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventList, getMyBlogs } from "../../store/slices/userSlice";
import axios from "axios";
import { useTranslation } from "react-i18next";

function SavePins_Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const EventList = useSelector(getEventList);
  const dispatch = useDispatch();

  const [savedpins,setSavedPins]=useState([])
  const [load, setload] = useState(false);

  const getBlogs = async () => {
    try {
      setload(true);
      setSavedPins(null);
      const response = await dispatch(getMyBlogs()).unwrap();
      console.log("abc1", response?.data);
      setSavedPins(response?.data ? response?.data : []);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  useEffect(() => {
    getBlogs();
  }, [EventList]);


  console.log('savedpins', savedpins)

  return (
<>
    
    <div className="wf-shortlist-dashboard">
    <div className="d-flex gap-4 align-items-end mb-2">
        <h2 className="font-size-26 dm-font purple-color mb-0">{t('Savedpins')}</h2>
        {savedpins?.length > 0 && (<a className="font-size-14 pink-color mb-0" href="/savedpins"><u>{t('ViewAll')}</u></a>)}
    </div>
    <div className="wf-filter-category-slider wf-saved-pins-slider wf-shadow-2 white-bg rounded py-3 px-4">
    {load ? (<div className="row">
    {new Array(4).fill(null).map((_, index) => (
      <div key={index} className=" col-md-3">
        <div className="saved-pins-image shimmer w-100"></div>
      </div>
    ))}
  </div>
) :savedpins?.length > 0 ? (
    <Swiper
    modules={[Navigation, A11y]}
    spaceBetween={15}
    navigation
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    breakpoints={{
      468: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 4,
      },
    }}>

{savedpins?.map((b) => (
      <SwiperSlide>
        <div className="savedpins-card">
        <img alt={b?.title} src={b.imageName} className="saved-pins-image w-100"/>
        </div>
        </SwiperSlide>
      ))}
  </Swiper>
  ) : (
    <NoEventUI name={"Saved Pins"} />
      )}
  </div>


      
    </div>

</>
  );
}

export default SavePins_Dashboard;