import React from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom'

const DeleteAccount = () => {
  return (
    <>
    <Helmet>
        <title>Delete Account - Shadi Tayari</title>
        <meta
          name="description"
          content="Explore the comprehensive FAQ section of ShadiTayari, the leading wedding planning app. Find answers to common questions about our app's features, services, and wedding planning tips."
        />
        <meta
          name="keywords"
          content="ShadiTayari, wedding planning"
        />
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container text-center">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">Delete Account</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <p className="font-size-20 text-center">If you would like to delete your account and all associated data, please click the button below. This action cannot be undone.</p>
          <Link className="btn wf-btn wf-purple-btn font-size-14 py-3 px-5" to="/">Delete My Account</Link>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default DeleteAccount