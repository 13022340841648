import React from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const SignUpStep2 = ({ formData, handleFormUpdate }) => {
  const { t } = useTranslation();

  const curryear= new Date().getFullYear();
  const yearsArray= [curryear,curryear+1,curryear+2]

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function handleDateChange(e) {
  const inputDate = e.target.value;
  const currentDate = getCurrentDate();

  if (inputDate < currentDate) {
      e.target.value = currentDate; 
  }
}

const daysOptions = [
  { value: 'weekdays', label: 'Weekdays' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

const seasonsOptions = [
  { value: 'spring', label: 'Spring', image: '../assets/images/spring.svg' },
  { value: 'summer', label: 'Summer', image: '../assets/images/summer.svg' },
  { value: 'fall', label: 'Fall', image: '../assets/images/fall.svg' },
  { value: 'winter', label: 'Winter', image: '../assets/images/winter.svg' },
];


const handleNextStep=()=>{

  const currentDate = getCurrentDate();


  if(formData.event_date=="1"&&formData.event_prefered_day=="1"&&formData.event_prefered_season=="1"&&formData.event_prefered_year=="1"){
    toast.error("Please select your desired field")
  } 
  else if (formData.event_date!=="" && formData.event_date!=="1" && formData.event_date < currentDate) {
    toast.error("Invalid date") 
  }
  else{
    handleFormUpdate({ page: formData.page+1 })
  }
}


  return (
    <>
        <h3 className="font-size-20 dm-font pink-color mb-3">{t('WhenisyourWedding')}?</h3>
          <div class="input-group wf-input-group wf-shadow-2 mb-3">
            <div class="form-floating wf-input">
              <input onBlur={handleDateChange} min={getCurrentDate()} value={!formData?.event_date==""||!formData?.event_date=="1"?formData?.event_date:""} onChange={(e)=>handleFormUpdate({ event_date: e.target.value, event_prefered_day:"", event_prefered_season:"", event_prefered_year:""})} type="date" class="form-control" id="floatingInput" placeholder="" 
              autocomplete="date" required />
              <label className="purple-color" for="floatingInput">{t('FixedDate')}</label>
            </div>
            <button onClick={()=>handleFormUpdate({ event_date: ""})} class="input-group-text pink-color"><i class="fas fa-times"></i></button>
          </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('NotSure')}? {t('Giveusahint')}!</h3>

          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Day')}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
            {daysOptions.map((day) => (
            <>
            <input type="radio" disabled={formData.event_date === '1' || formData.event_date === '' ? false : true} checked={formData.event_prefered_day === day.value}
            onChange={() => handleFormUpdate({ event_prefered_day: day.value })} name="day-options" id={day.value} class="btn-check" />
            <label class="btn wf-select-btn" for={day.value}>{day.label}</label>
            </>
            ))}
          </div>

          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Season')}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
          {seasonsOptions.map((season) => (
            <>
            <input type="radio" disabled={formData.event_date === '1' || formData.event_date === '' ? false : true} checked={formData.event_prefered_season === season.value}
            onChange={() => handleFormUpdate({ event_prefered_season: season.value })} name="season-options" id={season.value} class="btn-check" />
            <label class="btn wf-select-btn" for={season.value}>{season.label}</label>
            </>
          ))}
          </div>

          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Year')}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
          {yearsArray?.map((y)=>(
            <>
            <input type="radio" disabled={formData.event_date=="1"||formData.event_date==""?false:true} checked={formData.event_prefered_year==y} 
            onChange={()=>handleFormUpdate({ event_prefered_year: y})} name="year-options" id={y} class="btn-check" />
            <label class="btn wf-select-btn" for={y}>{y}</label>
            </>
          ))}
          </div>


        <h3 className="font-size-20 dm-font pink-color mb-2">{t('Nohint')}? </h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
            <input type="radio" checked={formData.event_prefered_season=="" && formData.event_prefered_day=="" && formData.event_prefered_year=="" && formData.event_date==""}
            onChange={()=>handleFormUpdate({ event_prefered_year: "", event_prefered_day: "", event_prefered_season:"",event_date:""})}  name="undecided" id="undecided" class="btn-check" />
            <label class="btn wf-select-btn" for="undecided">UNDECIDED</label>
        </div>
                 
        <div class="d-flex justify-content-center gap-3">
          <button onClick={()=>handleFormUpdate({ page: formData.page-1 })} class="btn wf-btn-2 wf-pink-outline-btn py-2 px-4 rounded-2">Back</button>
          <button class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" onClick={handleNextStep}>{t('Save')}</button>
        </div>    
    </>
  );
};
export default SignUpStep2;