import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from './HeaderLight'
import Spinner from "../../components/Spinner"
import Footer from './Footer'
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Heart } from 'lucide-react';
import { ArrowRight } from 'lucide-react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  getProfile,
  getEventList,
  getCitiesWithCountry
} from "../../store/slices/userSlice";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { toast } from "react-toastify";
import Modal from "react-modal";
import CategoriesCardSkeleton from "../LoadingSkeleton/CategoriesCardSkeleton";
import LocationCardSkeleton from "../LoadingSkeleton/LocationCardSkeleton"
import BlogCardSkeletonHome from "../LoadingSkeleton/BlogCardSkeletonHome"
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import { useTranslation } from "react-i18next";
import i18n from "../../components/i18n";
import { post } from "jquery";
import CustomAd from "../../components/Layout/CustomAd";
const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement("#root");




const Home = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);

  const [event, setevent] = useState([]);
  const [showfav, setshowfav] = useState(false);
  const [category_id, setcategory_id] = useState("");
  const [store_id, setstore_id] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const savedLanguage = localStorage.getItem('selectedLanguage');
  


  const [cities, setcities] = useState([]);
  const [top6cities, settop6cities] = useState([]);
  const [topcategories, settopcategories] = useState([]);
  const [featuredsuppliers, setfeaturedsuppliers] = useState([]);
  const [searchedCategroy, setsearchedCategroy] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [load, setload] = useState(false);
  const [loadd, setloadd] = useState(false);
  const [loaddd, setloaddd] = useState(false);
  
  
  const [city, setcity] = useState("");
  const [categories, setcategories] = useState("");
  const [subcategories, setsubcategories] = useState("");


  const [posts, setPosts] = useState([]);
  

  const fetchPosts = async () => {
    try {
      setloadd(true)
        const response = await fetch('https://st.digitalbee.studio/wp-json/wp/v2/posts?per_page=7');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Fetching author, tags, categories, and featured image data for each post
        const postsWithData = await Promise.all(
            data.map(async post => {
                // Fetch author data
                const authorResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/users/${post.author}`);
                if (!authorResponse.ok) {
                    throw new Error('Error fetching author data');
                }
                const authorData = await authorResponse.json();

                // Fetch tags data
                const tagsResponse = await Promise.all(
                    post.tags.map(async tagId => {
                        const tagResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/tags/${tagId}`);
                        if (!tagResponse.ok) {
                            throw new Error('Error fetching tag data');
                        }
                        const tagData = await tagResponse.json();
                        return tagData.name;
                    })
                );

                // Fetch categories data
                const categoriesResponse = await Promise.all(
                    post.categories.map(async categoryId => {
                        const categoryResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/categories/${categoryId}`);
                        if (!categoryResponse.ok) {
                            throw new Error('Error fetching category data');
                        }
                        const categoryData = await categoryResponse.json();
                        return categoryData.name;
                    })
                );

                // Fetch featured image data
                const mediaResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/media/${post.featured_media}`);
                if (!mediaResponse.ok) {
                    throw new Error('Error fetching featured image data');
                }
                const featuredImageResponse = await mediaResponse.json();

                return {
                    ...post,
                    authorName: authorData,
                    tags: tagsResponse,
                    categories: categoriesResponse,
                    featured_media: featuredImageResponse
                };
            })
        );

        setPosts(postsWithData);
        setloadd(false)
    } catch (error) {
        console.error('Error fetching data:', error);
        setloadd(false)
    }
};



  const getCity = async () => {
    try {      
      const response = await dispatch(getCitiesWithCountry(savedLanguage)).unwrap();
      console.log('responseaaa', response)  
      setcities(response?.data);
        
    } catch (rejectedValueOrSerializedError) {}
  };

  const getTop6City = async () => {
    try {
      
      const response = await axios.get(`vendorpage/gettop6cities`,{params:{lang: savedLanguage, countryType: "pakistan"}});
      console.log('responseaaa', response)  
      settop6cities(response?.data?.data);
        
    } catch (rejectedValueOrSerializedError) {}
  };

console.log('cities', cities)
  const getTopCategories = async () => {
    try {
      setload(true);
      const response = await axios.get('vendorpage/topcategories',{params:{lang: savedLanguage}});
      console.log('response', response)
      settopcategories(response?.data?.data);
      setload(false);

    } catch (rejectedValueOrSerializedError) {}
  };

  const FeaturedSuppliers = async () => {
    try {
      setloaddd(true);
      const response = await axios.post('vendorpage/featuredsuppliers',{user_id: profile?._id, lang: savedLanguage, countryType: "pakistan"});
      console.log('response', response)
      setfeaturedsuppliers(response?.data?.data);
      setloaddd(false);

    } catch (rejectedValueOrSerializedError) {}
  };

  const getVenueSubCategories = async () => {
    try {

      setload(true);
      const response = await axios.post(`user/getvenuesubcategories`, {name: "Venue", lang: savedLanguage});
      console.log('responsesss', response?.data?.data)
      
      
      setsubcategories(response?.data?.data);
      setload(false);

    } catch (rejectedValueOrSerializedError) {}
  };

  const getCategories = async () => {
    try {
       setload(true);

       const response = await axios.get(`user/getservices`,{params:{lang: savedLanguage}});
       console.log('responsexxaa', response)
       
       setcategories(response?.data?.data);
       setload(false);

    } catch (rejectedValueOrSerializedError) {}
  };


console.log('citiessss', cities)

  const handlePin = async () => {

    try {
    
    const finalIndex= store_id
  
    
   
    console.log('event', event)
    console.log('store_id', store_id)
    console.log('category_id', category_id)
    const abc = await axios.post("/vendorpage/favourite",{ id: profile?._id, user_events: myEvents?.map((evv)=>evv.Event._id),store_id:store_id, event_id:event?.map((e)=>e.event_id),category_id:category_id })
  
    if (abc) {
      closeModal();
      toast.success(abc?.data?.message);
      
      // FeaturedSuppliers();
      
      
    
      const index = featuredsuppliers.findIndex(item => item._id == finalIndex);
      console.log('index', index)
      let val= event
  
  
    // If the object with the specified _id is found, update its name
    if (index !== -1) {
      const newData = [...featuredsuppliers];
        newData[index].isfavourite = val;
  
        // Update the state with the new data
        setfeaturedsuppliers(newData);
    } 
                  
    // setevent([])
    // setcategory_id("")
    // setstore_id("")
   
  }

} catch (error) {
  // Handle error
  console.error("Error occurred:", error);
}
  
  
  };

  useEffect(() => {
      getCity();
      getTop6City();
      getTopCategories();
      FeaturedSuppliers();
      getVenueSubCategories();
      getCategories();
      fetchPosts();
    
  }, []);


  const viewModal = async (store_id,category_id,events) => {
    console.log('store_id,category_id,events', store_id,category_id,events)

    if(myEvents?.length<1){
      toast.error("You don't have any event to shortlist this store")
    }
    else{
    setmodalIsOpen(true);
    setstore_id(store_id)
    setcategory_id(category_id)
    setevent(events)
    setshowfav(events?.length>0?true:false)
    }
  };
  
  function closeModal() {
    setmodalIsOpen(false);
  }

  // const handleShotlistEventChange = (id) => {
  //   console.log('idddddddddddddddd', id);
  //   if (!event.includes(id)) {
  //     setevent([...event, id]);
  //   } else {
  //     const updatedEventIds = event.filter((itemId) => itemId !== id);
  //     setevent(updatedEventIds);
  //   }
  // };

  const handleShotlistEventChange = (id) => {
    console.log('idddddddddddddddd', id);
    
    const existingEventIndex = event.findIndex(event => event.event_id == id);

    console.log('existingEventIndex', existingEventIndex)
    
    if (existingEventIndex === -1) {

        const newEvent = {        
            event_id: id,
        
        };
        setevent([...event, newEvent]);
    } else {
     
        const updatedEvents = event.filter(event => event.event_id !== id);
        setevent(updatedEvents);
    }
};

  
  console.log('eventasas', event)
  console.log('post', posts)

  return (

    <>
<Modal
  closeTimeoutMS={500}
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  style={customStyles}
  contentLabel="Add Task"
  className="modal fade show wf-modal tasks">
  <div class="modal-dialog wf-modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header border-0 pb-0">
        <h1 class="font-size-26 dm-font purple-color" id="staticBackdropLabel" >{t('SelectAnEvent')}</h1> 
        <button type="button" onClick={closeModal} class="btn-close"></button>
      </div>
      <div class="modal-body">
        <p class="font-size-16 color-black" id="" >{t('Pleaseselecttheeventsforwhichyouwanttoshortlistthisstore')}</p>
        <div className="row">
          <div className="d-flex flex-wrap gap-2 mb-3">
          {myEvents?.length>0&&myEvents?.map((e)=>(<>
          <input onChange={()=>handleShotlistEventChange(e?.Event?._id)} checked={event?.some((ev) => ev?.event_id === e?.Event?._id)}  type="checkbox" class="btn-check" name="event-select" id={e?.Event?._id} autocomplete="off"/>
          <label style={{"--bs-btn-active-bg":`${e?.Event?.event_color}`}} className="btn wf-select-btn" for={e?.Event?._id}>{e?.Event?.event_name}</label>
          </>))}
          </div>
        </div> 
      </div>
      <div class="modal-footer border-0 justify-content-center">
        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2" onClick={()=>handlePin()}>{t('Add')}</button>
      </div>
    </div>
  </div>
</Modal>
<Helmet>
    <title>Shadi Tayari - Pakistan's Digital Wedding Planner</title>
    <meta name="description" content="Shadi Tayari, the AI-based wedding planning app for Pakistan. With features like vendor connections, budget management, and guest list tracking, every detail is meticulously planned." />
    <meta name="keywords" content="Shadi Tayari, wedding planning"/>
</Helmet>


<section class="home-hero home-main-bg" >
<Header theme="light" />
  <div class="container">
    <div class="home-hero-body">
      <h1 class="home-title font-size-50 dm-font color-white text-center mx-auto">
      Explore Wedding Suppliers & Venues in Pakistan
      </h1>
      <div className="search-wrapper">
              <div className="search-field-1">
                <div class="dropdown">
                  <a class="btn btn-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {searchedCategroy.name || <>{t('SelectCategory')}</>}
                  </a>
                  <ul class="dropdown-menu">
                    <li onClick={() => setsearchedCategroy("")}>
                      <a class="dropdown-item">{t('SelectCategory')}</a>
                    </li>
                    {categories &&
                      categories?.map((item, i) => (
                        <li key={i} onClick={() => setsearchedCategroy(item)}>
                          <a class="dropdown-item">{item.name}</a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="search-field-2">
                <div className="dropdown">
                  <a class="btn btn-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {searchQuery?.countryName ? searchQuery?.countryName : searchQuery?.name ? searchQuery.name : <>{t('SelectLocation')}</>}
                  </a>

                  <ul className="dropdown-menu">
                    <li onClick={() => setSearchQuery("")}>
                      <a class="dropdown-item">{t('SelectLocation')}</a>
                    </li>
                    {cities?.length > 0 && cities?.map((c) => (
                      <li className={`country-list ${searchQuery.name == c.name && "selected"}`}>
                        <a onClick={() => { setSearchQuery(c) }} class="dropdown-item" >
                          <img src={`${process.env.REACT_APP_IMGURL}${c.countryFlag}`} width="25" className="me-1" /> {c.countryName}</a>
                        {c.cities?.map((ci) => (<li className={`city-list ${searchQuery?.name == ci.name && "selected"}`}>
                          <a onClick={() => { setSearchQuery(ci) }} style={{ "padding-left": "30px" }} class="dropdown-item">{ci.name}</a>
                        </li>
                        ))}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="search-btn" onClick={() => {
                const path = searchedCategroy && searchQuery ?
                  `/suppliers/${searchedCategroy?.name?.toLowerCase()} in ${searchQuery?.name?.toLowerCase()}` :
                  searchedCategroy && !searchQuery ?
                    `/suppliers/${searchedCategroy?.name?.toLowerCase()}` :
                    !searchedCategroy && searchQuery ?
                      `/suppliers/${searchQuery?.name?.toLowerCase()}` :
                      `/suppliers`;

                if (path) {
                  navigate(path.replace(/\s+/g, '-'));
                }
              }}>
                <i class="fas fa-search"></i>
              </div>
            </div>
    </div>
  </div>
  <div className="home-hero-overlay"></div>
</section>
       
<section className="home-section-1 py-5 planning-tools-bg">
  <div className="container">
    <h2 className="font-size-30 dm-font color-black mb-4">{t('OurWeddingPlanningTools')}</h2>
    <div class="row gx-2 mb-4 gy-2">
      <div class="col-lg-3 col-md-12">
        <div className="gradient-bg h-100 px-4 py-3 rounded wf-shadow">
          <p className="font-size-16 color-white text-uppercase mb-0">{t('Easilyplanyourwedding')}</p>
          <div class="border-top mt-3 mb-4"></div>
          <button type="button" className="btn wf-btn-icon fw-bold mt-1 color-white px-0 py-0 w-100" onClick={(e)=>{e.preventDefault(); navigate(profile&&profile?.role=="customer"?'/dashboard':'/login')}}>{t('GetStarted')} <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'}  font-size-24`}></i></button>
        </div>
      </div>
      <div class="col-lg-9 col-md-12">
        <div className="row gx-2 h-100 gy-2">
          <div className="col-lg-2 col-md-4 col-6">
            <a href="/features/task-management/" >
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-4">
            <img height="60" src={"../assets/images/planner-icon-img.svg"} className="tool-img" />
            <p className="font-size-14 fw-semibold color-black mb-0 text-center">{t('TaskManager')}</p>
            </div>
            </a>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <a href={"/features/guest-management/"}>
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-4">
            <img height="60" src={"../assets/images/guest-list-icon-img.svg"} className="tool-img" />
            <p className="font-size-14 fw-semibold color-black mb-0 text-center">{t('GuestList')}</p>
            </div>
            </a>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <a href={"/features/budget-management/"}>
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-4">
            <img height="60" src={"../assets/images/budget-planning-icon-img.svg"} className="tool-img" />
            <p className="font-size-14 fw-semibold color-black mb-0 text-center">{t('BudgetPlanner')}</p>
            </div>
            </a>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <a>
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-3">
            <img height="60" src={"../assets/images/favorite-listing-icon-img.svg"} className="tool-img" />
            <p style={{lineHeight:1.2}} className="font-size-14 fw-semibold color-black mb-0 text-center">{t('ShortlistedVendors')}</p>
            </div>
            </a>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <a style={{cursor:'default',"filter":"grayscale(1)"}}>
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-3">
            <img height="60" src={"../assets/images/wedding-website-icon-img.svg"} className="tool-img" />
            <p style={{lineHeight:1.2}} className="font-size-14 fw-semibold color-black mb-0 text-center">{t('WeddingWebsite')}</p>
            </div>
            </a>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <a style={{cursor:'default',"filter":"grayscale(1)"}}>
            <div className="wf-shadow white-bg p-3 rounded h-100 d-flex align-items-center flex-column justify-content-center gap-4">
            <img height="60" src={"../assets/images/gifting-tools-icon-img.svg"} className="tool-img" />
            <p className="font-size-14 fw-semibold color-black mb-0 text-center">{t('GiftingTools')}</p>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="row mb-4 gy-3 gx-2">
      <div className="col-md-6">
        <CustomAd height="90" width="636" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
      </div>
      <div className="col-md-6">
        <CustomAd height="90" width="636" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
      </div>
      {/* <div className="col-md-12">
        <CustomAd height="160" width="1296" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/Leaderboard-banner.jpg`} name="Shadi Tayari" />
      </div> */}
    </div>

    <h2 className="font-size-30 dm-font color-black mb-4 mt-5">{t('FeaturedSuppliers')}</h2>
    <div className="featured-supplier-slider">
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={15}
      // slidesPerView={}
      navigation
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}>
  {loaddd ? (
  <div className="row">
    {new Array(4).fill(null).map((_, index) => (
      <VendorCardSkeletonHome key={index} />
    ))}
  </div>
) :featuredsuppliers?.map((d)=>(
        <SwiperSlide>
        <div key={d._id} className="vendor-card">
      <div className="vendor-card-body">
        <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d?.slug}`} >
          <img alt={d.store_name} src={`${process.env.REACT_APP_IMGURL}${d?.image}`} className="vendor-image w-100"/>
        </a>
        <div className="d-flex justify-content-between align-items-center top-box">
          <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase">Featured</span>
          {profile&&profile?.role=="customer"&&(<div className="heart-icon" onClick={() => viewModal(d?._id,d?.prefered_services[0]?._id,d?.isfavourite)}>
          {d.isfavourite?.length>0?<Heart fill="#EE0E7B" stroke="#EE0E7B" />:<Heart />}
          </div>)}
        </div>
        <div className="d-flex align-items-center bottom-box">
          <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
          <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${d?.prefered_services[0]?.name.replace(/\s+/g,'-').toLowerCase()}`}>{d?.prefered_services[0]?.name}</a>
          </span>
        </div>
      </div>
      <div className="vendor-card-footer">
        <h3 className="font-size-18 fw-semibold mb-1" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d?.slug}`}  className="color-black">{d.store_name}</a></h3>
        <div className="d-flex justify-content-between align-items-start">
          <p className="font-size-16 color-black mb-0">{`${d.cities.length>0?d.cities[0]?.name:""}${d.cities.length>0&&d.country.length>0?",":""} ${d.country.length>0?d.country[0]?.name:""}`}</p>
          <p style={{width:'60px'}} className="font-size-16 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{d?.reviews?.average}</span> ({d?.reviews?.total})</p>
        </div>
      </div> 
        </div>
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
  </div> 
</section>
       
<section className="home-section-2 number-one-picks-bg pt-5 mb-5">
  <div className="container">
    <div className="row gy-5">
      <div class="col-lg-4 col-md-5 align-self-center">
      <h2 className="font-size-46 dm-font color-white mb-3">Pakistan's<br/>#1 {t('Wedding')}<br />{t('PlanningApp')}</h2>
      {/* <p className="font-size-16 color-white mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim quis nostrud ad minim veniam.</p> */}
      <a href="/features/task-management" className="btn wf-btn-icon wf-gradient-btn text-uppercase w-75"> {t('Viewallfeatures')}  <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'} font-size-24 d-flex`}></i></a>

      </div>
      <div class="col-lg-8 col-md-7">
      <img src="../assets/images/mockup-image-home3.png" style={{marginBottom:'-33px'}} alt="" className="w-100" />
      </div>
    </div>
  </div>
</section>

<section className="home-section-3 py-5">
  <div className="container ">
    <div className="d-flex flex-row flex-wrap gap-3 categories-boxes">
      <div className="col-lg-3 col-md-5 col-12">
        <div className="gradient-bg wf-shadow h-100 rounded-4 py-5 px-4 d-flex flex-column  gap-2">
        <img width="55" src={`${process.env.REACT_APP_FrontURL}/assets/images/icon-for-home.svg`} className="mb-2" />
        <h2 className="font-size-46 dm-font color-white mb-2">{t('Categories')}</h2>
        <a href="/suppliers" className="btn wf-btn-icon mt-1 color-white px-0 py-0 w-100">{t('FromOurFinest')} <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'}  font-size-24 d-flex`}></i></a>
        </div>
      </div>
      {load ? new Array(22).fill(null).map((_, index) => (<CategoriesCardSkeleton key={index} />)) : categories?.length > 0 && categories?.map((c)=>(
      <div className="category-box">
      <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${c.slug}`}>
      <div className="category-card">
          <img src={`${c.cover_image1 ? `${process.env.REACT_APP_IMGURL}${c.cover_image1}` : `${process.env.REACT_APP_IMGURL}${c.cover_image}`}`} alt={c.name} className="category-img" />
          <div className="category-text">
            <p className="font-size-26 dm-font m-0 color-white">{c.name}</p>
            </div>
          </div>
        </a>
      </div>
      ))}
    </div>
  </div>
</section>

<section className="home-section-4 py-5 about-weddified">
  <div className="about-overlay"></div>
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <h2 className="font-size-46 dm-font color-white mb-3">About Shadi Tayari</h2>
        <p className="font-size-16 color-white mb-3">Welcome to Shadi Tayari, the AI-based wedding planning app for Pakistan. Our app offers personalized planning for weddings in Karachi, Lahore, Islamabad, and all cities across Pakistan, respecting unique traditions and cultural preferences. With features like vendor connections, budget management, and guest list tracking, every detail is meticulously planned.</p>
        <p className="font-size-16 color-white mb-4">Shadi Tayari integrates the rich traditions of Pakistani weddings into seamless planning for Mehndi, Baraat, and Valima ceremonies. Trusted by countless couples, our app makes wedding planning effortless and special. Download Shadi Tayari today – Where Tradition Meets Technology.</p>
        <a href={`${process.env.REACT_APP_FrontURL}/about`} className="btn wf-btn-icon wf-gradient-btn text-uppercase w-75"> {t('LearnMoreaboutus')}  <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'}  font-size-24 d-flex`}></i></a>
      </div>
    </div>
  </div>
</section>

<section className="home-section-5 py-5">
  <div className="container">
    <div className="row gy-4">
      <div className="col-lg-3 col-md-5">
        <div className="gradient-bg wf-shadow h-100 rounded-4 py-5 px-4 d-flex flex-column  gap-2">
        <img width="55" src={`${process.env.REACT_APP_FrontURL}/assets/images/icon-for-home.svg`} className="mb-2" />
        <h2 className="font-size-46 dm-font color-white mb-2">{t('GetIdeas&Inspirations')}</h2>
        <a href="/blog" className="btn wf-btn-icon mt-1 color-white px-0 py-0 w-100">{t('FromOurFinest')} <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'} font-size-24 d-flex`}></i></a>
        </div>
      </div>
        {loadd
        ? 
          new Array(7).fill(null).map((_, index) => (
            <BlogCardSkeletonHome key={index} />
          ))
        :posts&&posts?.slice(0,7)?.map((b)=>(
        <div key={b.id} className="col-lg-3 col-md-4 blog-card blog-card-home" >
      <div className="blog-card-body">
      <a
      href={`/${b?.slug}`}
      >
        <img alt={b.title.rendered} src={b?.yoast_head_json?.og_image[0]?.url?`${b?.yoast_head_json?.og_image[0]?.url}`:"https://via.placeholder.com/306x201"} className="blog-image w-100" />
        </a>
        {b.categories[0] ? (
                          <div className="d-flex align-items-center top-box">
                            <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                              <a
                                style={{ color: "white" }}
                                // href={`/ideas-inspiration/${b?.category_id?.slug}`}
                                >
                                {b?.categories[0]}
                              </a>
                            </span>
                          </div>
                        ) : null}

                        {b.categories[1] ? (
                          <div className="d-flex align-items-center bottom-box">
                            <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                              <a
                                // href={`${process.env.REACT_APP_FrontURL}/ideas-inspiration/${b.blog_category_id?.slug}`}
                              >
                                {b?.categories[1]}
                              </a>
                            </span>
                          </div>
                        ) : null}
      </div>
      <div className="blog-card-footer">
      <div className="row mb-2">
      <div className="col-12 d-flex align-items-center gap-2">
      <img width="40" height="40" alt="avatar" src={b?.authorName?.mpp_avatar?.full ? b?.authorName?.mpp_avatar?.full : `https://st.digitalbee.studio/wp-content/uploads/DP.jpg`} class="rounded-circle wf-shadow-2" style={{objectFit:'cover'}} />
      <p className="font-size-14 color-black mb-0 fw-medium">{b?.authorName?.name}</p>
      </div>
      </div>
        <h3 className="font-size-16 fw-semibold mb-1" >
        <a
        className="color-black"
        href={`/${b?.slug}`}>
        {b?.title?.rendered}
        </a></h3>
        <p className="font-size-14 color-black mb-0" dangerouslySetInnerHTML={{ __html: b.excerpt?.rendered.substring(0, 110) + '...' }}/>
        <div className="d-flex align-items-center justify-content-between" style={{marginTop:'-10px'}}>
        <p className="font-size-14 color-black mb-0">{moment(b?.date).format("D MMMM YYYY")}</p>
        {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
        </div>
        
      </div>
        </div>
        ))}         
    </div>
  </div>
</section>

<section className="home-section-6 py-5 explore-locations">
  <div className="container">
    <h2 className="font-size-30 dm-font color-black mb-4">{t('ExploreOurLocations')}</h2>
    <div className="explore-locations-rel">
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={15}
      // slidesPerView={3}
      navigation
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 6,
        },
      }}>
      {load ? (
  <div className="row">
    {new Array(6).fill(null).map((_, index) => (
      <LocationCardSkeleton key={index} />
    ))}
  </div>
) : 
  cities?.length > 0 && cities.map((c) => (
    c.cities.map((ci) => (
      <SwiperSlide key={ci.id}>
        <a href={`/suppliers/${ci.slug}`}>
          <div className="location-card">
            <img src={`${process.env.REACT_APP_IMGURL}${ci.imageName}`} className="location-img" />
            <div className="location-text">
              <p className="font-size-20 color-white mb-0 fw-semibold">{ci.name}</p>
            </div>
          </div>
        </a>
      </SwiperSlide>
    ))
  ))
}


      </Swiper>
    </div>
</div>
</section>

<section className="home-section-7 py-5 grow-your-bussiness">
  <div className="container">
    <div className="row justify-content-end">
      <div className="col-lg-5 col-md-6">
        <h2 className="font-size-46 dm-font color-black mb-3">{t('CulturalSpecificRecommendations')}</h2>
        <p className="font-size-16 color-black mb-2 mb-4">With features like custom vendor selection, culturally-specific recommendations, and an intuitive interface, planning your dream wedding becomes not just easy but also a delightful journey. Shadi Tayari is more than just an app; it's your dedicated partner in celebrating love and tradition, uniting cutting-edge technology with the rich wedding customs of Pakistan. Join us in transforming the way weddings are planned in this beautiful and vibrant region.</p>
            <a href="/login" className="btn wf-btn-icon wf-gradient-btn text-uppercase w-75"> {t('JoinfreeNow')} <i className={`bi ${currentLanguage === 'ar' ? 'bi-arrow-left-short' : 'bi-arrow-right-short'}  font-size-24 d-flex`}></i></a>
      </div>
    </div>
  </div>
</section>

<section className="home-section-8 py-5">
  <div className="container">
    <h2 className="font-size-30 dm-font color-black mb-4">{t('SearchByCategoryToFindYourWeddingTeam')}</h2>
    <p className="font-size-20 color-black mb-2 fw-semibold">{t('WeddingVenues')}</p>
    <p className="font-size-16 color-black">
    {load ? (
  <div className="d-flex gap-2">
    {new Array(10).fill(null).map((_, index) => (
      <div className="shimmer text-shimmer rounded-1" style={{width:'107px', height:'19px'}} key={index}></div>
    ))}
  </div>
) :subcategories&&subcategories?.map((s,i)=>(<a href={`/suppliers/${s.slug}`} className="color-black">{s.name}  {i<subcategories?.length-1&&'-'} </a>))}
    </p>
    <br />
    <p className="font-size-20 color-black mb-2 fw-semibold">{t('WeddingSuppliers')}</p>
    <p className="font-size-16 color-black">
    {load ? (
  <div className="d-flex gap-2 flex-wrap">
    {new Array(22).fill(null).map((_, index) => (
      <div className="shimmer text-shimmer rounded-1" style={{width:'107px',height:'19px'}} key={index}></div>
    ))}
  </div>
) :categories&&categories?.map((s,i)=>(<a href={`/suppliers/${s.slug}`} className="color-black">{s.name}  {i<categories?.length-1&&'-'} </a>))}
    </p>
  </div>
</section>

<section className="home-section-9 py-5 ">
  <div className="container">
    <h2 className="font-size-30 dm-font color-black mb-4">{t('WeddingVenuesByLocation')}</h2>
    <div class="row">
    {load ? ( new Array(4).fill(null).map((_, index) => (
  <div className="col-lg-3 col-md-6" key={index}>
    <div className="shimmer text-shimmer rounded-1 mb-2" style={{width:'150px',height:'39px'}}></div>
    {new Array(10).fill(null).map((_, i) => (
      <div className="shimmer text-shimmer rounded-1 my-2" style={{width:'210px',height:'18px'}} key={i}></div>
    ))}
  </div>
  ))
) :top6cities&&top6cities?.slice(0,4)?.map((c)=>(

      <div class="col-lg-3 col-md-6">
      <ul className="categories-location-links">
        <li className="font-size-22 color-black fw-semibold">{c.name}</li>
        {subcategories&&subcategories?.slice()?.map((cat)=>(<li 
        className="font-size-14 color-black" style={{"lineHeight":"14px"}}>
          <a href={`/suppliers/${cat.slug}-in-${c.slug}`}
          className="color-black">{cat.name} in {c.name}</a>
          </li> ))}
      </ul>
    </div>))} 
</div>
</div>
</section>

<section className="home-section-9 py-5 ">
  <div className="container">
    <h2 className="font-size-30 dm-font color-black mb-4">{t('WeddingSuppliersByLocation')}</h2>
    <div class="row">
    {load ? ( new Array(4).fill(null).map((_, index) => (
  <div className="col-lg-3 col-md-6" key={index}>
    <div className="shimmer text-shimmer rounded-1 mb-2" style={{width:'150px',height:'39px'}}></div>
    {new Array(22).fill(null).map((_, i) => (
      <div className="shimmer text-shimmer rounded-1 my-2" style={{width:'210px',height:'18px'}} key={i}></div>
    ))}
  </div>
  ))
) :top6cities&&top6cities?.slice(0,4)?.map((c)=>(

      <div class="col-lg-3 col-md-6">
      <ul className="categories-location-links">
        <li className="font-size-22 color-black fw-semibold">{c.name}</li>
        {categories&&categories?.slice()?.map((cat)=>(<li 
        className="font-size-14 color-black" style={{"lineHeight":"14px"}}>
          <a href={`/suppliers/${cat.slug}-in-${c.slug}`}
          className="color-black">{cat.name} in {c.name}</a>
          </li> ))}
      </ul>
    </div>))} 
</div>
</div>
</section>





<Footer />
</>
  )
}

export default Home