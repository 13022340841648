import React, { useState, useEffect } from 'react';

const Flag = ({ style }) => (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg"
      alt="Pakistan Flag"
      style={style}
      className="flag"
    />
  );

const ConfettiFlags = () => {
//   const [flags, setFlags] = useState([]);

//   useEffect(() => {
//     const numFlags = 100; // Number of flags
//     const newFlags = Array.from({ length: numFlags }, (_, i) => ({
//       id: i,
//       style: {
//         left: `${Math.random() * 100}%`,
//         animationDelay: `${Math.random() * 5}s`,
//         animationDuration: `${Math.random() * 3 + 2}s`,
//       },
//     }));
//     setFlags(newFlags);
//   }, []);

    const [flags, setFlags] = useState([]);
    const [showFlags, setShowFlags] = useState(false);

    // useEffect(() => {
    //     const numFlags = 100;
    //     const newFlags = Array.from({ length: numFlags }, (_, i) => ({
    //       id: i,
    //       style: {
    //         left: `${Math.random() * 100}%`,
    //         animationDelay: `${Math.random() * 5}s`,
    //         animationDuration: `${Math.random() * 3 + 2}s`,
    //       },
    //     }));
    //     setFlags(newFlags);
    
    //     // const showTimer = setTimeout(() => {
    //       setShowFlags(true);
    //     // }, 3000);
    
    //     const hideTimer = setTimeout(() => {
    //       setShowFlags(false);
    //     }, 11000);
    
    //     return () => {
    //     //   clearTimeout(showTimer);
    //       clearTimeout(hideTimer);
    //     };
    // }, []);

    // useEffect(() => {
    //     const numFlags = 100; // Number of flags
    //     const newFlags = Array.from({ length: numFlags }, (_, i) => ({
    //       id: i,
    //       style: {
    //         '--x': `${Math.random() * 2 - 1}`,
    //         '--y': `${Math.random() + 0.5}`,
    //         animationDelay: `${Math.random() * 2}s`,
    //       },
    //     }));
    //     setFlags(newFlags);

    //     const showTimer = setTimeout(() => {
    //       setShowFlags(true);
    //     }, 1000);
    
    //     const hideTimer = setTimeout(() => {
    //       setShowFlags(false);
    //     }, 8000);

    //     return () => {
    //       clearTimeout(showTimer);
    //       clearTimeout(hideTimer);
    //     };
    //   }, []);

    useEffect(() => {
        const numFlags = 100;
        const newFlags = Array.from({ length: numFlags }, (_, i) => ({
          id: i,
          style: {
            '--x': `${Math.random() * 2 - 1}`,
            '--y': `${Math.random()}`,
            animationDelay: `${Math.random() * 2}s`,
          },
        }));
        // const newFlags = Array.from({ length: numFlags }, (_, i) => ({
        //     id: i,
        //     style: {
        //       '--x': `${Math.random() * 2 - 1}`, // Random X direction
        //       '--y': `${Math.random() * 2 - 1}`, // Random Y direction
        //       animationDelay: `${Math.random() * 2}s`, // Random animation delay
        //     },
        // }));
        // const newFlags = Array.from({ length: numFlags }, (_, i) => ({
        //     id: i,
        //     style: {
        //       '--x': `${Math.random() * 2 - 1}`, // Random X direction
        //       '--y': `${Math.random() * 2 - 1}`, // Random Y direction
        //       animationDelay: `${Math.random() * 1.5}s`, // Random animation delay
        //     },
        // }));
        setFlags(newFlags);

        // const showTimer = setTimeout(() => {
          setShowFlags(true);
        // }, 1000);
    
        const hideTimer = setTimeout(() => {
          setShowFlags(false);
        }, 8000);
    
        return () => {
        //   clearTimeout(showTimer);
          clearTimeout(hideTimer);
        };
    }, []);

  return (
    <div>
      {showFlags && flags.map(flag => (
        <Flag key={flag.id} style={flag.style} />
      ))}
    </div>
  );
};

export default ConfettiFlags;
