import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getEventList,
  getProfile,
  getAllBookings,
  addManualBookings
} from "../../store/slices/userSlice";
import axios from "axios";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { Briefcase, CalendarCheck2, CheckCircle, LayoutList, ListFilter, Loader, Search } from "lucide-react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ArrowUpDown } from "lucide-react";
import { MessageSquare } from "lucide-react";
import { List } from "lucide-react";
import { PieChart } from 'react-minimal-pie-chart';
import BookingsContent from "./BookingsContent";

const customStyles = {
  content: {
    display: "block",
  },

};
Modal.setAppElement("#root");


const Bookings = () => {



  return (
    <>

      <section className="wf-user-booking wf-background px-md-5 pb-5 pt-2">
        <div className="container-fluid">
          <BookingsContent />
        </div>
      </section>
    </>
  );
};

export default Bookings;
