import { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import i18n from '../../components/i18n';
import AdSense from './AdSense';
import QRCode from 'qrcode.react';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = new Date();

  return (
    <>
     <footer className='border-top-light-color px-2'>
       <div className="container">
        <div className="row gx-md-5 footer-top mb-0 justify-content-md-between gy-4">
        <div className="col-lg-3 col-md-6">
        <img className="footer-logo mb-3" src={`${process.env.REACT_APP_FrontURL}/assets/images/logo-white.svg`} alt="ShadiTayari" width='280px' />
        <p className="font-size-12 color-white">Getting married? Don’t know where to start from? Then you are at the right place! At Shadi Tayari we have the complete database of all the wedding suppliers in Pakistan. Browse through our categories and choose your favourite wedding designer, venue, photographer, salon and many more - to create your magical wedding!</p>
         <div className="d-flex gap-3 align-items-center font-size-24 wf-social-media-links">
          <Link to="https://www.facebook.com/shaditayari/"><i class="bi bi-facebook"></i></Link>
          <Link to="https://x.com/ShadiTayari"><i class="bi bi-twitter-x"></i></Link>
          <Link to="https://www.instagram.com/shaditayari/"><i class="bi bi-instagram"></i></Link>
          <Link to="https://www.youtube.com/@ShadiTayariPk"><i class="bi bi-youtube"></i></Link>
          <Link to="https://www.linkedin.com/company/shadi-tayari-pk/"><i class="bi bi-linkedin"></i></Link>
         </div>
         </div>
        <div className="col-lg-2 col-md-6">
          <h3 className="font-size-20 dm-font color-white">{t('UsefulLinks')}</h3>
        <ul className="footer-links">
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/about`} className="color-white">{t('AboutUs')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/contact`} className="color-white">{t('ContactUs')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/suppliers/venue`} className="color-white">{t('Venues')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/suppliers`} className="color-white">{t('WeddingSuppliers')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/blog`} className="color-white">{t('Ideas&Inspirations')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/marketing`} className="color-white">{t('Marketing')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/privacy`} className="color-white">{t('PrivacyPolicy')}</Link></li>
           <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/support`} className="color-white">{t('Support')}</Link></li>
           {/* <li className="font-size-14 color-white"><Link to={`${process.env.REACT_APP_FrontURL}/ideas-inspiration`} className="color-white">{t('CookiePolicy')}</Link></li> */}
         </ul>
        </div>
        <div className="col-lg-3 col-md-6">
        <h3 className="font-size-20 dm-font color-white">Download Shadi Tayari Mobile App</h3>
        <p className="font-size-12 color-white">Join us in embracing the future of wedding planning with Shadi Tayari, where your perfect day starts.</p>
        <div class="d-flex gap-2">
          <div >
          <Link to="https://apps.apple.com/pk/app/shadi-tayari/id6504075843"><img className="store-app mb-2 w-100" src="../assets/images/app-store-img.svg" alt="google apple store" /></Link>
          <Link to="https://play.google.com/store/apps/details?id=com.shaditayari.app"><img className="store-app w-100" src="../assets/images/play-store-img.svg" alt="google apple store" /></Link>
          </div>
          {/* <img className="qr-code" height="110" src="../assets/images/ShadiTayari-qr-code.svg" alt="ShadiTayari qr code" /> */}
          <QRCode 
          // value={`${process.env.REACT_APP_FrontURL}/download`} 
          value="https://www.shaditayari.pk/download"
          renderAs="canvas"
          bgColor="#222222"
          fgColor="#ffffff"
          size={110} />
        </div>
        
        </div>
        <div className="col-lg-3 col-md-6">
          <AdSense />
        </div>
        <div className="col-md-12 pt-5">
        <p className="font-size-14 color-white">{`© ${date.getFullYear()} `}<Link to={process.env.REACT_APP_FrontURL} className="color-white">Shadi Tayari</Link>. {t('Allrightsreserved')}.</p>
        </div>
        </div>
       </div>
     </footer>
     </>
  )
}

export default Footer