import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './HeaderLight'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import toast from 'react-hot-toast'
import { getCitiesWithCountry } from '../../store/slices/userSlice'
import { useDispatch } from 'react-redux'

const RealWeddingsRegister = () => {
    const dispatch = useDispatch();

    const [sendEmails, setsendEmails] = useState(["hiba@weddified.com", "hello@weddifed.com", "ibad@digitalbee.studio"]);
    // const [sendEmails, setsendEmails] = useState(["muaazkasbati@gmail.com"]);
    const [cities, setcities] = useState(null);
    const [message, setmessage] = useState("");
    const [coverageConsent, setcoverageConsent] = useState(false);
    const [agreeTermsCondition, setagreeTermsCondition] = useState(false);
    const [formData, setFormData] = useState({
        bridesName: '',
        groomsName: '',
        email: '',
        phoneNumber: '',
        weddingDate: '',
        weddingLocation: '',
        weddingPlans: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const getCity = async () => {
        try {
            setcities(null)
            const response = await dispatch(getCitiesWithCountry("en")).unwrap();
            setcities(response?.data);
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    useEffect(() => {
        getCity();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                ...formData,
                coverageConsent: coverageConsent,
                agreeTermsCondition: agreeTermsCondition,
                receivers: sendEmails
            }
            console.log(payload, "payload");
            const response = await axios.post(`https://weddified-stagging-f1d398d9e1fe.herokuapp.com/user/realWeddingsRegister`, payload);
            if(response){
                setmessage(response?.data?.message)
                setcoverageConsent(false);
                setagreeTermsCondition(false)
                setFormData({
                    bridesName: '',
                    groomsName: '',
                    email: '',
                    phoneNumber: '',
                    weddingDate: '',
                    weddingLocation: '',
                    weddingPlans: ''
                })
            }
        } catch (rejectedValueOrSerializedError) {
          toast.error(rejectedValueOrSerializedError.response?.data?.message);
        }
    };

  return (
    <>
    <Helmet>
        <title>Register - Real Weddings - Shadi Tayari</title>
        <meta name="description" content="Explore the comprehensive FAQ section of ShadiTayari, the leading wedding planning app. Find answers to common questions about our app's features, services, and wedding planning tips."/>
        <meta name="keywords" content="ShadiTayari, wedding planning"/>
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section py-md-5">
      <div className="container">
        <div className="row mb-5 gy-3">
            <div className="col-lg-7 col-md-6 order-md-1 order-2">
                <h1 className="font-size-50 fw-semibold purple-color">Celebrate Your Love Story with <span className="pink-color">Shadi Tayari</span></h1>
                <p className="font-size-18 mb-5">Are you planning your dream wedding? Shadi Tayari is excited to offer you a unique opportunity to feature your special day in our <b>Real Weddings</b> section. Let our professional media team capture the highlights of your wedding – completely free of cost! Your wedding story will inspire thousands of other couples as they plan their own perfect day.</p>
                <div><img className="img-fluid" src="../assets/images/real-weddings-img-register-wf.png" alt="" /></div>
            </div>
            <div className="col-lg-5 col-md-6 order-md-2 order-1">
                <div className="gradient-bg p-4 rounded-3">
                    <h3 className='font-size-30 color-white fw-semibold'>Feature Your Wedding in Our Real Weddings Section</h3>
                    <p className="font-size-18 color-white">Simply fill out the submission form with your wedding details. If selected, our team will contact you to discuss the next steps. It’s easy, straightforward, and a fantastic opportunity to share your wedding day with the world.</p>
                    {!message ? 
                    <form onSubmit={handleSubmit}>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                        <input type="text" class="form-control" id="bridesName" placeholder="Bride's Full Name" autocomplete="name" value={formData.bridesName} onChange={handleChange} required />
                        <label for="bridesName">Bride's Full Name</label>
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                        <input type="text" class="form-control" id="groomsName" placeholder="Groom's Full Name" autocomplete="name" value={formData.groomsName} onChange={handleChange} required />
                        <label for="groomsName">Groom's Full Name</label>
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                        <input type="email" class="form-control" id="email" placeholder="Contact Email Address" autocomplete="email" value={formData.email} onChange={handleChange} required />
                        <label for="email">Contact Email Address</label>
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                        <input type="tel" class="form-control" id="phoneNumber" placeholder="Contact Phone Number" autocomplete="phone" value={formData.phoneNumber} onChange={handleChange} required />
                        <label for="phoneNumber">Contact Phone Number</label>
                    </div>
                    <div class="form-floating wf-input wf-input-101 mb-3">
                        <input type="date" class="form-control" id="weddingDate" placeholder="Wedding Date" autocomplete="date" value={formData.weddingDate} onChange={handleChange} required />
                        <label for="weddingDate">Wedding Date</label>
                    </div>
                    <div class="form-floating mb-3 wf-select wf-select-101">
                        <select class="form-select" id="weddingLocation" aria-label="Wedding Location" value={formData.weddingLocation} onChange={handleChange} required>
                        <option selected>Select Location</option>
                        {cities?.[0]?.cities?.map((data, i) => 
                        <option key={i} value={data?.name}>{data?.name}</option>
                        )}
                        </select>
                        <label className="purple-color" for="weddingLocation">Wedding Location</label>
                    </div>
                    <div class="form-floating mb-3 wf-textarea wf-textarea-101">
                        <textarea class="form-control" placeholder="Tell Us About Your Wedding Plans" id="weddingPlans" value={formData.weddingPlans} onChange={handleChange} style={{"height": "150px"}}></textarea>
                        <label for="weddingPlans">Tell Us About Your Wedding Plans</label>
                    </div>
                    <p className="font-size-18 color-white fw-semibold mb-1">Consent to Coverage & Content Use</p>
                    <div class="form-check wf-signup-checkbox mb-3">
                    <input class="form-check-input wf-shadow-2" type="checkbox" value="" id="consent-coverage-content-use" onChange={()=> setcoverageConsent(!coverageConsent)} checked={coverageConsent} required />
                    <label class="form-check-label color-white" for="consent-coverage-content-use">I agree to allow Shadi Tayari to cover my wedding and use the content on their website, app, and social media platforms</label>
                    </div>
                    <p className="font-size-18 color-white fw-semibold mb-1">Agree to Terms and Conditions</p>
                    <div class="form-check wf-signup-checkbox mb-3">
                    <input class="form-check-input wf-shadow-2" type="checkbox" value="" id="consent-coverage-content-use" onChange={()=> setagreeTermsCondition(!agreeTermsCondition)} checked={agreeTermsCondition} required />
                    <label class="form-check-label color-white" for="consent-coverage-content-use">I have read and agree to the <a href="" className="color-white">terms and conditions</a>.</label>
                    </div>
                    <button type="submit" className="btn wf-btn wf-purple-btn rounded-2 wf-shadow-2 w-100">Submit Your Wedding</button>
                    </form> : <div className='py-4'>
                    <h3 className='font-size-30 color-white fw-semibold text-center'>Thanks For Submitting</h3>
                    <p className="font-size-18 color-white text-center">The team will contact you in 1 to 2 working days.</p>
                        </div>}
                </div>
            </div>
        </div>
        <h2 className="font-szie-36 purple-color fw-semibold mb-5 text-center pt-5">Why Feature Your Wedding on Shadi Tayari?</h2>
        <div className="row gx-5 gy-3 mb-5">
            <div className="col-md-4 text-center">
                <img src="../assets/images/st-icon-Professional-Coverage-at-No-Cost.svg" className="mb-3" alt="" />
                <p className="font-size-18 fw-bold mb-2">Professional Coverage at No Cost</p>
                <p className="font-size-18 mb-0">Our media team will cover the key moments of your wedding day, providing you with beautiful, high-quality photos and videos – all for free</p>
            </div>
            <div className="col-md-4 text-center">
                <img src="../assets/images/st-icon-Inspire-Future-Brides-Grooms.svg" className="mb-3" alt="" />
                <p className="font-size-18 fw-bold mb-2">Inspire Future Brides and Grooms</p>
                <p className="font-size-18 mb-0">Share your unique wedding experience with our community and help inspire other couples who are planning their weddings.</p>
            </div>
            <div className="col-md-4 text-center">
                <img src="../assets/images/st-icon-Be-Part-Something-Special.svg" className="mb-3" alt="" />
                <p className="font-size-18 fw-bold mb-2">Be a Part of Something Special</p>
                <p className="font-size-18 mb-0">Join the Shadi Tayari family and let your love story be showcased on our platform, reaching thousands of viewers.</p>
            </div>
        </div>
        <h2 className="font-szie-36 pink-color fw-semibold mb-2 text-center pt-5">Join the Shadi Tayari Family</h2>
        <p className="font-size-18 text-center">At Shadi Tayari, we believe every wedding is a beautiful story waiting to be told. We’re thrilled to help you share yours.</p>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default RealWeddingsRegister