import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import CalendarData from './CalendarData'
import 'react-calendar/dist/Calendar.css';

const SmallCalendar = ({ selectedDate, onDateChange }) => {
  // const [date, setDate] = useState(new Date());
  const filteredEvents = CalendarData()



  // const onChange = (date) => {
  //   setDate(date);
  // };


  console.log('filteredEvents', CalendarData())


  return (
    <>
      <div className="calendar-container">
        <Calendar
          onChange={onDateChange}
          value={selectedDate}
          tileContent={({ date, view }) => {

            if (view === 'month') {
              const taskEvents = filteredEvents?.filter((event) => event.start.toDateString() === date.toDateString() && event.type === 'Task');
              const eventEvents = filteredEvents?.filter((event) => event.start.toDateString() === date.toDateString() && event.type === 'Event');

              if (taskEvents?.length > 0 || eventEvents?.length > 0) {
                return (
                  <div className="event-markers">
                    {taskEvents?.length > 0 && <div className="event-marker task"></div>}
                    {eventEvents?.length > 0 && <div className="event-marker event"></div>}
                  </div>
                );
              }
            }
            return null; // Always return null for dates with no events
          }}
        // tileContent={({ date, view }) => {
        // if (view === 'month') {
        //   const dayEvents = filteredEvents.filter((event) => event.start.toDateString() === date.toDateString()); 
        //   if (dayEvents.length > 0) {
        //     return (
        //       <div className="event-markers">
        //         {dayEvents.map((event, index) => (
        //           <div key={index} className={`event-marker ${event.type}`}></div>
        //         ))}
        //       </div>
        //     );
        //   }
        // }
        />
      </div>
    </>
  );
};

export default SmallCalendar;
