import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "datatables.net-dt/css/jquery.dataTables.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { CalendarDays, ListFilter } from "lucide-react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import "datatables.net";
import Modal from "react-modal";
import "react-day-picker/dist/style.css";
import _ from "lodash";

import {
  getProfile,
  getUserEventsTasks,
  getEventList,
  addTask,
  editTask,
  deleteTask,
  getUserEventList,
} from "../../store/slices/userSlice";
import axios from "axios"
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import ListSkeleton from "../LoadingSkeleton/ListSkeleton";
import { useTranslation } from "react-i18next";
import NoEventUI from "./noEventUI";
const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const TaskContent = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile)
  const myEvents = useSelector(getEventList);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple") ? JSON.parse(queryParams.get("couple")) : []);
  const [selectedCouple, setselectedCouple] = useState("");
  const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const isDashboard = location.pathname !== "/task-management"

  const [updated, setupdated] = useState(true);



  const EventList = myEvents?.map((ev) => {
    return {
      couple_id: ev?._id,
      id: ev?.Event?._id,
      name: ev?.Event?.event_name,
      color: ev?.Event?.event_color,
      date: ev?.Event?.event_date,
    };
  });

  const currentDate = new Date().toISOString().split("T")[0];
  const [filtertoggle, setfiltertoggle] = useState(true);
  const [toggle, settoggle] = useState([0, 1, 2, 3, 4, 5, 6, 7]);
  const [toggle1, settoggle1] = useState([]);
  const [toggle2, settoggle2] = useState(true);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [tasks, settasks] = useState([]);

  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  const [show, setshow] = useState(false);
  const [modalType, setModalType] = useState();
  const [userDetail, setUserDetail] = useState(null);
  const [feature, setFeature] = useState("");
  const [id, setId] = useState();

  const [name, setname] = useState("");
  const [assignedTo, setassignedTo] = useState("");
  const [status, setstatus] = useState("");
  const [duedate, setduedate] = useState("");
  const [eventid, seteventid] = useState("");
  const [categoryid, setcategoryid] = useState("");
  const [subtask, setsubtask] = useState([
    { name: "", date: "", completed: 0, completedDate: "" },
  ]);

  //filter states
  const [actualmyEvents, setactualmyEvents] = useState([]);
  const [event, setevent] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const [category, setcategory] = useState([]);
  const [duration, setduration] = useState("");
  const [filteredData, setfilteredData] = useState();
  const [indexOfCompleted, setindexOfCompleted] = useState();

  const handleToggleChange = (id) => {
    console.log("idddddddddddddddd", id);
    if (!toggle?.includes(id)) {
      settoggle([...toggle, id]);
    } else {
      const updatedEventIds = toggle.filter((itemId) => itemId !== id);
      settoggle(updatedEventIds);
    }
  };

  const getCategories = async () => {
    try {
      setload(true);
      const response = await axios.get(`user/getservices`);
      console.log("responsexx", response);
      setAllCategories(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) { }
  };

  const getAllTasks = async () => {
    try {

      console.log('myEvents', myEvents)
      setload(true);
      settasks(null);

      const eventIdss = []

      for (const events of myEvents) {
        if (events.Event && events.Event._id) {
          eventIdss.push(events.Event._id);
        }
      }

      const response = await dispatch(
        getUserEventsTasks({ event_ids: eventIdss })
      ).unwrap();
      console.log('response', response)
      if (response) {
        settasks(response?.data);
        setfilteredData(response?.data);
        setload(false);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (updated) {
      handleFilters();
    }
  }, [tasks]);

  const handleSetData = (e) => {

    const mergedEvents = [];


    e?.forEach(event => {
      const modifiedEvent = {
        ...event,
        Event: [event.Event]
      };

      const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
      );

      if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
      } else {
        mergedEvents.push(modifiedEvent);
      }
    });

    setactualmyEvents(mergedEvents)
    // setfilteredData(mergedEvents)
  }


  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllTasks();
    }
    return () => {
      mount = false;
    };
  }, [myEvents]);


  useEffect(() => {
    handleSetData(myEvents)
  }, [myEvents]);

  useEffect(() => {
    getCategories()
  }, []);



  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    setModalType(type);


    if (type == "userDetail" && feature == "add") {
      setUserDetail("");
      setId("");
      setselectedCouple("")
      setname("");
      setassignedTo("");
      setstatus("");
      setduedate("");
      seteventid("");
      setevent([]);
      setcategoryid("");
      setsubtask([
        // { name: "", date: "", completed: 0, completedDate: ""}
      ]);
      setFeature(feature);
    }
    if (type == "userDetail" && feature == "edit") {
      setUserDetail(item);
      setselectedCouple(item?.user_id)
      setname(item?.name);
      setassignedTo(item?.assignedTo?._id);
      setstatus(item?.status);
      setduedate(item?.date);
      seteventid(item?.event_id._id);
      setcategoryid(item?.category_id._id);
      setsubtask(item?.subtasks);
      setId(item?._id);
      setFeature(feature);
    }
    if (type == "filter" && feature == "filter") {
      setFeature(feature);
    }
    if (type == "delete") {
      setId(item);
      setFeature(feature);
    }
  };

  console.log("feature", feature);

  function closeModal() {
    setFeature("");
    setIsOpen(false);
  }

  console.log('profile', profile)

  const handleChange = async (e) => {
    console.log("eeeeeeeee", e);
    if (!toggle1.includes(e)) {
      settoggle1([...toggle1, e]);
    } else {
      settoggle1(toggle1.filter((service) => service !== e));
    }
  };

  const accountDelete = async (id) => {
    try {
      setload1(true);
      await dispatch(deleteTask(id)).unwrap();
      setIsOpen(false);
      // $("#tableData").DataTable().destroy();
      try {
        getAllTasks();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
      setload1(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const addMoresubtask = () => {
    setsubtask([
      ...subtask,
      { name: "", date: "", completed: 0, completedDate: "" },
    ]);
  };

  console.log("subtask", subtask);
  console.log("eventid", eventid);
  console.log("currentDate", currentDate);

  const saveBranch = async (t, updatedsubtask) => {
    try {
      if (myEvents?.length < 1) {
        toast.error("You don't have any event to proceed");
        return;
      }

      setload1(true);

      if (feature !== "edit" && feature !== "delete" && feature !== "add") {
        console.log('iff', t);

        await dispatch(
          editTask({
            _id: t?._id,
            event_id: t?.event_id?._id,
            name: t?.name,
            assignedTo: assignedTo,
            date: t?.date,
            status: t?.status,
            subtasks: updatedsubtask?.length > 0 ? JSON.stringify(updatedsubtask) : JSON.stringify([]),
            category_id: t?.category_id?._id,
          })
        ).unwrap();
        setupdated(coupleURLFilter ? true : false)
        await dispatch(getUserEventList()).unwrap();
        await getAllTasks();
        if (location.pathname !== "/task-management") {
          await dispatch(getUserEventList()).unwrap();
        }

      } else {
        if (feature === "add" && !eventid) {
          toast.error("Please select an event");
        } else if (feature === "add" && !name) {
          toast.error("Task name is required");
        } else if (!categoryid) {
          toast.error("Please select a category");
        } else if (subtask?.length > 0 && subtask[0]?.name === "") {
          toast.error("Subtask name is required");
        } else if (subtask?.length > 0 && subtask[0]?.date === "") {
          toast.error("Subtask due date is required");
        } else {
          if (feature === "edit") {
            await dispatch(
              editTask({
                _id: id,
                event_id: eventid,
                name: name,
                date: duedate,
                assignedTo: assignedTo,
                subtasks: subtask[0]?.name !== "" && subtask[0]?.date !== "" ? JSON.stringify(subtask) : [],
                category_id: categoryid,
              })
            ).unwrap();

            closeModal();
            setupdated(coupleURLFilter ? true : false)
            await dispatch(getUserEventList()).unwrap();
            await getAllTasks();
            if (location.pathname !== "/task-management") {
              await dispatch(getUserEventList()).unwrap();
            }
          } else {
            console.log('yes')
            await dispatch(
              addTask({
                event_id: eventid,
                user_id: selectedCouple ? selectedCouple : coupleURLFilter,
                name: name,
                date: duedate,
                assignedTo: assignedTo,
                subtasks: subtask[0]?.name !== "" && subtask[0]?.date !== "" ? JSON.stringify(subtask) : [],
                category_id: categoryid,
              })
            ).unwrap();

            closeModal();
            seteventid("")
            setevent("")
            setupdated(profile?.role == "planner" && coupleURLFilter ? true : false)
            await dispatch(getUserEventList()).unwrap();
            await getAllTasks();
          }
        }
      }

      setload1(false);

    } catch (rejectedValueOrSerializedError) {
      setload1(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('selectedCouple', selectedCouple)

  const due_date = [
    {
      id: 1,
      option: "Due today",
    },
    {
      id: 2,
      option: "Overdue",
    },
    {
      id: 3,
      option: "Due in 1-3 Weeks",
    },
    {
      id: 4,
      option: "Due in 1-3 Months",
    },
    {
      id: 5,
      option: "Due in 4-6 Months",
    },
    {
      id: 6,
      option: "Due in over 6 Months",
    },
    {
      id: 7,
      option: "Completed",
    },
  ];

  const durationToOptionMap = {
    "overdue": "Overdue",
    "completed": "Completed",
    "dueToday": "Due today",
    "due1To3Weeks": "Due in 1-3 Weeks",
    "due1To3Months": "Due in 1-3 Months",
    "due4To6Months": "Due in 4-6 Months",
    "dueOver6Months": "Due in over 6 Months"
  };

  const due_date1 = [
    {
      id: 1,
      option: "dueToday",
    },
    {
      id: 2,
      option: "overdue",
    },
    {
      id: 3,
      option: "due1To3Weeks",
    },
    {
      id: 4,
      option: "due1To3Months",
    },
    {
      id: 5,
      option: "due4To6Months",
    },
    {
      id: 6,
      option: "dueOver6Months",
    },
    {
      id: 7,
      option: "completed",
    },
  ];

  console.log("filteredData", filteredData);
  console.log("duration", duration);
  console.log("actualmyEvents", actualmyEvents);

  function formatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function handleDateChange(e) {
    const inputDate = e.target.value;
    const currentDate = formatDate();

    if (inputDate < currentDate) {
      e.target.value = currentDate;
    }
  }


  const handleFilters = () => {

    let updatedFilteredData = {};

    console.log('rightttt', coupleURLFilter)

    for (let key in tasks) {
      if (Array.isArray(tasks[key])) {
        updatedFilteredData[key] = tasks[key].filter((task) => {
          const eventMatches =
            event == "" || event.includes(task?.event_id?._id);
          const categoryMatches =
            category == "" || category.includes(task?.category_id?._id);
          const coupleMatches = coupleURLFilter == "" || coupleURLFilter?.includes(task.user_id);
          const coupleFilterMatches = selectedCouple == "" || selectedCouple == task.user_id;
          console.log('coupleMatches', coupleMatches)
          return eventMatches && categoryMatches && coupleMatches && coupleFilterMatches;
        });
      } else {
        updatedFilteredData[key] = tasks[key];
      }
    }

    console.log('updatedFilteredData', updatedFilteredData)

    if (duration !== "") {
      const filteredResult = {
        [duration]: updatedFilteredData[duration],
      };
      setfilteredData(filteredResult);
    }

    if (updatedFilteredData) {
      setfilteredData(updatedFilteredData);
      setindexOfCompleted(due_date1.findIndex((item) => item.option == duration));
    }
  };


  useEffect(() => {
    handleFilters();
  }, [filtertoggle]);




  useEffect(() => {

    const newCoupleURLFilter = queryParams.get("couple") || "";

    console.log('newCoupleURLFilter', newCoupleURLFilter)

    setcoupleURLFilter(newCoupleURLFilter);

    const newcoupleEventURLFilter = queryParams.get("events") || [];

    setevent(newcoupleEventURLFilter)

    setfiltertoggle(!filtertoggle)
  }, [location.search]);

  const handleEventChange = (id) => {
    console.log("idddddddddddddddd", id);
    if (!event.includes(id)) {
      setevent([...event, id]);
    } else {
      const updatedEventIds = event.filter((itemId) => itemId !== id);
      setevent(updatedEventIds);
    }
  };

  console.log('eventss', event)

  const handleCategoryChange = (id) => {
    if (!category.includes(id)) {
      setcategory([...category, id]);
    } else {
      const updatedcategoryIds = category.filter((itemId) => itemId !== id);
      setcategory(updatedcategoryIds);
    }
  };

  console.log("eventsssssssssssaa", eventid, categoryid);
  console.log("duedate", duedate);
  console.log("filteredData", filteredData);
  console.log("myEvents", myEvents);
  console.log('coupleURLfilter', queryParams.get("couple"))


  return (
    <div>
      {location.pathname == "/dashboard" || location.pathname == "/dashboard-planner" || location.pathname == "/couples-overview" ? (null) : (
        <>
          <div className="row mb-4">
            <div className="col-6">
              <h2 className="font-size-26 dm-font purple-color mb-0">{t("Tasks")}</h2>
            </div>
            {myEvents?.length > 0 && (<div className="col-6 d-flex justify-content-end gap-3 align-items-center">
              <button type="button" onClick={() => viewModal("", "userDetail", "add")} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">{t("Add")} {t("Task")}</button>
            </div>)}
          </div>
          <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Task"
            className={`modal fade show wf-modal tasks ${feature !== "delete" ? "" : "wf-delete-modal"
              }`}
          >
            <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
              {feature == "delete" ? (
                <div className="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <h1
                      class="font-size-22 dm-font purple-color"
                      id="staticBackdropLabel"
                    >
                      {t("Delete")} {t("Task")}
                    </h1>
                    <button
                      type="button"
                      onClick={closeModal}
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body mt-0">
                    <p className="font-size-14 color-black mb-0">
                      {t("Areyousureyouwanttodeletethis")} {t("Task")}
                    </p>
                  </div>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button
                      class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2"
                      onClick={closeModal}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                      disabled={load1}
                      onClick={() => accountDelete(id)}
                    >
                      {load1 ? (
                        <div
                          className="spinner-border wf-spinner-border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {t("Delete")} {t("Task")}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : feature == "add" || feature == "edit" ? (
                <div class="modal-content">
                  <div class="modal-header border-0">
                    <h1
                      class="font-size-22 dm-font purple-color"
                      id="staticBackdropLabel"
                    >
                      {feature == "add" ? (
                        <>
                          {t("CreateNew")} {t("Task")}
                        </>
                      ) : (
                        <>
                          {t("Edit")} {t("Task")}
                        </>
                      )}
                    </h1>
                    <button
                      type="button"
                      onClick={closeModal}
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="row gx-2">
                      <div
                        className={`${subtask?.length < 1 ? "col-md-5" : "col-md-12"
                          }`}
                      >


                        <div class="form-floating wf-input mb-2 wf-input-101">
                          <input
                            onChange={(e) => setname(e.target.value)}
                            type="text"
                            defaultValue={feature == "edit" ? name : ""}
                            class="form-control"
                            id="floatingInput"
                            placeholder={"Enter Task Name"}
                            autocomplete="name"
                            required
                          />
                          <label for="floatingInput">
                            {t("Task")} {t("Name")}
                          </label>
                        </div>
                      </div>
                      {subtask?.length < 1 && (
                        <div className="col-md-5">
                          <div class="form-floating wf-input mb-2 wf-input-101">
                            <input
                              onBlur={handleBlur}
                              defaultValue={feature == "edit" ? duedate : ""}
                              min={formatDate()}
                              onChange={(e) => setduedate(e.target.value)}
                              type="date"
                              class="form-control"
                              id="floatingInput"
                              autocomplete="date"
                            />
                            <label for="floatingInput">{t("DueDate")}</label>
                          </div>
                        </div>
                      )}


                      <div className="col-md-2 align-self-center">
                        {subtask?.length < 1 && (
                          <div class="form-check wf-signup-checkbox ">
                            <input
                              name="selectt"
                              checked={show}
                              onChange={() => setshow(!show)}
                              type="checkbox"
                              className="btn-check"
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label pink-color"
                              for="flexCheckDefault"
                            >
                              <u onClick={addMoresubtask}>
                                {t("Add")} {t("Subtask")}
                              </u>
                            </label>
                          </div>
                        )}
                      </div>

                    </div>



                    {profile?.role == "planner" && (<>
                      <p class="font-size-18 dm-font pink-color mb-2">
                        {t("Couples")}
                      </p>
                      <div className="d-flex gap-2 flex-wrap mb-4">
                        {load ? (
                          new Array(5)
                            .fill(null)
                            .map((_, index) => <ButtonSkeleton key={index} />)
                        ) : (
                          <>

                            {actualmyEvents?.map((e) => (
                              <>
                                <input
                                  checked={selectedCouple == e?._id}
                                  onChange={() => { setselectedCouple(e?._id); seteventid("") }}
                                  type="checkbox"
                                  name="event-select"
                                  id={e?._id}
                                  class="btn-check"
                                />
                                <label
                                  class="btn wf-select-btn"
                                  for={e?._id}
                                >
                                  {`${e?.person1?.name || ""} & ${e?.person2?.name || "partner"}`}
                                </label>
                              </>
                            ))}
                          </>
                        )}
                      </div></>)}


                    {profile?.role !== "planner" && (<><p class="font-size-18 dm-font pink-color mb-2">
                      {t("Events")}
                    </p>
                      <div className="d-flex gap-2 flex-wrap mb-4">
                        {load
                          ? new Array(5)
                            .fill(null)
                            .map((_, index) => <ButtonSkeleton key={index} />)
                          : EventList?.map((e) => (
                            <>
                              <input
                                checked={eventid == e?.id ? true : false}
                                onChange={() => { seteventid(e?.id); setcoupleURLFilter(e?.couple_id) }}
                                type="radio"
                                name="event-selectttt"
                                id={`${e?.id}-checked`}
                                class="btn-check"
                              />

                              <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.id}-checked`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                <span className="fw-semibold">{e.name}</span>
                                <span className="w-100" style={{ backgroundColor: ` ${e.color}`, height: '3px', marginTop: '-5px' }}></span>
                                <span className="color-grey">{e.date ? moment(e.date).format("D MMMM YYYY") : ""}</span>
                              </label>
                            </>
                          ))}
                      </div></>)}

                    {selectedCouple && profile?.role == "planner" && (
                      <>
                        <p className="font-size-18 dm-font pink-color mb-2">
                          {t("Events")}
                        </p>
                        <div className="d-flex gap-2 flex-wrap mb-4">

                          <div className="col-sm-11 d-flex gap-2 flex-wrap">
                            {load ? (
                              new Array(5).fill(null).map((_, index) => <ButtonSkeleton key={index} />)
                            ) : (
                              <>

                                {actualmyEvents?.filter((f) => f?._id === selectedCouple)[0]?.Event?.map((e) => (
                                  <>
                                    <input
                                      checked={eventid == e?._id ? true : false}
                                      onChange={() => seteventid(e?._id)}
                                      type="radio"
                                      name="event-select"
                                      id={e?._id}
                                      className="btn-check"
                                    />
                                    {e?.event_name ? (<label
                                      className="btn wf-select-btn"
                                      htmlFor={e?._id}
                                      style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                                    >
                                      {e?.event_name}
                                    </label>) : "No events to show"}
                                  </>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}



                    {eventid && profile?.role !== "planner" && (<><p class="font-size-18 dm-font pink-color mb-2">
                      {t("Assigned To")}
                    </p><div className="d-flex gap-2 flex-wrap mb-4">
                        {myEvents?.filter(item => item?.Event?._id == eventid)?.map((e) => (
                          <>

                            {e.person1 && (<><input
                              checked={assignedTo == e?.person1?._id ? true : false}
                              onChange={() => setassignedTo(e?.person1?._id)}
                              type="radio"
                              name="person1"
                              id={`${e?.person1?._id}`}
                              class="btn-check"
                            />

                              <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.person1?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                <span className="fw-semibold">{e?.person1?.name.charAt(0).toUpperCase() + e?.person1?.name.slice(1)}</span>
                                <span className="w-100" style={{ backgroundColor: ` ${e.Event.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                              </label></>)}

                            {e.person2 && (<><input
                              checked={assignedTo == e?.person2?._id ? true : false}
                              onChange={() => setassignedTo(e?.person2?._id)}
                              type="radio"
                              name="person2"
                              id={`${e?.person2?._id}`}
                              class="btn-check"
                            />

                              <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.person2?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                <span className="fw-semibold">{e?.person2?.name?.charAt(0).toUpperCase() + e?.person2?.name.slice(1)}</span>
                                <span className="w-100" style={{ backgroundColor: ` ${e?.Event?.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                              </label></>)}

                            {e.planner && (<><input
                              checked={assignedTo == e?.planner?._id ? true : false}
                              onChange={() => setassignedTo(e?.planner?._id)}
                              type="radio"
                              name="planner"
                              id={`${e?.planner?._id}`}
                              class="btn-check"
                            />

                              <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.planner?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                <span className="fw-semibold">{e?.planner?.name?.charAt(0).toUpperCase() + e?.planner?.name.slice(1)}</span>
                                <span className="w-100" style={{ backgroundColor: ` ${e.Event.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                              </label></>)}

                          </>
                        ))}
                      </div></>)}

                    {eventid && profile?.role == "planner" && (<><p class="font-size-18 dm-font pink-color mb-2">
                      {t("Assigned To")}
                    </p><div className="d-flex gap-2 flex-wrap mb-4">
                        {myEvents?.filter(item => item?.Event?._id == eventid)?.map((e) => {
                          console.log('eeee', e)
                          return (
                            <>

                              {e.person1 && (<><input
                                checked={assignedTo == e?.person1?._id ? true : false}
                                onChange={() => setassignedTo(e?.person1?._id)}
                                type="radio"
                                name="person1"
                                id={`${e?.person1?._id}`}
                                class="btn-check"
                              />

                                <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.person1?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                  <span className="fw-semibold">{e?.person1?.name.charAt(0).toUpperCase() + e?.person1?.name.slice(1)}</span>
                                  <span className="w-100" style={{ backgroundColor: ` ${e.Event.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                                </label></>)}

                              {e.person2 && (<><input
                                checked={assignedTo == e?.person2?._id ? true : false}
                                onChange={() => setassignedTo(e?.person2?._id)}
                                type="radio"
                                name="person2"
                                id={`${e?.person2?._id}`}
                                class="btn-check"
                              />

                                <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.person2?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                  <span className="fw-semibold">{e?.person2?.name?.charAt(0).toUpperCase() + e?.person2?.name.slice(1)}</span>
                                  <span className="w-100" style={{ backgroundColor: ` ${e?.Event?.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                                </label></>)}

                              {e.planner && (<><input
                                checked={assignedTo == e?.planner?._id ? true : false}
                                onChange={() => setassignedTo(e?.planner?._id)}
                                type="radio"
                                name="planner"
                                id={`${e?.planner?._id}`}
                                class="btn-check"
                              />

                                <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.planner?._id}`} style={{ "--bs-btn-active-bg": ` var(--color3)`, }}>
                                  <span className="fw-semibold">{e?.planner?.name?.charAt(0).toUpperCase() + e?.planner?.name.slice(1)}</span>
                                  <span className="w-100" style={{ backgroundColor: ` ${e.Event.event_color}`, height: '3px', marginTop: '-5px' }}></span>
                                </label></>)}

                            </>
                          )
                        })}
                      </div></>)}

                    <p class="font-size-18 dm-font pink-color mb-2">
                      {t("Categories")}
                    </p>
                    <div class="row gap-2 mb-4">
                      {load
                        ? new Array(22)
                          .fill(null)
                          .map((_, index) => (
                            <CaregoriesButtonSkeleton key={index} />
                          ))
                        : allCategories?.map((c) => (
                          <>
                            <input
                              checked={categoryid == c?._id ? true : false}
                              onChange={() => setcategoryid(c?._id)}
                              type="radio"
                              name="cat-select"
                              id={c?._id + 1}
                              class="btn-check"
                            />
                            <label
                              class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2 text-break"
                              style={{ lineHeight: "1.2" }}
                              for={c?._id + 1}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMGURL}${c.imageName}`}
                                className="mx-auto d-block color"
                                height="35"
                              />
                              <img
                                src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`}
                                className="mx-auto d-block white"
                                height="35"
                              />
                              {c.name}
                            </label>
                          </>
                        ))}
                    </div>


                    {subtask?.map((st, i) => (
                      <div className="add-subtask d-flex gap-2 align-items-center mb-2">
                        <div className="row w-100 gx-2">
                          <div className="col-md-6">
                            <div class="form-floating wf-input wf-input-101">
                              <input
                                onChange={(e) => {
                                  let updatedFAQs = [...subtask];
                                  updatedFAQs[i] = {
                                    ...updatedFAQs[i],
                                    name: e.target.value,
                                  };
                                  setsubtask(updatedFAQs);
                                }}
                                type="text"
                                defaultValue={st.name}
                                class="form-control"
                                id="floatingInput"
                                placeholder="Enter SubTask Name"
                                autocomplete="name"
                                required
                              />
                              <label for="floatingInput">{t("Subtask")}</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="form-floating wf-input wf-input-101">
                              <input
                                onChange={(e) => {
                                  let updatedFAQs = [...subtask];
                                  updatedFAQs[i] = {
                                    ...updatedFAQs[i],
                                    date: e.target.value,
                                  };
                                  setsubtask(updatedFAQs);
                                }}
                                type="date"
                                min={formatDate()}
                                defaultValue={st.date}
                                class="form-control"
                                id="floatingInput"
                                placeholder="Enter SubTask Date"
                                autocomplete="date"
                                required
                              />
                              <label for="floatingInput">{t("DueDate")}</label>
                            </div>
                          </div>
                        </div>
                        <div className="cursor-pointer col-1">
                          <p
                            onClick={() => {
                              console.log("Clicked index:", i);
                              const updatedFAQs = [...subtask];
                              updatedFAQs.splice(i, 1);
                              console.log("Updated FAQs:", updatedFAQs); //
                              setsubtask(updatedFAQs);
                            }}
                            className="font-size-12 pink-color mb-0 fw-medium"
                          >
                            <u>{t("Remove")}</u>
                          </p>
                          {i == subtask?.length - 1 &&
                            subtask[i]?.name !== "" &&
                            subtask[i]?.duedate !== "" && (
                              <p className="font-size-12 pink-color mb-0 fw-medium">
                                <u onClick={addMoresubtask}>
                                  {t("Add")} {t("More")}
                                </u>
                              </p>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div class="modal-footer border-0 justify-content-center">
                    <button
                      onClick={() => saveBranch()}
                      class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                      disabled={load1}
                    >
                      {load1 ? (
                        <div
                          className="spinner-border wf-spinner-border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {feature == "add" ? (
                            <>
                              {t("Add")} {t("Task")}
                            </>
                          ) : (
                            <>
                              {t("Edit")} {t("Task")}
                            </>
                          )}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              ) : feature == "filter" ? (
                <div className="modal-content">
                  <div class="modal-header border-0 pb-0">
                    <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Filter</h1>
                    <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body mt-0">

                    {profile?.role == "planner" && !coupleURLFilter && (<>
                      <div class="mb-4 row">
                        <label
                          for="staticEmail"
                          class="col-12 font-size-14 align-self-center col-form-label"
                        >
                          {t("Couples")}
                        </label>
                        <div class="col-12 d-flex gap-2 flex-wrap">
                          {load ? (
                            new Array(5)
                              .fill(null)
                              .map((_, index) => <ButtonSkeleton key={index} />)
                          ) : (
                            <>
                              <input
                                checked={!selectedCouple}
                                onChange={() => { setselectedCouple(""); setevent([]) }}
                                type="checkbox"
                                name="event-select"
                                id={554}
                                class="btn-check"
                              />
                              <label class="btn wf-select-btn" for={554}>
                                {t('All')}
                              </label>

                              {actualmyEvents?.map((e) => (
                                <>
                                  <input
                                    checked={selectedCouple == e?._id}
                                    onChange={() => { setselectedCouple(e?._id); setevent([]) }}
                                    type="checkbox"
                                    name="event-select"
                                    id={e?._id}
                                    class="btn-check"
                                  />
                                  <label
                                    class="btn wf-select-btn"
                                    for={e?._id}
                                  // style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                                  >
                                    {`${e?.person1?.name || ""} ${e?.person2?.name ? "&" : ""} ${e?.person2?.name || ""}`}
                                  </label>
                                </>
                              ))}
                            </>
                          )}
                        </div>
                      </div> </>)}

                    {profile?.role !== "planner" && (<><div class="mb-4 row">
                      <label
                        for="staticEmail"
                        class="col-12 font-size-14 align-self-center col-form-label"
                      >
                        {t("Events")}
                      </label>
                      <div class="col-12 d-flex gap-2 flex-wrap">
                        {load ? (
                          new Array(5)
                            .fill(null)
                            .map((_, index) => <ButtonSkeleton key={index} />)
                        ) : (
                          <>
                            <input
                              checked={event?.length < 1 ? true : false}
                              onChange={() => setevent([])}
                              type="checkbox"
                              name="event-select"
                              id={56}
                              class="btn-check"
                            />
                            <label class="btn wf-select-btn" for={56}>
                              {t('All')}
                            </label>

                            {EventList?.map((e) => (
                              <>
                                <input
                                  checked={event.includes(e.id)}
                                  onChange={() => handleEventChange(e.id)}
                                  type="checkbox"
                                  name="event-select"
                                  id={e.id}
                                  class="btn-check"
                                />
                                <label
                                  class="btn wf-select-btn"
                                  for={e.id}
                                  style={{ "--bs-btn-active-bg": ` ${e.color}` }}
                                >
                                  {e.name}
                                </label>
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    </div></>)}

                    {profile?.role == "planner" && !coupleURLFilter && selectedCouple !== "" && (<><div class="mb-4 row">
                      <label
                        for="staticEmail"
                        class="col-12 font-size-14 align-self-center col-form-label"
                      >
                        {t("Events")}
                      </label>
                      <div class="col-12 d-flex gap-2 flex-wrap">
                        {load ? (
                          new Array(5)
                            .fill(null)
                            .map((_, index) => <ButtonSkeleton key={index} />)
                        ) : (
                          <>
                            <input
                              checked={event?.length < 1 ? true : false}
                              onChange={() => setevent([])}
                              type="checkbox"
                              name="event-select"
                              id={56}
                              class="btn-check"
                            />
                            <label class="btn wf-select-btn" for={56}>
                              {t('All')}
                            </label>

                            {actualmyEvents
                              ?.filter((f) => f?._id == selectedCouple && f?.event_id)[0]?.Event?.map((e) => {
                                console.log('eeeeeeeeeeee', actualmyEvents, selectedCouple);
                                return (
                                  <>
                                    <input
                                      checked={event.includes(e?._id)}
                                      onChange={() => handleEventChange(e?._id)}
                                      type="checkbox"
                                      name="event-select"
                                      id={e?._id}
                                      className="btn-check"
                                    />
                                    <label
                                      className="btn wf-select-btn"
                                      htmlFor={e?._id}
                                      style={{ "--bs-btn-active-bg": `${e.event_color ? e.event_color : ""}` }}
                                    >
                                      {e.event_name ? e.event_name : ""}
                                    </label>
                                  </>
                                );
                              })}

                          </>
                        )}
                      </div>
                    </div></>)}


                    <div class="mb-4 row">
                      <label
                        for="staticEmail"
                        class="col-12 font-size-14 align-self-start col-form-label"
                      >
                        {t("Category")}
                      </label>
                      <div class="col-12 d-flex gap-2 flex-wrap">
                        {load ? (
                          new Array(22)
                            .fill(null)
                            .map((_, index) => <CaregoriesButtonSkeleton key={index} />)
                        ) : (
                          <>
                            <input
                              checked={category?.length < 1}
                              onChange={() => setcategory([])}
                              type="checkbox"
                              name="cat-select"
                              id={77}
                              class="btn-check"
                            />
                            <label
                              class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2 text-break"
                              style={{ lineHeight: "1.2" }}
                              for={77}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMGURL}all.png`}
                                className="mx-auto d-block color"
                                height="35"
                              />
                              <img
                                src={`${process.env.REACT_APP_IMGURL}white-all.png`}
                                className="mx-auto d-block white"
                                height="35"
                              />
                              {t('All')}
                            </label>
                            {allCategories?.map((c) => (
                              <>
                                <input
                                  checked={
                                    category?.length > 0 && category?.includes(c?._id)
                                      ? true
                                      : false
                                  }
                                  onChange={() => handleCategoryChange(c?._id)}
                                  type="checkbox"
                                  name="cat-select"
                                  id={c.name}
                                  class="btn-check"
                                />
                                <label
                                  class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2 text-break"
                                  style={{ lineHeight: "1.2" }}
                                  for={c.name}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_IMGURL}${c.imageName}`}
                                    className="mx-auto d-block color"
                                    height="35"
                                  />
                                  <img
                                    src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`}
                                    className="mx-auto d-block white"
                                    height="35"
                                  />
                                  {c.name}
                                </label>
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    <div class="mb-5 row ">
                      <label
                        for="staticEmail"
                        class="col-12 font-size-14 align-self-center col-form-label"
                      >
                        {t("DueDate")}
                      </label>
                      <div class="col-12 d-flex gap-2 flex-wrap">
                        <>
                          <input
                            checked={duration == ""}
                            onChange={() => setduration("")}
                            type="radio"
                            name="due-select"
                            id={`${98}-option`}
                            class="btn-check"
                          />
                          <label class="btn wf-select-btn" for={`${98}-option`}>
                            {t('All')}
                          </label>
                          {due_date1?.map((d, ind) => (
                            <>
                              <input
                                checked={duration == d.option}
                                onChange={() => setduration(d.option)}
                                type="radio"
                                name="due-select"
                                id={`${d.id}-option`}
                                class="btn-check"
                              />
                              <label class="btn wf-select-btn" for={`${d.id}-option`}>
                                {due_date[ind]?.option}
                              </label>
                            </>
                          ))}
                        </>
                      </div>
                    </div>


                  </div>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>Close</button>
                    <button onClick={() => { setfiltertoggle(!filtertoggle); closeModal() }} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Apply Filter</button>
                  </div>

                </div>) : null}
            </div>
          </Modal>
          <div className="col-md-12 d-flex justify-content-end">
            {tasks && Object.values(tasks)?.some(array => Array.isArray(array) && array.length > 0) && (
              <div onClick={() => viewModal("", "filter", "filter")} className="d-flex gap-2 align-items-center cursor-pointer">
                <ListFilter size={22} stroke="var(--color3)" />
                <p className="font-size-16 purple-color mb-0 fw-medium">Filters</p>
              </div>
            )}


          </div></>)}

      {duration === "" && filteredData && Object.values(filteredData).some(array => Array.isArray(array) && array.length > 0)
        ? (
          Object.keys(filteredData)?.slice(0, isDashboard ? 5 : filteredData?.length)?.map((duration, ind) => {
            console.log('durationnnnn', filteredData)
            const tasksForKey = filteredData[duration];
            console.log('duration', tasksForKey, duration);

            if (tasksForKey && tasksForKey?.length > 0) {
              const option = durationToOptionMap[duration];
              console.log('option', option);

              // Assuming due_date is an array of objects with 'option' property
              const indexx = due_date.findIndex(item => item.option === option);
              console.log('indexx', indexx);

              if (indexx !== -1) {
                return (
                  <div className="wf-task-group" key={`${ind}`}>
                    {isDashboard ? null : (
                      <div className="wf-task-group-head d-flex justify-content-between align-items-center cursor-pointer">
                        <p className="font-size-20 color-black mb-2 dm-font">
                          {t(due_date[indexx].option)}:{" "}
                          <span className="purple-color">
                            {tasksForKey.length}{" "}
                            {tasksForKey.length > 1 ? <>{t('Tasks')}</> : <>{t('Task')}</>}
                          </span>
                        </p>
                        <div
                          onClick={() => handleToggleChange(ind)}
                          className="purple-color cursor-pointer"
                        >
                          {toggle?.includes(ind) ? (
                            <i class="fas fa-chevron-up"></i>
                          ) : (
                            <i class="fas fa-chevron-down"></i>
                          )}
                        </div>
                      </div>)}
                    <div
                      className={`wf-task-group-foot ${toggle?.includes(ind) && "show"
                        }`}
                    >
                      {
                        // load? new Array(6).fill(null).map((_, index) => (
                        //     <ListSkeleton key={index} />
                        //   )):
                        tasksForKey?.slice(0, isDashboard ? 2 : filteredData?.length)?.map((t, index) => {
                          console.log('t,index', ind + index)
                          return (
                            <div className={location.pathname !== "/couples-overview" ? "wf-task-group-list wf-shadow-2 px-3 py-3 white-bg rounded mb-3" : "wf-shadow-2 white-bg rounded px-2 py-2 mb-3"}>
                              <div className={location.pathname !== "/couples-overview" ? "d-flex flex-wrap align-items-start justify-content-between gap-3 cursor-pointer" : "row align-items-stretch"}>
                                <div className={location.pathname !== "/couples-overview" ? "col-md-1 col-3 d-flex flex-column gap-2 align-items-center" : "col-md-2"}>
                                  <img
                                    width="55"
                                    src={`${process.env.REACT_APP_IMGURL}${t?.category_id?.imageName}`}
                                    className=""
                                  />
                                  <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-center text-uppercase text-break"><a className="color-white" href={`${process.env.REACT_APP_FrontURL}/suppliers/${t?.category_id?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{t?.category_id?.name}</a></span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <p className="font-size-18 color-black mb-0">{t.name}</p>
                                  <div className="d-flex flex-sm-row flex-column gap-2 align-items-md-center wf-subtask-info mb-2">
                                  <div className="d-flex gap-2 align-items-center mb-2">

                                    {t.subtasks?.length > 0 && t.subtasks?.map((st, index) => (
                                      <>
                                        <span
                                          id="pending"
                                          className="rounded-1"
                                          key={index}
                                          style={{
                                            height: "9px",
                                            width: "27px",
                                            backgroundColor: `${st.completed === 1
                                                ? "green"
                                                : currentDate <= t.date && st.date <= t.date && st.completed === 0
                                                  ? "grey"
                                                  : "red"
                                              }`,
                                          }}
                                        ></span>
                                      </>
                                    ))}


                                    {/* if no subtask then show dash for task */}

                                    {t.subtasks?.length < 1 && (
                                      <>
                                        <span
                                          id="pending"
                                          className="rounded-1"
                                          key={index}
                                          style={{
                                            height: "9px",
                                            width: "27px",
                                            backgroundColor: `${t.status === 1
                                                ? "green"
                                                : t.status === 0 && currentDate <= t.date
                                                  ? "grey"
                                                  : "red"
                                              }`,
                                          }}
                                        ></span>
                                      </>
                                    )}

                                    <p className="font-size-12 purple-color mb-0">{`${t.subtasks.length > 0
                                        ? ((t.subtasks.filter(
                                          (st) => st.completed === 1
                                        ).length /
                                          t.subtasks.length) *
                                          100).toFixed(0)
                                        : t.status == 1
                                          ? 100
                                          : 0
                                      }% Completed`}</p>

                                      </div>


                                    {profile?.role == "planner" && location.pathname !== "/couples-overview" && (<><div className="d-flex">
                                      <div className='d-flex gap-1 align-items-center'>
                                        <div className='avatar-couple-first'>
                                          <img width="57" height="57" alt="" src={myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                        </div>
                                        <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                          <img width="57" height="57" alt="" src={myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                        </div>
                                      </div>
                                    
                                      <div className=''>
                                        <h3 className="font-size-16 fw-bold purple-color mb-2">{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.name}<span className='font-size-12 fw-normal color-grey ms-1'>{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.user_purpose}</span></h3>
                                        <h3 className="font-size-16 fw-bold pink-color mb-2 mb-md-0">{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.name}<span className='font-size-12 fw-normal color-grey ms-1'>{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.user_purpose}</span></h3>
                                      </div>
                                      </div></>)}

                                  </div>
                                  <p className="font-size-16 mb-1 text-capitalize d-flex flex-column align-items-start w-auto">
                                    {t?.event_id?.event_name}
                                    <span className="d-block" style={{ backgroundColor: `${t?.event_id?.event_color}`, height: '3px', width: '80px', marginTop: '2px' }}></span>
                                  </p>
                                </div>
                                <div className="col-md-2 col-5 gap-1 align-items-center">
                                  <p className="font-size-14 color-black mb-0">
                                    Assigned to:
                                  </p>

                                  <p class="purple-color mb-0" >
                                    {t?.assignedTo?.name?.charAt(0)?.toUpperCase() + t?.assignedTo?.name?.slice(1)}
                                  </p>


                                </div>
                                <div className="col-md-2 col-6">
                                  <div className="d-flex gap-3 justify-content-end mb-2">
                                    {t.subtasks?.length < 1 && profile?._id == t.assignedTo._id && (
                                      <div className="d-flex align-items-center gap-2">
                                        <p className="font-size-12 purple-color mb-0">
                                          Completed
                                        </p>
                                        <input
                                          checked={t?.status == 1 ? true : false}
                                          onChange={() => {
                                            setFeature("");
                                            saveBranch(t, []);
                                          }}
                                          type="checkbox"
                                          class="btn-check"
                                          id={`${t?._id}`}
                                        />
                                        <label
                                          class="btn wf-btn-checkk"
                                          for={`${t?._id}`}
                                        >
                                          <i class="bi bi-check-lg d-flex"></i>
                                        </label>
                                      </div>
                                    )}
                                    {location.pathname == "/dashboard" || location.pathname == "/dashboard-planner" || location.pathname == "/couples-overview" ? (null) : (<div class="dropdown wf-list-dropdown">
                                      {profile?._id == t.assignedTo._id && (<a class="color-grey font-size-22" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="bi bi-three-dots-vertical"></i>
                                      </a>)}

                                      <ul class="dropdown-menu">
                                        {/* <li><a class="dropdown-item" >History</a></li> */}
                                        {profile?._id == t?.assignedTo?._id && (<><li><a class="dropdown-item" onClick={() => viewModal(t, "userDetail", "edit")}>Edit</a></li>
                                          <li><a class="dropdown-item" onClick={() => viewModal(t?._id, "delete", "delete")}>Delete</a></li></>)}
                                      </ul>
                                    </div>)}
                                    {t.subtasks?.length > 0 && profile?._id == t.assignedTo._id && (
                                      <div
                                        onClick={() => handleChange(t?._id)}
                                        className="purple-color"
                                      >
                                        {toggle1.includes(t?._id) ? (
                                          <i class="bi bi-chevron-down"></i>
                                        ) : (
                                          <i class="bi bi-chevron-right"></i>
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <div className="text-end">
                                    <p className="font-size-14 color-black mb-0">
                                      Due Date
                                    </p>
                                    <p className="font-size-14 purple-color mb-0">
                                      {moment(t.date).format("DD MMM YYYY")}
                                    </p>

                                  </div>
                                </div>
                              </div>
                              <div
                                className={`wf-subtasks ${toggle1.includes(t._id) && "show"
                                  }`}
                              >
                                {t.subtasks?.length > 0 && (
                                  <p className="font-size-16 pink-color mb-2 dm-font">
                                    {/* Subtasks */}
                                  </p>
                                )}
                                {t.subtasks?.map((st, indexx) => (
                                  <div
                                    key={indexx}
                                    className={`row mb-3 wf-shadow-2 px-2 py-3 wf-subtask `}
                                  >
                                    <div className="col-md-1 col-2 align-self-center">
                                      <h3
                                        id="pending"
                                        className="font-size-26 mb-0 color-grey">
                                        {indexx + 1}
                                      </h3>
                                    </div>
                                    <div className="col-md-9 col-10 align-self-center">
                                      <p className="font-size-16 color-black mb-0">{st.name}</p>
                                    </div>

                                    <div className="col-md-2 d-flex justify-content-end align-items-center gap-2">
                                      <div className="">
                                        <p className="font-size-14 purple-color mb-0 text-end">
                                          Completed
                                        </p>
                                        <p className="color-black font-size-14 mb-0 text-end">
                                          {moment(st.date).format("DD MMM YYYY")}
                                        </p>
                                      </div>

                                      <div>
                                        <input
                                          onChange={(e) => {
                                            console.log('yes')
                                            const updatedFAQs = t.subtasks.map((subtask, idx) => {
                                              if (idx === indexx) {
                                                return {
                                                  ...subtask,
                                                  completed: subtask.completed === 0 ? 1 : 0,
                                                  completedDate: subtask.completed === 0 ? currentDate : null,
                                                };
                                              }
                                              return subtask;
                                            });

                                            setsubtask(updatedFAQs);
                                            saveBranch(t, updatedFAQs);
                                          }}
                                          checked={st.completed === 1}
                                          type="checkbox"
                                          className="btn-check"
                                          id={st._id}
                                        />
                                        <label
                                          className="btn wf-btn-checkk"
                                          htmlFor={st._id}
                                        >
                                          <i className="bi bi-check-lg d-flex"></i>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                );
              }
            }
          })
        ) : duration !== "" &&
          filteredData && Object.values(filteredData).some(array => Array.isArray(array) && array.length > 0) && (filteredData[duration]?.length > 0) ? (
          <div className="wf-task-group">
            <div className="wf-task-group-head d-flex justify-content-between align-items-center cursor-pointer">
              <p className="font-size-20 color-black mb-2 dm-font">
                {due_date[indexOfCompleted]?.option}:{" "}
                <span className="purple-color">
                  {filteredData[duration]?.length}{" "}
                  {filteredData[duration]?.length > 1 ? "Tasks" : "Task"}
                </span>
              </p>

              <div
                onClick={() => settoggle2(!toggle2)}
                className="purple-color cursor-pointer"
              >
                {toggle2 ? (
                  <i class="fas fa-chevron-up"></i>
                ) : (
                  <i class="fas fa-chevron-down"></i>
                )}
              </div>
            </div>
            <div className={`wf-task-group-foot ${toggle2 && "show"}`}>
              {
                // load? new Array(6).fill(null).map((_, index) => (
                //     <ListSkeleton key={index} />
                //   )):
                filteredData[duration]?.slice(0, isDashboard ? 5 : filteredData?.length)?.map((t, index) => (
                  <div className={location.pathname !== "/couples-overview" ? "wf-task-group-list wf-shadow-2 px-3 py-3 white-bg rounded mb-3" : "wf-shadow-2 white-bg rounded px-2 py-2 mb-3"}>
                    <div className={location.pathname !== "/couples-overview" ? "d-flex flex-wrap align-items-start justify-content-between gap-3 cursor-pointer" : "row align-items-stretch"}>
                      <div className={location.pathname !== "/couples-overview" ? "col-md-1 col-3 d-flex flex-column gap-2 align-items-center" : "col-md-2"}>
                        <img
                          width="55"
                          src={`${process.env.REACT_APP_IMGURL}${t?.category_id?.imageName}`}
                          className=""
                        />
                        <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase"><a className="color-white" href={`${process.env.REACT_APP_FrontURL}/suppliers/${t?.category_id?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{t?.category_id?.name}</a></span>
                      </div>
                      <div className="col-md-6 col-12">
                        <p className="font-size-18 color-black mb-0">{t.name}</p>
                        <div className="d-flex flex-sm-row flex-column gap-2 align-items-md-center wf-subtask-info mb-2">
                                  <div className="d-flex gap-2 align-items-center mb-2">

                          {t.subtasks?.length > 0 && t.subtasks?.map((st, index) => (
                            <>
                              <span
                                id="pending"
                                className="rounded-1"
                                key={index}
                                style={{
                                  height: "9px",
                                  width: "27px",
                                  backgroundColor: `${st.completed === 1
                                      ? "green"
                                      : currentDate <= t.date && st.date <= t.date && st.completed === 0
                                        ? "grey"
                                        : "red"
                                    }`,
                                }}
                              ></span>
                            </>
                          ))}


                          {/* if no subtask then show dash for task */}

                          {t.subtasks?.length < 1 && (
                            <>
                              <span
                                id="pending"
                                className="rounded-1"
                                key={index}
                                style={{
                                  height: "9px",
                                  width: "27px",
                                  backgroundColor: `${t.status === 1
                                      ? "green"
                                      : t.status === 0 && currentDate <= t.date
                                        ? "grey"
                                        : "red"
                                    }`,
                                }}
                              ></span>
                            </>
                          )}

                          <p className="font-size-12 purple-color mb-0">{`${t.subtasks.length > 0
                              ? ((t.subtasks.filter(
                                (st) => st.completed === 1
                              ).length /
                                t.subtasks.length) *
                                100).toFixed(0)
                              : t.status == 1
                                ? 100
                                : 0
                            }% Completed`}</p>
                            </div>


                          {profile?.role == "planner" && location.pathname !== "/couples-overview" && (<><div className="d-flex">
                            <div className='d-flex gap-1 align-items-center'>
                              <div className='avatar-couple-first'>
                                <img width="57" height="57" alt="" src={myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                              </div>
                              <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                <img width="57" height="57" alt="" src={myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                              </div>
                            </div>
                          
                            <div className=''>
                              <h3 className="font-size-16 fw-bold purple-color mb-2">{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.name}<span className='font-size-12 fw-normal color-grey ms-1'>{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person1?.user_purpose}</span></h3>
                              <h3 className="font-size-16 fw-bold pink-color mb-2 mb-md-0">{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.name}<span className='font-size-12 fw-normal color-grey ms-1'>{myEvents?.find(item => item?.Event?._id == t?.event_id?._id)?.person2?.user_purpose}</span></h3>
                            </div></div></>)}

                        </div>
                        <p className="font-size-16 mb-1 text-capitalize d-flex flex-column align-items-start w-auto">
                          {t?.event_id?.event_name}
                          <span className="d-block" style={{ backgroundColor: `${t?.event_id?.event_color}`, height: '3px', width: '80px', marginTop: '2px' }}></span>
                        </p>
                      </div>
                      <div className="col-md-2 col-5 gap-1 align-items-center">
                        <p className="font-size-14 color-black mb-0">
                          Assigned to:
                        </p>

                        <p class="purple-color mb-0" >
                          {t?.assignedTo?.name?.charAt(0)?.toUpperCase() + t?.assignedTo?.name?.slice(1)}
                        </p>


                      </div>
                      <div className="col-md-2 col-6">
                        <div className="d-flex gap-3 justify-content-end mb-2">
                          {t.subtasks?.length < 1 && profile?._id == t.assignedTo._id && (
                            <div className="d-flex align-items-center gap-2">
                              <p className="font-size-12 purple-color mb-0">
                                Completed
                              </p>
                              <input
                                checked={t?.status == 1 ? true : false}
                                onChange={() => {
                                  setFeature("");
                                  saveBranch(t, []);
                                }}
                                type="checkbox"
                                class="btn-check"
                                id={`${t?._id}`}
                              />
                              <label
                                class="btn wf-btn-checkk"
                                for={`${t?._id}`}
                              >
                                <i class="bi bi-check-lg d-flex"></i>
                              </label>
                            </div>
                          )}
                          {location.pathname == "/dashboard" || location.pathname == "/dashboard-planner" || location.pathname == "/couples-overview" ? (null) : (<div class="dropdown wf-list-dropdown">
                            {profile?._id == t.assignedTo._id && (<a class="color-grey font-size-22" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots-vertical"></i>
                            </a>)}

                            <ul class="dropdown-menu">
                              {/* <li><a class="dropdown-item" >History</a></li> */}
                              {profile?._id == t?.assignedTo?._id && (<><li><a class="dropdown-item" onClick={() => viewModal(t, "userDetail", "edit")}>Edit</a></li>
                                <li><a class="dropdown-item" onClick={() => viewModal(t?._id, "delete", "delete")}>Delete</a></li></>)}
                            </ul>
                          </div>)}
                          {t.subtasks?.length > 0 && profile?._id == t.assignedTo._id && (
                            <div
                              onClick={() => handleChange(t?._id)}
                              className="purple-color"
                            >
                              {toggle1.includes(t?._id) ? (
                                <i class="bi bi-chevron-down"></i>
                              ) : (
                                <i class="bi bi-chevron-right"></i>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="text-end">
                          <p className="font-size-14 color-black mb-0">
                            Due Date
                          </p>
                          <p className="font-size-14 purple-color mb-0">
                            {moment(t.date).format("DD MMM YYYY")}
                          </p>

                        </div>
                      </div>
                    </div>
                    <div
                      className={`wf-subtasks ${toggle1.includes(t._id) && "show"
                        }`}
                    >
                      {t.subtasks?.length > 0 && (
                        <p className="font-size-16 pink-color mb-2 dm-font">
                          {/* Subtasks */}
                        </p>
                      )}
                      {t.subtasks?.map((st, indexx) => (
                        <div
                          key={indexx}
                          className={`row mb-3 wf-shadow-2 px-2 py-3 wf-subtask `}
                        >
                          <div className="col-md-1 col-2 align-self-center">
                            <h3
                              id="pending"
                              className="font-size-26 mb-0 color-grey">
                              {indexx + 1}
                            </h3>
                          </div>
                          <div className="col-md-9 col-10 align-self-center">
                            <p className="font-size-16 color-black mb-0">{st.name}</p>
                          </div>

                          <div className="col-md-2 d-flex justify-content-end align-items-center gap-2">
                            <div className="">
                              <p className="font-size-14 purple-color mb-0 text-end">
                                Completed
                              </p>
                              <p className="color-black font-size-14 mb-0 text-end">
                                {moment(st.date).format("DD MMM YYYY")}
                              </p>
                            </div>

                            <div>
                              <input
                                onChange={(e) => {
                                  const updatedFAQs = t.subtasks.map((subtask, idx) => {
                                    if (idx === indexx) {
                                      return {
                                        ...subtask,
                                        completed: subtask.completed === 0 ? 1 : 0,
                                        completedDate: subtask.completed === 0 ? currentDate : null,
                                      };
                                    }
                                    return subtask;
                                  });

                                  setsubtask(updatedFAQs);
                                  saveBranch(t, updatedFAQs);
                                }}
                                checked={st.completed === 1}
                                type="checkbox"
                                className="btn-check"
                                id={st._id}
                              />
                              <label
                                className="btn wf-btn-checkk"
                                htmlFor={st._id}
                              >
                                <i className="bi bi-check-lg d-flex"></i>
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ) : (<NoEventUI name={"Tasks"} />)}
    </div>
  )
}

export default TaskContent