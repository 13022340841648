import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoEventUI from './noEventUI';
import moment from "moment";
import {
  getEventList,
  getGuest,
  deleteGuest,
  addGuest,
  editGuest,
  getProfile,
  updateRSVP,
  inviteGuest,
  getUserEventList,
} from "../../store/slices/userSlice";
import {  useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { ArrowUpDown, CalendarX2, HelpCircle, ListFilter, Loader, Mail, Phone, Search } from "lucide-react";
import { CalendarCheck2 } from "lucide-react";
import { Home } from "lucide-react";
import { Link } from "lucide-react";
import ListSkeleton from "../LoadingSkeleton/ListSkeleton";
import { useTranslation } from "react-i18next";
import { PieChart } from 'react-minimal-pie-chart';



const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const GuestList = () => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const profile = useSelector(getProfile);
   const navigate = useNavigate();

  const [toggle, settoggle] = useState(false);
  const [toggle1, settoggle1] = useState([]);
  const [toggle2, settoggle2] = useState([]);
  const [load, setload] = useState(false);
  const [link,setlink]=useState("")

  const location=useLocation();
   const [actualmyEvents, setactualmyEvents] = useState([]);
   const queryParams = new URLSearchParams(location.search);
   const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple")?JSON.parse(queryParams.get("couple")):[]);
   const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events")?JSON.parse(queryParams.get("events")):[]);
   
   const [updated, setupdated] = useState(true);

  const [guests, setGuests] = useState([]);
  const [search, setsearch] = useState("");
  const [graphdata, setgraphdata] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [group, setGroup] = useState("");
  const [guest_of, setGuest_Of] = useState("");
  const [rsvp, setRsvp] = useState("");
  const [event_id, setevent_id] = useState([]);
  const [eventid, seteventid] = useState("");
  const [user_id, setuser_id] = useState([]);
  const [male_count, setMale_Count] = useState(0);
  const [female_count, setFemale_Count] = useState(0);
  const [child_count, setChild_Count] = useState(0);
  const [selectedinvitation, setselectedinvitation] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [feature, setFeature] = useState(null);
  const [id, setId] = useState();
  const [viewHistoryIndex, setViewHistoryIndex] = useState("");
  const [inviteSentPercentage, setinviteSentPercentage] = useState("");
  const [load1, setload1] = useState(false);
  
  
  //filter states
  const [event, setevent] = useState(queryParams.get("events")?JSON.parse(queryParams.get("events")):[]);
  const [guestoffilter, setguestoffilter] = useState("");
  const [groupfilter, setgroupfilter] = useState("");
  const [rsvpfilter, setrsvpfilter] = useState("none");
  const [invitation, setinvitation] = useState("");
  const [filteredData, setfilteredData] = useState();
  const [modalType, setModalType] = useState();
  const [filtertoggle, setfiltertoggle] = useState(true);

  
  
  //send invite
  const [sendtype, setsendtype] = useState("");
  const [sendeventid, setsendeventid] = useState("");
  const [sendeventnametoshow, setsendeventnametoshow] = useState("");
  const [sendguestid, setsendguestid] = useState("");
  const [invitemedium, setinvitemedium] = useState("");
  

  const [selectedCouple, setselectedCouple] = useState("");

  
  const handleToggleChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!toggle1.includes(id)) {
      settoggle1([...toggle1, id]);
    } else {
      const updatedEventIds = toggle1.filter((itemId) => itemId !== id);
      settoggle1(updatedEventIds);
    }
  };
  
  const handleToggleChange1 = (id) => {
    console.log('idddddddddddddddd', id);
    if (!toggle2.includes(id)) {
      settoggle2([...toggle2, id]);
    } else {
      const updatedEventIds = toggle2.filter((itemId) => itemId !== id);
      settoggle2(updatedEventIds);
    }
  };


 
  
  const handleEventChange = (id) => {
    console.log("idddddddddddddddd", id);
    if (!event.includes(id)) {
      setevent([...event, id]);
    } else {
      const updatedEventIds = event.filter((itemId) => itemId !== id);
      setevent(updatedEventIds);
    }
  };

  
  const handleChangeRSVP = async (status,guestid,eventid) => {

    const abc= await dispatch(
      updateRSVP({
        status: status,
        guestid: guestid,
        eventid: eventid,
      })
    ).unwrap();
   

    if(abc){
      getAllGuests()
    }

  };
  
  // const sendInvitation = async (status,guestid,eventid) => {

  //   const abc= await dispatch(
  //     updateRSVP({
  //       status: status,
  //       guestid: guestid,
  //       eventid: eventid,
  //     })
  //   ).unwrap();
   

  //   if(abc){
  //     getAllGuests()
  //   }

  // };

  const handleSetData = (e) => {

    const mergedEvents = [];


    e?.forEach(event => {
    const modifiedEvent = {
        ...event,
        Event: [event.Event]
    };

    const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
    );

    if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
    } else {
        mergedEvents.push(modifiedEvent);
    }
});

setactualmyEvents(mergedEvents)
// setfilteredData(mergedEvents)
}


  
  
const sendInvite = async (type) => {
if(sendguestid.length<1){
toast.error("Please select some guests")
}
else if(!sendeventid){
toast.error("Please select an event")
}
// else if(!invitemedium&&invitemedium!=="copyLink"){
// toast.error("Please select an option to send invitation")
// }

else{
  setload1(true)
  console.log('invitemedium', invitemedium)
  setlink("");

  const abc= await dispatch(
    inviteGuest({
      guestid: sendguestid,
      event: {id: sendeventid, name: sendeventnametoshow},
      type: sendtype,
      medium: type?type:invitemedium,     
    })
  ).unwrap();
 console.log('abcccc', abc)

  if(abc){
    if(type=="copyLink"){
      setlink(abc?.data);
      copyText(abc?.data)
    }
    else if(invitemedium=="whatsapp"){
      const url = `https://wa.me/${sendguestid[0]?.whatsapp}?text=${encodeURIComponent(abc?.data)}`;
    window.open(url, '_blank');

    closeModal()
    setsendguestid("")
    setsendeventid("")
    setsendeventnametoshow("")
    setinvitemedium("")
      
    }
    else{
    getAllGuests()
    closeModal()
    setselectedinvitation()
    setsendguestid("")
    setsendeventid("")
    setsendeventnametoshow("")
    setinvitemedium("")
    }
  }
  setload1(false)
}

  };

  const handleInviteChange = (idd) => {
    
    if (selectedinvitation.some((id)=>id.id==idd._id)) {
        setselectedinvitation(selectedinvitation.filter((id) => id.id !== idd._id));
      } else {
        setselectedinvitation([...selectedinvitation, { id: idd._id, name: idd.name, email: idd.email, whatsapp: idd.whatsapp }]);
      }
  };

  const handleSelectAllChange = (event) => {
      if (event.target.checked) {
          // setselectedinvitation([])
          const allUserIds = guests?.map((g) => ({ id: g._id, name: g.name, email: g.email, whatsapp: g.whatsapp }));
          setselectedinvitation(allUserIds);
      } else {
          setselectedinvitation([]);
      }
  };

  
  const calculateLabelPosition = (dataEntry) => {
    const startAngle = dataEntry.startAngle;
    const endAngle = dataEntry.endAngle;
    const angle = (startAngle + endAngle) / 2;

    // Calculate the centroid of the pie slice
    const radius = 100; // Adjust this radius as needed
    const x = 50 + radius * Math.cos(-angle * (Math.PI / 180));
    const y = 50 + radius * Math.sin(-angle * (Math.PI / 180));

    return { x, y };
  };
  const Invitation_Status = [
    {
      id: 1,
      option: 'Sent',
    },
    {
      id: 2,
      option: 'Not Sent',
    },
    
  ];
  const RSVP_Status = [
    {
      id: 1,
      option: 'Attending',
      value: 1,
    },
    {
      id: 2,
      option: 'Not Attending',
      value: 0,
    },
    {
      id: 3,
      option: 'May Be',
      value: 3,
    },
    {
      id: 4,
      option: 'Pending',
      value: 2,
    },
  ];
  const groupOptions = [
    { 
      id: 1, 
      option: 'Family',
      image: 'wf-family-group-icon.svg', 
      image_white: 'wf-family-group-icon-white.svg', 
    },
    { 
      id: 2, 
      option: 'Friends',
      image: 'wf-friends-group-icon.svg', 
      image_white: 'wf-friends-group-icon-white.svg', 
    },
    { 
      id: 3, 
      option: 'Work',
      image: 'wf-work-group-icon.svg', 
      image_white: 'wf-work-group-icon-white.svg', 
    },
    { 
      id: 4, 
      option: 'Other',
      image: 'wf-family-group-icon.svg', 
      image_white: 'wf-family-group-icon-white.svg',  
    },
  ];

console.log('selectedinvitation', selectedinvitation)

  const flattenInvitations = (data) => {
    return data.map(item => {
      return {
        ...item,
        invitations: Array.isArray(item.invitations)
          ? item.invitations
          : Object.values(item.invitations)
      };
    });
  };
  
 

  const getAllGuests = async () => {
    try {

      let eventIds = [];

    for (const events of myEvents) {
      if (events.Event && events.Event._id) {
      eventIds.push(events.Event._id);
    }
    }
    
      setGuests(null);
      const response = await dispatch(
        getGuest({ event_ids: eventIds })
      ).unwrap();
if(response){
      setGuests(response?.data);
      setfilteredData(response?.data)
  
      const graphValues= calculateGraphValues(myEvents,response.data)
      setgraphdata(graphValues)
      const overall= calculateOverallPercentage(response?.data);
      setinviteSentPercentage(overall)
      // setfiltertoggle(!filtertoggle); 
    }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('graphdata', graphdata)
  console.log('myEvents', myEvents)

  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllGuests();
    }
    return () => {
      mount = false;
    };
  }, [myEvents]);



  function viewModal(item, type, feature,index) {
    setIsOpen(true);
  console.log('item', item)

    if (type == "userDetail" && feature == "add") {
      setUserDetail("");
      setId("");
      setName("");
      setselectedCouple("")
      setevent_id("")
      setuser_id([])
      setEmail("");
      seteventid("");
      setFeature(feature);
      setMale_Count(0);
      setFemale_Count(0);
      setChild_Count(0);
      setGroup("");
      setGuest_Of("");
      setRsvp("");
      setPhone("")
      setAddress("")
      setWhatsapp("")
    }
    if (type == "userDetail" && feature == "edit") {
      setUserDetail(item);
      setselectedCouple(item?.user_id?._id)
      setId(item?._id);
      setName(item?.name);
      setevent_id(item?.events?.map((m)=>m._id))
      setuser_id([item?.user_id?._id])
      seteventid(item?.event_id?._id);
      setcoupleURLFilter(item?.user_id?._id)
      setEmail(item?.email);
      setMale_Count(item?.male_count);
      setFemale_Count(item?.female_count);
      setChild_Count(item?.child_count);
      setAddress(item?.address)
      setPhone(item?.phone)
      setWhatsapp(item?.whatsapp)
      setGroup(item?.group);
      setGuest_Of(item?.guest_of);
      setRsvp(item?.revp);
      setFeature(feature);
    }

    if (type == "invitation") {
      setFeature(feature);
      setModalType(type);
    }

    if (type == "invites-history") {
      setViewHistoryIndex(index)
      setUserDetail(item);
      setFeature(feature);
    }

    if (type == "delete") {
      setId(item);
      setFeature(feature);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  const handleSkillChange = (eventid,e1) => {
  //   if(profile?.role!=="planner"){
  //   setcoupleURLFilter(e1)
  // }
  
    if (!event_id?.includes(eventid)) {
      setevent_id([...event_id, eventid]); 
    } else {
      const updatedEventIds = event_id.filter((id) => id !== eventid);
      setevent_id(updatedEventIds);
    }

    if (!user_id?.includes(e1)) {
      setuser_id([...user_id, e1]); 
    } else {
      const updatedEventIds = user_id.filter((id) => id !== e1);
      setuser_id(updatedEventIds);
    }
  };

  console.log('user_id', user_id)

  const handlesenddata = (guestid,eventid,type) => {

    console.log('guestid', guestid)

    if(type=="single"){
    setsendguestid([{id: guestid._id, name: guestid.name, email: guestid.email, whatsapp: guestid.whatsapp}]);
    setsendeventid(eventid._id);
    setsendeventnametoshow(eventid.event_name);
    setsendtype(type)
    viewModal("", "invitation", "invitation")
  }
    else{
      setsendguestid(guestid);
      // setsendeventid(eventid);
      setsendtype(type)
      viewModal("", "invitation", "invitation")
    }
  };


console.log('filters', event,guestoffilter,groupfilter,rsvpfilter,invitation)
console.log('userDetail', userDetail)
console.log('sendguestid', sendguestid)


const copyText = (newlink) => {
  console.log('newlink', newlink);

  
  const input = document.createElement('input');
  input.style.position = 'fixed';
  input.style.opacity = 0;
  input.value = newlink;
  document.body.appendChild(input);

  // Select the text inside the input
  input.select();

  // Copy the selected text to clipboard
  document.execCommand('copy');

  // Remove the temporary input element
  document.body.removeChild(input);

  // Notify user
  toast.success('Link copied to clipboard');
};



const handleFilters = () => {
  let filteredDataa = guests?.filter((i) => {
      
      const couplematches = coupleURLFilter?.length === 0 || coupleURLFilter?.includes(i?.user_id?._id);
      const coupleFilterMatches = selectedCouple=="" || selectedCouple==i?.user_id?._id;
      const eventmatches = event?.length === 0 || i?.events?.some(ev => event?.includes(ev._id));
      const guestofmatches = !guestoffilter || i?.guest_of?.toLowerCase() === guestoffilter?.toLowerCase();
      const groupmatches = !groupfilter || i?.group?.toLowerCase() === groupfilter?.toLowerCase();
      const rsvpmatches = !event || rsvpfilter === "none" || i.invitations?.some(obj => obj.event_id._id === event && obj.rsvp === rsvpfilter);
      const inv = !invitation || (invitation === "sent" && i.invitations?.length > 0) || (invitation === "not sent" && !i.invitations?.length);
      
      return  couplematches && coupleFilterMatches && eventmatches && guestofmatches && groupmatches && rsvpmatches && inv;
  });

  setfilteredData(filteredDataa);
  // const overall = calculateOverallPercentage(filteredDataa);
  // setinviteSentPercentage(overall);
};


useEffect(() => {
  if (search === "") {
      handleFilters();
  } else {


        const filteredEvents = guests?.filter(i => {
        const namematches = i.name?.toLowerCase().includes(search?.toLowerCase());
        const couplematches = coupleURLFilter?.length === 0 || coupleURLFilter?.includes(i?.user_id?._id);
        const coupleFilterMatches = selectedCouple=="" || selectedCouple==i?.user_id?._id;
        const eventmatches = event?.length === 0 || i?.events?.some(ev => event?.includes(ev._id));
        const guestofmatches = !guestoffilter || i?.guest_of?.toLowerCase() === guestoffilter?.toLowerCase();
        const groupmatches = !groupfilter || i?.group?.toLowerCase() === groupfilter?.toLowerCase();
        const rsvpmatches = !event || rsvpfilter === "none" || i.invitations?.some(obj => obj.event_id._id === event && obj.rsvp === rsvpfilter);
        const inv = !invitation || (invitation === "sent" && i.invitations?.length > 0) || (invitation === "not sent" && !i.invitations?.length);
      
        return namematches && couplematches && coupleFilterMatches && eventmatches && guestofmatches && groupmatches && rsvpmatches && inv
  });

      setfilteredData(filteredEvents);
  }
}, [search]);
  




  function calculateOverallPercentage(data) {
    let totalFilledInvitations = 0;
    let totalInvitations = 0;
  
    // Calculate total filled invitations and total invitations
    data?.forEach(item => {
      const allEmpty = item?.invitations?.every(invitation => Object.keys(invitation).length === 0);
      totalFilledInvitations += !allEmpty&&item.invitations.filter(invitation => '_id' in invitation?.guest_id).length;
      totalInvitations += item?.invitations?.length;
    });
  
    // Calculate overall percentage
    const overallPercentage = (totalFilledInvitations / totalInvitations) * 100;
    return overallPercentage.toFixed(0); // Rounding to 2 decimal places
  }

  function calculateGraphValues(allEvents,data) {
    const finalData = allEvents?.map(eventItem => {
      if(eventItem?.Event && eventItem?.Event?.event_name){

      const matchingEvent = data?.find(item => item?.events?.some(event => event?.event_name === eventItem?.Event?.event_name));
      
      if (matchingEvent) {
        const { male_count, female_count, child_count } = matchingEvent;
        const totalValue = male_count + female_count + child_count;
        return {
          title: eventItem.Event.event_name,
          value: totalValue, 
          color: eventItem.Event.event_color
        };
      }
      else{
        return {
          title: eventItem.Event.event_name,
          value: 0,
          color: eventItem.Event.event_color
        };
      }
      }
      })
      const filteredFinalData = finalData?.filter(item => item !== null && item !== undefined);
    return filteredFinalData;
  }


  


  const accountDelete = async (id) => {
    try {
      setload1(true)
      await dispatch(deleteGuest(id)).unwrap();
      setIsOpen(false);
      // $("#tableData").DataTable().destroy();
      try {
        getAllGuests();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
      setload1(false)
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };


  const saveBranch = async (e) => {
    e.preventDefault();

    try {
      if(myEvents?.length<1){
        toast.error("You don't have any event to proceed")
      }
      else{
      setload1(true)
      if (feature == "edit") {
        await dispatch(
          editGuest({
            guest_id: id,
            event_id: event_id,
            user_id: user_id,
            name: name,
            email: email,
            male_count: male_count,
            female_count: female_count,
            child_count: child_count,
            group: group,
            guest_of: guest_of,
            rsvp: rsvp,
            address: address,
            phone: phone,
            whatsapp: whatsapp,
          })
        ).unwrap();
        await dispatch(getUserEventList()).unwrap();
        closeModal();
        setName("");
        setEmail("");
        setAddress("");
        setPhone("");
        setWhatsapp("");
        setGroup("");
        setGuest_Of("");
        setRsvp("");
        setMale_Count(0);
        setFemale_Count(0);
        setChild_Count(0);
        getAllGuests();
      } else {
        await dispatch(
          addGuest({
            event_id: JSON.stringify(event_id),
            user_id: JSON.stringify(user_id),
            name: name,
            email: email,
            male_count: male_count,
            female_count: female_count,
            child_count: child_count,
            group: group,
            guest_of: guest_of,
            rsvp: rsvp,
            address: address,
            email: email,
            phone: phone,
            whatsapp: whatsapp,
          })
        ).unwrap();

        closeModal();
        await dispatch(getUserEventList()).unwrap();
        getAllGuests();
      }
      setload1(false)
    }
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };




console.log('filteredData', filteredData)

useEffect(() => {
  handleFilters();
}, [filtertoggle]);

useEffect(() => {
  if (updated) {
    handleFilters();
  }
}, [guests]);


useEffect(() => {
  handleSetData(myEvents)
}, [myEvents]);

useEffect(() => {
   
  const newCoupleURLFilter = queryParams.get("couple") || "";

  setcoupleURLFilter(newCoupleURLFilter);
 
  const newcoupleEventURLFilter = queryParams.get("events") || [];

  setevent(newcoupleEventURLFilter)

  setfiltertoggle(!filtertoggle)
}, [location.search]);



console.log('coupleURLFilter', coupleURLFilter)
console.log('coupleEventURLFilter', coupleEventURLFilter)
  console.log('filteredData?.length == selectedinvitation?.length', filteredData?.length ,selectedinvitation?.length)

  return (
  
  <>
 <Modal
  // shouldCloseOnEsc={true}
  shouldCloseOnOverlayClick={true}
  closeTimeoutMS={500}
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  style={customStyles}
  contentLabel="Add Guest"
  className={`modal fade show wf-modal guests ${modalType === 'invitation' ? 'wf-invitation-modal' : ''} ${modalType === 'delete' ? 'wf-delete-modal' : ''}`}
>
  <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
    
      {modalType === "userDetail" ? (
        <>
        <div className="modal-content">
        <div class="modal-header border-0 pb-0">
        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{feature == "edit" ? <>{t('Edit')} {t('Guest')}</> : <>{t('Add')} {t('Guest')}</>}</h1>
        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div className="row mb-3 gy-3">

        <div className="col-md-6">
        <p className="font-size-18 dm-font pink-color mb-2">{t('Guest')} {t('Details')}</p>
        <div class="form-floating wf-input wf-input-101">
          <input onChange={(e) => { setName(e.target.value) }} placeholder={feature == "add" ? "Enter Guest Name" : ""} defaultValue={feature == "edit" ? userDetail?.name : ""} type="text" class="form-control" id="floatingInput" autocomplete="name" required />
          <label for="floatingInput">{t('Guest')} {t('Name')}</label>
        </div>
        </div>

        {profile?.role=="planner"&&(<div className="col-md-6">
        <p className="font-size-18 dm-font pink-color mb-2">{t('Guestsof')}</p>

      <div class="d-flex gap-2 mb-3">
        <input checked={guest_of == "groom" ? true : false} onChange={(e) => {setGuest_Of(e.target.value)}} type="radio" name="guest-of" id="groom" value="groom" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="groom">
          {/* <img src={myEvents&&profile?._id!==myEvents[0]?.person2?._id&&myEvents[0]?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person1?.imageName}`:myEvents&&profile?._id!==myEvents[0]?.person1?._id&&myEvents[0]?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person2?.imageName}`:"https://via.placeholder.com/100x100"} className="rounded-circle groom-bride-image" style={{height:'40px',width:'40px'}} /> */}
          {t('Groom')}
        </label>

          <input checked={guest_of == "bride" ? true : false} onChange={(e) => {setGuest_Of(e.target.value)}} type="radio" name="guest-of" id="bride" value="bride" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2"  for="bride">
          {/* <img src={myEvents&&profile?._id==myEvents[0]?.person1?._id&&myEvents[0]?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person2?.imageName}`:myEvents&&profile?._id==myEvents[0]?.person2?._id&&myEvents[0]?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person1?.imageName}`:"https://via.placeholder.com/100x100"} className="rounded-circle groom-bride-image" style={{height:'40px',width:'40px'}} /> */}
          {t('Bride')}
        </label>
      </div>
        </div>)}
        {/* </div> */}


        {profile?.role=="planner" &&(<>   
          <p class="font-size-18 dm-font pink-color mb-2">
               {t("Couples")}
                </p>
                
                <div className="col-md-6">
                {load ? (
                  new Array(5)
                    .fill(null)
                    .map((_, index) => <ButtonSkeleton key={index} />)
                ) : (
                  <>
                
                    {actualmyEvents?.map((e) => (
                      <>
                        <input
                          checked={selectedCouple==e._id}
                          onChange={() => {setselectedCouple(e._id);setevent_id([])}}
                          type="checkbox"
                          name="event-select"
                          id={e._id}
                          class="btn-check"
                        />
                        <label
                          class="btn wf-select-btn"
                          for={e._id}
                        >
                        {`${e?.person1?.name||""} ${e?.person2?.name?"&":""} ${e?.person2?.name||""}`}
                        </label>
                      </>
                    ))}
                  </>
                )}
                </div></>)} 

        

        {profile?.role!=="planner"&&(<div className="col-md-6">
        <p className="font-size-18 dm-font pink-color mb-2">{t('Guestsof')}</p>
        <div class="d-flex gap-2 mb-3">
        <input checked={guest_of == "groom" ? true : false} onChange={(e) => {setGuest_Of(e.target.value)}} type="radio" name="guest-of" id="groom" value="groom" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="groom">
          <img src={myEvents&&myEvents[0]?.person1&&myEvents[0]?.person1?.user_purpose=="groom"&&myEvents[0]?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person1?.imageName}`:myEvents[0]?.person2?.imageName?myEvents[0]?.person2?.imageName:"https://via.placeholder.com/100x100"} className="rounded-circle groom-bride-image" style={{height:'40px',width:'40px'}} />
          {t('Groom')}
        </label>

          <input checked={guest_of == "bride" ? true : false} onChange={(e) => {setGuest_Of(e.target.value)}} type="radio" name="guest-of" id="bride" value="bride" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2"  for="bride">
        <img src={myEvents&&myEvents[0]?.person1?.user_purpose&&myEvents[0]?.person1?.user_purpose=="bride"&&myEvents[0]?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person1?.imageName}`:myEvents[0]?.person2?.imageName?myEvents[0]?.person2?.imageName:"https://via.placeholder.com/100x100"} className="rounded-circle groom-bride-image" style={{height:'40px',width:'40px'}} />
        {t('Bride')}
        </label>
      </div>
        </div>)}

        </div>



       


        {selectedCouple && profile?.role=="planner" && (
  <>
    <p className="font-size-18 dm-font pink-color mb-2">
      {t("Events")}
    </p>
    <div className="d-flex gap-2 flex-wrap mb-4">
     
      <div className="col-sm-11 d-flex gap-2 flex-wrap">
        {load ? (
          new Array(5).fill(null).map((_, index) => <ButtonSkeleton key={index} />)
        ) : (
          <>
            
            {actualmyEvents?.filter((f) => f?._id == selectedCouple)[0]?.Event?.map((e) => (
              <>
                <input
                  onChange={()=>{handleSkillChange(e._id,actualmyEvents?.filter((f) => selectedCouple==f._id)[0]?._id)}} 
                  checked={event_id?.includes(e?._id) ? true : false}
                  // checked={coupleEventURLFilter?.includes(e._id)}
                  name="event-select"
                  type="checkbox"
                  id={e?._id}
                  className="btn-check"
                />
                {e?.event_name?(<label
                  className="btn wf-select-btn"
                  htmlFor={e?._id}
                  style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                >
                  {e.event_name}
                </label>):"No events to show"}
              </>
            ))}
          </>
        )}
      </div>
    </div>
  </>
              )}
              
        
        <p className="font-size-18 dm-font pink-color mb-2">{t('Count')}</p>
        <div className="row mb-3 gy-3">
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-male-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" /> 
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Male')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button disabled={male_count==0?true:false} onClick={() => { setMale_Count(male_count-1); }} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input type="text" name="quant[2]" class="form-control input-number" value={male_count} min="0" max="100"/>
        <span class="input-group-btn">
            <button  onClick={() => { setMale_Count(male_count+1); }} type="button"  class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-female-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" />
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Female')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button disabled={female_count==0?true:false} onClick={() => { setFemale_Count(female_count-1); }} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input type="text" name="quant[2]" class="form-control input-number" value={female_count} min="0" max="100"/>
        <span class="input-group-btn">
            <button onClick={() => { setFemale_Count(female_count+1); }} type="button" class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-child-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" />
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Kids')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button onClick={() => { setChild_Count(child_count-1); }} disabled={child_count==0?true:false} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input type="text" name="quant[2]" class="form-control input-number" value={child_count} min="0" max="100"/>
        <span class="input-group-btn">
            <button onClick={() => { setChild_Count(child_count+1); }} type="button" class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        </div>
        

        {profile?.role!=="planner"&&(<><p className="font-size-18 dm-font pink-color mb-2">{t('Events')}</p>
      
        <div className="d-flex gap-2 flex-wrap mb-4">
                {load ? new Array(5).fill(null).map((_, index) => (
                  <ButtonSkeleton key={index} />
                )) :  myEvents?.map((e) => (
                  <>
                  <input onChange={()=>handleSkillChange(e.Event._id,e._id)} 
                  checked={event_id?.includes(e.Event._id) ? true : false}
                    type="checkbox"
                    name="event-selectttt"
                    id={`${e?.Event?._id}-checked`}
                    class="btn-check"
                  />
                  <label class="btn wf-select-btn d-flex flex-column gap-1 align-items-start" for={`${e?.Event?._id}-checked`} style={{ "--bs-btn-active-bg": ` var(--color3)`,}}>
                    <span className="fw-semibold">{e.Event.event_name}</span>
                    <span className="w-100" style={{backgroundColor:` ${e.Event.event_color}`, height:'3px', marginTop:'-5px'}}></span>
                    <span className="color-grey">{moment(e.Event.event_date).format("D MMMM YYYY")}</span>
                  </label></>))}
              </div></>)}


           
          <p className="font-size-18 dm-font pink-color mb-2">{t('Group')}</p>
          <div class="d-flex gap-2 flex-wrap mb-3">
          {groupOptions?.map((c)=>(
                <>
                <input onChange={(e) => setGroup(e.target.value) } checked={group == c.option?true:false} type="radio" name="cat-select" id={c.option} value={c.option}  class="btn-check" />
                <label class="btn wf-select-btn d-flex flex-row justify-content-center gap-2 align-items-center" style={{width:'110px', lineHeight:'1.2'}} for={c.option}>
                  <img src={`../assets/images/${c.image}`} className="mx-auto d-block color" height="30" />
                  <img src={`../assets/images/${c.image_white}`} className="mx-auto d-block white" height="30" />
                {t(c.option)}</label>
                </>
          ))}
          </div>
        
        <p className="font-size-18 dm-font pink-color mb-2">{t('Contact')} {t('Details')}</p>
        <div className="row gx-3 gy-3 mb-3">
        <div className="col-md-4">
            <div class="input-group wf-input-group wf-shadow-2">
              <span class="input-group-text purple-color"><i class="bi d-flex bi-telephone-fill"></i></span>
              <div class="form-floating wf-input">
                {/* <input
                 onChange={(e) => {setPhone(e.target.value)}} 
                 defaultValue={userDetail?.phone} type="tel" class="form-control" id="phone-number" placeholder="phone number"  autocomplete="tel" pattern="[0-9]*"/> */}
                 <input onChange={(e) => {setPhone(e.target.value)}}  value={phone} type="tel" className="form-control" id="phone-number" maxLength={13} placeholder="phone-number" autoComplete="tel"/>
                <label for="phone-number">{t('Phone')}</label>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="input-group wf-input-group wf-shadow-2">
              <span class="input-group-text purple-color"><i class="bi d-flex bi-envelope-fill"></i></span>
              <div class="form-floating wf-input">
                <input 
                onChange={(e) => {setEmail(e.target.value)}} 
                
                 defaultValue={userDetail?.email} type="email" class="form-control" id="Email" placeholder="Email" autocomplete="Email" maxLength={320}/>
                <label for="Email">{t('Email')}</label>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="input-group wf-input-group wf-shadow-2">
              <span class="input-group-text purple-color"><i class="bi d-flex bi-whatsapp"></i></span>
              <div class="form-floating wf-input">
                <input 
                onChange={(e) => {setWhatsapp(e.target.value)}} 
                 value={whatsapp}  type="tel" class="form-control" id="WhatsApp" placeholder="WhatsApp" autocomplete="tel" maxLength={13}/>
                <label for="WhatsApp">{t('WhatsApp')}</label> 
              </div>
            </div>
          </div>
          <div className="col-md-12">
          <div class="input-group wf-input-group wf-shadow-2">
              <span class="input-group-text purple-color"><i class="bi d-flex bi-house-fill"></i></span>
              <div class="form-floating wf-input">
                <input onChange={(e) => {setAddress(e.target.value)}} defaultValue={userDetail?.address} type="text" class="form-control" id="address" placeholder="Address" autocomplete="address"/>
                <label for="address">{t('Address')}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0 justify-content-center gap-2">
        <button onClick={(e)=>saveBranch(e)} class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" disabled={load1}>
        {load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>{t('Save')}</>
          )}
        </button>
      </div>
    </div>
    </>
      ) : modalType === "delete" ? (
        <>
    <div className="modal-content">
      <div class="modal-header border-0 pb-0">
        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Delete')} {t('Guest')}</h1>
        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body mt-0">
        <p className="font-size-14 color-black mb-0">{t('Areyousureyouwanttodeletethis')} {t('Guest')}?</p>
      </div>
      <div class="modal-footer border-0 justify-content-center gap-2">
        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => accountDelete(id)} disabled={load1}>
        {load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>{t('Delete')} {t('Guest')}</>
          )}
        </button>
      </div>
      </div>
        </>
      ) : modalType === "invitation" ? (<>
      <div className="modal-content">
      <div class="modal-header border-0 pb-0">
      <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Send Invite')}</h1>
      <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body mt-0">
      <div className="d-flex align-items-center flex-wrap mb-3 gap-3">

      {sendguestid?.length==1&&(<><input onClick={()=>sendInvite("copyLink")} checked={invitemedium == "copyLink" ? true : false} type="radio" name="copyLink" id="copyLink" value="copyLink" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="copyLink">
        <Link width={22} height={22} strokeWidth={1}  />
        {t('Copy Link')}
        </label></>)}

      <input checked={invitemedium == "address" ? true : false} onClick={()=>setinvitemedium("address")} type="radio" name="address" id="address" value="address" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="address">
        <Home width={22} height={22} strokeWidth={1}  />
        {t('Home')}
        </label>
        
      {sendguestid?.length==1&&(<><input checked={invitemedium == "whatsapp" ? true : false} onClick={()=>setinvitemedium("whatsapp")} type="radio" name="whatsapp" id="whatsapp" value="whatsapp" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="whatsapp">
        <i style={{fontSize:'20px' }} class="bi bi-whatsapp d-flex"></i>
        {t('WhatsApp')}
        </label></>)}
        
       <input checked={invitemedium == "phone" ? true : false} onClick={()=>setinvitemedium("phone")} type="radio" name="phone" id="phone" value="phone" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="phone">
        <a href={sendguestid[0]?.phone?`tel:${sendguestid[0]?.phone}`:""}><Phone width={22} height={22} strokeWidth={1} stroke={"#000000"}/></a>
        {t('Phone')}
        </label>
        
      <input checked={invitemedium == "email" ? true : false} onClick={()=>setinvitemedium("email")} type="radio" name="email" id="email" value="email" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="email">
        <Mail width={22} height={22} strokeWidth={1}  />
        {t('Email')}
        </label>
        
      <input checked={invitemedium == "inPerson" ? true : false} onClick={()=>setinvitemedium("inPerson")} type="radio" name="inPerson" id="inPerson" value="inPerson" class="btn-check" />
        <label class="btn wf-select-btn d-flex flex-row gap-2 align-items-center py-2" for="inPerson">
        <Mail width={22} height={22} strokeWidth={1}  />
        {t('In Person')}
        </label>
    
      </div>
    </div>
    <div class="modal-footer border-0 justify-content-center gap-2">
      <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
      <button onClick={()=>sendInvite()} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" disabled={load1||invitemedium==""}>{load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>{t('Send')}</>
          )}</button>
    </div>

    </div>
      </>) : modalType === "invites-history" ? (   
      
      <div className="modal-content">
      <div class="modal-header border-0 pb-0">
        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('InvitesHistory')}</h1>
        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body mt-0">
    
      <p style={{color:`${userDetail?.events[viewHistoryIndex]?.event_color}`}} className="font-size-16 mb-2 dm-font text-capitalize">{userDetail?.events[viewHistoryIndex]?.event_name}</p>
      <div className="wf-invite-history-list wf-shadow-2 px-3 py-2 white-bg rounded mb-3" style={{borderLeft:`10px solid ${userDetail?.events[viewHistoryIndex]?.event_color}`}}>
        <div className="d-flex align-items-center gap-4 flex-row flex-wrap justify-content-between">
        <div className="">
     
        {userDetail?.invitations?.length>0 && userDetail?.invitations?.some(obj => obj?.event_id?._id == userDetail?.events[viewHistoryIndex]?._id) ? (
     
     <p className="font-size-14 color-black text-capitalize mb-0">{t('Invited')}</p>
   
   ):(<p className="font-size-14 color-black text-capitalize mb-0">{t('NotInvited')}</p>)}
        <p className="font-size-10 color-black text-uppercase mb-0">{t('Status')}</p>
        </div>
        <div className="">
        <p className="font-size-14 color-black text-capitalize mb-0">{userDetail.male_count + userDetail.female_count + userDetail.child_count}</p>
        <p className="font-size-10 color-black text-uppercase mb-0">{t('Total')} {t('Guest')}</p>
        </div>
        <div className="">
        <p className="font-size-14 color-black text-capitalize mb-0">{userDetail?.rsvp == 1  ? <>{t('Attending')}</>: userDetail?.rsvp == 3 ? <>{t('May be')}</> : userDetail?.rsvp == 0 ? <>{t('Not Attending')}</>:<>{t('Pending')}</>}</p>
        <p className="font-size-10 color-black text-uppercase mb-0">{t('RSVP')}</p>
        </div>
        <div className="">
        <p className="font-size-14 color-black text-capitalize mb-0">{userDetail?.male_count + userDetail?.female_count + userDetail?.child_count}</p>
        <p className="font-size-10 color-black text-uppercase mb-0">{t('Confirmed')} {t('Guest')}</p>
        </div>
        <div className="">
          <button onClick={()=>handlesenddata(userDetail,userDetail?.events[viewHistoryIndex],"single")} class="btn wf-btn-2 wf-pink-outline-btn py-2 px-2 rounded-2">{t('SendInvite')}</button>
        </div>
        <div className="d-flex align-items-center gap-3">
        {userDetail?.address&&(<Home width={22} height={22} strokeWidth={1} stroke={"#804099"} />)}
        {userDetail?.whatsapp&&(<i  style={{ color:'#804099',fontSize:'20px' }} class="bi bi-whatsapp d-flex"></i>)}
        {userDetail?.phone&&(<Phone width={22} height={22} strokeWidth={1} stroke={"#804099"} />)}
        {userDetail?.email&&(<Mail width={22} height={22} strokeWidth={1} stroke={"#804099"} />)}
      </div>
      <div onClick={() => handleToggleChange1(userDetail?.events[viewHistoryIndex]?._id)} className="cursor-pointer purple-color">
      {toggle2.includes(userDetail?.events[viewHistoryIndex]?._id) ?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
        </div>
        </div>
        <div className={`wf-subtasks wf-budget-inner ${toggle2.includes(userDetail?.events[viewHistoryIndex]?._id) &&"show"}`}>
       
        <div className="d-flex gap-4 mb-2">
        <p className="font-size-12 color-black text-capitalize mb-0">{moment(userDetail?.createdAt).format("D MMMM YYYY")}</p>
        <p className="font-size-12 color-black text-capitalize mb-0">{`${userDetail?.name} ${t('Family included in Weddings guest list')}`}</p>
        </div>
      

       {[userDetail?.invitations[viewHistoryIndex]]?.filter(invitation => {
    const keysToCheck = ["whatsapp", "email", "address", "inPerson", "phone"];
    return keysToCheck?.some(key => {
        const sendValue = invitation?.[key]?.send;
        return sendValue === 1 && invitation?.[key]?.date !== undefined && invitation?.[key]?.date !== null && invitation?.[key]?.date !== "";
    });
})
.sort((a, b) => {
    const dateA = a?.whatsapp?.date || "";
    const dateB = b?.whatsapp?.date || "";
    return dateA?.localeCompare(dateB);
})
.map((invitation, index) => {
    const sendKey = ["whatsapp", "email", "address", "inPerson", "phone"].find(key => invitation?.[key]?.send === 1);
    const date = invitation?.[sendKey]?.date;

    if(invitation?.event_id?._id == userDetail?.events[viewHistoryIndex]?._id) {
        return (
            <div key={index} className="d-flex gap-4 mb-2">
                <p className="font-size-12 color-black text-capitalize mb-0">{moment(date).format("D MMMM YYYY")}</p>
                <p className="font-size-12 color-black text-capitalize mb-0">{`Invitation sent via ${sendKey}`}</p>
            </div>
        );
    }
})}



      
        </div>
        
      </div>
     
      </div>
      </div>
      ) : modalType === "filter" && (      <div className="modal-content">
      <div class="modal-header border-0 pb-0">
      <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Filter</h1>
      <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body mt-0">

    {!coupleURLFilter&&profile?.role=="planner"&&(<>
            <div class="mb-4 row">
              <label
                for="staticEmail"
                class="col-sm-1 font-size-14 align-self-center col-form-label"
              >
                {t("Couples")}
              </label>
              <div class="col-sm-11 d-flex gap-2 flex-wrap">
                {load ? (
                  new Array(5)
                    .fill(null)
                    .map((_, index) => <ButtonSkeleton key={index} />)
                ) : (
                  <>
                    <input
                     checked={!selectedCouple}
                     onChange={() => {setselectedCouple("");setevent_id([])}}
                      type="checkbox"
                      name="event-select"
                      id={5545}
                      class="btn-check"
                    />
                    <label class="btn wf-select-btn" for={5545}>
                      {t('All')}
                    </label>

                    {actualmyEvents?.map((e) => (
                      <>
                        <input
                           checked={selectedCouple==e?._id}
                           onChange={() => {setselectedCouple(e?._id);setevent([])}}
                          type="checkbox"
                          name="event-select"
                          id={e._id}
                          class="btn-check"
                        />
                        <label
                          class="btn wf-select-btn"
                          for={e._id}
                          // style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                        >
                        {`${e?.person1?.name||""} ${e?.person2?.name?"&":""} ${e?.person2?.name||""}`}
                        </label>
                      </>
                    ))}
                  </>
                )}
              </div>
            </div> </>)}

    {profile?.role!=="planner"&&(<><div class="mb-4 row">
          <label for="staticEmail" class="col-sm-1 font-size-14 align-self-center col-form-label">{t('Events')}</label>
          <div class="col-sm-11 d-flex gap-2 flex-wrap">
                {load? new Array(5).fill(null).map((_, index) => (
            <ButtonSkeleton key={index} />
          )) :
          <>
                <input onChange={()=>{setevent("")}} checked={event==""?true:false} type="checkbox" name="event-select" id={`eventtt`} class="btn-check" />
                <label class="btn wf-select-btn" for={`eventtt`}>{t('All')}</label>
         {myEvents?.map((e)=>(
          <>
                <input checked={event==e.Event._id?true:false} onChange={()=>{setevent(e.Event._id)}} type="checkbox" name="event-select" id={`${e.Event.event_name}-eventtt`} class="btn-check" />
                <label class="btn wf-select-btn" for={`${e.Event.event_name}-eventtt`} style={{'--bs-btn-active-bg':` ${e.Event.event_color}`}}>{e.Event.event_name}</label>
                </>
                ))}
                </>
}
          </div>
        </div></>)}

    {!coupleURLFilter && profile?.role=="planner"&& selectedCouple!==""&&(<><div class="mb-4 row">
              <label
                for="staticEmail"
                class="col-sm-1 font-size-14 align-self-center col-form-label"
              >
                {t("Events")}
              </label>
              <div class="col-sm-11 d-flex gap-2 flex-wrap">
                {load ? (
                  new Array(5)
                    .fill(null)
                    .map((_, index) => <ButtonSkeleton key={index} />)
                ) : (
                  <>
                    <input
                      checked={event?.length < 1 ? true : false}
                      onChange={() => setevent([])}
                      type="checkbox"
                      name="event-select"
                      id={56}
                      class="btn-check"
                    />
                    <label class="btn wf-select-btn" for={56}>
                      {t('All')}
                    </label>

                    {actualmyEvents?.filter((f)=> f._id==selectedCouple && f?.event_id)[0]?.Event?.map((e) => {
                      console.log('eeeeeeeeeeee', e)
                      return(
                      <>
                        <input
                          checked={event.includes(e._id)}
                          onChange={() => handleEventChange(e._id)}
                          type="checkbox"
                          name="event-select"
                          id={e._id}
                          class="btn-check"
                        />
                        <label
                          class="btn wf-select-btn"
                          for={e._id}
                          style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                        >
                          {e.event_name}
                        </label>
                      </>
                    )})}
                  </>
                )}
              </div>
            </div></>)}

        <div className="row mb-4 gy-4">
        <div className="col-lg-12 col-md-12">
        <div class="row">
          <label for="staticEmail" class="col-sm-2 font-size-14 align-self-center col-form-label">{t('Guestof')}</label>
          <div class="col-sm-10 d-flex gap-2 flex-wrap">
                <input  onChange={()=>setguestoffilter("")} checked={guestoffilter==""?true:false}  value="" type="checkbox" name="guest-off" id="alll" class="btn-check" />
                <label class="btn wf-select-btn" for="alll">{t('Both')}</label>

                <input onChange={()=>setguestoffilter("bride")} checked={guestoffilter=="bride"?true:false}  value="bride" type="checkbox" name="guest-off" id="bb" class="btn-check" />
                <label class="btn wf-select-btn" for="bb">{t('Bride')}</label>

                <input onChange={()=>setguestoffilter("groom")} checked={guestoffilter=="groom"?true:false}  value="groom" type="checkbox" name="guest-off" id="cc" class="btn-check" />
                <label class="btn wf-select-btn" for="cc">{t('Groom')}</label>
          </div>
        </div>
        </div>
        <div className="col-lg-12 col-md-12">
        <div class="row">
          <label for="staticEmail" class="col-sm-3 font-size-14 align-self-center col-form-label">{t('InvitationStatus')}</label>
          <div class="col-sm-9 d-flex gap-2 align-self-start flex-wrap">
                <>
                <input checked={invitation==""?true:false} onChange={()=>setinvitation("")} type="checkbox" name="payment-status" id={`option`} class="btn-check" />
                <label class="btn wf-select-btn" for={`option`}>{t('All')}</label>
                {Invitation_Status?.map((d)=>(
                  <>
                <input checked={invitation==d.option?.toLowerCase()?true:false} onChange={()=>setinvitation(d.option?.toLowerCase())} type="checkbox" name="payment-status" id={`${d.id}-option`} class="btn-check" />
                <label class="btn wf-select-btn" for={`${d.id}-option`}>{t(d.option)}</label>
                </>
                ))}
                </>
          </div>
        </div>
        </div>
        </div>
        <div className="row mb-4 gy-4">
        <div className="col-lg-12 col-md-12">
        <div class="row">
          <label for="staticEmail" class="col-sm-2 font-size-14 align-self-center col-form-label">{t('Guests')} {t('Type')}</label>
          <div class="col-sm-10 d-flex gap-2 flex-wrap align-self-center">
                <>
                <input checked={groupfilter==""?true:false} onChange={()=>setgroupfilter("")} type="checkbox" name="payment-status" id={`option-guest`} class="btn-check" />
                <label class="btn wf-select-btn" for={`option-guest`}>{t('All')}</label>
            {groupOptions?.map((gp)=>(
                <>
                <input checked={groupfilter==gp.option?.toLowerCase()?true:false} onChange={()=>setgroupfilter(gp.option?.toLowerCase())} type="checkbox" name="payment-status" id={`${gp.id}-option-guest`} class="btn-check" />
                <label class="btn wf-select-btn" for={`${gp.id}-option-guest`}>{t(gp.option)}</label>
            </>
            ))}
                </>
          </div>
        </div>
        </div>
        <div className="col-lg-12 col-md-12">
        <div class="row">
          <label for="staticEmail" class="col-sm-3 font-size-14 align-self-center col-form-label">{t('RSVPStatus')}</label>
          <div class="col-sm-9 d-flex gap-2 align-self-start flex-wrap">
            <>
            <input checked={rsvpfilter=="none"?true:false} onChange={()=>setrsvpfilter("none")} type="checkbox" name="payment-status" id={`option-rsvp`} class="btn-check" />
            <label class="btn wf-select-btn" for={`option-rsvp`}>{t('All')}</label>
                {RSVP_Status?.map((d)=>(
            <>
                <input checked={rsvpfilter!==""&&rsvpfilter==d.value?true:false} onChange={()=>setrsvpfilter(d.value)} type="checkbox" name="payment-status" id={`${d.value}-options-rsvp`} class="btn-check" />
                <label class="btn wf-select-btn" for={`${d.value}-options-rsvp`}>{t(d.option)}</label>
                </>
                ))}
                </>
          </div>
        </div>
        </div>
        </div>
    </div>
    <div class="modal-footer border-0 justify-content-center gap-2">
      <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>Close</button>
      <button  class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={()=>{setfiltertoggle(!filtertoggle); closeModal()}}>Apply Filter</button>
    </div>

    </div>)}
    </div>
</Modal>

  <section className="wf-guest-list px-md-5 pb-5 pt-2">
   
    <div className="">
    <div className="row mb-4">
    <div className="col-6">
    <h2 className="font-size-26 dm-font purple-color mb-0">{t('Guests')}</h2>
    </div>
    {myEvents?.length>0&&(<div className="col-6 d-flex justify-content-end gap-3 align-items-center">
    <button type="button" onClick={() => viewModal("", "userDetail", "add")} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">{t('Add')} {t('Guest')}</button>
    </div>)}
    </div>

    {!queryParams.get("couple") && !queryParams.get("events") && (<><div className="row mb-4 gy-3">
          <div className="col-md-4">
            <div className="wf-shadow-2 white-bg rounded p-3 h-100">
              <div className="d-flex justify-content-between mb-2">
                <div>
                  <p className="color-grey font-size-20 mb-0">Total Guests</p>
                  <p className="purple-color font-size-20 mb-0 fw-bold">{guests?.reduce((acc, curr) => acc + curr.male_count + curr.female_count + curr.child_count, 0)}
                  {/* <span className="color-grey fw-medium">/4000</span> */}
                  </p>
                  <p className="color-black font-size-18 mb-0">{myEvents?.filter((f)=>f?.Event?.event_name)?.length} {myEvents?.filter((f)=>f?.Event?.event_name)?.length>1?"Events":"Event"}</p>
                </div>
                <img width="75" className="" alt="icon" src={`../assets/images/guest-icon-wf.svg`} />
              </div>
                <div className="w-100 mb-3">
                      <p style={{ lineHeight: "normal" }} className="font-size-20 pink-color mb-1" >
                        <span className="color-grey">Invitation Sent </span>{
  guests?.reduce((count, item) => {
    const allEmpty = item?.invitations?.every(invitation => Object.keys(invitation).length === 0);
    // const invitationsLength = !allEmpty && item?.invitations?.filter(invitation => invitation?.guest_id && '_id' in invitation?.guest_id)?.length;
    if (!allEmpty) {
      const { male_count, female_count, child_count } = item;
      return count + male_count + female_count + child_count;
    }
    return count;
  }, 0) || 0
}</p>
                    <div className="d-flex gap-1 align-items-center">
                      <p className="font-size-20 pink-color mb-0">{!isNaN(inviteSentPercentage) && `${inviteSentPercentage||0}${'%'}`}</p>
                    <div class="progress w-100" style={{ height: "12px", width: "100%" }}>
                      <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{"width":`${!isNaN(inviteSentPercentage) &&inviteSentPercentage}%`, "backgroundColor": "var(--color4)"}} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                  </div>
                  <p style={{ lineHeight: "normal" }} className="font-size-20 pink-color mb-1" >
                        <span className="color-grey">RSVP Confirmed</span> {guests?.reduce((count, obj) => {
        const invitationsCount = obj.invitations.reduce((invCount, invite) => {
            if (invite.rsvp === 1) {
                return invCount + 1;
            }
            return invCount;
        }, 0);
        return count + invitationsCount;
    }, 0)}</p>
                <div className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ lineHeight: "normal" }} className="font-size-20 pink-color mb-1 text-start" >
                        Attending <b>{guests?.reduce((count, obj) => {
                       
                      const invitationsCount = obj.invitations?.filter((f)=>f.rsvp==1)?.reduce((invCount, invite) => {
                          
                            return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;  
                      }, 0);
                      return count + invitationsCount;
                  }, 0)}</b></p>
                      <p style={{ lineHeight: "normal" }} className="font-size-20 purple-color mb-1 text-end" >
                      Not Attending <b>{guests?.reduce((count, obj) => {
                       
                       const invitationsCount = obj.invitations?.filter((f)=>f.rsvp==0)?.reduce((invCount, invite) => {
                             return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;
                       }, 0);
                       return count + invitationsCount;
                   }, 0)}</b></p>
                    </div>
                    <div class="progress" style={{ height: "12px", width: "100%" }}>
                      <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${guests?.reduce((count, obj) => {
        const invitationsCount = obj.invitations?.filter(invite => 
            invite.rsvp === 1
        )?.reduce((invCount, invite) => 
            invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
        , 0) || 0;

        const totalGuestsCount = guests?.reduce((totalCount, guest) => 
            totalCount + guest.male_count + guest.female_count + guest.child_count
        , 0) || 0;

        console.log('totalGuestsCount', totalGuestsCount, invitationsCount);
        
        // Calculate percentage correctly and ensure toFixed is applied correctly
        const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
        return count + Math.round(percentageForGuest);
    }, 0)}%`, backgroundColor: "var(--color4)", }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
            </div>
          </div>
          <div className="col-md-8">
          <div className="wf-shadow-2 white-bg rounded p-2 h-100">
          <div className="row gx-2 gy-3" >
            <div className="col-md-4">
            <p className="purple-color font-size-20 mb-0 dm-font">All Events</p>
            <PieChart
              data={graphdata}
              animate= {true}
              // label={({ dataEntry }) => (
              //   `${dataEntry.title}: ${dataEntry.value}`
              // )}
             
              labelStyle={{
                fontSize: '10px',
                fontFamily: 'Arial, sans-serif',
                fontWeight: 'bold',
              }}
              style={{
                width: '275px',
                height: '275px',
              }}
              />
            </div>
            <div className="col-md-8">
              <div className="d-flex gap-2 flex-column">
              {myEvents?.map((e, index) => (
              e?.Event?._id&&(<div className="col-md-12">
                <div className="wf-shadow-2 rounded white-bg d-flex flex-wrap p-2 gap-md-res">
                <div className="col-md-2 col-6 align-self-center">
                <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                    {e?.Event?.event_name}
                  <span className="d-block mt-1" style={{ backgroundColor: `${e?.Event?.event_color}`, height:'3px', width:'60px' }}></span>
                </h4>
                </div>
                <div className="col-md-2 col-6 align-self-center">
                <p className="color-black font-size-14 mb-0">Total Guests</p>
                  <p className="purple-color font-size-14 mb-0 fw-bold">{guests?.reduce((sum, obj) => {
                  const event = obj.events.find(event => event?._id === e?.Event?._id);
                  if (event) {
                    const countsSum = obj.male_count + obj.female_count + obj.child_count;
                    return sum + countsSum;
                  }
                  return sum;
                }, 0)}</p>
                </div>
                <div className="col-md-3 col-12 pe-3">
                <div className="w-100">
                      <p className="font-size-14 pink-color mb-0" ><span className="color-grey">Invitation Sent </span>
                      {
                        guests?.filter((g) => 
                          g?.invitations?.some((s) => s.event_id?._id === e?.Event?._id)
                        )?.reduce((count, item) => {
                          const validInvitations = item?.invitations?.filter((invitation) => Object.keys(invitation).length > 0 && '_id' in invitation?.guest_id);
                        
                          if (validInvitations?.length > 0) {
                            return count + (item.male_count || 0) + (item.female_count || 0) + (item.child_count || 0);
                          }
                        
                          return count;
                        }, 0) || 0
                      }
                </p>
                    <div className="d-flex gap-1 align-items-center">
                    <p className="font-size-14 pink-color mb-0">
                    {(() => {
                      let totalFilledInvitations = 0;
                      let totalInvitations = 0;

                      guests?.forEach(item => {
                        const allEmpty = item?.invitations?.every(invitation => Object.keys(invitation).length === 0);
  
                        if (!allEmpty) {
                          totalFilledInvitations += !allEmpty&&item.invitations?.filter(invitation =>
                            '_id' in invitation?.guest_id && invitation?.event_id?._id === e?.Event?._id
                          ).length || 0;
                        }
  
  
                        totalInvitations += !allEmpty&&item.invitations?.filter(invitation =>
                          '_id' in invitation?.guest_id && invitation?.event_id?._id === e?.Event?._id
                        ).length || 0;
                      });

                      return totalInvitations > 0 ? `${((totalFilledInvitations / totalInvitations) * 100).toFixed(0)}%` : "0%";
                    })()}
                  </p>
                    <div class="progress w-100" style={{ height: "12px", width: "100%" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-label="Example 20px high"
                      style={{
                        width: (() => {
                          let totalFilledInvitations = 0;
                          let totalInvitations = 0;

                          guests?.forEach(item => {
                            const allEmpty = item?.invitations?.every(invitation => Object.keys(invitation).length === 0);

                            if (!allEmpty) {
                              totalFilledInvitations += !allEmpty&&item.invitations?.filter(invitation =>
                                '_id' in invitation?.guest_id && invitation?.event_id?._id === e?.Event?._id
                              ).length || 0;
                            }

                            totalInvitations += !allEmpty&&item.invitations?.filter(invitation =>
                              '_id' in invitation?.guest_id && invitation?.event_id?._id === e?.Event?._id
                            ).length || 0;
                          });

                          const percentage = totalInvitations > 0 ? (totalFilledInvitations / totalInvitations) * 100 : 0;
                          return `${percentage.toFixed(0)}%`;
                        })(),
                        backgroundColor: "var(--color4)"
                      }}
                      aria-valuenow="80%"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>

                    </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 col-12">
                <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-0">
                      <p className="font-size-14 pink-color mb-0 text-start" >Attending <b>{guests?.reduce((count, obj) => {
                       
                       const invitationsCount = obj.invitations?.filter((f)=>f.rsvp==1 && f?.event_id?._id === e?.Event?._id)?.reduce((invCount, invite) => {
                           
                             return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;  
                       }, 0);
                       return count + invitationsCount;
                   }, 0)}</b></p>
                      <p className="font-size-14 purple-color mb-0 text-end" >Not Attending <b>{guests?.reduce((count, obj) => {
                       
                       const invitationsCount = obj.invitations?.filter((f)=>f.rsvp==0 && f?.event_id?._id === e?.Event?._id)?.reduce((invCount, invite) => {
                             return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;
                       }, 0);
                       return count + invitationsCount;
                   }, 0)}
                  </b></p>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                    <p className="font-size-14 pink-color mb-0">
              {guests?.reduce((count, obj) => {
                  const invitationsCount = obj.invitations?.filter(invite => 
                      invite.rsvp === 1 && invite.event_id?._id === e?.Event?._id
                  )?.reduce((invCount, invite) => 
                      invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
                  , 0) || 0;

                  const totalGuestsCount = guests?.filter(guest => 
                      guest.events.some((g) => g._id == e?.Event?._id)
                  )?.reduce((totalCount, guest) => 
                      totalCount + guest.male_count + guest.female_count + guest.child_count
                  , 0) || 0;
  
                  const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
                  return count + Math.round(percentageForGuest);
              }, 0) + '%'}
</p>

                    <div class="progress w-100" style={{ height: "12px", width: "100%" }}>
                      <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${guests?.reduce((count, obj) => {
        const invitationsCount = obj.invitations?.filter(invite => 
            invite.rsvp === 1 && invite.event_id?._id === e?.Event?._id
        )?.reduce((invCount, invite) => 
            invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
        , 0) || 0;

        const totalGuestsCount = guests?.filter(guest => 
            guest.events.some((g) => g._id == e?.Event?._id)
        )?.reduce((totalCount, guest) => 
            totalCount + guest.male_count + guest.female_count + guest.child_count
        , 0) || 0;

        console.log('totalGuestsCount', totalGuestsCount, invitationsCount);
        
        // Calculate percentage correctly and ensure toFixed is applied correctly
        const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
        return count + Math.round(percentageForGuest);
    }, 0)}%`, backgroundColor: "var(--color4)", }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p className="font-size-14 purple-color mb-0">{guests?.reduce((count, obj) => {
        const invitationsCount = obj.invitations?.filter(invite => 
            invite.rsvp === 0 && invite.event_id?._id === e?.Event?._id
        )?.reduce((invCount, invite) => 
            invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
        , 0) || 0;

        const totalGuestsCount = guests?.filter(guest => 
            guest.events.some((g) => g._id == e?.Event?._id)
        )?.reduce((totalCount, guest) => 
            totalCount + guest.male_count + guest.female_count + guest.child_count
        , 0) || 0;

        console.log('totalGuestsCount', totalGuestsCount, invitationsCount);
        
        // Calculate percentage correctly and ensure toFixed is applied correctly
        const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
        return count + Math.round(percentageForGuest);
    }, 0) + '%'}</p>
                    </div>
                  </div>
                </div>
                
                </div>
              </div>)
              ))}
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>

        
        <h2 className="font-size-22 dm-font purple-color mb-2">Categories</h2>
        <div className="wf-shadow-2 rounded white-bg p-3 mb-4">
          <div className="row gx-2 gy-3">
            {groupOptions?.map((val, index) => (
          <div key={index} className="col-md-3">
              <div className="wf-shadow-2 rounded white-bg p-2">
                <div className="d-flex justify-content-between mb-3">
                <img style={{height:'auto', width:'45px', objectFit:'contain'}} className="" alt="icon" src={`${process.env.REACT_APP_IMGURL}${val.image}`} />
                <div className="w-75">
                  <div className="d-flex justify-content-between">
                  <p className="purple-color font-size-16 mb-0"><span className="fw-semibold">{guests?.filter(v => v.group?.toLowerCase() === val.option?.toLowerCase())?.reduce((sum, obj) => sum + (obj.male_count || 0) + (obj.female_count || 0) + (obj.child_count || 0), 0)}</span> Guests</p>
                  <p className="color-grey font-size-14 mb-0">Invitation Sent <span className="pink-color fw-bold">{guests?.reduce((count, item) => {
                const allEmpty = item?.invitations?.every(invitation => Object.keys(invitation).length === 0);
                console.log('allEmpty', allEmpty)
                // const invitationsLength = !allEmpty &&item.invitations?.filter(invitation => '_id' in invitation?.guest_id)?.length;
                if (!allEmpty && item.group?.toLowerCase() === val.option?.toLowerCase()) {
                  const { male_count, female_count, child_count } = item;
                  return count + male_count + female_count + child_count;
                }
                return count;
              }, 0)}</span></p>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                  <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase color-white">{val.option}</span>
                  <div className="d-flex gap-1 align-items-center w-100">
                  <p className="font-size-16 pink-color mb-0">{(((guests?.filter(v => v.group?.toLowerCase() === val.option?.toLowerCase())?.reduce((acc, guest) => acc + guest.male_count + guest.female_count + guest.child_count, 0) / guests?.reduce((acc, guest) => acc + guest.male_count + guest.female_count + guest.child_count, 0)) * 100) || 0).toFixed(0)}%</p>
                    <div class="progress w-100" style={{ height: "12px", width: "100%" }}>
                      <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${(((guests?.filter(v => v.group?.toLowerCase() === val.option?.toLowerCase())?.reduce((acc, guest) => acc + guest.male_count + guest.female_count + guest.child_count, 0) / guests?.reduce((acc, guest) => acc + guest.male_count + guest.female_count + guest.child_count, 0)) * 100) || 0).toFixed(0)}%`, backgroundColor: "var(--color4)", }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                  </div>
                  
                </div>
                </div>
                <div className="w-100">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <p className="font-size-16 pink-color mb-0 text-start" >Attending <b>{guests?.reduce((count, obj) => {
                      const invitationsCount = obj.invitations?.filter((f)=>f.rsvp === 1 && f.guest_id.group?.toLowerCase() === val.option?.toLowerCase()).reduce((invCount, invite) => {                         
                              return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;                                          
                      }, 0);
                      return count + invitationsCount;
                  }, 0)}</b></p>
                      <p className="font-size-16 purple-color mb-0 text-end" >Not Attending <b>{guests?.reduce((count, obj) => {
                      const invitationsCount = obj.invitations?.filter((f)=>f.rsvp === 0 && f.guest_id.group?.toLowerCase() === val.option?.toLowerCase()).reduce((invCount, invite) => {                         
                              return invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count;                                          
                      }, 0);
                      return count + invitationsCount;
                  }, 0)}</b></p>
                    </div>
                    <div className="d-flex gap-1 align-items-center">
                      <p className="font-size-16 pink-color mb-0">{guests?.reduce((count, obj) => {
                  const invitationsCount = obj.invitations?.filter(invite => 
                      invite.rsvp === 1 && invite.guest_id.group?.toLowerCase() === val.option?.toLowerCase()
                  )?.reduce((invCount, invite) => 
                      invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
                  , 0) || 0;

                  const totalGuestsCount = guests?.filter(guest => 
                    guest.group?.toLowerCase() === obj.group?.toLowerCase()
                  )?.reduce((totalCount, guest) => 
                      totalCount + guest.male_count + guest.female_count + guest.child_count
                  , 0) || 0;

                  console.log('invitationsCount,totalGuestsCount', invitationsCount,totalGuestsCount)
  
                  const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
                  return count + Math.round(percentageForGuest>100?100:percentageForGuest);
              }, 0) + '%'}</p>
                    <div class="progress w-100" style={{ height: "12px", width: "100%" }}>
                      <div class="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${guests?.reduce((count, obj) => {
                  const invitationsCount = obj.invitations?.filter(invite => 
                      invite.rsvp === 1 && invite.guest_id.group?.toLowerCase() === val.option?.toLowerCase()
                  )?.reduce((invCount, invite) => 
                      invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
                  , 0) || 0;

                  const totalGuestsCount = guests?.filter(guest => 
                    guest.group?.toLowerCase() === obj.group?.toLowerCase()
                  )?.reduce((totalCount, guest) => 
                      totalCount + guest.male_count + guest.female_count + guest.child_count
                  , 0) || 0;

                  console.log('invitationsCount,totalGuestsCount', invitationsCount,totalGuestsCount)
  
                  const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
                  return count + Math.round(percentageForGuest>100?100:percentageForGuest);
              }, 0)}%`, backgroundColor: "var(--color4)", }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p className="font-size-16 purple-color mb-0">{guests?.reduce((count, obj) => {
                  const invitationsCount = obj.invitations?.filter(invite => 
                      invite.rsvp === 0 && invite.guest_id.group?.toLowerCase() === val.option?.toLowerCase()
                  )?.reduce((invCount, invite) => 
                      invCount + invite.guest_id.male_count + invite.guest_id.female_count + invite.guest_id.child_count
                  , 0) || 0;

                  const totalGuestsCount = guests?.filter(guest => 
                    guest.group?.toLowerCase() === obj.group?.toLowerCase()
                  )?.reduce((totalCount, guest) => 
                      totalCount + guest.male_count + guest.female_count + guest.child_count
                  , 0) || 0;

                  console.log('invitationsCount,totalGuestsCount', invitationsCount,totalGuestsCount)
  
                  const percentageForGuest = totalGuestsCount > 0 ? (invitationsCount / totalGuestsCount * 100) : 0;
                  return count + Math.round(percentageForGuest>100?100:percentageForGuest);
              }, 0) + '%'}</p>
                    </div>
                  </div>
              </div>
            </div>
            ))}
          </div>
        </div></>)}
        
        

        <div className="row mb-3 gy-3">
        <div className="col-md-6">
        {coupleURLFilter?.length<1 && coupleEventURLFilter?.length<1 && (<p className="purple-color font-size-20 mb-0 dm-font">All Guests</p>)}
          </div>
          <div className="col-md-6 d-flex gap-4 align-items-center">
          {guests?.length>0&&(<><div class="flex-grow-1 wf-search-chats wf-search-chats-2">
              <input onChange={(e)=>setsearch(e.target.value)} type="text" class="form-control wf-search-chats-field"  placeholder="Search Guests..." />
              <Search width="18" height="18" className="wf-search-chats-icon" stroke="var(--color3)" />
          </div>
          <div className="d-flex align-items-center gap-2 cursor-pointer" onClick={() => viewModal("", "filter")}>
          <ListFilter size={22} stroke="var(--color3)" />
            <p className="font-size-16 purple-color mb-0 fw-medium">{t('Filters')}</p>
            </div></>)}
            {/* <div class="dropdown wf-list-dropdown">
            <a class="d-flex align-items-center gap-2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <ArrowUpDown size={22} stroke="var(--color4)" />
            <p className="font-size-16 pink-color mb-0 fw-medium">Sort By</p>
            </a>

            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </div> */}
          </div>
        </div>



        {/* below filter part */}

        <div className="row mb-4 gy-3">
        {filteredData?.length>0&&(<div className="col-lg-6 col-md-12 d-flex align-items-center gap-2 align-self-center">
          <div>
          <input checked={filteredData?.length == selectedinvitation?.length} onChange={handleSelectAllChange} type="checkbox" class="btn-check" id="all-select" />
          <label class="btn wf-btn-checkk" for="all-select"><i class="bi bi-check-lg d-flex"></i></label>
          </div>
          <p className="font-size-14 purple-color mb-0">{t('SelectAll')}</p>
        </div>)}
        {selectedinvitation?.length>0&&(<div className="col-lg-6 col-md-12">
          <div className="row align-items-center">
          <div className="col-2">
          <p className="font-size-14 color-black mb-0">{t('InviteFor')}</p>
          </div>
          <div className="col-7">
          <div class="dropdown wf-dropdown-101">
            <a class="dropdown-toggle font-size-12 color-black white-bg wf-shadow-2 py-3 px-2 rounded-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {sendeventnametoshow?sendeventnametoshow:"Select Event"}
            </a>

            <ul class="dropdown-menu wf-shadow-2">
            {myEvents?.map((e)=>(
              <li onClick={()=>{setsendeventid(e.Event._id);setsendeventnametoshow(e.Event.event_name)}}><a class="dropdown-item">{e.Event.event_name}</a></li>
            ))}
            </ul>
          </div>
          </div>
          {sendeventid&&(<div className="col-3 text-end">
          <button onClick={()=>handlesenddata(selectedinvitation,"","multiple")} type="button" class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">{t('SendInvite')}</button>
          </div>)}
          </div>
        </div>)}
        </div>



          {load? new Array(6).fill(null).map((_, index) => (
            <ListSkeleton key={index} />
          )):filteredData?.length>0?filteredData?.map((g,i)=>(<>
          <div key={i} className="wf-guest-group-list wf-shadow-2 px-3 py-3 white-bg rounded mb-3">
      <div className="task-ff p-0">
      <div className="row gy-3 justify-content-between">
        <div className="w-auto align-self-center">
          <input checked={selectedinvitation?.some((id)=>id.id==g._id)?true:false} onClick={()=>handleInviteChange(g)} type="checkbox" class="btn-check" id={g._id} />
          <label class="btn wf-btn-checkk2" for={g._id}><i class="bi bi-check-lg d-flex"></i></label>
        </div>

        <div className="col-lg-2 col-md-2 col-10 d-flex gap-3 align-items-center">
        <p className="font-size-16 color-black mb-0">{g.name}</p>
        {/* <div onClick={() => viewModal(g, "userDetail", "edit")} className="color-black font-size-14">
        <i class="bi bi-pencil cursor-pointer" ></i>
        </div>
        <div onClick={() =>viewModal(g?._id, "delete", "delete")} className="color-black font-size-14">
        <i class="bi bi-trash cursor-pointer" ></i>
        </div> */}
        </div>
        <div className="col-lg-3 col-md-2 col-12 d-flex justify-content-between align-items-center">
          <div className="d-flex gap-2 align-items-center">
          <img src={`../assets/images/wf-${g.group?.toLowerCase()}-group-icon.svg`} class="" alt="" width="30"/>
          <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase color-white">{t(g.group)}</span>
          </div>
          {profile?.role!=="planner"&&(<div className="d-flex gap-2 align-items-center">
        <img src={g?.guest_of=="groom"&&myEvents[0]?.person1?.user_purpose=="groom"&&myEvents[0]?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person1?.imageName}`:myEvents[0]?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${myEvents[0]?.person2?.imageName}`:"https://via.placeholder.com/100x100"} class="rounded-circle groom-bride-image" style={{height:'50px', width:'50px'}} />
        <div>
          <p className="font-size-14 color-black mb-0">Guest of</p>
          <p className="font-size-16 purple-color mb-0 fw-medium">{g?.guest_of?.charAt(0)?.toUpperCase() + g?.guest_of?.slice(1)}</p>
        </div>
        </div>)}
          
          {profile?.role=="planner"&&(<div className="d-flex gap-2 align-items-center">
          <div className='wf-up-avatar d-flex mb-2'>
                                        <div className='avatar-couple-first'>
                                            <img width="57" height="57" alt=""  src={g?.user_id?.person1&&g?.user_id?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${g?.user_id?.person1?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                        </div>
                                        <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                            <img width="57" height="57" alt="" src={g?.user_id?.person2&&g?.user_id?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${g?.user_id?.person2?.imageName}`:"https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                        </div>
                                        <div>
          <p className="font-size-14 color-black mb-0">Couple</p>
          <p className="font-size-16 purple-color mb-0 fw-medium">{g?.user_id[0]?.person1?.name||"partner"}&{g?.user_id[0]?.person2?.name||"partner"}</p>
        </div>
                                    </div>
        </div>)}

        </div>
        <div className="col-lg-2 col-md-4 col-7 d-flex align-items-center gap-3 justify-content-end">
          <div className="d-flex align-items-end gap-1">
          <img height="28" src="../assets/images/wf-male-guests-icon.svg" alt="" className="male-guest-icon" />
            <span style={{lineHeight:'1'}} className="font-size-14 mb-0 purple-color">
            {g.male_count}
            </span>
          </div>
          <div className="d-flex align-items-end gap-1">
          <img height="28" src="../assets/images/wf-female-guests-icon.svg" alt="" className="female-guest-icon" />
            <span style={{lineHeight:'1'}} className="font-size-14 mb-0 purple-color">
            {g.female_count}
            </span>
          </div>
          <div className="d-flex align-items-end gap-1">
            <img height="28" src="../assets/images/wf-child-guests-icon.svg" alt="" className="child-guest-icon" />
            <span style={{lineHeight:'1'}} className="font-size-14 mb-0 purple-color">
            {g.child_count}
            </span>
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-5 d-flex gap-2 justify-content-center align-items-center">
        {g?.address&&(<Home size={28} strokeWidth={1} stroke={g.address ? "#EE0E7B" : "#B1B1B1"} />)}
        {g?.whatsapp&&(<i style={{ color: g.whatsapp ? "#EE0E7B" : "#B1B1B1",fontSize:'26px' }} class="bi bi-whatsapp d-flex"></i>)}
        {g?.phone&&(<Phone size={28} strokeWidth={1} stroke={g.phone ? "#EE0E7B" : "#B1B1B1"} />)}
        {g?.email&&(<Mail size={28} strokeWidth={1} stroke={g.email ? "#EE0E7B" : "#B1B1B1"} />)}
      </div>
      <div className="col-lg-2 col-md-3 col-12 d-flex justify-content-end gap-4 align-items-center">
      <p className="font-size-16 purple-color mb-0 fw-medium">{g?.male_count + g?.female_count + g?.child_count} {g?.male_count + g?.female_count + g?.child_count>1?<>{t('Guests')}</>:<>{t('Guest')}</>}</p>
        <div onClick={() => handleToggleChange(g?._id)} className="cursor-pointer purple-color">
        {toggle1?.includes(g?._id) ?<i class="fas fa-chevron-up"></i>:<i class="fas fa-chevron-down"></i>}
        </div>
        <div class="dropdown wf-list-dropdown">
                    <a class="color-grey font-size-22" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots-vertical"></i>
                    </a>

                    <ul class="dropdown-menu">
                    {/* {g?.invitations?.length>0&&(<p onClick={() => viewModal(g, "invites-history", "invites-history",i)} className="font-size-10 pink-color mb-0 cursor-pointer"><u>{t('ViewHistory')}</u></p>)}  */}
                    <li><a class="dropdown-item" onClick={() => viewModal(g, "invites-history", "invites-history",i)}>History</a></li>
                    <li><a class="dropdown-item" onClick={() => viewModal(g, "userDetail", "edit")}>Edit</a></li>
                    <li><a class="dropdown-item" onClick={() =>viewModal(g?._id, "delete", "delete")}>Delete</a></li>
                    </ul>
                  </div>
      </div>
      </div>
      <div className={`wf-subtasks wf-budget-inner ${toggle1?.includes(g?._id)&&"show"}`}>
      {g?.events?.map((e,i)=>(
      <div className="mb-3 wf-shadow-2 px-3 py-3 white-bg rounded">
      <div className="row mb-1 gy-3 justify-content-between">
      <div className="col-lg-2 col-6 align-self-center">
      <p className="font-size-16 mb-1 text-capitalize d-flex flex-column align-items-start w-auto">
      {e.event_name}
        <span className="d-block" style={{ backgroundColor: `${e.event_color}`, height:'3px', width:'80px', marginTop:'2px' }}></span>
      </p>
       {/* {g?.invitations?.length>0&&(<p onClick={() => viewModal(g, "invites-history", "invites-history",i)} className="font-size-10 pink-color mb-0 cursor-pointer"><u>{t('ViewHistory')}</u></p>)}  */}
      </div>
      <div className="col-lg-2 col-6 align-self-center">
      <p className="font-size-14 color-black text-capitalize mb-0">{t('Status')}:  
      
      {g?.invitations?.length>0 && g?.invitations?.some(obj => obj?.event_id?._id == e?._id) ? (
     
        <span className="purple-color" style={{marginLeft:'5px'}}>{t('Invited')}</span>
      
      ):(<span className="pink-color" style={{marginLeft:'5px'}}>{t('NotInvited')}</span>)}
     
        
     
    
      </p>
      <p className="font-size-12 color-black text-capitalize mb-0">
    {g?.invitations
        ?.filter((invitation,i) => {
          if(i==0){
            const keysToCheck = ["whatsapp", "email", "address", "inPerson", "phone"];
            return keysToCheck?.some(key => {
                const sendValue = invitation[key]?.send;
                return sendValue === 1 && invitation[key]?.date !== undefined && invitation[key]?.date !== null && invitation[key]?.date !== "";
            });
        }})
        .sort((a, b) => {
            const getLeastDate = (invitation) => {
                const validDates = ["whatsapp", "email", "address", "inPerson", "phone"]
                    .filter(key => invitation[key]?.send === 1 && invitation[key]?.date !== undefined && invitation[key]?.date !== null && invitation[key]?.date !== "")
                    .map(key => invitation[key]?.date);

                return validDates.length > 0 ? Math.min(...validDates) : null;
            };

            const dateA = getLeastDate(a);
            const dateB = getLeastDate(b);

            return dateA && dateB ? dateA.localeCompare(dateB) : 0;
        })
        .map((invitation) => {
            const sendKey = ["whatsapp", "email", "address", "inPerson", "phone"].find(key => invitation[key]?.send === 1);
            const date = invitation[sendKey]?.date;

            return invitation?.event_id?._id==e._id&&date ? moment(date).format("D MMMM YYYY") : null;
        })}
</p>

      </div>
     
      {g?.invitations?.length>0&&g?.invitations?.some(obj => [0, 1, 2, 3].includes(obj?.rsvp)&& obj?.event_id?._id == e?._id)&&(<div className="col-lg-3 col-md-4 col-12 d-flex align-items-center gap-3 justify-content-md-center">
      <p className="font-size-12 color-black text-capitalize mb-0">{t('RSVP')}</p>
        <div class="dropdown wf-list-dropdown">
        <div role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase color-white">
          {g?.invitations?.length>0 && g?.invitations?.some(obj => obj?.rsvp == 1 && obj?.event_id?._id == e?._id) && (
            <>
               {t('Attending')}
            </>
          )}
          {g?.invitations?.length>0 && g?.invitations?.some(obj => obj?.rsvp == 0 && obj?.event_id?._id == e?._id) && (
            <>
              {t('Not Attending')}
            </>
          )}
          {g?.invitations?.length>0 && g?.invitations?.some(obj => obj?.rsvp == 3 && obj?.event_id?._id == e?._id) && (
            <>
              {t('Maybe')}
            </>
          )}
          {g?.invitations?.length>0 && g?.invitations?.some(obj => obj?.rsvp == 2 && obj?.event_id?._id == e?._id) && (
            <>
              {t('Pending')}
            </>
          )}
          {/* {g?.invitations?.some(obj => obj?.rsvp !== 2 && obj?.rsvp !== 0 && obj?.rsvp !== 1 && obj?.rsvp !== 3 && obj?.event_id?._id !== e?._id) && (
            <>
              {t('Not Applicable')}
            </>
          )}  */}
        
         
        </span>
        </div>
        <ul class="dropdown-menu">
          <li onClick={()=>handleChangeRSVP(1,g?._id,e?._id)}>
              <a class="dropdown-item" ><CalendarCheck2 size={10} /> {t('Attending')}</a>
          </li>
          <li onClick={()=>handleChangeRSVP(0,g?._id,e?._id)}>
              <a class="dropdown-item" ><CalendarX2 size={10} /> {t('Not Attending')}</a>
          </li>
          <li onClick={()=>handleChangeRSVP(3,g?._id,e?._id)}>
              <a class="dropdown-item" ><HelpCircle size={10} /> {t('Maybe')}</a>
          </li>
          <li onClick={()=>handleChangeRSVP(2,g?._id,e?._id)}>
              <a class="dropdown-item" ><Loader size={10} /> {t('Pending')}</a>
          </li>
        </ul>
      </div>
      </div>)}

      <div className="col-lg-2 col-md-4 col-12 text-end align-self-center">
      <button onClick={()=>handlesenddata(g,e,"single")} class="btn wf-btn-2 wf-pink-outline-btn py-2 px-4 rounded-2">{t('SendInvite')}</button>
      </div>
      </div>
      </div>
      ))}
      </div>
      </div>
      
      </div>
          </>
            )):<NoEventUI name={"Guests"} />}
    </div>
  </section>
</>
  );
};

export default GuestList;




