import React, { useState } from "react";
import {  useNavigate, useLocation } from "react-router-dom";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlogCat,  getProfile, } from "../../store/slices/userSlice";
import axios from "axios";
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { useTranslation } from "react-i18next";



function Blogs_Dashboard() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const profile = useSelector(getProfile);
  const [data, setdata] = useState([]);
  const [blogcategories, setblogcategories] = useState([]);
  const [value, setvalue] = useState([]);


  

  const BlogCategories = async () => {
    try {
      setblogcategories(null);
      const response = await dispatch(getAllBlogCat()).unwrap();
      setblogcategories(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const handleChange = async (e) => {
    if (!value.includes(e)) {
      setvalue([...value, e.toString()]);
    } else {
      setvalue(value.filter((service) => service !== e));
    }
  };


  const Details = async () => {
    try {
      setload(true);

      const response = await axios.get(`vendorpage/getfilterblogs`, {
        params: {
          user_id: profile ? profile?._id : "",
          page: 1,
          blog_category_id: value.length>0?JSON.stringify(value):JSON.stringify([]),
        }
        });


      setdata(response?.data?.results);
      setload(false);
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    BlogCategories();
  }, []);
  
  useEffect(() => {
    Details()
     
 }, [value]);


  return (
    <>
    <div className="wf-shortlist-dashboard">
    <div className="d-flex gap-4 align-items-end mb-2">
        <h2 className="font-size-26 dm-font color-black mb-0">{t('Ideas&Inspirations')}</h2>
        <a className="font-size-14 pink-color mb-0" href="/ideas-inspiration"><u>{t('ViewAll')}</u></a>
    </div>
    <div className="d-flex gap-2 wf-select-btn mb-3 flex-wrap">
    {load ? (
    new Array(8).fill(null).map((_, index) => (
      <ButtonSkeleton key={index} />
    ))
  ) : (
    <>
      <input onChange={()=>setvalue([])} checked={value.length<1?true:false} type="checkbox" name="blog-select" className="btn-check" id={11} />
      <label className="btn wf-select-btn" for={11}>
        All
      </label>
      {blogcategories?.map((cat, index) => (
        <>
          <input onChange={(e)=>handleChange(e.target.value)} value={cat._id} checked={value.includes(cat._id)?true:false} type="checkbox" name="blog-select" className="btn-check" id={cat._id} />
          <label className="btn wf-select-btn" for={cat._id}>
            {cat.name}
          </label>
        </>
      ))}
    </>
  )}
    </div>
    <div className="wf-filter-category-slider wf-saved-pins-slider wf-shadow-2 white-bg rounded py-3 px-4 mb-3">
    <Swiper
    modules={[Navigation, A11y]}
    spaceBetween={15}
    // slidesPerView={3}
    navigation
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    breakpoints={{
      468: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 4,
      },
    }}>

      {load ? (<div className="row">
    {new Array(4).fill(null).map((_, index) => (
      <VendorCardSkeletonHome key={index} />
    ))}
  </div>
) : data?.map((b) => (
  <SwiperSlide>
    <div key={b.id} className="blog-card blog-card-dashboard ">
      <div className="blog-card-body">
      <a
          className="color-black"
          onClick={()=>{
          
          }}
          href={`/blog/${b?.title
            .replace(/[^\w\s’']/gi, "")
            .replace(/’s\s*/gi, "-")
            .replace(/['"]/g, "")
            .replace(/\s+/g, "-")
            .toLowerCase()}`}
          
          >
          <img alt={b.store_name} src={`${process.env.REACT_APP_IMGURL}${b?.image}`} className="blog-image w-100"/>
        </a>
        {b.category_id?.name?(<div className="d-flex align-items-center top-box">
          <span className="font-size-10 rounded-1 wf-purple-btn py-1 px-2 text-uppercase cursor-pointer">
          <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${b?.category_id?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{b?.category_id?.name}</a>
          </span>
        </div>):null}
        {b.blog_category_id?.name?(<div className="d-flex align-items-center bottom-box">
          <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase cursor-pointer">
          <a href={`${process.env.REACT_APP_FrontURL}/ideas-inspiration/${b.blog_category_id?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`}>{b?.blog_category_id?.name}</a>
          </span>
        </div>):null}
      </div>
      <div className="blog-card-footer">
        <h3 className="font-size-16 dm-font fw-medium mb-1" >
        <a
            className="purple-color"
            onClick={()=>{
           
            }}
            href={`/blog/${b?.title
              .replace(/[^\w\s’']/gi, "")
              .replace(/’s\s*/gi, "-")
              .replace(/['"]/g, "")
              .replace(/\s+/g, "-")
              .toLowerCase()}`}
              
            >        {b.title.substring(0,35)}...</a></h3>
        <p className="font-size-12 color-black mb-1">{b.excerpt.substring(0,40)}...</p>
        <div className="d-flex align-items-center justify-content-between">
        <p className="font-size-12 color-black mb-0">{moment(b?.createdAt).format("D MMMM YYYY")}</p>
        {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
        </div>
      </div>
    </div>
    </SwiperSlide>
  ))}
  </Swiper>
  </div>
    </div>
    
    </>
  );
}

export default Blogs_Dashboard;