import React, { useEffect, useState, useRef } from "react";
import Header from "./HeaderLight";
import Footer from "./Footer";
import Cheerio from 'cheerio';
import { Pin } from "lucide-react";
import { PinOff } from "lucide-react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  Likes,
  AddComments,
  PinUnPin,
  getServices,
} from "../../store/slices/userSlice";
import moment from "moment";
import DOMPurify from 'dompurify';
import {
  getProfile
} from "../../store/slices/userSlice";
import axios from "axios";
import parse from "html-react-parser";
import BlogTemplateSkeleton from "../LoadingSkeleton/BlogTemplateSkeleton";
import { useTranslation } from "react-i18next";
import VendorCardSkeleton from "../LoadingSkeleton/VendorCardSkeleton";
import CustomAd from "../../components/Layout/CustomAd";


const BlogDetails1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(getProfile);

  // const data= location?.state?.data

  const [load, setload] = useState(false);
  const [blogs, setblogs] = useState([]);
  const [data, setdata] = useState([]);
  const [comment, setcomment] = useState("");
  const [readTime, setreadTime] = useState("");
  const [categories, setcategories] = useState(null);
  const [category, setcategory] = useState("");
  const [vendors, setvendors] = useState(null);

  // const urlParts = window.location.pathname.split('/');
  // const slug = urlParts[urlParts.length - 1];
  const urlParts = window.location.pathname.split('/');
  let slug = urlParts[urlParts.length - 1];

  if (!slug && urlParts.length >= 2) {
    slug = urlParts[urlParts.length - 2];
  }
  
  // Calculate reading time
  const calculateReadingTime = (text) => {
    const wordsPerMinute = 225; 
    const textWithoutImages = text.replace(/<img[^>]*>.*?<\/img>/gi, '');
    const words = textWithoutImages.split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    setreadTime(Math.ceil(minutes));
  };

  const fetchPosts = async () => {
    try { 
      setload(true)
        const response = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/posts?slug=${slug}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();

        if (data.length === 0  && !slug) {
          navigate('/404');
          return;
        }

        // Fetching author, tags, categories, and featured image data for each post
        const postsWithData = await Promise.all(
            data.map(async post => {
                // Fetch author data
                const authorResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/users/${post.author}`);
                if (!authorResponse.ok) {
                    throw new Error('Error fetching author data');
                }
                const authorData = await authorResponse.json();

                // Fetch tags data
                const tagsResponse = await Promise.all(
                    post.tags.map(async tagId => {
                        const tagResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/tags/${tagId}`);
                        if (!tagResponse.ok) {
                            throw new Error('Error fetching tag data');
                        }
                        const tagData = await tagResponse.json();
                        return tagData.name;
                    })
                );

                // Fetch categories data
                const categoriesResponse = await Promise.all(
                    post.categories.map(async categoryId => {
                        const categoryResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/categories/${categoryId}`);
                        if (!categoryResponse.ok) {
                            throw new Error('Error fetching category data');
                        }
                        const categoryData = await categoryResponse.json();
                        return categoryData.name;
                    })
                );

                // Fetch featured image data
                const mediaResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/media/${post.featured_media}`);
                // if (!mediaResponse.ok) {
                //     throw new Error('Error fetching featured image data');
                // }
                const featuredImageResponse = await mediaResponse.json();

                return {
                    ...post,
                    authorName: authorData || "",
                    tags: tagsResponse || "",
                    categories: categoriesResponse || "",
                    featured_media: featuredImageResponse || ""
                };
            })
        );

        setdata(postsWithData[0]);
        setload(false)
    } catch (error) {
        console.error('Error fetching data:', error);
        setload(false)
    }
};

  const abcd=()=>{
    const p= location.pathname.split('/')
    const p1= decodeURIComponent(p[p.length-1])
    const originalTitle = p1
    console.log('originalTitleeee', originalTitle)
    console.log("ttttttttt",originalTitle)
  }

  useEffect(() => {
    fetchPosts();
    window.scrollTo({ top: 0, left: 0})
    abcd()
  }, [slug, location.pathname]);

  
  
  const handlePin = async (image, id, title) => {
    console.log("id", id);
    console.log("image", image);
    
    const result = await dispatch(
      PinUnPin({ blog_id: id, imageName: image, user_id: profile?._id })
    ).unwrap();
    
    if (result) {
      const $ = Cheerio.load(data.description);
      const imgTag = $(`img[src="${image}"]`);

      if (imgTag.length > 0) {
        const isPinned = imgTag.attr('data-ispinned') === 'true';
        imgTag.attr('data-ispinned', isPinned ? 'false' : 'true');
        
        setdata((prevData) => ({
          ...prevData,
          description: $.html(),
        }));
      }
    }
  };
  
  
  
  const sanitizedHtml = DOMPurify.sanitize(data?.content?.rendered);
  const sanitizedexcerpt = DOMPurify.sanitize(data?.excerpt?.rendered);
  
  const parsedHtml = parse(sanitizedHtml, {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'a' && domNode.children.length === 1) {
        const childNode = domNode.children[0];
        if (childNode.type === 'tag' && childNode.name === 'img') {
          const isPinned = childNode.attribs['data-ispinned'] === 'false';
          console.log('isPinned', isPinned);
          const iconComponent = isPinned ? <Pin /> : <PinOff />;
          const imgSrc = childNode.attribs.src;
          
          return (
            <div className="blog-pin-image">
              <img src={imgSrc} alt="Image" />
              {profile ? (
                <span
                className="pin-blog"
                onClick={() => handlePin(imgSrc, data?._id, data?.title)}
                >
                  {iconComponent}
                </span>
              ) : null}
            </div>
          );
        }
      } else if (domNode.type === 'tag' && domNode.name === 'img') {
        const isPinned = domNode.attribs['data-ispinned'] === 'false';
        console.log('isPinned', isPinned);
        const iconComponent = isPinned ? <Pin /> : <PinOff />;
        const imgSrc = domNode.attribs.src;
        
        return (
          <div className="blog-pin-image">
            <img src={imgSrc} alt="Image" />
            {profile ? (
              <span
              className="pin-blog"
              onClick={() => handlePin(imgSrc, data?._id, data?.title)}
              >
                {iconComponent}
              </span>
            ) : null}
          </div>
        );
      } else {
        return domNode;
      }
    },
  });

  const shareCurrentPage = async () => {
    try {
      if (navigator.share) {
        
        await navigator.share({
          title: 'Share this link',
          url: window.location.href,
        });
      } else {
        
        const fallbackUrl = `${process.env.REACT_APP_FrontURL}/share?url=${encodeURIComponent(window.location.href)}`;
        window.open(fallbackUrl, '_blank');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };
  
  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, '_blank');
  };
  
  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
    window.open(whatsappShareUrl, '_blank');
  };
  
  useEffect(() => {
    calculateReadingTime(sanitizedHtml);
  }, [sanitizedHtml]);

  const strippedExcerpt = data?.excerpt?.rendered?.replace(/<\/?p>/g, '');

  
  console.log(data, "data");
  console.log(strippedExcerpt, "strippedExcerpt");

  const getCategories = async () => {
    try{
      setcategories(null)
      const response = await dispatch(getServices()).unwrap()
      setcategories(response?.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if(categories && data){
      const blogCategoryName = data?.categories?.[0] || data?.categories?.[0]
      const findCategory = categories?.find(data => data?.name === blogCategoryName)
      setcategory(findCategory)
    }
  }, [categories, data]);

  console.log(category, "category");
  console.log(categories, "categories");
  console.log(vendors, "vendors");

  const getVendors = async () => {
    try {
      setvendors(null)
      const response = await axios.post('vendorpage/getallstores', {
          page: 1,
          user_id: profile ? profile?._id : "",
          text: "",
          category: JSON.stringify([category?._id]),
          sub_category: JSON.stringify([]),
          country: JSON.stringify([]),
          city: JSON.stringify([]),
          area: JSON.stringify([]),
          question: JSON.stringify([]),
          attribute: JSON.stringify([]),
          sub_attribute: JSON.stringify([]),
          min_price: "",
          max_price: "",
          sortby: "latest",
          lang: "en",
          countryType: "pakistan"
      });

      const { results: newResults } = response?.data;

      console.log(response, "response");
      setvendors(newResults)
  
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if(category){
      getVendors();
    }
  }, [category]);
  
  
  return (
    <>
    <Helmet>
        <title>{data?.yoast_head_json?.og_title}</title>
        <meta name="description" content={data?.yoast_head_json?.description}/>
        <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/${slug}`}/>
    </Helmet>

    <Header theme="dark" />
    {load ? (<BlogTemplateSkeleton />) : (<>
      <section className="single-blog-1">
        <div className="container">
          <div class="border-top  mb-3 mt-2"></div>
          <div className="row">
            <div className="col-6">
              <div className="go-back-btn" onClick={()=>navigate("/blog")}><i class="fas fa-arrow-left"></i> &nbsp;{t('GoBack')}</div>
            </div>
            <div className="col-6 d-flex justify-content-end gap-2" >
              <div onClick={shareCurrentPage} className="options-vendor-storefront">
                <div className="share-option">
                <i class="fas fa-share-alt"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div onClick={shareOnFacebook} className="share-option">
                <i class="bi bi-facebook"></i>
                </div>
              </div>
              <div className="options-vendor-storefront">
                <div onClick={shareOnWhatsApp} className="share-option">
                <i class="bi bi-whatsapp"></i>
                </div>
              </div>
            </div>         
          </div>
          <div>
            <img src={`${data?.featured_media?.link}`} className="single-blog-image mt-3 mb-3"/>
            <h1 style={{lineHeight:'1.2'}} className="h2-title playfair-font color-black mb-md-0 mb-2">{data?.title?.rendered}</h1>
            <div className="d-flex flex-wrap align-items-center gap-4 mb-3 before-css">
              <p className="font-size-20 color-black fw-semibold mb-0">{t('Postby')} <span className="pink-color">{data?.authorName?.name}</span></p>
              <p className="font-size-20 color-black fw-semibold mb-0"><a href={category ? `${process.env.REACT_APP_FrontURL}/suppliers/${category?.slug}` : ""} className="pink-color">{category ? category?.name : data?.categories?.join(', ')}</a></p>
              <p className="font-size-20 color-black fw-semibold mb-0">{t('Publishedon')}{" "}<span className="pink-color">{moment(data?.date).format("D MMMM YYYY")}</span> </p>
              <p className="font-size-20 color-black fw-semibold mb-0">{t('ReadMins')}{" "}<span className="pink-color">{readTime}</span></p>
            </div>
          </div>
        </div>
      </section>
      <section className="single-blog-2 pb-5">
        <div className="container">
          <div className="row gy-3 gx-5">
            <div className="col-lg-8 col-md-12" >
              {/* <div className="blog-content" dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}/> */}
              <div className="blog-content">{parsedHtml}</div>
            </div>
            <div className="col-lg-4 col-md-12 position-relative overflow-hidden">
            {vendors?.length > 0 ?<>
            <h2 className="font-size-26 dm-font mb-3">{category?.name} Suppliers</h2>
            <div className="row gy-4 mb-4">
            {vendors?.slice(0, 4)?.map((data, i)=>(
            <div key={i} className="col-lg-12 vendor-card">
              <div className="vendor-card-body">
                <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${data.slug}`}>
                <img alt={data.store_name} src={`${process.env.REACT_APP_IMGURL}${data?.image}`} className="vendor-image w-100"/>
                </a>
                <div className="d-flex justify-content-between align-items-center top-box">
                  {data?.is_featured==1?(<span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase">{t('Featured')}</span>):null}
                </div>
                <div className="d-flex align-items-center bottom-box">
                  <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                  <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${data?.prefered_services[0]?.slug}`}>{data?.prefered_services[0]?.name}</a>
                  </span>
                </div>
              </div>
              <div className="vendor-card-footer">
                <h3 className="font-size-18 fw-semibold mb-1" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${data.slug}`} className="color-black">{data.store_name}</a></h3>
                <div className="d-flex justify-content-between align-items-center">
                <p className="font-size-16 color-black mb-0">{`${data.cities.length>0?data.cities[0]?.name:""}${data.cities.length>0&&data.country.length>0?",":""} ${data.country.length>0?data.country[0]?.name:""}`}</p>

                  <p className="font-size-16 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{data?.reviews?.average}</span> ({data?.reviews?.total})</p>
                </div>
              </div> 
            </div>
            ))}
            </div>
            </> : null}
            <div id="download-app" className="mb-3">
          <h2 className="font-size-26 dm-font color-black mb-2">{t('DownloadourMobileApp')}</h2>
            <p className="font-size-14 purple-color mb-2">{t('StartPlanningYourDreamWeddingToday')}!</p>
            <div className="wf-shadow-2 white-bg rounded py-3 px-4">
            <img class="img-fluid w-100 mb-4" src={`${process.env.REACT_APP_FrontURL}/assets/images/download-app-graphics.svg`} alt=""/>
            <div className="d-flex align-items-center gap-3 mb-3 justify-content-center">
            <Link to="https://play.google.com/store/apps/details?id=com.shaditayari.app"><img width={150} class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt=""/></Link>
            <Link to="https://apps.apple.com/pk/app/shadi-tayari/id6504075843"><img width={150} class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt=""/></Link>
          </div>
          <h2 className="font-size-20 color-black mb-0 text-center dm-font"><a href="https://www.shaditayari.pk/download" className="color-black"><u>{t('Download')} <span className="pink-color">Shadi Tayari</span> {t('forFree')}!</u></a></h2>
            </div>
          </div>
              <div className="ad-box mt-3">
              <CustomAd height="400" width="400" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
              </div>
            </div>
          </div>
        </div>
      </section>
      </>)}
      <Footer />

      
      </>
  );
};

export default BlogDetails1;