import React from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";

const Support = () => {
  return (
    <>
    <Helmet>
        <title>Support - Shadi Tayari</title>
        <meta name="description" content="We are here to assist you with all your wedding planning needs. Our goal is to make your experience seamless and enjoyable."/>
        <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/support`}/>
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">Support</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <h2 className='font-size-30 fw-semibold'>Welcome to the ShadiTayari.pk Support Center!</h2>
    <p className="font-size-18">We are here to assist you with all your wedding planning needs. Our goal is to make your experience seamless and enjoyable. Whether you're a couple planning your big day or a vendor looking to list your services, we’ve got you covered.</p>

    <h2 className='font-size-30 fw-semibold'>Frequently Asked Questions</h2>
    <h2 className='font-size-30 fw-semibold'>1. How do I list my services on ShadiTayari.pk?</h2>
    <p className="font-size-18">Step 1: Register for an account by clicking on the “Sign Up” button at the top right corner of our homepage.</p>
    <p className="font-size-18">Step 2: Verify your email address and log in to your account.</p>
    <p className="font-size-18">Step 3: Navigate to the “List Your Services” section.</p>
    <p className="font-size-18">Step 4: Fill out the listing form with details about your services, including location, offerings, pricing, and photos.</p>
    <p className="font-size-18">Step 5: Submit your listing for review. Our team will verify the information and publish your services within 24-48 hours.</p>

    <h2 className='font-size-30 fw-semibold'>2. How can I find and book wedding suppliers and venues?</h2>
    <p className="font-size-18">Step 1: Use the search bar on our homepage to enter your desired location and type of service.</p>
    <p className="font-size-18">Step 2: Browse through the listings and use filters to narrow down your options.</p>
    <p className="font-size-18">Step 3: Click on a listing to view more details, read reviews, and check availability.</p>
    <p className="font-size-18">Step 4: Follow the booking instructions provided in the listing. Some suppliers and venues can be booked directly through our platform, while others may require you to contact them.</p>

    <h2 className='font-size-30 fw-semibold'>3. What are the benefits of using ShadiTayari.pk?</h2>
    <p className="font-size-18">Comprehensive listings of wedding suppliers and venues across Pakistan.</p>
    <p className="font-size-18">Easy-to-use platform with advanced search and filter options.</p>
    <p className="font-size-18">Verified reviews and ratings from real customers.</p>
    <p className="font-size-18">Secure booking process and customer support.</p>
    <p className="font-size-18">Access to wedding planning resources and inspiration.</p>

    <h2 className='font-size-30 fw-semibold'>4. I forgot my password. How can I reset it?</h2>
    <p className="font-size-18">Click on the “Forgot Password” link on the login page.</p>
    <p className="font-size-18">Enter your registered email address and click “Submit.”</p>
    <p className="font-size-18">Check your email for a password reset link and follow the instructions.</p>

    <h2 className='font-size-30 fw-semibold'>5. How do I contact customer support?</h2>
    <p className="font-size-18">You can reach our support team by emailing <a href="mailto:support@shaditayari.pk">support@shaditayari.pk</a> or by filling out the contact form below. Our team is available 24/7 to assist you with any issues or questions.</p>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default Support