import React from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";

const CopywriteNotice = () => {
  return (
    <>
    <Helmet>
        <title>Copyright Notice - Shadi Tayari</title>
        <meta name="description" content="Explore the comprehensive FAQ section of ShadiTayari, the leading wedding planning app. Find answers to common questions about our app's features, services, and wedding planning tips."/>
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">Copyright Notice</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <p className="font-size-18">© 2024 ShadiTayari.pk. All Rights Reserved.</p>
    <p className="font-size-18">Welcome to ShadiTayari.pk. This website and its content are protected by copyright laws. The following terms and conditions govern the use of our website and its content.</p>

    <h2 className='font-size-30 fw-semibold'>Ownership of Content</h2>
    <p className="font-size-18">All content on ShadiTayari.pk, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, is the property of ShadiTayari.pk or its content suppliers and is protected by international copyright laws. The compilation of all content on this site is the exclusive property of ShadiTayari.pk and is protected by international copyright laws.</p>

    <h2 className='font-size-30 fw-semibold'>Use of Content</h2>
    <p className="font-size-18">You are granted a limited license to access and make personal use of ShadiTayari.pk. This license does not include any resale or commercial use of this site or its contents; any collection and use of any listings, descriptions, or prices; any derivative use of this site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools.</p>
    <p className="font-size-18">ShadiTayari.pk or its content suppliers grant you a limited, revocable, and nonexclusive right to create a hyperlink to the home page of ShadiTayari.pk so long as the link does not portray ShadiTayari.pk, its affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter.</p>

    <h2 className='font-size-30 fw-semibold'>Prohibited Actions</h2>
    <p className="font-size-18">You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of ShadiTayari.pk and our affiliates without express written consent. You may not use any meta tags or any other "hidden text" utilizing ShadiTayari.pk's name or trademarks without the express written consent of ShadiTayari.pk.</p>

    <h2 className='font-size-30 fw-semibold'>Copyright Complaints</h2>
    <p className="font-size-18">ShadiTayari.pk respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us immediately at:</p>
    <p className="font-size-18">Email: <a href="mailto:copyright@shaditayari.pk">copyright@shaditayari.pk</a></p>
    <p className="font-size-18">Address:<br/>
    ShadiTayari.pk</p>
    <p className="font-size-18">Please provide the following information:</p>
    <p className="font-size-18">A description of the copyrighted work that you claim has been infringed.</p>
    <p className="font-size-18">A description of where the material that you claim is infringing is located on the site.</p>
    <p className="font-size-18">Your address, telephone number, and email address.</p>
    <p className="font-size-18">A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</p>
    <p className="font-size-18">A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
    <p className="font-size-18">An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.</p>

    <h2 className='font-size-30 fw-semibold'>Changes to This Notice</h2>
    <p className="font-size-18">ShadiTayari.pk reserves the right to make changes to our site, policies, and these terms of use at any time. If you have any questions regarding our copyright policy, please contact us at <a href="mailto:copyright@shaditayari.pk">copyright@shaditayari.pk</a>.</p>

    <p className="font-size-18">Thank you for visiting ShadiTayari.pk. We appreciate your respect for our intellectual property and the intellectual property of others.</p>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default CopywriteNotice