import { useEffect, useState } from "react";
import {
  getEventList,
  getUserEventList,
  deleteEvent,
  getEventTasks,
  getProfile,
} from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import Counter from "./Counter";
import EventsCardSkeleton from "../LoadingSkeleton/EventsCardSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { useTranslation } from "react-i18next";
import { MapPin } from "lucide-react";
import NoEventUI from "./noEventUI";

const EventList_Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const EventList = useSelector(getEventList);
  const myEvents = useSelector(getEventList);
  const [deleteModal, setdeleteModal] = useState(false);
  const [load, setload] = useState(false);
  const [load2, setload2] = useState(false);
  const [event, setevent] = useState("");

  const GetEventNames = async () => {
    try {
      // setEventNames(null);
      setload(true);
      const response = await dispatch(getUserEventList()).unwrap();
      setload(false);
      // setEventNames(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
    setdeleteModal(false);
  }
  const customStyles = {
    content: {
      display: "block",
      "--bs-modal-width": "30%",
    },
  };

  function formatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [selectedId, setSelectedId] = useState("");
  const currentDate = formatDate();

  const DeleteEvent = async () => {
    await dispatch(deleteEvent(selectedId));
    await GetEventNames();
    return closeModal();
  };

  useEffect(() => {
    if (EventList) {
      setload2(true);
      console.log("ttt", EventList);
      setevent(EventList[0]?.Event);
      setload2(false);
    }
  }, [EventList, load]);

  console.log("eventrtrrrrrrr", event);

  // useEffect(() => {
  //   if (EventList?.length > 0) {
  //     setload(false);
  //   } else {
  //     setload(true);
  //   }
  // }, [EventList]);

  return (
    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={deleteModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="delete"
        className="modal fade show wf-modal delete-event"
      >
        <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
          <div className="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1
                class="font-size-22 dm-font purple-color"
                id="staticBackdropLabel"
              >
                {t("Delete")} {t("Event")} {t("Confirmation")}
              </h1>
              <button
                type="button"
                onClick={closeModal}
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-0">
              <p className="font-size-14 color-black mb-0">
                {t("Areyousureyouwanttodeletethis")} {t("Event")}?
              </p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button
                class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2"
                onClick={closeModal}
              >
                {t("Cancel")}
              </button>
              <button
                class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                onClick={() => DeleteEvent()}
              >
                {t("Delete")} {t("Event")}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="wf-event-list mb-4">
        <div className="row mb-3 gy-3">
          <div className="col-lg-12 col-md-12 align-self-start d-flex gap-3 align-items-center justify-content-between">
            <h2 className="font-size-26 dm-font purple-color mb-0">
              {t("Events")}
            </h2>
           
            <button
              type="button"
              onClick={() => {
                navigate("/event");
              }}
              class="btn wf-btn-2 wf-pink-btn py-2 rounded-2"
            >
              {t("Add")} {t("Event")}
            </button>
          </div>
          
          {EventList?.length > 0 && EventList?.some((s)=>s.event_id!==null) ? (  
          <div className="col-lg-12 col-md-12 d-flex gap-2 flex-wrap">
            {load
              ? new Array(6)
                  .fill(null)
                  .map((_, index) => <ButtonSkeleton key={index} />)
              : EventList?.length > 0
              ? EventList?.map((ev) => (
                  <>
                    <input
                      checked={ev?.Event?._id == event?._id}
                      onChange={() => setevent(ev?.Event)}
                      type="radio"
                      name="event-select"
                      class="btn-check"
                      id={ev?.Event?._id}
                    />
                    <label
                      class="btn wf-select-btn"
                      for={ev?.Event?._id}
                      style={{
                        "--bs-btn-active-bg": ` ${ev?.Event?.event_color}`,
                      }}
                    >
                      {" "}
                      {ev?.Event?.event_name}
                    </label>
                  </>
                ))
              : null}
          </div>):null}
        </div>

        {load ? (
          <EventsCardSkeleton />
        ) : (
          EventList?.length > 0 && EventList?.some((s)=>s.event_id!==null) ?
           (
            <div className="wf-event-tab wf-shadow-2 white-bg rounded p-3 mb-2">
              <div className="d-flex flex-column flex-xl-row">
                <div className="couplesEvent_details px-md-4 py-1">
                  <div className="d-flex align-items-center justify-content-between gap-3">
                    <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start text-break" style={{width:'160px'}}>
                      {event?.event_name}
                      <span
                        className="d-block mt-1"
                        style={{
                          backgroundColor: `${event?.event_color}`,
                          height: "3px",
                          width: "100%",
                        }}
                      ></span>
                    </h4>
                    {/* <Counter /> */}
                    {event?.event_date && (
                      <p className="font-size-14 text-end color-grey mb-0">
                        {Math.ceil(
                          Math.abs(new Date(event.event_date) - new Date()) /
                            (1000 * 60 * 60 * 24)
                        )}{" "}
                        Days away
                      </p>
                    )}
                  </div>
                  <p className="font-size-16 dm-font purple-color mb-1">
                    {event?.event_date?moment(event?.event_date).format("D MMM YYYY"):""}
                  </p>
                  <p className="font-size-14 d-flex align-items-center gap-1">
                    <MapPin stroke="var(--color4)" size={16} />
                    {event?.event_city?.name}
                  </p>

                  {event ? (
                    <div className="d-flex flex-wrap gap-2 align-items-center mb-2">
                      <div className="bride-image-name">
                        <img
                          src={
                            profile?.imageName
                              ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}`  
                              : "https://via.placeholder.com/100x100"
                          }
                          class="rounded-circle groom-bride-image mx-auto d-block mb-1"
                        />
                        <h3 className="font-size-20 pink-color dm-font mb-0 text-center text-truncate">
                          {profile?.name.split(" ")[0]}
                        </h3>
                      </div>
                      <div className="bride-groom-text mb-4">
                        <h3 className="font-size-36 dm-font mb-0">&</h3>
                      </div>
                      <div className="groom-image-name">
                        <img
                          src={
                            profile?._id !== EventList[0]?.person2?._id &&
                            EventList[0]?.person2?.imageName
                              ? `${process.env.REACT_APP_IMGURL}${EventList[0]?.person2?.imageName}`
                              : profile?._id !== EventList[0]?.person2?._id &&
                                !EventList[0]?.person2?.imageName
                              ? "https://via.placeholder.com/100x100"
                              : profile?._id !== EventList[0]?.person1?._id &&
                                EventList[0]?.person1?.imageName
                              ? `${process.env.REACT_APP_IMGURL}${EventList[0]?.person1?.imageName}`
                              : profile?._id !== EventList[0]?.person1?._id &&
                                !EventList[0]?.person1?.imageName
                              ? "https://via.placeholder.com/50x50"
                              : "https://via.placeholder.com/50x50"
                          }
                          class="rounded-circle groom-bride-image mx-auto d-block mb-1"
                        />
                        {EventList[0]?.person2 ? (
                          <h3 className="font-size-20 purple-color dm-font mb-0 text-center text-truncate">
                            {profile?._id !== EventList[0]?.person2?._id
                              ? EventList[0]?.person2?.name.split(" ")[0]
                              : EventList[0]?.person1?.name.split(" ")[0]}
                            &nbsp;
                          </h3>
                        ) : (
                          <h3 className="font-size-20 purple-color dm-font mb-0 text-center text-truncate">
                            {t("Partner")}
                            &nbsp;
                          </h3>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="Event_Details container-fluid">
                  <div className="row row-gap-2 row-cols-1 row-cols-md-2 row-cols-xxl-4">
                    <div className="col px-1">
                      <div className="event-list-card rounded-3 px-3 py-2">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="font-size-22 color-black dm-font mb-0">
                            {event?.eventTasks?.length}
                          </p>
                          <img
                            src="../assets/images/tasks-event-icon-wf.svg"
                            alt=""
                          />
                        </div>
                        <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                          {t("Tasks")}
                          <span
                            className="d-block mt-1"
                            style={{
                              backgroundColor: `${event?.event_color}`,
                              height: "3px",
                              width: "60px",
                            }}
                          ></span>
                        </h4>
                        <p className="font-size-14 color-black mb-1">
                          Total{" "}
                          <span className="fw-bold">
                            {event?.eventTasks?.length} Tasks
                          </span>
                        </p>
                        <p className="font-size-14 color-black mb-2">
                          Remaining{" "}
                          <span className="fw-bold">
                            {
                              event?.eventTasks?.filter((f) => f?.status === 0)
                                ?.length
                            }{" "}
                            Tasks
                          </span>
                        </p>
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">
                            {t("Overdue")}
                          </p>
                          <p className="font-size-14 pink-color mb-0">
                            <span className="fw-bold">
                              {(
                                (event?.eventTasks?.filter(
                                  (f) =>
                                    f?.status == 0 &&
                                    f?.date <
                                      currentDate
                                )?.length /
                                  Math.max(event?.eventTasks?.length, 1)) *
                                  100 || 0
                              ).toFixed(0)}
                              %
                            </span>{" "}
                            {`
                      (${
                        event?.eventTasks?.filter(
                          (f) =>
                            f?.status === 0 &&
                            currentDate > f?.date
                        )?.length || 0
                      } ${
                              event?.eventTasks?.filter(
                                (f) =>
                                  f?.status === 0 &&
                                  currentDate >
                                    f?.date
                              )?.length > 1
                                ? "Tasks"
                                : "Task"
                            })
                      `}
                          </p>
                        </div>
                        <div className="mb-2">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color4)",
                                width: `${
                                  (event?.eventTasks?.filter(
                                    (f) =>
                                      f?.status === 0 &&
                                      currentDate >
                                        f?.date
                                  )?.length /
                                    Math.max(event?.eventTasks?.length, 1)) *
                                    100 || 0
                                }%`,
                              }}
                              aria-valuenow={0}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">
                            {t("Completed")}
                          </p>
                          <p className="font-size-14 purple-color mb-0">
                            <span className="fw-bold">
                              {(
                                (event?.eventTasks?.filter(
                                  (f) => f?.status === 1
                                )?.length /
                                  Math.max(event?.eventTasks?.length, 1)) *
                                  100 || 0
                              ).toFixed(0)}
                              %
                            </span>{" "}
                            {
                              event?.eventTasks?.filter((f) => f?.status === 1)
                                ?.length
                            }{" "}
                            {event?.eventTasks?.filter((f) => f?.status === 1)
                              ?.length > 1
                              ? "Tasks"
                              : "Task"}
                          </p>
                        </div>
                        <div className="">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color3)",
                                width: `${(
                                  (event?.eventTasks?.filter(
                                    (f) => f?.status === 1
                                  )?.length /
                                    Math.max(event?.eventTasks?.length, 1)) *
                                    100 || 0
                                ).toFixed(0)}%`,
                              }}
                              aria-valuenow={(
                                (event?.eventTasks?.filter(
                                  (f) => f?.status == 1
                                )?.length /
                                  event?.eventTasks?.length) *
                                100
                              ).toFixed(0)}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col px-1">
                      <div className="event-list-card rounded-3 px-3 py-2">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="font-size-22 color-black dm-font mb-0">
                            PKR{" "}
                            {event?.eventBudget?.event_total
                              ? event?.eventBudget?.event_total
                              : 0}
                          </p>
                          <img
                            src="../assets/images/budget-event-icon-wf.svg"
                            alt=""
                          />
                        </div>
                        <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                          {t("Budget")}
                          <span
                            className="d-block mt-1"
                            style={{
                              backgroundColor: `${event?.event_color}`,
                              height: "3px",
                              width: "60px",
                            }}
                          ></span>
                        </h4>
                        <p className="font-size-14 color-black mb-4">
                          Total <span className="fw-bold">PKR{" "}
                            {event?.eventBudget?.event_total
                              ? event?.eventBudget?.event_total
                              : 0}</span>
                        </p>
                        <div className="d-flex gap-2 align-items-center mb-1 pt-2">
                          <p className="font-size-14 color-black mb-0">Spent</p>
                          <p className="font-size-14 pink-color mb-0">
                            <span className="fw-bold">
                            {(isNaN(((event?.eventBudget?.event_total - event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0)) ? 0 : ((event?.eventBudget?.event_total - event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0)) || 0}
%
                            </span>{" "}
                            (PKR {event?.eventBudget?.event_total-event?.eventBudget?.event_total_available||0})
                          </p>
                        </div>
                        <div className="mb-2">
                          <div
                            className="progress"
                            style={{ height: "12px", width: "100%" }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color4)",
                                width: `${((event?.eventBudget?.event_total - event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0) || 0}%`,
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">
                            Remaining
                          </p>
                          <p className="font-size-14 purple-color mb-0">
                            <span className="fw-bold">
                              {" "}
                              {(isNaN(((event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0)) ? 0 : ((event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0)) || 0}%
                            </span>{" "}
                            (PKR {event?.eventBudget?.event_total_available || 0})
                          </p>
                        </div>
                        <div className="">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color3)",
                                width: `${((event?.eventBudget?.event_total_available) / event?.eventBudget?.event_total * 100).toFixed(0) || 0}%`,
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col px-1">
                      <div className="event-list-card rounded-3 px-3 py-2">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="font-size-22 color-black dm-font mb-0">
                            {event?.eventBookings?.length}
                          </p>
                          <img
                            src="../assets/images/bookings-event-icon-wf.svg"
                            alt=""
                          />
                        </div>
                        <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                          {t("Bookings")}
                          <span
                            className="d-block mt-1"
                            style={{
                              backgroundColor: `${event?.event_color}`,
                              height: "3px",
                              width: "60px",
                            }}
                          ></span>
                        </h4>
                        <p className="font-size-14 color-black mb-1">
                          Total{" "}
                          <span className="fw-bold">
                            {event?.eventBookings?.length}{" "}
                            {event?.eventBookings?.length > 1
                              ? "Bookings"
                              : "Booking"}
                          </span>
                        </p>
                        <p className="font-size-14 color-black mb-5">
                          Remaining{" "}
                          <span className="fw-bold">
                            {`${
                              event &&
                              event?.eventBookings &&
                              event?.eventBookings?.filter((booking) => {
                                const index = event.prefered_services.findIndex(
                                  (item) => item?._id === booking?.category_id
                                );
                                return index !== -1;
                              }).length
                            }
                          -${" "} ${
                              event &&
                              event?.eventBookings &&
                              event?.eventBookings?.filter((booking) => {
                                const index = event.prefered_services.findIndex(
                                  (item) => item._id === booking.category_id
                                );
                                return index !== -1;
                              }).length > 1
                                ? "Bookings"
                                : "Booking"
                            }`}
                          </span>
                        </p>
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">
                            {t("Confirmed")}
                          </p>
                          <p className="font-size-14 purple-color mb-0 fw-bold">
                            {(
                              (event?.eventBookings?.filter(
                                (f) =>
                                  f?.status === 1 && f?.user_event == event?._id
                              )?.length /
                                Math.max(event?.eventBookings?.length, 1)) *
                                100 || 0
                            ).toFixed(0)}
                            %
                          </p>
                        </div>
                        <div className="">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color3)",
                                width: `${(
                                  (event?.eventBookings?.filter(
                                    (f) =>
                                      f?.status === 1 &&
                                      f?.user_event == event?._id
                                  )?.length /
                                    Math.max(event?.eventBookings?.length, 1)) *
                                    100 || 0
                                ).toFixed(0)}%`,
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col px-1">
                      <div className="event-list-card rounded-3 px-3 py-2">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <p className="font-size-22 color-black dm-font mb-0">
                            {event?.eventGuests?.length &&
                              event?.eventGuests?.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0) +
                                  (guest.child_count || 0),
                                0
                              )}
                          </p>
                          <img
                            src="../assets/images/guests-event-icon-wf.svg"
                            alt=""
                          />
                        </div>
                        <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                          {t("Guests")}
                          <span
                            className="d-block mt-1"
                            style={{
                              backgroundColor: `${event?.event_color}`,
                              height: "3px",
                              width: "60px",
                            }}
                          ></span>
                        </h4>
                        <p className="font-size-14 color-black mb-1">
                          Total{" "}
                          <span className="fw-bold">
                            {" "}
                            {event?.eventGuests?.length &&
                              event?.eventGuests?.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0) +
                                  (guest.child_count || 0),
                                0
                              )}
                          </span>
                        </p>
                        <p className="font-size-14 color-black mb-2">
                          Adults{" "}
                          <span className="fw-bold">
                            {" "}
                            {event?.eventGuests?.length &&
                              event?.eventGuests?.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0),
                                0
                              )}
                          </span>{" "}
                          {`(${
                            event?.eventGuests?.length &&
                            (
                              (event?.eventGuests.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0),
                                0
                              ) /
                                event?.eventGuests.reduce(
                                  (total, guest) =>
                                    total +
                                    (guest.male_count || 0) +
                                    (guest.female_count || 0) +
                                    (guest.child_count || 0),
                                  0
                                )) *
                              100
                            ).toFixed(0)
                          }%)`}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mb-0">
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">Bride</p>
                          <p className="font-size-14 pink-color mb-0">
                            {event?.eventGuests
                              ?.filter((f) => f?.guest_of == "bride")
                              ?.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0) +
                                  (guest.child_count || 0),
                                0
                              )}{" "}
                            <span className="fw-bold">
                              {`(${
                                ((event?.eventGuests
                                  ?.filter((f) => f?.guest_of === "bride")
                                  ?.reduce(
                                    (total, guest) =>
                                      total +
                                      (guest.male_count || 0) +
                                      (guest.female_count || 0) +
                                      (guest.child_count || 0),
                                    0
                                  ) /
                                  event?.eventGuests?.reduce(
                                    (total, guest) =>
                                      total +
                                      (guest.male_count || 0) +
                                      (guest.female_count || 0) +
                                      (guest.child_count || 0),
                                    0
                                  )) *
                                100||0).toFixed(0)
                              }%)`}
                            </span>
                          </p>
                        </div>
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">Groom</p>
                          <p className="font-size-14 pink-color mb-0">
                            {event?.eventGuests
                              ?.filter((f) => f?.guest_of == "groom")
                              ?.reduce(
                                (total, guest) =>
                                  total +
                                  (guest.male_count || 0) +
                                  (guest.female_count || 0) +
                                  (guest.child_count || 0),
                                0
                              )}{" "}
                            <span className="fw-bold">
                              {`(${
                                ((event?.eventGuests
                                  ?.filter((f) => f?.guest_of === "groom")
                                  ?.reduce(
                                    (total, guest) =>
                                      total +
                                      (guest.male_count || 0) +
                                      (guest.female_count || 0) +
                                      (guest.child_count || 0),
                                    0
                                  ) /
                                  event?.eventGuests?.reduce(
                                    (total, guest) =>
                                      total +
                                      (guest.male_count || 0) +
                                      (guest.female_count || 0) +
                                      (guest.child_count || 0),
                                    0
                                  )) *
                                100||0).toFixed(0)
                              }%)`}
                            </span>
                          </p>
                        </div>
                        </div>
                        <div className="mb-2">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: " var(--color4)",
                                width: `${
                                  (event?.eventGuests
                                    ?.filter((f) => f?.guest_of === "bride")
                                    ?.reduce(
                                      (total, guest) =>
                                        total +
                                        (guest.male_count || 0) +
                                        (guest.female_count || 0) +
                                        (guest.child_count || 0),
                                      0
                                    ) /
                                    event?.eventGuests?.reduce(
                                      (total, guest) =>
                                        total +
                                        (guest.male_count || 0) +
                                        (guest.female_count || 0) +
                                        (guest.child_count || 0),
                                      0
                                    )) *
                                  100||0
                                }%`,
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      
                        <div className="d-flex gap-2 align-items-center mb-1">
                          <p className="font-size-14 color-black mb-0">
                            {t("RSVPConfirmed")}
                          </p>
                          <p className="font-size-14 purple-color mb-0">
                            <span className="fw-bold">
                              {(event?.invitedGuests?.length > 0
                                ? event?.invitedGuests?.filter(
                                    (f) => f?.rsvp === 1
                                  )?.length / event?.invitedGuests?.length
                                : 0) * 100 || 0}
                              %
                            </span>
                            {`(${
                              event?.invitedGuests?.length > 0
                                ? event?.invitedGuests?.filter(
                                    (f) => f?.rsvp == 1
                                  )?.length
                                : 0
                            })`}
                          </p>
                        </div>
                        <div className="">
                          <div
                            className="progress"
                            style={{
                              height: "12px",
                              width: "100%",
                              // marginRight: "5px",
                            }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                backgroundColor: "var(--color3)",
                                width: `${
                                  (event?.invitedGuests?.length > 0
                                    ? event?.invitedGuests?.filter(
                                        (f) => f?.rsvp === 1
                                      )?.length / event?.invitedGuests?.length
                                    : 0) * 100 || 0
                                }%`,
                              }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ):(<NoEventUI name={"Events"}/>)
        )}
       
      </div>
    </>
  );
};
export default EventList_Dashboard;
