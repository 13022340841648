import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEventList } from "../../store/slices/userSlice";

const Counter = ({event}) => {
  const myEvents = useSelector(getEventList);

  const [countdown, setCountdown] = useState({
    // days: 0,
    // hours: 0,
    // minutes: 0,
    // seconds: 0,
    days: 0,
    weeks: 0,
    months: 0
  });

  const [preferredSeason, setPreferredSeason] = useState("");
  const [preferredYear, setPreferredYear] = useState("");
  const [preferredDate, setPreferredDate] = useState("");
  const [eventName, seteventName] = useState("");
  

  const setValues = () => {
    console.log('myEventssssssssss', event?.event_prefered_season)
    setPreferredSeason(event?.event_prefered_season);
    setPreferredYear(event?.event_prefered_year);
    setPreferredDate(event?.event_date);
    seteventName(event?.event_name);
  };

  

  useEffect(() => {
    if (event) {
      setValues();
      
    }
    const timer = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [event]);


  const updateCountdown = () => {
    const targetDateTime = new Date(event && event?.event_date).getTime();
    const currentTime = new Date().getTime();
    const remainingTime = Math.max(targetDateTime - currentTime, 0);

    // const weeks = Math.floor(days / 7);
    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    // const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    const weeks = Math.floor(remainingDays / 7);

    // setCountdown({ weeks, days, hours, minutes, seconds });
    setCountdown({ months, weeks, days: remainingDays });
  };

  // const { days, hours, minutes, seconds, weeks } = countdown;
  const { months, weeks, days } = countdown;

  const seasonMonths = {
    winter: [12, 1, 2],
    spring: [3, 4, 5],
    summer: [6, 7, 8],
    autumn: [9, 10, 11],
  };

  let content = null;

//  if (preferredDate) {
//     content = (
//       <>
//         <li>
//           <span id="days">{days}</span>Days
//         </li>
//         <li>
//           <span id="hours">{hours}</span>Hours
//         </li>
//         <li>
//           <span id="minutes">{minutes}</span>Minutes
//         </li>
//         <li>
//           <span id="seconds">{seconds}</span>Seconds
//         </li>
//         <br />
//       </>
//     );
//   } 
console.log('preferredDate', preferredDate)
if (preferredDate) {

  console.log('uuuuuuuuas');
  
  content = (
    <>
      {months>0&&(<li>
        <span id="months">{months}</span><p className="font-size-14 color-grey mb-0">{months>1?"Months":"Month"} away</p>
      </li>)}
      {weeks>0&&(<li>
        <span id="weeks">{weeks}</span><p className="font-size-14 color-grey mb-0">{weeks>1?"Weeks":"Week"} away</p>
      </li>)}
      {days>0&&(<li>
        <span id="days">{days}</span><p className="font-size-14 color-grey mb-0">{days>1?"Days":"Day"} away</p>
        </li>)}
      <br />
    </>
  );
}
  else if (preferredSeason && preferredYear) {
 

    const currentMonth = new Date().getMonth() + 1;
    const targetMonths = seasonMonths[preferredSeason];

    let remainingMonths = 0;

    if (currentMonth <= targetMonths[0]) {
      remainingMonths = targetMonths[0] - currentMonth;
    } else {
      remainingMonths = 12 - currentMonth + targetMonths[0];
    }

    remainingMonths += (preferredYear - new Date().getFullYear()) * 12;

    // content = (
    //   <p className="font-size-30 color-black playfair-font">
    //     <strong className="purple-color">{remainingMonths}</strong> months until {preferredSeason} {preferredYear}
    //   </p>
    // );
    content = (
      <p className="font-size-30 color-black playfair-font">
        <strong className="purple-color">{remainingMonths}</strong> months until {preferredSeason} {preferredYear}
      </p>
    );
  } else if (preferredSeason) {
    

    const currentMonth = new Date().getMonth() + 1;
    const targetMonths = seasonMonths[preferredSeason];
console.log('targetMonths', targetMonths)
    let remainingMonths = 0;

    if (currentMonth <= targetMonths[0]) {
      remainingMonths = targetMonths[0] - currentMonth;
    } else {
      remainingMonths = 12 - currentMonth + targetMonths[0];
    }

    // content = (
    //   <p className="font-size-30 color-black playfair-font">
    //     <strong className="purple-color">{remainingMonths}</strong> months until {preferredSeason} {new Date().getFullYear()}
    //   </p>
    // );
    content = (
      <p className="font-size-30 color-black playfair-font">
        <strong className="purple-color">{remainingMonths}</strong> months until {preferredSeason} {new Date().getFullYear()}
      </p>
    );
  } else if (preferredYear) {
 

    const currentYear = new Date().getFullYear();

    let remainingMonths = 0;

    if (currentYear < preferredYear) {
      remainingMonths = (preferredYear - currentYear - 1) * 12 + (12 - new Date().getMonth() + 1);
    }

    // content = (
    //   <p className="font-size-30 color-black playfair-font">
    //     <strong className="purple-color">{remainingMonths}</strong> months until {preferredYear}
    //   </p>
    // );
    content = (
      <p className="font-size-30 color-black playfair-font">
        <strong className="purple-color">{remainingMonths}</strong> months until {preferredYear}
      </p>
    );
  }
// console.log('content', content)
  return content;
};

export default Counter;
