import { configureStore } from '@reduxjs/toolkit'
import usersReducer from './slices/userSlice';
import socketReducer from './slices/socketSlice';
 
const Store = configureStore({
    reducer: {
      users: usersReducer,
      socket: socketReducer 
     } 
  })
export default Store  