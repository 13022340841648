import React, { useEffect } from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';

const AboutUs = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, []);

  return (
    <>
      <Helmet>
        <title>About Shadi Tayari</title>
        <meta name="description" content="Shadi Tayari was launched in 2015 as a suppliers listing directory website and a blog dedicated to wedding planning and weddings in Pakistan."/>
        <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/about`}/>
      </Helmet>

      <section class="about-hero about-bg">
        <Header theme="light" />
        <div class="container">
          <div class="about-hero-body">
            <h1 class="font-size-46 color-white dm-font">About Shadi Tayari</h1>
            <p className="font-size-16 color-white about-content mb-0">
              Welcome to Shadi Tayari, Pakistan's premier wedding planning app designed to turn your dream wedding into reality. Our platform offers a seamless planning experience, connecting you with the best suppliers across the country. Whether you're looking for the perfect venue, a talented photographer, exquisite decor, or delicious catering, Shadi Tayari has you covered.</p>
          </div>
        </div>
        <div className="about-hero-overlay"></div>
      </section>
      <section className="about-section-2 py-5">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-8 col-md-12 order-lg-1 order-2 align-self-center">
              <h2 className="font-size-26 dm-font color-black mb-3">Why Shadi Tayari?</h2>
              <ul>
                <li>Comprehensive Supplier List: Access a wide range of trusted suppliers for every wedding need.</li>
                <li>User-Friendly App: Easily navigate and plan your wedding with our intuitive app, available on Google Play and the Apple App Store.</li>
                <li>Personalized Planning Tools: Utilize our advanced tools to manage your guest list, budget, and timeline effortlessly.</li>
              </ul>
              <h2 className="font-size-26 dm-font color-black mb-3">Our Journey</h2>
              <p>Shadi Tayari was launched in 2015 as a suppliers listing directory website and a blog dedicated to wedding planning and weddings in Pakistan. Over the years, we have grown and evolved to become a comprehensive wedding planning application, catering to the diverse needs of couples across the country. Our platform not only provides a detailed directory of wedding suppliers but also offers valuable insights, tips, and inspiration through our blogs.
                <br /><br />
                Our mission is to make wedding planning stress-free and enjoyable, allowing you to focus on creating beautiful memories. Join thousands of couples who have trusted Shadi Tayari to make their wedding day unforgettable. Download our app today and start planning the wedding of your dreams!</p>

            </div>
            <div className="col-lg-4 col-md-12 order-lg-2 order-1">
              <img src="../assets/images/about-shadi-tayari-img.jpg" className="about-us-img w-100 rounded-4" alt="" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default AboutUs