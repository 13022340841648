import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getEventList, userLogout } from "../../store/slices/userSlice"
import { useTranslation } from 'react-i18next';
import { Navigation, Pagination, A11y, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import NoEventUI from '../UserDashboard/noEventUI';

const Events_Dashboard = () => {

  const { t } = useTranslation();
  const profile = useSelector(getProfile)
  const myEvents = useSelector(getEventList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();



  console.log('myEvents', myEvents)


  return (
    <>
      {/* Row 1 */}
      <div className='row mb-4'>
        <div className='col-md-8'>
          <div class="d-flex gap-4 align-items-end mb-2">
            <h2 class="font-size-30 dm-font purple-color mb-0">Upcoming Events</h2>
            {myEvents?.length > 0 && (<a class="font-size-22 pink-color mb-0" href="/event-list"><u>View All</u></a>)}
          </div>
        </div>
        {myEvents?.length > 0 && (<div className='col-md-4 text-md-end'>
          <button onClick={() => navigate("/event")} type="button" class="btn wf-btn-2 wf-pink-btn py-2 rounded-2 me-5">+ Add New</button>
        </div>)}

      </div>
      <div className="wf-filter-category-slider wf-saved-pins-slider mb-4">
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={15}
          navigation
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          breakpoints={{
            468: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }}>

          {myEvents?.filter(item => item.Event && '_id' in item.Event)?.length > 0 ? myEvents?.filter(item => item.Event && '_id' in item.Event)?.map((e) => (

            <SwiperSlide>
              <div className='wf-shadow-2 white-bg rounded px-3 py-3 mx-1 my-1'>
                <p className='font-size-16 color-grey d-flex justify-content-between'>
                  <span>{e?.Event?.event_name}</span>
                  <span className='cursor-pointer'><i className="bi bi-chevron-right me-3"></i></span>
                </p>
                <div className='wf-up-avatar d-flex mb-2'>
                  <div className='avatar-couple-first'>
                    <img width="68" height="68" alt="" src={e.person1 && e?.person1?.imageName && `${process.env.REACT_APP_IMGURL}${e?.person1?.imageName}` || "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                  </div>
                  <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                    <img width="68" height="68" alt="" src={e.person2 && e?.person2?.imageName && `${process.env.REACT_APP_IMGURL}${e?.person2?.imageName}` || "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                  </div>
                </div>
                <h3 class="font-size-20 dm-font fw-medium color-black mb-2">{`${e?.person1?.name || ""} & ${e?.person2?.name || "partner"}`}</h3>
                <h4 class="font-size-18 dm-font fw-medium purple-color mb-1">{moment(e?.Event?.event_date).format("dddd, D MMMM YYYY")}</h4>

                <p className="font-size-14 color-grey mb-0">
                  {Math.ceil(
                    Math.abs(new Date(e?.Event?.event_date) - new Date()) /
                    (1000 * 60 * 60 * 24)
                  ) || 0}{" "}
                  Days away
                </p>
                <p class="font-size-16 color-black mb-1"><i class="bi bi-geo-alt-fill pink-color me-1"></i>{e?.Event?.event_city?.name}</p>
              </div>
            </SwiperSlide>
          )) : <NoEventUI name={"Events"} />}
        </Swiper>

      </div>
    </>
  )
}

export default Events_Dashboard