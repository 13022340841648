import axios from 'axios';
import React, { useEffect, useState } from 'react'
import IdeasInspirationSkeleton from '../LoadingSkeleton/IdeasInspirationSkeleton';
import { Helmet } from "react-helmet-async";
import Header from './HeaderLight';
import moment from 'moment';
import BlogCardSkeleton from '../LoadingSkeleton/BlogCardSkeleton';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Footer from './Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CustomAd from '../../components/Layout/CustomAd';

const Blogs = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [posts, setPosts] = useState([]);
  const [loadd, setloadd] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  const fetchPosts = async () => {
    try {
      setloadd(true);
      setPosts([])
      const response = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/posts?per_page=18&page=${currentPage}&_=${Date.now()}`);
      if (!response.ok) {
        toast.error('Post Not Found');
        return
      }
      const data = await response.json();
      const totalPages = parseInt(response.headers.get('X-WP-TotalPages'));
  
      // Fetching author, tags, and categories data for each post
      const postsWithData = await Promise.all(
        data.map(async post => {
          // Fetch author data
          const authorResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/users/${post.author}`);
          if (!authorResponse.ok) {
            console.log('Error fetching author data');
          }
          const authorData = await authorResponse.json();
  
          // Fetch tags data
          const tagsResponse = await Promise.all(
            post.tags.map(async tagId => {
              const tagResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/tags/${tagId}`);
              if (!tagResponse.ok) {
                console.log('Error fetching tag data');
              }
              const tagData = await tagResponse.json();
              return tagData.name;
            })
          );
  
          // Fetch categories data
          const categoriesResponse = await Promise.all(
            post.categories.map(async categoryId => {
              const categoryResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/categories/${categoryId}`);
              if (!categoryResponse.ok) {
                console.log('Error fetching category data');
              }
              const categoryData = await categoryResponse.json();
              return categoryData.name;
            })
          );
  
          // Fetch featured image data
          const mediaResponse = await fetch(`https://st.digitalbee.studio/wp-json/wp/v2/media/${post.featured_media}`);
          if (!mediaResponse.ok) {
            console.log('Error fetching featured image data');
          }
          const featuredImageResponse = await mediaResponse.json();
  
          return {
            ...post,
            authorName: authorData,
            tags: tagsResponse,
            categories: categoriesResponse,
            featured_media: featuredImageResponse
          };
        })
      );
  
      setPosts(postsWithData);
      setTotalPages(totalPages);
      setloadd(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setloadd(false);
    }
  };

  useEffect(() => {
    console.log("api hit before");
    fetchPosts();
    console.log("api hit after");
  }, [currentPage]);
  

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      pages.push(renderPageLink(1));

      if (startPage > 2) {
        pages.push(renderEllipsis());
      }
      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }
    return pages;
  };

  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? "active" : ""}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>{page}</a>
    </li>
  );

  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

  console.log(posts,"posts");
  console.log(currentPage,"currentPage");
  


  return (
    <>
    <Helmet>
      <title>Ideas & Inspirations - Shadi Tayari</title>
      <meta name="description" content="Discover everything about weddings, relationships, and more with the experts at Shadi Tayari. From bridal inspiration to the latest celebrity wedding news, we provide articles you can trust."/>
      <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/blog`}/>
    </Helmet>
    <section class="archives-hero ideas-inspiration">
      <div className="archive-img">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/ideas-inspiration-background.jpg`} className="w-100" alt=""/>
      </div>
      <Header theme="light" />
      <div class="container pb-3">
        <div className="pt-5 pb-5 ">
          <h1 class="h1-title dm-font color-white text-center">{t("GetIdeas&Inspirations")}</h1>
        </div>
      </div>
    </section>
    <section className="archives-section pt-5 pb-4">
          <div className="container">
            <div className="row mb-2">
              <div className="col-md-6">
                <h2 class="font-size-36 dm-font purple-color mb-0">{t("Ideas&InspirationFromOurFinest")}</h2>
              </div>
            </div>
            <div className="row mb-4 gy-3">
              <div className="col-md-6">
                <p className="font-size-16 color-black mb-0">
                  {/* {res > 0
                    ? `Showing ${startResult} - ${endResult} (Total: ${res})`
                    : `Total: ${res}`} */}
                </p>
              </div>
              <div className="col-md-6 d-flex justify-content-md-end">
              </div>
            </div>

            <div className="row gy-5">
              {loadd ? (new Array(18).fill(null).map((_, index) => <BlogCardSkeleton key={index} />)) : posts?.length > 0 ? (<>
                {posts?.slice(0, Math.ceil(posts?.length / 2))?.map((b, i) => (
                <div key={i} className="col-lg-4 col-md-6 blog-card">
                  <div className="blog-card-body">
                    <a className="color-black" href={`/${b?.slug}`}>
                      <img alt={b?.title?.rendered} src={ b?.yoast_head_json?.og_image[0]?.url ? `${b?.yoast_head_json?.og_image[0]?.url}` : "https://via.placeholder.com/415x268"} className="blog-image w-100"/>
                    </a>
                    {b.categories[0] ? (
                      <div className="d-flex align-items-center top-box">
                        <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                          <a style={{ color: "white" }}>{b?.categories[0]}</a>
                        </span>
                      </div>
                    ) : null}

                    {b.categories[1] ? (
                      <div className="d-flex align-items-center bottom-box">
                        <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                          <a>{b?.categories[1]}</a>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="blog-card-footer">
                    <div className="row mb-2">
                      <div className="col-12 d-flex align-items-center gap-2">
                        <img width="40" height="40" alt="avatar" src={b?.authorName?.mpp_avatar?.full ? b?.authorName?.mpp_avatar?.full : `https://st.digitalbee.studio/wp-content/uploads/DP.jpg`} class="rounded-circle wf-shadow-2" style={{ objectFit: "cover" }}/>
                        <p className="font-size-14 color-black mb-0 fw-medium mt-1">{b?.authorName?.name}</p>
                      </div>
                    </div>
                    <h3 className="font-size-18 fw-semibold mb-1"><a className="color-black" href={`/${b?.slug}`}>{b?.title?.rendered}</a></h3>
                    <p className="font-size-16 color-black mb-0" dangerouslySetInnerHTML={{ __html: b.excerpt?.rendered.substring(0, 110) + '...' }}/>
                    <div className="d-flex align-items-center justify-content-between" style={{marginTop:'-10px'}}>
                      <p className="font-size-14 color-black mb-0">{moment(b?.date).format("D MMMM YYYY")}</p>
                      {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
                    </div>
                  </div>
                </div>
              ))}
            <div className="col-md-6">
              <CustomAd height="90" width="636" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
            </div>
            <div className="col-md-6">
              <CustomAd height="90" width="636" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
            </div>
            {posts?.slice(Math.ceil(posts?.length / 2))?.map((b, i) => (
                <div key={i} className="col-lg-4 col-md-6 blog-card">
                  <div className="blog-card-body">
                    <a className="color-black" href={`/${b?.slug}`}>
                      <img alt={b?.title?.rendered} src={ b?.yoast_head_json?.og_image[0]?.url ? `${b?.yoast_head_json?.og_image[0]?.url}` : "https://via.placeholder.com/415x268"} className="blog-image w-100"/>
                    </a>
                    {b.categories[0] ? (
                      <div className="d-flex align-items-center top-box">
                        <span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase cursor-pointer">
                          <a style={{ color: "white" }}>{b?.categories[0]}</a>
                        </span>
                      </div>
                    ) : null}

                    {b.categories[1] ? (
                      <div className="d-flex align-items-center bottom-box">
                        <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                          <a>{b?.categories[1]}</a>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="blog-card-footer">
                    <div className="row mb-2">
                      <div className="col-12 d-flex align-items-center gap-2">
                        <img width="40" height="40" alt="avatar" src={b?.authorName?.mpp_avatar?.full ? b?.authorName?.mpp_avatar?.full : `https://st.digitalbee.studio/wp-content/uploads/DP.jpg`} class="rounded-circle wf-shadow-2" style={{ objectFit: "cover" }}/>
                        <p className="font-size-14 color-black mb-0 fw-medium mt-1">{b?.authorName?.name}</p>
                      </div>
                    </div>
                    <h3 className="font-size-18 fw-semibold mb-1"><a className="color-black" href={`/${b?.slug}`}>{b?.title?.rendered}</a></h3>
                    <p className="font-size-16 color-black mb-0" dangerouslySetInnerHTML={{ __html: b.excerpt?.rendered.substring(0, 110) + '...' }}/>
                    <div className="d-flex align-items-center justify-content-between" style={{marginTop:'-10px'}}>
                      <p className="font-size-14 color-black mb-0">{moment(b?.date).format("D MMMM YYYY")}</p>
                      {/* <p className="font-size-14 color-black mb-0">5 mins read</p> */}
                    </div>
                  </div>
                </div>
              ))}
              </>
            ) : null}
            </div>

            <nav aria-label="Page navigation example" className="mt-5">
              <ul className="pagination wf-pagination justify-content-between">
                <li className="page-item">
                  {currentPage > 1 && (
                    <a onClick={() => handlePageChange(currentPage - 1)} className="page-link" aria-label="Previous"><i className="bi bi-arrow-left"></i></a>
                  )}
                </li>
                <ul className="pagination wf-pagination-2 gap-1">
                  {renderPaginationLinks()}
                </ul>

                <li className="page-item">
                  {currentPage < totalPages && (
                    <a onClick={() => handlePageChange(currentPage + 1)} className="page-link" aria-label="Next"><i className="bi bi-arrow-right"></i></a>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </section>
    <Footer />
  </>
  )
}

export default Blogs