import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile, userLogout, getEventList, getUnreadMessagesNotification
} from "../../store/slices/userSlice";
import { AlignJustify, Mail, Settings, Bell } from 'lucide-react';
import { X } from 'lucide-react';
import { context } from '../../context/context';
import ChangePassword from '../../../src/components/ChangePassword';
import Modal from 'react-modal';
import { Heart } from 'lucide-react';
import LanguageSwitcher from '../../components/Layout/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';


const customStyles = {
  content: {
    display: "block",
    '--bs-modal-width': '30%',
  },
};
Modal.setAppElement('#root');


const Header = ({ theme, width }) => {

  const { t, i18n } = useTranslation();
  const [toggle, settoggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventList = useSelector(getEventList);
  const location = useLocation();
  const unread = useSelector(getUnreadMessagesNotification);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const profile = useSelector(getProfile);


  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  const { toggleButton, SetToggleButton } = useContext(context);


  const handleLogout = async () => {
    try {


      await dispatch(userLogout()).unwrap();
      navigate("/")


    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
    }
  }
  const currentLanguage = i18n.language;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const handleMouseEnter = () => setDropdownOpen(true);
  const handleMouseLeave = () => setDropdownOpen(false);
  const handleMouseEnter2 = () => setDropdownOpen2(true);
  const handleMouseLeave2 = () => setDropdownOpen2(false);

  return (
    <>
      <header className={`${isScrolled ? 'wf-nav-sticky' : ''} ${theme === 'dark' ? 'wf-dark-header' : 'wf-light-header'}`}>
        <div className={width === 'full' ? '' : 'container'}>
          <nav className={`navbar navbar-expand-xl navbar-light pt-4 pb-4 ${width === 'full' && 'px-md-5'}`}>
            <div className="container-fluid">
              <a className="navbar-brand mt-0" href={`${process.env.REACT_APP_FrontURL}`}>
                <img className="img-fluid light-logo" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-white-arabic.png' : 'logo-white.svg'}`} alt="Shadi Tayyari" width={currentLanguage === 'ar' ? '180px' : '240'} />
                <img className="img-fluid dark-logo" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="Shadi Tayyari" width={currentLanguage === 'ar' ? '180px' : '240'} />
              </a>
              <a className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i className="bi bi-list navbar_open"></i>
                <i className="bi bi-x navbar_close"></i>
              </a>

              <div className={`collapse p-5  p-xl-0 navbar-collapse ${profile ? "wf-logged-in" : "wf-logged-out"}`} id="navbarSupportedContent">
                <ul className="navbar-nav gap-15 m-xl-auto">
                  <li className="nav-item">
                    <a className={location?.pathname == "/suppliers" ? "nav-link active font-size-18 text-uppercase" : "nav-link font-size-18 text-uppercase"} href={`${process.env.REACT_APP_FrontURL}/suppliers`}>{t('Suppliers')}</a>
                  </li>
                  <li className="nav-item">
                    <a className={location?.pathname == "/suppliers/venue" ? "nav-link active font-size-18 text-uppercase" : "nav-link font-size-18 text-uppercase"} href={`${process.env.REACT_APP_FrontURL}/suppliers/venue`}>{t('Venues')}</a>
                  </li>
                  <li className="nav-item">
                    <a className={location?.pathname == "/suppliers/wedding-planner" ? "nav-link active font-size-18 text-uppercase" : "nav-link font-size-18 text-uppercase"} href={`${process.env.REACT_APP_FrontURL}/suppliers/wedding-planner`}>Planners</a>
                  </li>
                  {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle font-size-18 text-uppercase" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {t('Features')}
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item font-size-16 text-capitalize" href={`${process.env.REACT_APP_FrontURL}/features/task-management`}>{t('TaskManagement')}</a></li>
            <li><a className="dropdown-item font-size-16 text-capitalize" href={`${process.env.REACT_APP_FrontURL}/features/budget-management`}>{t('BudgetManagement')}</a></li>
            <li><a className="dropdown-item font-size-16 text-capitalize" href={`${process.env.REACT_APP_FrontURL}/features/guest-management`}>{t('GuestManagement')}</a></li>
          </ul>
        </li> */}
                  <li className="nav-item">
                    <a className={location?.pathname == "/ideas-inspiration" ? "nav-link active font-size-18 text-uppercase" : "nav-link font-size-18 text-uppercase"} href={`${process.env.REACT_APP_FrontURL}/blog`}>Inspire</a>
                  </li>
                  {/* <li className="nav-item">
          <a className="nav-link font-size-18 text-uppercase"><LanguageSwitcher /></a>
        </li> */}

                </ul>
                {profile ? (
                  <div style={{ width: '233px' }} className="d-flex align-items-center gap-25 justify-content-md-end wf-header-profile">
                    <div className='custom-dropdown'>
                      <a className="dropdown-link wf-settings" onClick={() => navigate("/settings")} >
                        <Settings stroke={`${isScrolled ? 'var(--color3)' : (theme === 'dark' ? 'var(--color3)' : 'var(--color1)')}`} />
                      </a>
                    </div>
                    <div className="dropdown custom-dropdown notification">
                      <a className="dropdown-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <Bell stroke={`${isScrolled ? 'var(--color3)' : (theme === 'dark' ? 'var(--color3)' : 'var(--color1)')}`} />
                        {/* <i className="far fa-bell icon-notifications"></i> */}
                        {/* <span className="number">5</span> */}
                      </a>
                      <div className="dropdown-menu" >
                        <div className="d-flex align-items-center px-2 py-1">
                          <p className="font-size-16 dm-font purple-color mb-0">{t('Notifications')}</p>
                        </div>

                        <ul className="custom-notifications">
                          <li className="unread">
                            <a href="#" className="d-flex align-items-center">
                              <div className="img mr-3">
                                <img src="https://via.placeholder.com/50x50" alt="Image" className="img-fluid" />
                              </div>
                              <p className="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                              <span className="notification-date">Tue, 12:30am</span>
                            </a>
                          </li>

                          <li className="">
                            <a href="#" className="d-flex align-items-center">
                              <div className="img mr-3">
                                <img src="https://via.placeholder.com/50x50" alt="Image" className="img-fluid" />
                              </div>
                              <p className="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                              <span className="notification-date">Tue, 12:30am</span>
                            </a>
                          </li>
                          <li className="">
                            <a href="#" className="d-flex align-items-center">
                              <div className="img mr-3">
                                <img src="https://via.placeholder.com/50x50" alt="Image" className="img-fluid" />
                              </div>
                              <p className="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                              <span className="notification-date">Tue, 12:30am</span>
                            </a>
                          </li>
                          <li className="">
                            <a href="#" className="d-flex align-items-center">
                              <div className="img mr-3">
                                <img src="https://via.placeholder.com/50x50" alt="Image" className="img-fluid" />
                              </div>
                              <p className="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                              <span className="notification-date">Tue, 12:30am</span>
                            </a>
                          </li>
                          <li className="">
                            <a href="#" className="d-flex align-items-center">
                              <div className="img mr-3">
                                <img src="https://via.placeholder.com/50x50" alt="Image" className="img-fluid" />
                              </div>
                              <p className="text mb-0"><span className="font-size-14 pink-color dm-font">Claudia Gideon</span><br /> <span>marked the task done a day ago</span></p>
                              <span className="notification-date">Tue, 12:30am</span>
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className='custom-dropdown'>
                      <a className="dropdown-link" onClick={() => navigate("/chat")}>
                        <Mail stroke={`${isScrolled ? 'var(--color3)' : (theme === 'dark' ? 'var(--color3)' : 'var(--color1)')}`} />
                        {/* <span className="number">5</span> */}
                      </a>
                    </div>

                    <div className="wf-profile dropdown ml-2">

                      <a className="d-flex align-items-center gap-2" href="#" role="button">
                        {/* <p className="font-size-14 dm-font purple-color mb-0">{profile?.name}</p> */}
                        <div className="position-relative rounded-circle">
                          {profile?.role == "customer" ? (<img width="60" alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://via.placeholder.com/50x50"} className="rounded-circle avatar" />)
                            : (<img alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://via.placeholder.com/50x50"} className="rounded-circle avatar" />)}
                          {unread?.length > 0 ? (<span className="badge pink-bg color-white rounded-circle position-absolute bottom-0 wf-profile-badge">{unread?.length}</span>) : null}
                        </div>
                      </a>

                      <div className="dropdown-menu pb-2" aria-labelledby="dropdownUser">
                        <div className="d-flex align-items-center gap-2 px-2" >
                          <div className="position-relative rounded-circle">
                            <img width="56" alt="avatar" src={profile?.imageName ? `${process.env.REACT_APP_IMGURL}${profile?.imageName}` : "https://via.placeholder.com/50x50"} className="rounded-circle avatar" />
                          </div>
                          <p className="font-size-16 dm-font pink-color mb-0">{profile?.name}</p>
                        </div>
                        <div style={{ '--bs-dropdown-divider-bg': '#e7e7e7' }} className="dropdown-divider"></div>
                        <ul className="list-unstyled mb-0">
                          <li><a className="dropdown-item" onClick={() => navigate("/chat")}>
                            <i className="bi bi-envelope"></i> {t('Messages')} {unread?.length > 0 ? (<span className="badge pink-bg color-white rounded-circle position-absolute end-0 mx-2">{unread?.length}</span>) : null}
                          </a></li>
                          <li><a className="dropdown-item" href={profile?.role == "planner" ? "/dashboard-planner" : "/dashboard"}>
                            <i className="bi bi-person"></i> {t('Dashboard')}
                          </a></li>
                          <li><a className="dropdown-item" onClick={openModal}>
                            <i className="bi bi-gear"></i> {t('ChangePassword')}
                          </a></li>
                          <li><a className="dropdown-item" >
                            <i className="bi bi-question-circle"></i> {t('Help&Support')}
                          </a></li>
                          <li><a className="dropdown-item" onClick={handleShow2}>
                            <i className="bi bi-briefcase"></i> Add Business
                          </a></li>
                          <li><a className="dropdown-item" onClick={handleLogout}>
                            <i className="bi bi-box-arrow-right"></i> {t('Logout')}
                          </a></li>
                        </ul>
                      </div>
                    </div>

                  </div>
                ) : (
                  <>

                    <div style={{ width: '240px' }} className="d-flex flex-column flex-md-row align-items-md-center row-gap-4 gap-2 wf-header-login">
                      <a href='https://business.shaditayari.pk/' type="button" className="btn sign-in-btn">{t('Business')}</a>
                      <button onClick={() => navigate("/login", { state: location.pathname })} type="button" className="btn sign-up-btn">{t('Login')}</button>
                    </div>
                    {/* <div style={{ width: '240px' }} className="d-flex flex-column flex-md-row align-items-md-center row-gap-4 gap-2">

                      <div className="dropdown  wf-businesslogins" onMouseEnter={handleMouseEnter}   onMouseLeave={handleMouseLeave}>
                        <a className="btn purple-bg  color-white border-0" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                        {t('For Suppliers')}
                        </a>
                        <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                          <li>
                            <a href='https://business.weddified.com/' type="button" className="dropdown-item btn sign-in-btn">{t('Add Suppliers')}</a>
                          </li>
                          <li>
                            <a href='https://business.weddified.com/' type="button" className="dropdown-item btn sign-in-btn">{t('Business Login')}</a>
                          </li>
                        </ul>
                      </div>

                      <div className="dropdown  wf-logins" onMouseEnter={handleMouseEnter2}   onMouseLeave={handleMouseLeave2}>
                        <a className="btn wf-gradient-btn border-0" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                          <i className="bi bi-list navbar_open"></i>
                        </a>
                        <ul className={`dropdown-menu ${dropdownOpen2 ? 'show' : ''}`}>
                          <li>
                            <button onClick={() => navigate("/login", { state: location.pathname })} type="button" className="btn dropdown-item sign-in-btn">{t('Login')}</button>
                          </li>
                          <li>
                            <button onClick={() => navigate("/signup")} type="button" className="btn dropdown-item sign-in-btn">{t('Sign Up')}</button>
                          </li>
                        </ul>
                      </div>

                    </div> */}
                  </>
                )}

              </div>
            </div>
          </nav>
        </div>
      </header>

      {/*  */}
      <Modal

        isOpen={show2}
        onRequestClose={handleClose2}
        contentLabel="Sign Up"
        style={customStyles}
        className="modal fade show wf-modal wf-delete-modal tasks"
      >
        <div className="modal-dialog modal-dialog-centered wf-modal-dialog">

          <div className="modal-content">

            <div className="modal-header border-0 pb-0">
              <button type="button" onClick={handleClose2} className="btn-close z-3" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body  mt-0">
              <div className="row justify-content-center  mb-4">
                <div className="col-12">
                  <img className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={'assets/images/logo.svg'} alt="Shadi Tayyari" width={'270px'} />
                  <form>
                    <div className="form-floating wf-input wf-input-101 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name"
                        autocomplete="name"
                        maxLength={40}
                        required
                      />
                      <label for="floatingInput">Your Full Name</label>
                    </div>

                    <div className="form-floating wf-input wf-input-101 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name"
                        autocomplete="name"
                        maxLength={40}
                        required
                      />
                      <label for="floatingInput">Your Business Name</label>
                    </div>

                    <div>
                      <div className="dropdown mb-3 country-city-dropdown">
                        <a className="btn dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Business Type</a>
                        <ul className="dropdown-menu" >
                          <li className="country cursor-pointer">
                            <a><span style={{ marginLeft: '10px', }}>Business Type1</span></a>
                          </li>
                          <li className="country cursor-pointer">
                            <a><span style={{ marginLeft: '10px', }}>Business Type2</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="form-check wf-signup-checkbox mb-3">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                      <label className="form-check-label" for="flexCheckDefault"> Accept all Terms & Conditions
                      </label>
                    </div>
                    <div className="d-grid gap-2 mt-2">
                      <button className="btn wf-purple-btn wf-btn btn-lg" type="submit">Sign Up</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal-footer border-0">
            </div>

          </div>
        </div>
      </Modal>
      {/*  */}
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Change Password"
        className="modal fade show wf-modal"
      >
        <ChangePassword closeModal={closeModal} />
      </Modal>
    </>
  )
}

export default Header