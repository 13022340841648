import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from './HeaderLight'
import Footer from './Footer';
import { Heart } from 'lucide-react';
import Spinner from "../../components/Spinner"
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-fade";
import VendorCardSkeleton from '../LoadingSkeleton/VendorCardSkeleton'
import TextSkeleton from '../LoadingSkeleton/TextSkeleton'
import { context } from '../../context/context';
import axios from "axios";
import {
  getProfile,
  getEventList,
  getCitiesWithCountry
} from "../../store/slices/userSlice";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { current } from "@reduxjs/toolkit";
import SupplierTemplateSkeleton from "../LoadingSkeleton/SupplierTemplateSkeleton";
import { useTranslation } from "react-i18next";
import CustomAd from "../../components/Layout/CustomAd";
import { Helmet } from "react-helmet-async";
const customStyles = {
  content: {
    "--bs-modal-width": "35%",
    display: "block",
  },
};
Modal.setAppElement("#root");

const Vendors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location=useLocation();
  const savedLanguage = localStorage.getItem('selectedLanguage');
  const myEvents = useSelector(getEventList);
  const [event, setevent] = useState([]);
  const [category_id, setcategory_id] = useState("");
  const [store_id, setstore_id] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [load, setload] = useState(true); // for header
  const [load1, setload1] = useState(true); //for stores
  const [load2, setload2] = useState(true); //for filters
  const [content, setcontent] = useState(false);
  const [ready, setready] = useState(0);
  const [click, setclick] = useState(false);
  const [priceToggle, setpriceToggle] = useState(false);
  

  const profile = useSelector(getProfile);

  const { toggleButton,SetToggleButton } = useContext(context);

  const viewModal = async (store_id,category_id,events) => {

    if(myEvents?.length<1){
      toast.error("You don't have any event to shortlist this store")
    }
    else{
    setmodalIsOpen(true);
    setstore_id(store_id)
    setcategory_id(category_id)
    setevent(events)
    }
  };
  
  function closeModal() {
    setmodalIsOpen(false);
  }

//get initial data
const [categories, setcategories] = useState("");
const [categoryimage, setcategoryimage] = useState("");
const [cities, setcities] = useState(""); //fordropdownwithcountries
const [countries, setcountries] = useState("");
const [citiesonly, setcitiesonly] = useState("");
const [areas, setareas] = useState("");
const [cityimage, setcityimage] = useState("");
const [subcategories, setsubcategories] = useState([]);
const [questions, setquestions] = useState([]);
const [initattributes, setinitattributes] = useState([]);
const [initsubattributes, setinitsubattributes] = useState([]);

//get user typing queries
const [searchedCategroy, setsearchedCategroy] = useState("");
const [changedsearchedCategroy, setchangedsearchedCategroy] = useState("");
const [searchQuery, setSearchQuery] = useState(""); 
const [changedsearchQuery, setchangedSearchQuery] = useState(""); 
const [cityorcountry, setcityorcountry] = useState(""); 
  
//user typed or selected data to send on backend
const [searchValue, setSearchValue] = useState("");
const [cat, setcat] = useState([]);
const [subcat, setsubcat] = useState([]);
const [question, setquestion] = useState([]);
const [country, setcountry] = useState([]);
const [city, setcity] = useState([]);
const [area, setarea] = useState([]);
const [att, setatt] = useState([]);
const [subatt, setsubatt] = useState([]);
const million = 100000;
const [value1, setValue1] = useState(0);
const [value2, setValue2] = useState(million);
const [sortby, setsortby] = useState("latest");
const [res, setres] = useState("");
const [startResult, setStartResult] = useState(0);
const [endResult, setEndResult] = useState(0);

//pagination

const [data,setdata]=useState([])
const [currentPage, setCurrentPage] = useState(1);
const [totalPages , settotalPages] = useState("");
const queryParams = new URLSearchParams(location.search);


const pathSegments = window.location.pathname.split('/');
const lastSegment = pathSegments[pathSegments.length - 1];
const parts = lastSegment.split('-in-');




const IncrementView = async (store_id,type) => {
  try {
    console.log("store_id",store_id)
    await axios.post(`vendorpage/incrementview`, { store_id: store_id, type: type });
  } catch (error) {
    console.error("Error incrementing view:", error);
  }
}
 
console.log('searchQuery', searchQuery)
  
const getallStores = async (currentPage, init = false) => {
  try {
    if(!content){

      console.log("aaaaaaaaaaaasssssddd")
    
      setload1(true)
    
    console.log('sortby', sortby)
    
    const response = await axios.post('vendorpage/getallstores', {
      // params: {
        page: currentPage,
        user_id: profile ? profile?._id : "",
        text: searchValue,
        category: JSON.stringify(cat),
        sub_category: JSON.stringify(subcat),
        country: JSON.stringify(country),
        city: JSON.stringify(city),
        area: JSON.stringify(area),
        question: JSON.stringify(question),
        attribute: JSON.stringify(att),
        sub_attribute: JSON.stringify(subatt),
        min_price: value1,
        max_price: value2,
        sortby: sortby,
        lang: savedLanguage,
        countryType: "pakistan"
      // }
    });

    const { results: newResults, totalResults: newTotalResults,  startResult: startResult, endResult: endResult } = response?.data;

    setres(newTotalResults)
    setStartResult(startResult)
    setEndResult(endResult)
    setload1(false)
    
    if (!init) {
      setdata((prevResults) => [...prevResults, ...newResults]);      
    } else {
      setdata(newResults);
    }
    
    // Set the total pages based on the total results from the response
    const itemsPerPage = 18;
    const totalPages = Math.ceil(newTotalResults / itemsPerPage);
    settotalPages(totalPages);
    setload1(false);
    setready(ready+1)
    // setcontent(true)
   }

  } catch (rejectedValueOrSerializedError) {
    console.log(rejectedValueOrSerializedError);
  }
};

console.log('countryysaas', searchedCategroy)


const getallLocations = async () => {
  try {

    setload(true);
    setcontent(true);

      setcat([]);
      setcity([]);
      setcountry([]);
  




    const queryParams = new URLSearchParams(window.location.search);
    const filters = {
      search: queryParams.get("search"),
      country: queryParams.get("country"),
      city: queryParams.get("city"),
      area: queryParams.get("area"),
      cat: queryParams.get("cat"),
      subcat: queryParams.get("subcat"),
      ques: queryParams.get("ques"),
      att: queryParams.get("att"),
      subatt: queryParams.get("subatt"),
      minprice: queryParams.get("minprice"),
      maxprice: queryParams.get("maxprice"),
      sortby: queryParams.get("sortby"),
      page: queryParams.get("page"),
    };

    if (filters.search) {
      setSearchValue(splitAndTrim(filters.search));
    }

    if (filters.country) {
      setcountry(splitAndTrim(filters.country));
    }

    if (filters.city) {
      setcity(splitAndTrim(filters.city));
    }

    if (filters.area) {
      setarea(splitAndTrim(filters.area));
    }

    if (filters.cat) {
      setcat(splitAndTrim(filters.cat));
    }

    if (filters.subcat) {
      setsubcat(splitAndTrim(filters.subcat));
    }

    if (filters.ques) {
      setquestion(splitAndTrim(filters.ques));
    }

    if (filters.att) {
      setatt(splitAndTrim(filters.att));
    }

    if (filters.subatt) {
      setsubatt(splitAndTrim(filters.subatt));
    }

    if (filters.minprice) {
      setValue1(splitAndTrim(filters.minprice));
    } else {
      setValue1(0);
    }

    if (filters.maxprice) {
      setValue2(splitAndTrim(filters.maxprice));
    } else {
      setValue2(100000);
    }

    if (filters.sortby) {
      setsortby(splitAndTrim(filters.sortby)?.[0]?.toLowerCase());
    }

    if (filters.page) {
      setCurrentPage(parseInt(filters.page, 10));
    }

    if(parts[0]!=="suppliers"){
    const setalldata= await axios.post("vendorpage/setinitdata", {parts: parts, lang: savedLanguage});

    if(setalldata?.data?.message=="reload"){
      window.location.href = setalldata?.data?.data;
    }
    else{

      

      if(setalldata?.data?.data?.condition=="categoryExists and cityExists"){

        console.log("saaaaaaafirst",setalldata?.data?.data?.categoryExists,setalldata?.data?.data?.cityExists)

      setcategoryimage(setalldata?.data?.data?.categoryExists?.cover_image1 ? setalldata?.data?.data?.categoryExists?.cover_image1 : setalldata?.data?.data?.categoryExists?.cover_image)
      setcityimage(setalldata?.data?.data?.cityExists?.imageName)
      setcat([setalldata?.data?.data?.categoryExists?._id]);
      
      setsearchedCategroy(setalldata?.data?.data?.categoryExists)
      setchangedsearchedCategroy(setalldata?.data?.data?.categoryExists)

      setcity([setalldata?.data?.data?.cityExists?._id]);
      setcityorcountry("city")

      setSearchQuery(setalldata?.data?.data?.cityExists)
      setchangedSearchQuery(setalldata?.data?.data?.cityExists)

    }


     else if(setalldata?.data?.data?.condition=="categoryExists and countryExists"){

      console.log('categoryExists,countryExists', setalldata?.data?.data?.categoryExists,setalldata?.data?.data?.countryExists)

      setcategoryimage(setalldata?.data?.data?.categoryExists?.cover_image1 ? setalldata?.data?.data?.categoryExists?.cover_image1 : setalldata?.data?.data?.categoryExists?.cover_image)
      setcat([setalldata?.data?.data?.categoryExists._id]);
    
      setsearchedCategroy(setalldata?.data?.data?.categoryExists)
      setchangedsearchedCategroy(setalldata?.data?.data?.categoryExists)

      setcountry([setalldata?.data?.data?.countryExists?._id]);
      setcityorcountry("country")

      setSearchQuery(setalldata?.data?.data?.countryExists)

      setchangedSearchQuery(setalldata?.data?.data?.countryExists)

    }

     else if(setalldata?.data?.data?.condition=="categoryExists and areaExists"){

                                   
          setcategoryimage(setalldata?.data?.data?.categoryExists?.cover_image1 ? setalldata?.data?.data?.categoryExists?.cover_image1 : setalldata?.data?.data?.categoryExists?.cover_image)
          setcat([setalldata?.data?.data?.categoryExists._id]);

          setsearchedCategroy(setalldata?.data?.data?.categoryExists)
          setchangedsearchedCategroy(setalldata?.data?.data?.categoryExists)

          setarea([setalldata?.data?.data?.areaExists?._id]);   
          setcity([setalldata?.data?.data?.areaExists.city_id._id]);
          setcityorcountry("city")

          setSearchQuery(setalldata?.data?.data?.areaExists)
          setchangedSearchQuery(setalldata?.data?.data?.areaExists)


    }

     else if(setalldata?.data?.data?.condition=="subCategoryExists and cityExists"){

      setsubcat([setalldata?.data?.data?.subCategoryExists?._id]);
      setcat([setalldata?.data?.data?.subCategoryExists?.parent_id?._id])
      setsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setchangedsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setcity([setalldata?.data?.data?.cityExists?._id]);
      setcityorcountry("city")
      setSearchQuery(setalldata?.data?.data?.cityExists)
      setchangedSearchQuery(setalldata?.data?.data?.cityPart)


    }

     else if(setalldata?.data?.data?.condition=="subCategoryExists and countryExists"){

                                   
      setsubcat([setalldata?.data?.data?.subCategoryExists?._id]);
      setcat([setalldata?.data?.data?.subCategoryExists?.parent_id?._id])
      setsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setchangedsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setcountry([setalldata?.data?.data?.countryExists?._id]);
      setcityorcountry("city")
      setSearchQuery(setalldata?.data?.data?.cityExists)
      setchangedSearchQuery(setalldata?.data?.data?.cityPart)


    }

     else if(setalldata?.data?.data?.condition=="subCategoryExists and areaExists"){

                                   
      setsubcat([setalldata?.data?.data?.subCategoryExists?._id]);
      setcat([setalldata?.data?.data?.subCategoryExists?.parent_id?._id])
      setsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setchangedsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setarea([setalldata?.data?.data?.areaExists?._id]);
      setcityorcountry("city")
      setSearchQuery(setalldata?.data?.data?.areaExists)
      setchangedSearchQuery(setalldata?.data?.data?.cityPart)


    }

     else if(setalldata?.data?.data?.condition=="cityExists"){

                                   
      setcityimage(setalldata?.data?.data?.cityExists?.imageName)
      setSearchQuery(setalldata?.data?.data?.cityExists)
      setchangedSearchQuery(setalldata?.data?.data?.cityExists)
      setsearchedCategroy("")
      setchangedsearchedCategroy("")
      setcity([setalldata?.data?.data?.cityExists?._id]);
      setcityorcountry("city")


    }

     else if(setalldata?.data?.data?.condition=="countryExists"){

                                   
      setSearchQuery(setalldata?.data?.data?.countryExists)
          
      setchangedSearchQuery(setalldata?.data?.data?.countryExists)
      
      setsearchedCategroy("")
      
      setchangedsearchedCategroy("")
      
      setcountry([setalldata?.data?.data?.countryExists?._id]);
      setcityorcountry("country")


    }

     else if(setalldata?.data?.data?.condition=="areaExists"){

                                   
      setarea([setalldata?.data?.data?.areaExists?._id]);
          
      setcity([setalldata?.data?.data?.areaExists.city_id._id]);
      setcityorcountry("city")
      
      setSearchQuery(setalldata?.data?.data?.areaExists)
      
      setchangedSearchQuery(setalldata?.data?.data?.areaExists)
      
      setsearchedCategroy("")
      
      setchangedsearchedCategroy("")
      

    }

     else if(setalldata?.data?.data?.condition=="categoryExists"){


                                   
      setcategoryimage(setalldata?.data?.data?.categoryExists?.cover_image1 ? setalldata?.data?.data?.categoryExists?.cover_image1 : setalldata?.data?.data?.categoryExists?.cover_image)
          
      setsearchedCategroy(setalldata?.data?.data?.categoryExists)
      setchangedsearchedCategroy(setalldata?.data?.data?.categoryExists)
          
      setSearchQuery("")
          
      setchangedSearchQuery("")
          
      setcat([setalldata?.data?.data?.categoryExists?._id]);
      

    }

     else if(setalldata?.data?.data?.condition=="subCategoryExists"){

                                   
     
      setsubcat([setalldata?.data?.data?.subCategoryExists?._id]);
      setcat([setalldata?.data?.data?.subCategoryExists?.parent_id?._id])
      setsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
      setchangedsearchedCategroy(setalldata?.data?.data?.subCategoryExists?.parent_id)
            
      setSearchQuery("")
            
      setchangedSearchQuery("")
      

    }

   

    }
    
    
    
  }
  setload(false);
  setcontent(false);

  } catch (error) {
    console.error("Error in getallLocations:", error);
  }
};

console.log('content', content)
console.log('searchedCategroy', searchedCategroy)
console.log('load', load)


const getCategoriesAndLocation= async()=>{

  const [cityresponse, categoryresponse,co] = await Promise.all([
    dispatch(getCitiesWithCountry(savedLanguage)).unwrap(),
    axios.get("user/getservices",{params:{lang: savedLanguage}}),
    await axios.get("user/getcountries",{params:{lang: savedLanguage}})
  ]);
  
  setcities(cityresponse?.data);
  setcategories(categoryresponse?.data?.data);
  // setcountries(co?.data?.data);
}


const splitAndTrim = (str) => str ? str.split(",").map(item => item.trim()) : [];





const removeExtraQueriesfromURL = async () => {
  const filters = {};
  const queryParams = new URLSearchParams(window.location.search);

  const countryfilterFromUrl = queryParams.get('country');
  const cityfilterFromUrl = queryParams.get('city');
  const areafilterFromUrl = queryParams.get('area');
  const categoryfilterFromUrl = queryParams.get('cat');
  const subcategoryfilterFromUrl = queryParams.get('subcat');
  const questionfilterFromUrl = queryParams.get('ques');
  const attributefilterFromUrl = queryParams.get('att');
  const subattributefilterFromUrl = queryParams.get('subatt');
  const minpricefilterFromUrl = queryParams.get('minprice');
  const maxpricefilterFromUrl = queryParams.get('maxprice');
  const sortbyfilterFromUrl = queryParams.get('sortby');
  const pagefilterFromUrl = queryParams.get('page');

  // Handle 'country' filter
  if (countryfilterFromUrl && country?.length > 0) {
    filters.country = countryfilterFromUrl;
  }

  // Handle 'city' filter
  if (cityfilterFromUrl && city?.length > 0) {
    filters.city = cityfilterFromUrl;
  }

  // Handle 'area' filter
  if (areafilterFromUrl && area?.length > 0) {
    filters.area = areafilterFromUrl;
  }

  // Handle 'category' filter
  if (categoryfilterFromUrl && cat?.length > 0) {
    filters.cat = categoryfilterFromUrl;
  }

  // Handle 'subcategory' filter
  if (subcategoryfilterFromUrl && subcat?.length > 0) {
    filters.subcat = subcategoryfilterFromUrl;
  }

  // Handle 'question' filter
  if (questionfilterFromUrl) {
    if (questionfilterFromUrl.includes(',')) {
      const questionArray = questionfilterFromUrl.split(',');
      setquestion(questionArray);
    } else {
      setquestion([questionfilterFromUrl]);
    }
  }

  // Handle 'attribute' filter
  if (attributefilterFromUrl && att?.length > 0) {
    filters.att = attributefilterFromUrl;
  }

  // Handle 'subattribute' filter
  if (subattributefilterFromUrl && subatt?.length > 0) {
    filters.subatt = subattributefilterFromUrl;
  }

  // Handle 'minprice' filter
  if (minpricefilterFromUrl && value1 !== 0) {
    filters.minprice = minpricefilterFromUrl;
  }

  // Handle 'maxprice' filter
  if (maxpricefilterFromUrl && value2 !== million) {
    filters.maxprice = maxpricefilterFromUrl;
  }

  // Handle 'sortby' filter
  if (sortbyfilterFromUrl && sortby !== "") {
    filters.sortby = sortbyfilterFromUrl;
  }

  // Handle 'page' filter
  if (pagefilterFromUrl) {
    filters.page = pagefilterFromUrl;
  }

  // Remove empty filters from the URL
  const nonEmptyFilters = Object.keys(filters)
    .map((key) => `${key}=${filters[key]}`)
    .join('&');

  const newSearch = nonEmptyFilters ? `?${nonEmptyFilters}` : '';

  // Update the URL with the non-empty filters
  const newUrl = `${location.pathname}${newSearch}`;
  window.history.replaceState({}, '', newUrl);
};


  const handleSlider1Change = (e) => {
    const newValue = Math.min(
      million * (e.target.value / 100), // Convert back to a million
      value2 - million * 0.01 // Ensure a gap of 1% from the other slider
    );
    setValue1(newValue);

    queryParams.set('minprice', newValue);
    
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleSlider2Change = (e) => {
    const newValue = Math.max(
      million * (e.target.value / 100), // Convert back to a million
      value1 + million * 0.01 // Ensure a gap of 1% from the other slider
    );
    setValue2(newValue);

    queryParams.set('maxprice', newValue);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  
  const handlePageChange = (page) => {
    console.log('Setting page:', page);
    setCurrentPage(page);
  
    queryParams.set('page', encodeURIComponent(page));
  
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    console.log('New URL:', newUrl);
  
    // Use navigate or window.location.href to navigate
    navigate(newUrl);
  };

  
  useEffect(() => {
    const handlePopState = (event) => {
    
      console.log('Back button clicked, URL changed to: ' + document.location);
  
    
      window.history.back();
  };

   
    window.addEventListener('popstate', handlePopState);

   
    return () => {
        window.removeEventListener('popstate', handlePopState);
    };

}, []);




  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5; 
  
    if (totalPages <= maxPagesToShow) {
    
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
     
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    
      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
  
     
      pages.push(renderPageLink(1));
  
      
      if (startPage > 2) {
        pages.push(renderEllipsis());
      }
  
      
      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }
  
      
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }
  
    
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }
  
    return pages;
  };
  
  
  
  
  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );
  
  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );

 
  
  const getSubCategories = async (id) => {
    try {
    
      setload2(true)
      const response = await axios.post(`user/getsubcategories`, {id: id, type: "search", lang: savedLanguage});
      
      setsubcategories(response?.data?.data);
      setload2(false)

    } catch (rejectedValueOrSerializedError) {}
  };


  const getQuestions = async (id) => {
    try {

      setload2(true)
      const response = await axios.post(`user/getsearchquestions`, {id: id, type: "search", lang: savedLanguage});
      setquestions(response?.data?.data);
      setload2(false)

    } catch (rejectedValueOrSerializedError) {}
  };


  const getAttributes = async (id) => {
    try {
      setload2(true)
      setinitattributes(null);
      const response = await axios.post(`user/getattributes`, {id: id[0], type: "search", lang: savedLanguage});

      setinitattributes(response?.data?.data);
      setload2(false)
      
    } catch (rejectedValueOrSerializedError) {}
  };

  console.log('cityPart', searchQuery)

  const getSubAttributes = async (id) => {
    try {
      setload2(true)
      setinitsubattributes(null);
      const response =  await axios.post(`user/getsubattributes`, {id: id[0], type: "search", lang: savedLanguage});
      setinitsubattributes(response?.data?.data);
      setload2(false)
      
    } catch (rejectedValueOrSerializedError) {}
  };


  const getCitiesByCountryId = async () => {
    try {
      setload2(true)
      setcitiesonly(null);
      const response = await axios.post('user/getcityByCountryId', {country: "66596fcc9079984b6b40ad6e", lang: savedLanguage});
      setcitiesonly(response?.data?.data);
      setload2(false)
    } catch (rejectedValueOrSerializedError) {}
  };

  const getAreaByCityId = async () => {
    try {
      setload2(true)
      setareas(null);
      const response = await axios.post('user/getareaByCityId', {city: city, lang: savedLanguage});
      console.log('responsasassae', response)
      setareas(response?.data?.data);
      setload2(false)
    } catch (rejectedValueOrSerializedError) {}
  };


  

  console.log('countryorcity', cityorcountry)
  console.log('cityyy', city)
  console.log('areas', areas)
  console.log('area', area)
  console.log('questions', questions)
  console.log('searchQuery', searchQuery)
  console.log('subcategories', subcategories)
  console.log('searchedCategroyzz', searchedCategroy)
  console.log('categoryimageeeeee', categoryimage,cityimage,cat)
 




  
  useEffect(() => {

    getallLocations()

  }, [click]); 



  useEffect(() => {
    
    
    getallStores(currentPage,true)
    removeExtraQueriesfromURL()
  

  }, [cat,city,country,area,subcat,question,att,subatt,priceToggle,sortby,currentPage,click]);



  useEffect(() => {
    
    
    getCitiesByCountryId()
  

  }, []);

  useEffect(() => {
    
    
    getAreaByCityId()
  

  }, [city]);

  


  useEffect(() => {
    

    
    getSubCategories([cat]);
    getQuestions([cat]);
    getAttributes([cat]);
    getSubAttributes([cat])

    
   
  
}, [cat]);


  useEffect(() => {
    
    
    
    renderPaginationLinks()

     
  
}, [totalPages]);


  useEffect(() => {
    
    
    
    window.scrollTo({ top: 0, left: 0})

    
   
  
}, [currentPage]);

  useEffect(() => {
    
    
    
    getCategoriesAndLocation()

    
   
  
}, []);





const handlePin = async () => {

  try {
   
  const finalIndex= store_id

 
  const abc = await axios.post("/vendorpage/favourite",{ id: profile?._id, user_events: myEvents?.map((evv)=>evv.Event._id),store_id:store_id, event_id:event,category_id:category_id })

  if (abc) {
    closeModal();
    toast.success(abc?.data?.message);
 
    const index = data.findIndex(item => item._id == finalIndex);
    console.log('index', index)
    let val= event

  if (index !== -1) {
    const newData = [...data];
      newData[index].isfavourite = val;
      setdata(newData);
  } 
  
 
}

} catch (error) {
// Handle error
console.error("Error occurred:", error);
}


};

const handleRemoveCitiesOnCountryRemove=(e)=>{

  const filteredArr = citiesonly.filter(obj => obj.country_id === e);

  const filteredArr2 = city.filter(id => !filteredArr.some(obj => obj._id === id));

  setcity(filteredArr2)


  
}

const handleCountryChange = (e) => {



  let updatedCountries;


  if (country.includes(e)) {
   
    updatedCountries = country.filter((service) => service !== e);
    
  } else {
   
    updatedCountries = [...country, e];
  }

  setcountry(updatedCountries);
  handleRemoveCitiesOnCountryRemove(e)
 
  queryParams.set('country', updatedCountries.join(',')); 


  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleCityChange = (e) => {
  let updatedCountries;

  if (city.includes(e)) {
   
    updatedCountries = city.filter((service) => service !== e);
  } else {
   
    updatedCountries = [...city, e];
  }

  setcity(updatedCountries);
  queryParams.set('city', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleAreaChange = (e) => {

  let updatedCountries;

  if (area.includes(e)) {
   
    updatedCountries = area.filter((service) => service !== e);
  } else {
   
    updatedCountries = [...area, e];
  }

  setarea(updatedCountries);
  queryParams.set('area', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleSubCategoryChange = (e) => {
  let updatedCountries;

  if (subcat.includes(e)) {
   
    updatedCountries = subcat.filter((service) => service !== e);
  } else {
   
    updatedCountries = [...subcat, e];
  }

  setsubcat(updatedCountries);
  queryParams.set('subcat', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleQuestionChange = (e,type,removeradioids) => {
  let updatedCountries;
console.log('type', type)
console.log('removeradioids', removeradioids)

  
if(type=="radio"){

  let idsFinal = removeradioids?.map((id)=>id._id)


  
  const ids=idsFinal.filter((service) => service !== e);

  console.log('idsFinal', ids,e)

  let radioFinal= question.filter(item => !ids.includes(item));

  radioFinal=[...radioFinal,e]

  console.log('radiofinal', radioFinal)
  

  updatedCountries = radioFinal?.length>0?radioFinal:[e];
}
  
else{
    if (question.includes(e)) {
     
      updatedCountries = question.filter((service) => service !== e);
    } else {
     
      updatedCountries = [...question, e];
    }
  }


  setquestion(updatedCountries);
  queryParams.set('ques', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleAttributeChange = (e) => {
  let updatedCountries;

  if (att.includes(e)) {
   
    updatedCountries = att.filter((service) => service !== e);
  } else {
   
    updatedCountries = [...att, e];
  }

  setatt(updatedCountries);
  queryParams.set('att', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleSubAttributeChange = (e) => {
  let updatedCountries;

  if (subatt.includes(e)) {
   
    updatedCountries = subatt.filter((service) => service !== e);
  } else {
   
    updatedCountries = [...subatt, e];
  }

  setsubatt(updatedCountries);
  queryParams.set('subatt', updatedCountries.join(',')); 

  
  navigate(`${location.pathname}?${queryParams.toString()}`);
};

const handleShotlistEventChange = (id) => {
  console.log('idddddddddddddddd', id);
  if (!event.includes(id)) {
    setevent([...event, id]);
  } else {
    const updatedEventIds = event.filter((itemId) => itemId !== id);
    setevent(updatedEventIds);
  }
};

console.log('searchedCategroy', searchedCategroy)

  return (
    <>
    <Helmet>
      <title>{location.pathname === "/suppliers" ? "Find Supplier - Shadi Tayari" : `${searchedCategroy?.name ? `${searchedCategroy?.name}` : ""}${searchedCategroy?.name && searchQuery?.name ? " in " : "" }${searchQuery?.name ? searchQuery?.name : ""}`}</title>
      <meta name="description" content="Find Wedding Venue and Wedding Supplier in Islamabad, Lahore, and Karachi"/>
    </Helmet>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Task"
        className="modal fade show wf-modal tasks"
      >
        <div class="modal-dialog wf-modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-0">
              <h1
                class="font-size-26 dm-font purple-color"
                id="staticBackdropLabel"
              >
                Select An Event
              </h1>
              <button
                type="button"
                onClick={closeModal}
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>

            </div>


            <div class="modal-body">

              <p class="font-size-16  color-black">Please select the events for which you want to shortlist this store</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {myEvents?.length > 0 && myEvents?.map((e) => (<>
                  <input onChange={() => handleShotlistEventChange(e?.Event?._id)} checked={event?.includes(e.Event._id) ? true : false} type="checkbox" class="btn-check" name="event-select" id={e?.Event?._id} autocomplete="off" />
                  <label style={{ "--bs-btn-active-bg": `${e.Event.event_color}` }} className="btn wf-select-btn" for={e?.Event?._id}>{e?.Event?.event_name}</label>
                </>))}
              </div>

            </div>
            <div class="modal-footer border-0 justify-content-center">
              <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2" onClick={() => handlePin()}>Add</button>
            </div>
          </div>
        </div>
      </Modal>

      <section class="archives-hero">
        {!load ? (
          <div className="archive-img">
            <img className="w-100" alt=""
              src={categoryimage !== "" ? `${process.env.REACT_APP_IMGURL}${categoryimage}` : cityimage !== "" ? `${process.env.REACT_APP_IMGURL}${cityimage}` : `../assets/images/supplier_background.jpg`} />
          </div>
        ) : (
          <div className="archive-img shimmer"></div>)}
        <Header theme="light" />
        <div class="container pb-3">
          <div className="pt-5 pb-5 ">
            {location.pathname && !load ? (
              searchedCategroy && searchQuery ? (
                <h1 className="h1-title playfair-font color-white text-center">
                  {`Find Me A ${searchedCategroy?.name} IN ${searchQuery?.name}`}
                </h1>
              ) : searchedCategroy && !searchQuery ? (
                <h1 className="h1-title playfair-font color-white text-center">
                  {`Find Me A ${searchedCategroy?.name
                    }`}
                </h1>
              ) : searchQuery && !searchedCategroy ? (
                <h1 className="h1-title playfair-font color-white text-center">
                  {`Find Me A Supplier IN ${searchQuery?.name
                    }`}
                </h1>
              ) : <h1 className="h1-title playfair-font color-white text-center">
                Find Me A Supplier
              </h1>
            ) : (<h1 className="h1-title playfair-font color-white text-center">
              &nbsp;
            </h1>)}
            <div className="search-wrapper search-wrapper-2">
              <div className="search-field-1 search-field-input">
                <div class="dropdown">
                  <div class="input-group search-field-input-group">
                    <input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} type="text" class="form-control" placeholder={t("Search")} />
                    <button onClick={() => setSearchValue("")} class="input-group-text" id="basic-addon1"><i className="bi bi-x-circle"></i></button>
                  </div>
                  {/* {showDropdown && (
          <div className="dropdown-menu show" style={{ width: "100%" }}>
            <div className="list-autocomplete">
              {new Array(6).fill(null).map((option, index) => (
                  <button key={index} type="button" className="dropdown-item">
                    {option.tag}
                  </button>
                ))}
            </div>
          </div>
        )} */}
                </div>
              </div>
              <div className="search-field-2">
                <div class="dropdown">
                  <a class="btn btn-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {changedsearchedCategroy?.name ? changedsearchedCategroy?.name : <>{t('SelectCategory')}</>}
                  </a>
                  <ul class="dropdown-menu">
                    <li onClick={() => setchangedsearchedCategroy("")}>
                      <a class="dropdown-item">{t('SelectCategory')}</a>
                    </li>
                    {categories &&
                      categories.map((item, i) => (
                        <li key={i} onClick={() => setchangedsearchedCategroy(item)}>
                          <a class="dropdown-item">{item.name}</a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="search-field-3">
                <div className="dropdown">
                  <a class="btn btn-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {changedsearchQuery?.countryName ? changedsearchQuery?.countryName : changedsearchQuery?.name ? changedsearchQuery.name : <>{t('SelectLocation')}</>}
                  </a>

                  <ul className="dropdown-menu">
                    <li onClick={() => setchangedSearchQuery("")}>
                      <a class="dropdown-item">{t('SelectLocation')}</a>
                    </li>
                    {cities?.length > 0 && cities?.map((c) => (<li className={`country-list ${changedsearchQuery.name == c.name && "selected"}`}><a onClick={() => { setchangedSearchQuery(c); setcityorcountry("country") }} class="dropdown-item" ><img src={`${process.env.REACT_APP_IMGURL}${c.countryFlag}`} width="25" className="me-1" /> {c.countryName}</a>
                      {c.cities?.map((ci) => (<li className={`city-list ${changedsearchQuery?.name == ci.name && "selected"}`}><a onClick={() => { setchangedSearchQuery(ci); setcityorcountry("city") }} style={{ "padding-left": "30px" }} class="dropdown-item">{ci.name}</a></li>))}
                    </li>))}
                  </ul>
                </div>
              </div>
              <div className="search-btn" onClick={() => {
                setclick(!click)
                setready(ready + 1)
                const path = changedsearchedCategroy && changedsearchQuery ?
                  `/suppliers/${changedsearchedCategroy?.slug}-in-${changedsearchQuery?.slug}` :
                  changedsearchedCategroy && !changedsearchQuery ?
                    `/suppliers/${changedsearchedCategroy?.slug}` :
                    !changedsearchedCategroy && changedsearchQuery ?
                      `/suppliers/${changedsearchQuery?.slug}` :
                      `/suppliers`;

                if (path) {
                  if (searchValue) {
                    queryParams.set('search', searchValue);
                    console.log('queryParams', queryParams.toString())
                    navigate(`${path}?${queryParams.toString()}`);
                  }
                  else {

                    navigate(`${path}`);
                  }
                }
              }}>
                <i class="fas fa-search"></i>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="archives-section">
        <div className="container container-xxxll">
          <div className="row gy-4">
            <div className="col-md-3">
              <a className="d-md-none" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <h2 class="font-size-26 playfair-font color-black mb-4">{t('FilterBox')} <i class="bi bi-chevron-compact-down"></i></h2>
              </a>
              <div class="collapse d-md-block" id="collapseExample">






                {load2 ? (
                  <>
                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mb-2"></span>
                    <span style={{ width: '100%', height: '14px' }} className="shimmer rounded d-block mb-1"></span>


                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>

                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>


                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>

                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>

                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>

                    <span style={{ width: '110px', height: '24px' }} className="shimmer rounded d-block mt-4 mb-2"></span>
                    <div className="d-flex flex-row flex-wrap">
                      {new Array(6).fill(null).map((_, index) => (
                        <TextSkeleton key={index} />
                      ))}
                    </div>
                  </>
                )
                  :
                  (<>

                    <h4 class="font-size-22 color-black mt-4 mb-2">{t('Price')}</h4>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="font-size-16 color-black mb-0">PKR {Math.round((value1 / million) * million)}</p>
                      <p className="font-size-16 color-black mb-0">PKR {Math.round((value2 / million) * million)}</p>
                    </div>
                    <div className="price-slider">
                      <div className="slider" id="slider-distance">
                        <div>
                          <div className="inverse-left" style={{ width: `${(value1 / million) * 100}%` }}></div>
                          <div className="inverse-right" style={{ width: `${100 - (value2 / million) * 100}%` }}></div>
                          <div className="range" style={{ left: `${(value1 / million) * 100}%`, right: `${100 - (value2 / million) * 100}%` }}></div>
                          <span className="thumb" style={{ left: `${(value1 / million) * 100}%` }}></span>
                          <span className="thumb" style={{ left: `${(value2 / million) * 100}%` }}></span>
                          {/* <div className="sign" style={{ left: `${(value1 / million) * 100}%` }}>
    <span id="value"></span>
  </div>
  <div className="sign" style={{ left: `${(value2 / million) * 100}%` }}>
    <span id="value"></span>
  </div> */}
                        </div>
                        <input
                          type="range"
                          tabIndex="0"
                          onMouseUp={() => setpriceToggle(!priceToggle)}
                          value={(value1 / million) * 100}
                          max="100"
                          min="0"
                          step="1"
                          onChange={handleSlider1Change}
                        />
                        <input
                          type="range"
                          tabIndex="0"
                          onMouseUp={() => setpriceToggle(!priceToggle)}
                          value={(value2 / million) * 100}
                          max="100"
                          min="0"
                          step="1"
                          onChange={handleSlider2Change}
                        />

                      </div>
                    </div>


                    {/* {countries?.length>0&&searchQuery==""&&(<div><h4  class="font-size-22 color-black mt-4 mb-2">{t('Countries')}</h4>
      <div  className="d-flex flex-row flex-wrap">
       {countries?.map((c)=>(<div class="form-check wf-filter-check">
        <input class="form-check-input" type="checkbox" value={c._id} id={c._id} checked={country?.includes(c?._id)} onChange={()=>handleCountryChange(c?._id)} />
        <label class="form-check-label" for={c._id}>
        {c?.name}
        </label>
      </div>))}
      </div></div>)} */}

                    {/* {country?.length > 0 && ( */}
                    <>
                      <h4 className="font-size-22 color-black mt-4 mb-2">{t('Cities')}</h4>
                      {data && (
                        <div className="d-flex flex-row flex-wrap">
                          {citiesonly?.length > 0 && citiesonly?.map(c => (
                            <div className="form-check wf-filter-check" key={c._id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={c._id}
                                id={c._id}
                                checked={city?.includes(c?._id)}
                                onChange={() => handleCityChange(c._id)}
                              />
                              <label className="form-check-label" for={c._id}>
                                {c.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                    {/* )} */}



                    {city?.length > 0 && areas?.length > 0 && (
                      <>
                        <h4 className="font-size-22 color-black mt-4 mb-2">{t('Areas')}</h4>

                        <div className="d-flex flex-row flex-wrap">
                          {areas?.length > 0 && areas?.map(c => (
                            <div className="form-check wf-filter-check" key={c._id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={c._id}
                                id={c._id}
                                checked={area?.includes(c?._id)}
                                onChange={() => handleAreaChange(c._id)}
                              />
                              <label className="form-check-label" for={c._id}>
                                {c.name}
                              </label>
                            </div>
                          ))}
                        </div>

                      </>
                    )}


                    {cat !== "" && subcategories?.length > 0 && (
                      <>
                        <h4 class="font-size-22 color-black mt-4 mb-2">{t('Sub-Categories')}</h4>
                        <div className="d-flex flex-row flex-wrap">
                          {subcategories[0]?.subcategories?.map((s) => (<div class="form-check wf-filter-check">
                            <input class="form-check-input" type="checkbox" id={s?._id} value={s?._id} checked={subcat?.includes(s?._id)} onChange={() => handleSubCategoryChange(s._id)} />
                            <label class="form-check-label" for={s?._id}>
                              {s?.name}
                            </label>
                          </div>))}
                        </div>
                      </>
                    )}

                    {cat !== "" && questions?.length > 0 ? questions?.map((q) => (<>
                      <h4 class="font-size-22 color-black mt-4 mb-2">{q?.headingText}</h4>
                      <div className="d-flex flex-row flex-wrap">
                        {q?.options?.map((s) => (
                          <div class="form-check wf-filter-check">
                            <input class="form-check-input" type="checkbox" id={s?._id} value={s?._id} checked={question?.includes(s?._id)} onChange={() => handleQuestionChange(s._id, q.type, q?.options)} />
                            <label class="form-check-label" for={s._id}>
                              {s.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                    )) : null}
                    {cat !== "" && initattributes?.length > 0 && (
                      <>
                        <h4 class="font-size-22 color-black mt-4 mb-2">{t('Attributes')}</h4>
                        <div className="d-flex flex-row flex-wrap">
                          {initattributes[0]?.subcategories?.map((s) => (<div class="form-check wf-filter-check">
                            <input class="form-check-input" type="checkbox" id={s?._id} value={s?._id} checked={att?.includes(s?._id)} onChange={() => handleAttributeChange(s._id)} />
                            <label class="form-check-label" for={s?._id}>
                              {s?.name}
                            </label>
                          </div>))}
                        </div>
                      </>
                    )}





                    {cat !== "" && initsubattributes?.length > 0 && (
                      <>
                        <h4 class="font-size-22 color-black mt-4 mb-2">{t('ExtraFeatures')}</h4>
                        <div className="d-flex flex-row flex-wrap">
                          {initsubattributes[0]?.subcategories?.map((s) => (<div class="form-check wf-filter-check">
                            <input class="form-check-input" type="checkbox" id={s?._id} value={s?._id} checked={subatt?.includes(s?._id)} onChange={() => handleSubAttributeChange(s._id)} />
                            <label class="form-check-label" for={s?._id}>
                              {s?.name}
                            </label>
                          </div>))}
                        </div>
                      </>
                    )}
                  </>)}
              </div>
            </div>
            <div className="col-md-9">


              {country?.length > 0 || city?.length > 0 || area?.length > 0 || subcat?.length > 0 || question?.length > 0 || att?.length > 0 || subatt?.length > 0 ? (<div id="fiter-tab" className="d-flex flex-wrap align-items-center gap-2 mb-5">
                <h2 class="font-size-26 playfair-font color-black mb-0" style={{ "marginRight": "10px" }}>{t('Filters')}</h2>

                {/* //country filters */}
                {parts.length === 1 && countries?.length > 0 && countries?.filter(obj => country?.includes(obj._id)).map(c => (
                  <div >
                    <span className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                      {c?.name}
                      <i onChange={() => handleCountryChange(c._id)} className="bi bi-x cursor-pointer"></i>
                    </span>
                  </div>
                ))}

                {/* //city filters */}
                {cityorcountry !== "city" && citiesonly?.filter(obj => city?.includes(obj._id)).map(c => (
                  <span onChange={() => handleCityChange(c._id)} className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                    {c.name}
                    <i className="bi bi-x cursor-pointer"></i>
                  </span>
                ))}

                {/* //area filters */}
                {area?.length > 0 && areas?.filter(obj => area?.includes(obj._id)).map(c => (
                  <span className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                    {c.name}
                    <i onChange={() => handleAreaChange(c._id)} className="bi bi-x cursor-pointer"></i>
                  </span>
                ))}

                {/* //subcat filters */}
                {subcat?.length > 0 && subcategories?.length > 0 && subcategories[0]?.subcategories?.filter(obj => subcat?.includes(obj._id)).map(c => (
                  <span className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                    {c.name}
                    <i onChange={() => handleSubCategoryChange(c._id)} className="bi bi-x cursor-pointer"></i>
                  </span>
                ))}

                {/* //ques filters */}
                {question.length > 0 && questions?.map(questionItem => (
                  <div key={questionItem._id}>
                    {questionItem.options.filter(option => question.includes(option._id)).map(option => (
                      <span key={option._id} className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                        {option.name}
                        <i onChange={() => handleQuestionChange(option._id)} className="bi bi-x cursor-pointer"></i>
                      </span>
                    ))}
                  </div>
                ))}
                {/* //att filters */}
                {att?.length > 0 && initattributes?.length > 0 && initattributes[0]?.subcategories?.filter(obj => att?.includes(obj._id)).map(c => (
                  <span className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                    {c.name}
                    <i onChange={() => handleAttributeChange(c._id)} className="bi bi-x cursor-pointer"></i>
                  </span>
                ))}

                {/* //subatt filters */}
                {subatt?.length > 0 && initsubattributes?.length > 0 && initsubattributes[0]?.subcategories?.filter(obj => subatt?.includes(obj._id)).map(c => (
                  <span className="font-size-14 rounded-1 wf-grey-btn py-1 px-3 fw-medium">
                    {c.name}
                    <i onChange={() => handleSubAttributeChange(c._id)} className="bi bi-x cursor-pointer"></i>
                  </span>
                ))}
              </div>
              ) : null}


              <div className="row  mb-4">
                <div className="col-md-6">
                  <p className="font-size-16 color-black mb-0">{res > 0 ? `Showing ${startResult} - ${endResult} (Total: ${res})` : `Total: ${res}`}</p>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                  <select className="currency-select sorting" onChange={(e) => { setsortby(e.target.value); queryParams.set('sortby', e.target.value); navigate(`${location.pathname}?${queryParams.toString()}`); }}>
                    <option selected={sortby == "latest"} value="latest">{t('Latest')}</option>
                    {/* <option value="Relevance">Relevance</option> */}
                    <option selected={sortby == "price-high-to-low"} value="price-high-to-low">{t('PriceHightoLow')}</option>
                    <option selected={sortby == "price-low-to-high"} value="price-low-to-high">{t('PriceLowtoHigh')}</option>
                    <option selected={sortby == "recent-updated"} value="recent-updated">{t('RecentUpdated')}</option>
                    <option selected={sortby == "most-popular"} value="most-popular">{t('MostPopular')}</option>
                  </select>
                </div>
              </div>

              <div className="row gy-4">
                {load1 ? (
                  new Array(18).fill(null).map((_, index) => (
                    <VendorCardSkeleton key={index} />
                  ))
                ) : data?.length > 0 ? (<>
                  {data?.slice(0, Math.ceil(data?.length / 2))?.map((d) => (
                    <div key={d._id} className="col-lg-4 col-md-6 vendor-card">
                      <div className="vendor-card-body">
                        <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={() => IncrementView(d._id, "profile")}>
                          <img alt={d.store_name} src={`${process.env.REACT_APP_IMGURL}${d?.image}`} className="vendor-image w-100" />
                        </a>
                        <div className="d-flex justify-content-between align-items-center top-box">
                          {d?.is_featured == 1 ? (<span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase">{t('Featured')}</span>) : null}
                          {profile && profile?.role == "customer" && (<div className="heart-icon" onClick={() => viewModal(d?._id, d?.prefered_services[0]?._id, d?.isfavourite)}>
                            {d?.isfavourite?.length > 0 ? <Heart fill="#EE0E7B" stroke="#EE0E7B" /> : <Heart />}
                          </div>)}
                        </div>
                        <div className="d-flex align-items-center bottom-box">
                          <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                            <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${d?.prefered_services[0]?.slug}`}>{d?.prefered_services[0]?.name}</a>
                          </span>
                        </div>
                      </div>
                      <div className="vendor-card-footer">
                        <h3 className="font-size-18 fw-semibold mb-1" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={() => IncrementView(d._id, "profile")} className="color-black">{d.store_name}</a></h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="font-size-16 color-black mb-0">{`${d.cities.length > 0 ? d.cities[0]?.name : ""}${d.cities.length > 0 && d.country.length > 0 ? "," : ""} ${d.country.length > 0 ? d.country[0]?.name : ""}`}</p>

                          <p className="font-size-16 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{d?.reviews?.average}</span> ({d?.reviews?.total})</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12">
                    <CustomAd height="120" width="966" link="https://www.shaditayari.pk/" image={`${process.env.REACT_APP_FrontURL}/assets/images/default-ad.jpg`} name="Shadi Tayari" />
                  </div>
                  
                  {data?.slice(Math.ceil(data?.length / 2))?.map((d) => (
                    <div key={d._id} className="col-lg-4 col-md-6 vendor-card">
                      <div className="vendor-card-body">
                        <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={() => IncrementView(d._id, "profile")}>
                          <img alt={d.store_name} src={`${process.env.REACT_APP_IMGURL}${d?.image}`} className="vendor-image w-100" />
                        </a>
                        <div className="d-flex justify-content-between align-items-center top-box">
                          {d?.is_featured == 1 ? (<span className="font-size-12 rounded-1 wf-purple-btn py-1 px-3 text-uppercase">{t('Featured')}</span>) : null}
                          {profile && profile?.role == "customer" && (<div className="heart-icon" onClick={() => viewModal(d?._id, d?.prefered_services[0]?._id, d?.isfavourite)}>
                            {d?.isfavourite?.length > 0 ? <Heart fill="#EE0E7B" stroke="#EE0E7B" /> : <Heart />}
                          </div>)}
                        </div>
                        <div className="d-flex align-items-center bottom-box">
                          <span className="font-size-12 rounded-1 wf-pink-btn py-1 px-3 text-uppercase cursor-pointer">
                            <a href={`${process.env.REACT_APP_FrontURL}/suppliers/${d?.prefered_services[0]?.slug}`}>{d?.prefered_services[0]?.name}</a>
                          </span>
                        </div>
                      </div>
                      <div className="vendor-card-footer">
                        <h3 className="font-size-18 fw-semibold mb-1" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${d.slug}`} onClick={() => IncrementView(d._id, "profile")} className="color-black">{d.store_name}</a></h3>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="font-size-16 color-black mb-0">{`${d.cities.length > 0 ? d.cities[0]?.name : ""}${d.cities.length > 0 && d.country.length > 0 ? "," : ""} ${d.country.length > 0 ? d.country[0]?.name : ""}`}</p>

                          <p className="font-size-16 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{d?.reviews?.average}</span> ({d?.reviews?.total})</p>
                        </div>
                      </div>
                    </div>
                  ))} </> ) : null}
              </div>



              <nav aria-label="Page navigation example" className="mt-5">
                <ul class="pagination wf-pagination justify-content-between">

                  <li className="page-item">
                    {!totalPages <= 1 && currentPage > 1 && (<a onClick={() => handlePageChange(currentPage - 1)} className="page-link" aria-label="Previous">
                      <i className="bi bi-arrow-left"></i>
                    </a>)}
                  </li>

                  <ul class="pagination wf-pagination-2 gap-1">
                    {renderPaginationLinks()}
                  </ul>
                  <li className="page-item">
                    {totalPages > 1 && currentPage !== totalPages && (<a onClick={() => handlePageChange(currentPage + 1)} className="page-link" aria-label="Next">
                      <i className="bi bi-arrow-right"></i>
                    </a>)}
                  </li>
                </ul>
              </nav>

            </div>
          </div>

        </div>
      </section>


      <Footer />
    </>
    // )
  )
}

export default Vendors