import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import {
  getEthnics, getReligions, getThemes, getProfile, completeSignUp, addEvent, getUserEventList
} from "../../store/slices/userSlice";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { useTranslation } from "react-i18next";

const SignUpStep5 = ({ formData, handleFormUpdate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);


  const [ethnics, setEthnics] = useState(null);
  const [religions, setreligions] = useState(null);
  const [themes, setthemes] = useState(null);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);



  const Ethnics = async () => {
    try {
      setload(true);
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      console.log("abc", response?.data);
      setEthnics(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Religions = async () => {
    try {
      setload(true);
      setreligions(null);
      const response = await dispatch(getReligions()).unwrap();
      console.log("abc", response?.data);
      setreligions(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Themes = async () => {
    try {
      setload(true);
      setthemes(null);
      const response = await dispatch(getThemes()).unwrap();
      console.log("abc", response?.data);
      setthemes(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Ethnics();
      Religions();
      Themes();
    }
    return () => {
      mount = false;
    };
  }, []);



  return (
    <>

            <h3 className="font-size-20 dm-font pink-color mb-2">{t('PleaseSelectyourReligiousPreference')}</h3>
            <p className="font-size-14 purple-color mb-2">{t('WhatReligiouscustomsareyouplanningtofollow')}:</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(5).fill(null).map((_, index) => (
                <div key={index} className='shimmer rounded-3' style={{height:'100px', width:'100px'}}></div>
              )):religions?.length>0&&religions.map((t)=>(
          <>
          <input type="radio" checked={formData.prefered_religion==t._id} onChange={(e)=>handleFormUpdate({ prefered_religion: t._id})} id={t._id} name="religion" class="btn-check" />
          <label key={t._id} class="btn wf-select-btn d-flex flex-column justify-content-center gap-2" style={{width:'100px'}} for={t._id}>
            <img src={`${process.env.REACT_APP_IMGURL}${t?.imageName}`} className="mx-auto d-block color" height="35" />
            <img src={`${process.env.REACT_APP_IMGURL}white-${t?.imageName}`} className="mx-auto d-block white" height="35" />
            {t.name}</label>
          </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('SelectYourEthnicinfluence')}</h3>
        <p className="font-size-14 purple-color mb-2">{t('Wouldyouliketohaveanyethnicinfluenceinyourevent')}?</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(8).fill(null).map((_, index) => (
            <ButtonSkeleton key={index} />
          )):ethnics?.length>0&&ethnics.map((t)=>(
            <>
            <input type="radio" checked={formData.prefered_ethnics==t._id} onChange={(e)=>handleFormUpdate({ prefered_ethnics:  t._id})} name="ehtnic-option" id={t._id} class="btn-check" />
            <label class="btn wf-select-btn" for={t._id}>{t.name}</label>
            </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('ThinkingofaWeddingtheme')}?</h3>
        <p className="font-size-14 purple-color mb-2">{t('WhatTypeofThemeWeddingareyouplanningfor')}:</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(8).fill(null).map((_, index) => (
            <ButtonSkeleton key={index} />
          )):themes?.length>0&&themes.map((t)=>(
            <>
            <input type="radio" checked={formData.prefered_themes==t._id} onChange={(e)=>handleFormUpdate({ prefered_themes:  t._id})} name="themes-option" id={t.name} class="btn-check" />
            <label class="btn wf-select-btn" for={t.name}>{t.name}</label>
            </>
          ))}
        </div>

            <div class="d-flex justify-content-center gap-3">

                <button class="btn wf-btn-2 wf-pink-outline-btn py-2 px-4 rounded-2" onClick={()=>{if(formData.page === 4 && !formData.isquestion) {
                  handleFormUpdate({ page: formData.page-1 })
                }
                else if(formData.page === 4 && formData.isquestion) {
                  handleFormUpdate({ page: formData.page-2 })
                }
                else{
                  handleFormUpdate({ page: formData.page-1 })
                }}}>{t('Back')}</button>

                <button class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" disabled={load1} onClick={async () => {
setload1(true);
              
                  await dispatch(
                    addEvent({
                                person1: profile?._id,
                                person2: null,
                                event_name: formData.event_name,
                                event_color: formData.event_color,
                                event_city: formData.event_city,
                                event_date: formData.event_date,
                                event_prefered_day: formData.event_prefered_day,
                                event_prefered_season: formData.event_prefered_season,
                                event_prefered_year: formData.event_prefered_year,
                                no_of_guests: formData.no_of_guests,
                                user_budget: parseInt(formData.user_budget),
                                user_budget_currency: formData.user_budget_currency,
                                prefered_services: JSON.stringify(
                                    formData.prefered_services
                                  ),              
                                sub_services: JSON.stringify(formData.sub_services),
                                prefered_religion: formData.prefered_religion,
                                prefered_ethnics: formData.prefered_ethnics,
                                prefered_themes: formData.prefered_themes,
                                role: "customer"
                              })
                            ).unwrap();
                            await dispatch(getUserEventList())
                            navigate("/dashboard");    
                            setload1(false);                  
                          }}>
                            {load1 ? (
          <div className="spinner-border wf-spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <> {t('Save')}</> 
        )}
                          </button>
                </div>
  
           
    </>
  );
};

export default SignUpStep5;