import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Firstlogin,
  getEventList,
  getProfile,
  getUserEventsTasks,
} from "../../store/slices/userSlice";
import { Newsletter } from "../Public/Newsletter"


import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Navigation, Pagination, A11y, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import EventList_Dashboard from "./EventList_Dashboard";
import Blogs_Dashboard from "./Blogs_Dashboard";
import Chatlist_Dashboard from "./Chatlist_Dashboard";
import SavePins_Dashboard from "./SavePins_Dashboard";
import Counter from "./Counter";
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import { Heart } from "lucide-react";
import WeddingSuppliers_Dashboard from "./WeddingSuppliers_Dashboard";
import Venues_Dashboard from "./Venues_Dashboard";
import { useTranslation } from "react-i18next";
import TaskList_Dashboard from "./TaskList_Dashboard";
import Bookings_Dashboard from "./Bookings_Dashboard";
import noEventUI from "./noEventUI";
import SmallCalendar from '../../components/Layout/SmallCalendar'



const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const EventList = useSelector(getEventList);
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();

  const Profile = async () => {
    try {
      await dispatch(Firstlogin()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    GettaskNames();
    Profile();
  }, [EventList]);


  const GettaskNames = async () => {
    try {
      const response = await dispatch(
        getUserEventsTasks({ event_ids: EventList })
      );
      console.log("Response from getUserEventsTasks:", response.payload.data);

      if (response) {
        console.log("Setting tasks:", response);
      } else {
        console.log("No data received from getUserEventsTasks.");
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };


  console.log('myEvents', myEvents)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, []);

  const [date, setDate] = useState(new Date());

  const handleDateChange = (date) => {
    setDate(date);
    navigate('/calendar', { state: { date } }); // Navigate to '/calendar' with date state
  };


  return (
    <>
      <section className="wf-user-dashboard wf-background px-md-5 pb-5 pt-2">
        <div className="container-fluid">
          <div className="row gy-4">
            <div className="col-md-12">
              <EventList_Dashboard />

            </div>
            <div className="col-lg-8 col-md-12">
              <TaskList_Dashboard />
              <Bookings_Dashboard />
              <SavePins_Dashboard />
            </div>
            <div className="col-lg-4 col-md-12">
              {/* Row 1 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <div class="d-flex gap-4 align-items-end mb-0">
                    <h2 class="font-size-40 dm-font color-black mb-0">Calendar</h2>
                  </div>
                </div>

              </div>
              {/* Row 2 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <div className='Wediffied-Calender'>
                    <SmallCalendar selectedDate={date} onDateChange={handleDateChange} />
                  </div>
                </div>
              </div>
              <Chatlist_Dashboard />
              <div id="download-app" className="mb-3">
                <h2 className="font-size-26 dm-font color-black mb-2">{t('DownloadourMobileApp')}</h2>
                <p className="font-size-14 purple-color mb-2">{t('StartPlanningYourDreamWeddingToday')}!</p>
                <div className="wf-shadow-2 white-bg rounded py-3 px-4">
                  <img class="img-fluid w-100 mb-4" src={`${process.env.REACT_APP_FrontURL}/assets/images/download-app-graphics.svg`} alt="" />
                  <div className="d-flex align-items-center gap-3 mb-3 justify-content-center">
                    <div><img class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt="" /></div>
                    <div><img class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt="" /></div>
                  </div>
                  <h2 className="font-size-20 color-black mb-0 text-center dm-font"><a className="color-black"><u>{t('Download')} <span className="pink-color">{t('ShadiTayari')}</span> {t('forFree')}!</u></a></h2>
                </div>
              </div>
              <Newsletter />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;