import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getProfile,
} from "../../store/slices/userSlice";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Overlay, Tooltip } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarData from './CalendarData'

const localizer = momentLocalizer(moment);

const IconStyle = {
    cursor: "pointer"
};

const Event = ({ event }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef(null);

    const closeTooltip = () => {
        setShowTooltip(false);
    };

    const openTooltip = () => {
        setShowTooltip(true);
    }

    // Determine the CSS class based on the type of event
    const eventClass = event.type === 'Event' ? 'event' : 'task';
    const events_class = `w-100 d-block overflow-hidden text-nowrap text_overflow_ellipsis mb-1 ${eventClass}`;

    return (
        <div ref={ref}>
            <span className={events_class}
                onMouseOver={openTooltip}
                onMouseLeave={closeTooltip}
            >{event.title}</span>
            <Overlay
                target={ref.current}
                show={showTooltip}
                placement="top"
                onHide={closeTooltip}
            >
                <Tooltip id="rbc" className="wf-rbc_calendertooltip">
                    <TooltipContent style={{ maxWidth: 300 }} event={event} onClose={closeTooltip} />
                </Tooltip>
            </Overlay>
        </div>
    );
}

const TooltipContent = ({ onClose, event }) => {
    const profile = useSelector(getProfile);
    console.log('eventere', event)
    return (
        <>
            {/* <i className="bi bi-x pull-right bg-black" style={IconStyle} onClick={onClose}></i> */}
            {/* <div>{event.title}</div>
            <div>{event.description}</div> */}
            <div className="container-fluid">

                {event.type === 'Event' ? (
                    // Events Html  
                    <div className='row mb-0'>
                        <div className='col-12'>
                            <div className='wf-shadow-2 white-bg rounded px-3 py-3 '>
                                <div className='d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-md-center gap-3 gap-md-2'>
                                    <div className="d-flex">
                                        <div className='wf-event-name'>
                                            <p className="font-size-10 fw-bold color-black me-3 mb-0">{event.title}</p>
                                            <span className='eventline nikah' style={{ background: `${event?.data?.Event?.event_color}` }}></span>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='wf-up-avatar d-flex mb-2'>
                                            <div className='avatar-couple-first'>
                                                <img width="34" height="34" alt="" src={(event.data?.person1 && event.data?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${event.data?.person1?.imageName}` : "https://via.placeholder.com/50x50")} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                            </div>
                                            <div className='avatar-couple-sec' style={{ marginLeft: '-10px' }}>
                                                <img width="34" height="34" alt="" src={(event.data?.person2 && event.data?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${event.data?.person2?.imageName}` : "https://via.placeholder.com/50x50")} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                            </div>
                                        </div>
                                        <h3 className="font-size-10 fw-medium color-black mb-1">
                                            <span className='purple-color'>{event.data?.person1?.name || ""}</span> & <span className='pink-color'> {event.data?.person2?.name || "partner"}</span>
                                        </h3>
                                    </div>
                                    <div className='d-flex gap-4'>
                                        <div className='wf-eventslistdate'>
                                            <p className='font-size-10 color-black mb-1'>{moment(event.data?.Event?.event_date).format("dddd, D MMMM YYYY")}
                                            </p>
                                        </div>
                                        <div className='wf-eventslistlocation'>
                                            <p className='font-size-10 color-black mb-1'>{event.data?.Event?.event_city?.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    // TaSK hTML
                    <div className='row mb-0'>
                        <div className='col-12'>
                            <div className='wf-shadow-2 white-bg rounded px-2 py-2 mb-0'>
                                <div className='row align-items-stretch'>
                                    <div className='col-md-2'>
                                        <div className='wf-task-avatar d-flex mb-2'>
                                            <img width="58" height="59" alt="" src={(event?.data?.taskCategory && event?.data?.taskCategory?.imageName
                                                ? `${process.env.REACT_APP_IMGURL}${event?.data?.taskCategory?.imageName}` : "https://via.placeholder.com/50x50")} class="" style={{}} />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center bottom-box mb-2 mb-md-0">
                                            <span className="font-size-8 rounded-1 wf-pink-btn py-1 px-2 text-uppercase">
                                                <a href="#" className="color-white">{event.data.taskCategory.name}</a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-7 align-self-center'>
                                        <h3 className="font-size-10 fw-medium color-black mb-2">{event.title}</h3>
                                        <div className='No-of-per-complete mb-2 d-flex gap-2'>
                                            {/* <div className='d-flex gap-2 align-items-center'>
                                            <div className='color-green rounded' style={{ width: '30px', height: '9px' }}></div> <div className='color-red rounded' style={{ width: '30px', height: '9px' }}></div>
                                        </div> */}
                                            <h4 className="font-size-8 fw-medium purple-color mb-0">
                                                {/* 100% Completed */}
                                                {event.data.status == 1 ? "Completed" : "Incomplete"}
                                            </h4>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between mb-2'>
                                            <div className='wf-event-name'>
                                                <p className="font-size-10 fw-medium color-black me-2 mb-0">{event?.event?.event_name}</p>
                                                <span className='eventline nikah'></span>
                                            </div>
                                            <div class="d-flex align-items-center gap-1 mb-0 me-md-5">
                                                <h4 className="font-size-10 fw-medium color-black mb-0">Assigned to:</h4>
                                                <p class="wf-Task-assigned-dropdown purple-color font-size-10 mb-0">{profile?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 text-end d-flex flex-md-column justify-content-between'>
                                        {/* <div className='purple-color d-flex justify-content-end align-items-center gap-2'>
                                        <h4 className="font-size-10 fw-medium purple-color mb-0"> Completed </h4>
                                        <div>
                                            <input type="checkbox" class="btn-check" id="123" />
                                            <label class="btn wf-btn-checkk" for="123" >
                                                <i class="bi bi-check-lg d-flex"></i>
                                            </label>
                                        </div>
                                    </div> */}
                                        <div className='wf-taskduedate'>
                                            <h3 className="font-size-10 color-black mb-1">Due Date</h3>
                                            <p className="font-size-10 purple-color mb-1">{moment(event.data?.date).format("D MMM YYYY")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    // TaSK hTML
                )}

            </div>
        </>
    );
}

const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {};
    if (event.type === 'Event') {
        style.backgroundColor = '#804099';
    } else if (event.type === 'Task') {
        style.backgroundColor = '#000';
    }
    style.border = 'none';
    style.borderRadius = '0px';
    style.fontSize = "10px";
    style.outline = '0px';
    return {
        style: style
    };
};

const CustomToolbar = ({ label, onNavigate, onView, views, view }) => (
    <div className="rbc-toolbar">
        <span className="rbc-btn-group">
            <button type="button" onClick={() => onNavigate('TODAY')} className="rbc-btn-nav rounded">
                Today
            </button>
            {/* <span onClick={() => onNavigate('PREV')} className="cursor-pointer ms-2">
                <i class="bi bi-chevron-left"></i>
            </span>
            <span onClick={() => onNavigate('NEXT')} className="cursor-pointer me-2">
                <i class="bi bi-chevron-right"></i>
            </span> */}
        </span>
        <span className="rbc-toolbar-label text-start ">{label}</span>
        <span className="rbc-btn-group">
            <select
                className="form-select shadow-none"
                onChange={(e) => onView(e.target.value)}
                value={view}
            >
                {views.map((view) => (
                    <option key={view} value={view}>
                        {view.charAt(0).toUpperCase() + view.slice(1)}
                    </option>
                ))}
            </select>
        </span>
    </div>
);

const BigCalendar = ({ selectedDate, onDateChange, events, eventType, onEventTypeChange }) => {


    // const [date, setDate] = useState(new Date());
    const [view, setView] = useState('month');
    // const filteredEvents = CalendarData() ban kiya
    // Function to handle date change
    // const onChange = (date) => {
    //     setDate(date);
    //     // Handle date change event if needed
    // };

    const handleSelectSlot = (slotInfo) => {
        onDateChange(slotInfo.start);
        setView('day');
    };

    const handleViewChange = (newView) => {
        setView(newView);
    };
    return (
        <>
            {/* wf-shadow-2 white-bg rounded */}
            <div className='wf-rbc_calender  px-1 px-sm-1 py-3'>
                <Calendar
                    // onChange={onChange}
                    onNavigate={onDateChange}
                    date={selectedDate}
                    style={{ height: 700 }}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    defaultDate={moment().toDate()}
                    eventPropGetter={eventStyleGetter}
                    localizer={localizer}
                    views={['month', 'week', 'day']} // Only include the month view
                    toolbar={true} // Display the toolbar with navigation buttons
                    popup={true}
                    selectable
                    onSelectSlot={handleSelectSlot}
                    view={view}
                    onView={handleViewChange}
                    components={{
                        event: Event,
                        toolbar: CustomToolbar
                    }}
                />
            </div>
        </>
    )
}

export default BigCalendar
