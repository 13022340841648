import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NoEventUI from './noEventUI';
import {
  getEventList,
  getProfile,
  getAllBookings,
  approvePayment,
  addManualBookings,
  getUserEventList,
} from "../../store/slices/userSlice";
import axios from "axios";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { Briefcase, CalendarCheck2, CheckCircle, LayoutList, ListFilter, Loader, Search } from "lucide-react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ArrowUpDown } from "lucide-react";
import { MessageSquare } from "lucide-react";
import { List } from "lucide-react";
import { PieChart } from 'react-minimal-pie-chart';


const customStyles = {
  content: {
    display: "block",
  },

};
Modal.setAppElement("#root");



const BookingsContent = () => {

  const { t } = useTranslation();
  const myEvents = useSelector(getEventList);
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [graphdata, setgraphdata] = useState([]);
  const [load, setload] = useState(false);
  const [modalType, setmodalType] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [modalType1, setmodalType1] = useState("");
  const [modalIsOpen1, setmodalIsOpen1] = useState(false);
  const [userDetail, setuserDetail] = useState();
  const [filtertoggle, setfiltertoggle] = useState(true);
  const [search, setsearch] = useState("");
  const location = useLocation();
  const isDashboard = location.pathname !== "/bookings"
  const queryParams = new URLSearchParams(location.search);
  const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple") ? JSON.parse(queryParams.get("couple")) : []);
  const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const [updated, setupdated] = useState(true);
  const [actualmyEvents, setactualmyEvents] = useState([]);
  const [selectedCouple, setselectedCouple] = useState("");



  const [bookings, setbookings] = useState([]);
  const [eventlistdata, seteventlistdata] = useState([]);
  const [categorylistdata, setcategorylistdata] = useState([]);
  const [event_id, setevent_id] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [totalprice, settotalprice] = useState(0);
  const [paymentstatus, setpaymentstatus] = useState("fullypaid");
  const [initdeposit, setinitdeposit] = useState(0);
  const [initdeposittask, setinitdeposittask] = useState(false);
  const [initdepositdate, setinitdepositdate] = useState("");
  const [finaldeposit, setfinaldeposit] = useState(0);
  const [finaldeposittask, setfinaldeposittask] = useState(false);
  const [finaldepositdate, setfinaldepositdate] = useState("");
  const [remainingAmount, setremainingAmount] = useState(0);
  const [load1, setload1] = useState(false);

  // const [bookingId, setbookingId] = useState("");
  // const [paymentRecieved, setpaymentRecieved] = useState("");
  // const [value, setvalue] = useState("");

  //filter states
  const [event, setevent] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const [category, setcategory] = useState([]);
  const [status, setstatus] = useState(5);
  const [filteredData, setfilteredData] = useState();


  const EventList = myEvents?.map((ev) => {
    return {
      couple_id: ev?._id,
      id: ev?.Event?._id,
      name: ev?.Event?.event_name,
      color: ev?.Event?.event_color,
      date: ev?.Event?.event_date,
    };
  });



  const viewModal = async ({ type, obj }) => {
    console.log('typeeeeeeeeeee', type)
    if (type == "create") {
      setmodalType(type)
      setmodalIsOpen(true);
    }
    // else if(type=="paymentApprove"){
    //   // setvalue(value)
    //   // setpaymentRecieved(paymentRecieved)
    //   // setbookingId(bookingId)
    //   setmodalIsOpen(true);
    //   approveInitDeposit(bookingId,value,paymentRecieved)
    // }
    else if (type == "details") {
      setmodalType(type)
      setmodalIsOpen(true);
      setuserDetail(obj)
    }
    else if (type == "filter") {
      setmodalType(type)
      setmodalIsOpen(true);
    }
    else {
      setmodalType1(type)
      setmodalIsOpen1(true);
    }
  };

  function closeModal(type) {
    if (type == "create") {
      setmodalIsOpen(false);
      setmodalType("")
    }
    if (type == "details") {
      setmodalIsOpen(false);
      setmodalType("")
    }
    if (type == "filter") {
      setmodalIsOpen(false);
      setmodalType("")
    }
    else {
      setmodalIsOpen1(false);
      setmodalType1("")
    }
  }


  const approveInitDeposit = async ({ value, paymentRecieved, bookingId }) => {
    try {
      const response = await dispatch(approvePayment({ bookingId: bookingId, paymentRecieved: paymentRecieved, value: value })).unwrap();
      console.log('response', response)
      if (response) {
        closeModal("details")
        await getBookings()
        await dispatch(getUserEventList()).unwrap();
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const currentDate = getCurrentDate();


  const handleSetData = (e) => {

    const mergedEvents = [];


    e?.forEach(event => {
      const modifiedEvent = {
        ...event,
        Event: [event.Event]
      };

      const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
      );

      if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
      } else {
        mergedEvents.push(modifiedEvent);
      }
    });

    setactualmyEvents(mergedEvents)
    // setfilteredData(mergedEvents)
  }


  useEffect(() => {
    handleSetData(myEvents)
  }, [myEvents]);



  const getBookings = async () => {
    try {
      setload(true);
      setbookings(null);

      let eventIds = [];

      for (const events of myEvents) {
        if (events.Event && events.Event._id) {
          eventIds.push(events.Event._id);
        }
      }

      const response = await dispatch(
        getAllBookings({ event_ids: eventIds })
      ).unwrap();

      console.log('response', response)
      if (response) {
        setbookings(response?.data);
        setfilteredData(response?.data);



        const graphValues = calculateGraphValues(myEvents, response.data)
        setgraphdata(graphValues)

        const eventlist = sumSimilarObjects(response.data)
        seteventlistdata(eventlist)

        const categorylist = sumSimilarObjectsCategory(response.data)
        setcategorylistdata(categorylist)

      }
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('bookings', bookings)


  function sumSimilarObjects(arr) {
    // Object to store sums for each unique user_event._id
    const sums = {};

    console.log('arr', arr)
    // Iterate over each object in the array
    arr.forEach(obj => {
      const event_id = obj.user_event._id;
      const event_name = obj.user_event.event_name;
      const event_color = obj.user_event.event_color;

      // If the eventId already exists in sums, update the sums
      if (event_id in sums) {
        sums[event_id].paid += obj.paid || 0;
        sums[event_id].due += obj.due || 0;
        sums[event_id].totalPrice += obj.totalPrice || 0;
      } else {
        // If the event_id doesn't exist, initialize the sums
        sums[event_id] = {
          paid: obj.paid || 0,
          due: obj.due || 0,
          totalPrice: obj.totalPrice || 0
        };
      }
    });

    // Convert the sums object back to an array of objects
    const result = Object.keys(sums).map(eventId => ({
      _id: arr.find(obj => obj.user_event._id === eventId).user_event._id,
      event_name: arr.find(obj => obj.user_event._id === eventId).user_event.event_name,
      event_color: arr.find(obj => obj.user_event._id === eventId).user_event.event_color,
      paid: sums[eventId].paid,
      due: sums[eventId].due,
      totalPrice: sums[eventId].totalPrice
    }));

    return result;
  }

  function sumSimilarObjectsCategory(arr) {
    // Object to store sums for each unique category_id
    const sums = {};
    const existence = {};

    // Iterate over each object in the array
    arr.forEach(obj => {
      const category_id = obj.category_id._id;


      // If the category_id already exists in sums, update the sums
      if (category_id in sums) {
        sums[category_id].paid += obj.paid || 0;
        sums[category_id].due += obj.due || 0;
        sums[category_id].totalPrice += obj.totalPrice || 0;
        existence[category_id]++;
      } else {
        // If the category_id doesn't exist, initialize the sums and existence count
        sums[category_id] = {
          paid: obj.paid || 0,
          due: obj.due || 0,
          totalPrice: obj.totalPrice || 0
        };
        existence[category_id] = 1; // Initialize count to 1
      }
    });

    // Convert the sums object back to an array of objects
    const result = Object.keys(sums).map(category_id => ({
      _id: category_id,
      name: arr.find(obj => obj.category_id._id === category_id).category_id.name,
      imageName: arr.find(obj => obj.category_id._id === category_id).category_id.imageName,
      paid: sums[category_id].paid,
      due: sums[category_id].due,
      totalPrice: sums[category_id].totalPrice,
      length: existence[category_id] // Assign the count of occurrences
    }));

    return result;
  }


  console.log('eventlistdata', eventlistdata)
  console.log('categorylistdata', categorylistdata)

  function calculateGraphValues(allEvents, data) {
    const finalData = allEvents?.map(eventItem => {
      if (eventItem?.Event && eventItem?.Event?.event_name) {
        const matchingEvent = data?.filter(item => item?.user_event?.event_name == eventItem?.Event?.event_name)?.length;
        console.log('matchingEvent', matchingEvent)
        if (matchingEvent) {
          // const { male_count, female_count, child_count } = matchingEvent;
          // const totalValue = male_count + female_count + child_count;
          return {
            title: eventItem.Event.event_name,
            value: matchingEvent,
            color: eventItem.Event.event_color
          };
        }
        else {
          return {
            title: eventItem.Event.event_name,
            value: 0,
            color: eventItem.Event.event_color
          };
        }
      }
    })
    const filteredFinalData = finalData?.filter(item => item !== null && item !== undefined);
    return filteredFinalData;
  }


  const getCategories = async () => {
    try {
      setload(true);
      const response = await axios.get(`user/getservices`);
      console.log("responsexx", response);
      setAllCategories(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) { }
  };

  useEffect(() => {

    getCategories();
    getBookings();


  }, [myEvents]);

  const Booking_Status = [
    {
      id: 5,
      option: "All",
    },
    {
      id: 0,
      option: "Cancelled",
    },
    {
      id: 1,
      option: "Confirmed",
    },
    {
      id: 2,
      option: "Pending",
    },
    {
      id: 3,
      option: "Completed",
    },
  ];

  const handleFilters = () => {
    const filteredData = bookings?.filter((i) => {
      const coupleMatches = coupleURLFilter?.length == "" || coupleURLFilter?.includes(i.user_id);
      const coupleFilterMatches = selectedCouple == "" || selectedCouple == i?.user_id;
      const eventMatches = event == "" || event?.includes(i?.user_event._id);
      const categoryMatches =
        category == "" || category.includes(i?.category_id._id);
      const statusmatches = status == 5 || i?.status == status;
      return eventMatches && statusmatches && categoryMatches && coupleMatches && coupleFilterMatches;
    });

    setfilteredData(filteredData);
  };

  useEffect(() => {
    handleFilters();
  }, [filtertoggle]);


  useEffect(() => {

    const newCoupleURLFilter = queryParams.get("couple") || "";

    setcoupleURLFilter(newCoupleURLFilter);

    const newcoupleEventURLFilter = queryParams.get("events") || [];

    setevent(newcoupleEventURLFilter)

    setfiltertoggle(!filtertoggle)
  }, [location.search]);


  useEffect(() => {
    if (search === "") {
      handleFilters()
    } else {
      const searchText = search.toLowerCase();

      const filteredEvents = bookings?.filter(i => {
        const coupleMatches = coupleURLFilter?.length == "" || coupleURLFilter?.includes(i.user_id);
        const coupleFilterMatches = selectedCouple == "" || selectedCouple == i?.user_id;
        const eventNameMatches = i?.user_event?.event_name?.toLowerCase().includes(searchText);
        const eventMatches = event == "" || event?.includes(i?.user_event._id);
        const categoryMatches =
          category == "" || category.includes(i?.category_id._id);
        const statusmatches = status == 5 || i?.status == status;
        const titleMatch = i?.title?.toLowerCase().includes(searchText);
        const storeNameMatch = i?.vendor_event?.store_name?.toLowerCase().includes(searchText);
        return titleMatch || storeNameMatch || eventNameMatches && coupleMatches && coupleFilterMatches && eventMatches && categoryMatches && statusmatches;
      });
      setfilteredData(filteredEvents);
    }
  }, [search]);





  useEffect(() => {
    if (updated) {
      handleFilters();
    }
  }, [bookings]);

  console.log('modalType', modalType)

  const saveBranch = async (route) => {
    try {
      setload1(true)
      console.log('initdeposit', initdeposit, totalprice)

      if (!paymentstatus) {
        toast.error("Payment status is required")
      }
      else if (!event_id) {
        toast.error("Please select an event")
      }
      else if (!title) {
        toast.error("Title is required")
      }
      else if (myEvents?.length < 1) {
        toast.error("You don't have any event to proceed")
      }
      else if (parseInt(initdeposit) > parseInt(totalprice)) {
        toast.error("Initial deposit cannot be greater than total price")
      }
      else if (parseInt(finaldeposit) > parseInt(totalprice)) {
        toast.error("Final deposit cannot be greater than total price")
      }
      else if (parseInt(finaldeposit) + parseInt(initdeposit) > parseInt(totalprice)) {
        toast.error("Price cannot be greater then total price")
      }
      else {

        const response = await dispatch(
          addManualBookings({
            event_id: event_id,
            user_id: coupleURLFilter,
            store_category_id: category_id,
            title: title,
            description: description,
            price: totalprice,
            paymentStatus: paymentstatus,
            initialDeposit: initdeposit,
            initDepositDate: initdepositdate,
            initDepositTask: initdeposittask,
            finalDeposit: totalprice - initdeposit,
            finalDepositDate: finaldepositdate,
            finalDepositTask: finaldeposittask,
            price: totalprice,
            remainingAmount: remainingAmount > 0 ? remainingAmount : null,
            force: route == "modal" ? true : false,
          })
        ).unwrap();

        console.log("responseeeee34", response);
        // return
        if (response.data.remainingAmount > 0 && response.status == 0) {
          console.log('aaa123')
          setremainingAmount(response.data.remainingAmount);
          viewModal({ type: "add" });
        } else {
          settitle("");
          setdescription("");
          settotalprice("");
          setpaymentstatus("");
          setinitdeposit("");
          setinitdeposittask(false);
          setinitdepositdate("");
          setfinaldeposit("");
          setfinaldeposittask(false);
          setfinaldepositdate("");
          setremainingAmount(0);
          setupdated(false)
          await getBookings();
          await dispatch(getUserEventList()).unwrap();
          setmodalIsOpen(false);
          setmodalType("")
          setmodalIsOpen1(false);
          setmodalType1("")
        }

      }
      setload1(false)
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleEventChange = (id) => {
    console.log("idddddddddddddddd", id);
    if (!event.includes(id)) {
      setevent([...event, id]);
    } else {
      const updatedEventIds = event?.filter((itemId) => itemId !== id);
      setevent(updatedEventIds);
    }
  };

  const handleCategoryChange = (id) => {
    if (!category.includes(id)) {
      setcategory([...category, id]);
    } else {
      const updatedcategoryIds = category.filter((itemId) => itemId !== id);
      setcategory(updatedcategoryIds);
    }
  };

  console.log("userDetail", userDetail);

  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const options = {
    // title: "My Daily Activities",
    legend: "none",
    pieHole: 0.4,
    is3D: false,
  };


  return (
    <div>
      {modalType1 == "add" ? (<Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen1}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add Task"
        className={`modal fade show wf-modal wf-delete-modal`}
      >
        <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
          <div className="modal-content" id="addbooking">
            <div className="modal-header border-0 pb-0">
              <h1 className="font-size-22 dm-font purple-color" id="addbooking">{t('Confirmation')}</h1>
              <button type="button" onClick={() => closeModal("add")} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p className="color-black font-size-14 mb-2">{t('priceisgreater')}</p>
            </div>
            <div className="modal-footer border-0 justify-content-center gap-2">
              <button className="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={() => closeModal("add")}>{t('Cancel')}</button>
              <button onClick={() => saveBranch("modal")} className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" disabled={load1}>{load1 ? (
                <div className="spinner-border wf-spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>{t('Accept')}</>
              )}</button>
            </div>
          </div>
        </div>
      </Modal>) :
        modalType == "create" ? (<Modal
          // shouldCloseOnOverlayClick={true}
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Task"
          className={`modal fade show wf-modal`}
        >
          <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
            <div className="modal-content" id="addbooking">
              <div className="modal-header border-0 pb-0">
                <h1 className="font-size-22 dm-font purple-color" id="addbooking">
                  {t('Add')} {t('Booking')}
                </h1>
                <button
                  type="button"
                  onClick={() => closeModal("create")}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">

                {profile?.role == "planner" && (<>
                  <p className="font-size-18 dm-font pink-color mb-2">
                    {t("Couples")}
                  </p>
                  <div className="d-flex gap-2 flex-wrap mb-4">
                    {load ? (
                      new Array(5)
                        .fill(null)
                        .map((_, index) => <ButtonSkeleton key={index} />)
                    ) : (
                      <>

                        {actualmyEvents?.map((e) => (
                          <>
                            <input
                              checked={selectedCouple == e._id}
                              onChange={() => { setselectedCouple(e._id) }}
                              type="checkbox"
                              name="event-select"
                              id={e._id}
                              className="btn-check"
                            />
                            <label
                              className="btn wf-select-btn"
                              for={e._id}
                            >
                              {`${e?.person1?.name || ""} ${e?.person2?.name ? "&" : ""} ${e?.person2?.name || ""}`}
                            </label>
                          </>
                        ))}
                      </>
                    )}
                  </div></>)}


                {profile?.role !== "planner" && (<><div className="mb-3 row">
                  <label
                    for="staticEmail"
                    className="col-12 font-size-18 dm-font pink-color align-self-center col-form-label"
                  >
                    {t('Events')}
                  </label>
                  <div className="col-12 d-flex flex-wrap gap-2">
                    {load
                      ? new Array(5)
                        .fill(null)
                        .map((_, index) => <ButtonSkeleton key={index} />)
                      : myEvents?.map((e) => (
                        <>
                          <input
                            checked={event_id == e.Event._id}
                            onChange={() => { setevent_id(e.Event._id); setcoupleURLFilter(e.Event.user_id) }}
                            type="radio"
                            name="event-select"
                            id={e.Event._id}
                            className="btn-check"
                          />
                          <label
                            className="btn wf-select-btn"
                            for={e.Event._id}
                            style={{
                              "--bs-btn-active-bg": ` ${e.Event.event_color}`,
                            }}
                          >
                            {e.Event.event_name}
                          </label>
                        </>
                      ))}
                  </div>
                </div></>)}

                {selectedCouple && profile?.role == "planner" && (
                  <>
                    <p className="font-size-18 dm-font pink-color mb-2">
                      {t("Events")}
                    </p>
                    <div className="d-flex gap-2 flex-wrap mb-4">

                      <div className="col-12 d-flex gap-2 flex-wrap">
                        {load ? (
                          new Array(5).fill(null).map((_, index) => <ButtonSkeleton key={index} />)
                        ) : (
                          <>

                            {actualmyEvents?.filter((f) => f._id == selectedCouple && f?.event_id)[0]?.Event?.map((e) => (
                              <>
                                <input
                                  checked={event_id == e?._id}
                                  onChange={() => setevent_id(e?._id)}
                                  type="radio"
                                  name="event-select"
                                  id={e?._id}
                                  className="btn-check"
                                />
                                {e.event_name ? (
                                  <label
                                    className="btn wf-select-btn"
                                    htmlFor={e._id}
                                    style={{ "--bs-btn-active-bg": e.event_color }}
                                  >
                                    {e.event_name}
                                  </label>
                                ) : (
                                  <div>No events to show</div>
                                )}
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}


                <p className="font-size-18 dm-font pink-color mb-2">{t('Category')}</p>
                <div className="d-flex gap-2 flex-wrap mb-3">
                  {load
                    ? new Array(22)
                      .fill(null)
                      .map((_, index) => (
                        <CaregoriesButtonSkeleton key={index} />
                      ))
                    : allCategories?.map((c) => (
                      <>
                        <input
                          checked={category_id == c._id}
                          onChange={() => setcategory_id(c._id)}
                          type="radio"
                          name="cat-select"
                          id={c._id + 1}
                          className="btn-check"
                        />
                        <label
                          className="btn wf-select-btn d-flex flex-column justify-content-center gap-2"
                          style={{ width: '15%', lineHeight: '1.2' }}
                          for={c._id + 1}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMGURL}${c.imageName}`}
                            className="mx-auto d-block color"
                            height="35"
                          />
                          <img
                            src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`}
                            className="mx-auto d-block white"
                            height="35"
                          />
                          {c.name}
                        </label>
                      </>
                    ))}
                </div>

                <div className="form-floating wf-input wf-input-101 mb-3">
                  <input
                    onChange={(e) => settitle(e.target.value)}
                    type="text"
                    defaultValue={title}
                    className="form-control"
                    id="floatingInput"
                    placeholder="Booking Title"
                    required
                  />
                  <label for="floatingInput">{t('Booking')} {t('Title')}</label>
                </div>

                <div className="form-floating wf-textarea wf-textarea-101 mb-3">
                  <textarea
                    onChange={(e) => setdescription(e.target.value)}
                    defaultValue={description}
                    className="form-control"
                    placeholder="Booking Description"
                    id="booking-description"
                    style={{ height: "100px" }}
                  ></textarea>
                  <label for="booking-description">{t('Booking')} {t('Description')}</label>
                </div>

                <div className="row mb-3 gy-3">
                  <div className="col-md-6">
                    <p className="font-size-18 dm-font pink-color mb-2">
                      {t('TotalPrice')}
                    </p>
                    <div className="form-floating wf-input wf-input-101">
                      <input
                        // onChange={(e) => settotalprice(e.target.value)}
                        onChange={(e) => {
                          const enteredValue = parseFloat(e.target.value); // Use parseFloat for decimal values
                          if (e.target.value == "") {
                            setinitdeposit(0)
                            setfinaldeposit(0)
                            settotalprice(0)
                          }

                          else if (!isNaN(enteredValue) && enteredValue >= 0) {
                            settotalprice(enteredValue);
                          }
                          else {
                            e.target.value = null
                          }
                        }}
                        type="number"
                        className="form-control"
                        id="price-total"
                        placeholder="Total Price"
                        autocomplete="price"
                        required
                      />
                      <label for="price-total">{t('TotalPrice')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="font-size-18 dm-font pink-color mb-2">
                      {t('PaymentStatus')}
                    </p>
                    <div className="d-flex gap-2 flex-wrap">
                      <input
                        checked={paymentstatus == "fullypaid"}
                        onChange={(e) => {
                          setpaymentstatus(e.target.value);
                          setinitdeposit(0)
                        }}
                        value="fullypaid"
                        type="radio"
                        name="payment-status"
                        className="btn-check"
                        id="Fully-Paid"
                      />
                      <label
                        style={{
                          lineHeight: "23px",
                          "--bs-btn-active-bg": "var(--color3)",
                        }}
                        className="btn wf-select-btn py-3 px-4"
                        for="Fully-Paid"
                      >
                        {t('FullyPaid')}
                      </label>

                      <input
                        checked={paymentstatus == "partiallypaid"}
                        onChange={(e) => {
                          setpaymentstatus(e.target.value);
                          setinitdeposit(0)
                        }}
                        value="partiallypaid"
                        type="radio"
                        name="payment-status"
                        className="btn-check"
                        id="Partially-Paid"
                      />
                      <label
                        style={{
                          lineHeight: "23px",
                          "--bs-btn-active-bg": "var(--color3)",
                        }}
                        className="btn wf-select-btn py-3 px-4"
                        for="Partially-Paid"
                      >
                        {t('PartiallyPaid')}
                      </label>


                    </div>
                  </div>
                </div>

                <div className="row mb-3 gy-3" id="Initial-Deposit">
                  <div className="col-md-6">
                    <div className="form-floating wf-input wf-input-101">
                      <input
                        disabled={paymentstatus == "fullypaid"}
                        onChange={(e) => {
                          const enteredValue = parseFloat(e.target.value); // Use parseFloat for decimal values
                          if (!isNaN(enteredValue) && enteredValue >= 0) {
                            setinitdeposit(enteredValue);
                          }
                          else {
                            e.target.value = null
                          }
                        }}
                        value={initdeposit}
                        type="number"
                        className="form-control"
                        id="Initial-Deposit"
                        placeholder="Initial Deposit"
                        autocomplete="price"
                        required
                      />
                      <label for="Initial-Deposit">{t('InitialDeposit')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating wf-input wf-input-101">
                      <input
                        disabled={paymentstatus == "fullypaid"}

                        onChange={(e) => setinitdepositdate(e.target.value)}
                        type="date"
                        min={getCurrentDate()}
                        className="form-control"
                        id="Due-Date"
                        placeholder="Due Date"
                        autocomplete="price"
                        required
                      />
                      <label for="Due-Date">{t('DueDate')}</label>
                    </div>
                  </div>
                  {/* <div className="col-md-2 align-self-center">
                  <div className="form-check wf-signup-checkbox ">
                    <input
                      disabled={paymentstatus=="fullypaid"}
                      checked={initdeposittask}                    
                      onChange={(e) => setinitdeposittask(!initdeposittask)}
                      className="form-check-input wf-shadow-2"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label pink-color"
                      for="flexCheckDefault"
                    >
                      <u>{t('Add')} {t('Task')}</u>
                    </label>
                  </div>
                </div> */}
                </div>

                <div className="row mb-3 gy-3" id="Final-Payment">
                  <div className="col-md-6">
                    <div className="form-floating wf-input wf-input-101">
                      <input
                        disabled
                        value={paymentstatus == "fullypaid" ? totalprice : totalprice - initdeposit}
                        type="number"
                        className="form-control"
                        id="Final-Payment"
                        placeholder="Final Deposit"
                        autocomplete="price"
                        required
                      />
                      <label for="Final-Payment">{t('FinalPayment')}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating wf-input wf-input-101">
                      <input
                        defaultValue={finaldepositdate}
                        onChange={(e) => setfinaldepositdate(e.target.value)}
                        type="date"
                        min={getCurrentDate()}
                        className="form-control"
                        id="Due-Date"
                        placeholder="Due Date"
                        autocomplete="price"
                        required
                      />
                      <label for="Due-Date">{t('DueDate')}</label>
                    </div>
                  </div>
                  {/* <div className="col-md-2 align-self-center">
                  <div className="form-check wf-signup-checkbox ">
                    <input
                    checked={finaldeposittask}
                      onChange={(e) => setfinaldeposittask(!finaldeposittask)}
                      className="form-check-input wf-shadow-2"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label pink-color"
                      for="flexCheckDefault"
                    >
                      <u>{t('Add')} {t('Task')}</u>
                    </label>
                  </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center gap-2">
                <button
                  className="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2"
                  onClick={() => closeModal("create")}
                >
                  {t('Cancel')}
                </button>
                <button
                  onClick={() => saveBranch("")}
                  className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                  disabled={load1}
                >{load1 ? (
                  <div className="spinner-border wf-spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>{t('Save')}</>
                )}
                </button>
              </div>
            </div>
          </div>
        </Modal>) : modalType == "filter" ? (<Modal
          // shouldCloseOnOverlayClick={true}
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Task"
          className={`modal fade show wf-modal`}
        >
          <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <h1 className="font-size-22 dm-font purple-color" id="staticBackdropLabel">Filter</h1>
                <button type="button" onClick={() => closeModal("filter")} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mt-0">

                {coupleURLFilter?.length < 1 && profile?.role == "planner" && (<>
                  <div className="mb-4 row">
                    <label
                      for="staticEmail"
                      className="col-12 font-size-14 align-self-center col-form-label"
                    >
                      {t("Couples")}
                    </label>
                    <div className="col-12 d-flex gap-2 flex-wrap">
                      {load ? (
                        new Array(5)
                          .fill(null)
                          .map((_, index) => <ButtonSkeleton key={index} />)
                      ) : (
                        <>
                          <input
                            checked={!selectedCouple}
                            onChange={() => { setselectedCouple(""); setevent([]) }}
                            type="checkbox"
                            name="event-select"
                            id={554}
                            className="btn-check"
                          />
                          <label className="btn wf-select-btn" for={554}>
                            {t('All')}
                          </label>

                          {actualmyEvents?.map((e) => (
                            <>
                              <input
                                checked={selectedCouple == e._id}
                                onChange={() => { setselectedCouple(e._id); setevent([]) }}
                                type="checkbox"
                                name="event-select"
                                id={e._id}
                                className="btn-check"
                              />
                              <label
                                className="btn wf-select-btn"
                                for={e._id}
                              // style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                              >
                                {`${e?.person1?.name || ""} ${e?.person2?.name ? "&" : ""} ${e?.person2?.name || ""}`}
                              </label>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div> </>)}

                {profile?.role !== "planner" && (<><div className="mb-4 row">
                  <label
                    for="staticEmail"
                    className="col-12 font-size-14 align-self-center col-form-label"
                  >
                    {t("Events")}
                  </label>
                  <div className="col-12 d-flex gap-2 flex-wrap">
                    {load ? (
                      new Array(5)
                        .fill(null)
                        .map((_, index) => <ButtonSkeleton key={index} />)
                    ) : (
                      <>
                        <input
                          checked={event?.length < 1 ? true : false}
                          onChange={() => setevent([])}
                          type="checkbox"
                          name="event-select"
                          id={56}
                          className="btn-check"
                        />
                        <label className="btn wf-select-btn" for={56}>
                          {t('All')}
                        </label>

                        {EventList?.map((e) => (
                          <>
                            <input
                              checked={event.includes(e.id)}
                              onChange={() => handleEventChange(e.id)}
                              type="checkbox"
                              name="event-select"
                              id={e.id}
                              className="btn-check"
                            />
                            <label
                              className="btn wf-select-btn"
                              for={e.id}
                              style={{ "--bs-btn-active-bg": ` ${e.color}` }}
                            >
                              {e.name}
                            </label>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </div></>)}

                {coupleURLFilter?.length < 1 && selectedCouple && profile?.role == "planner" && (<><div className="mb-4 row">
                  <label
                    for="staticEmail"
                    className="col-12 font-size-14 align-self-center col-form-label"
                  >
                    {t("Events")}
                  </label>
                  <div className="col-12 d-flex gap-2 flex-wrap">
                    {load ? (
                      new Array(5)
                        .fill(null)
                        .map((_, index) => <ButtonSkeleton key={index} />)
                    ) : (
                      <>
                        <input
                          checked={event?.length < 1 ? true : false}
                          onChange={() => setevent([])}
                          type="checkbox"
                          name="event-select"
                          id={56}
                          className="btn-check"
                        />
                        <label className="btn wf-select-btn" for={56}>
                          {t('All')}
                        </label>

                        {actualmyEvents?.filter((f) => f._id == selectedCouple && f?.event_id)[0]?.Event?.map((e) => {
                          console.log('eeeeeeeeeeee', e)
                          return (
                            <>
                              <input
                                checked={event.includes(e._id)}
                                onChange={() => handleEventChange(e._id)}
                                type="checkbox"
                                name="event-select"
                                id={e._id}
                                className="btn-check"
                              />
                              <label
                                className="btn wf-select-btn"
                                for={e._id}
                                style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                              >
                                {e.event_name}
                              </label>
                            </>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div></>)}
                <div className="mb-4 row">
                  <label
                    for="staticEmail"
                    className="col-12 font-size-14 align-self-start col-form-label"
                  >
                    {t('Category')}
                  </label>
                  <div className="col-12 d-flex gap-2 flex-wrap">
                    {load ? (
                      new Array(22)
                        .fill(null)
                        .map((_, index) => <CaregoriesButtonSkeleton key={index} />)
                    ) : (
                      <>
                        <input
                          checked={category?.length < 1}
                          onChange={() => setcategory([])}
                          type="checkbox"
                          name="cat-select"
                          id={77}
                          className="btn-check"
                        />
                        <label className="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{ width: '11%', lineHeight: '1.2' }} for={77}>
                          <img src={`${process.env.REACT_APP_IMGURL}all.png`} className="mx-auto d-block color" height="35" />
                          <img src={`${process.env.REACT_APP_IMGURL}white-all.png`} className="mx-auto d-block white" height="35" />
                          All</label>
                        {allCategories?.map((c) => (
                          <>
                            <input
                              checked={
                                category?.length > 0 && category?.includes(c._id)
                                  ? true
                                  : false
                              }
                              onChange={() => handleCategoryChange(c._id)}
                              type="checkbox"
                              name="cat-select"
                              id={c.name}
                              className="btn-check"
                            />
                            <label
                              className="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2"
                              style={{ width: "11%", lineHeight: "1.2" }}
                              for={c.name}
                            >
                              <img
                                src={`${process.env.REACT_APP_IMGURL}${c.imageName}`}
                                className="mx-auto d-block color"
                                height="35"
                              />
                              <img
                                src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`}
                                className="mx-auto d-block white"
                                height="35"
                              />
                              {c.name}
                            </label>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="mb-5 row">
                  <label
                    for="staticEmail"
                    className="col-12 font-size-14 align-self-center col-form-label"
                  >
                    {t('Status')}
                  </label>
                  <div className="col-12 d-flex gap-2 flex-wrap">
                    {Booking_Status?.map((d) => (
                      <>
                        <input
                          checked={status == d.id}
                          onChange={() => setstatus(d.id)}
                          type="radio"
                          name="due-select"
                          id={`${d.id}-option`}
                          className="btn-check"
                        />
                        <label className="btn wf-select-btn" for={`${d.id}-option`}>
                          {t(d.option)}
                        </label>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center gap-2">
                <button className="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={() => closeModal("filter")}>Close</button>
                <button className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => { setfiltertoggle(!filtertoggle); closeModal("filter") }}>Apply Filter</button>
              </div>
            </div>
          </div>
        </Modal>) : modalType == "conversation" ? (<Modal
          // shouldCloseOnOverlayClick={true}
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Task"
          className={`modal fade show wf-modal`}
        >
          <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <h1 className="font-size-22 dm-font purple-color" id="exampleModalLabel">Conversation</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="flex-grow-1 d-flex flex-wrap">
                  <div className="flex-grow-1 d-flex gap-2 align-items-center">
                    <img src="https://via.placeholder.com/50x50" className="rounded-circle wf-user-image" alt="Name" width="40" height="40" style={{ border: '2px solid var(--color3)' }} />
                    <div className="flex-grow-1">
                      <p className="font-size-16 purple-color mb-0 fw-bold">Name</p>
                      <p className="font-size-14 purple-color mb-0 fw-semibold">Bride</p>
                    </div>
                  </div>
                  <div className="flex-grow-1 d-flex gap-2 align-items-center">
                    <img src="https://via.placeholder.com/50x50" className="rounded-circle wf-user-image" alt="Name" width="40" height="40" style={{ border: '2px solid var(--color4)' }} />
                    <div className="flex-grow-1">
                      <p className="font-size-16 pink-color mb-0 fw-bold">Name</p>
                      <p className="font-size-14 pink-color mb-0 fw-semibold">Bride</p>
                    </div>
                  </div>
                </div>
                <div className="w-100 border-bottom my-3"></div>

              </div>
            </div>
          </div>
        </Modal>) : modalType == "details" ? (<Modal
          // shouldCloseOnOverlayClick={true}
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Add Task"
          className={`modal fade show wf-modal`}
        >
          <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <h1 className="font-size-22 dm-font purple-color" id="exampleModalLabel">Booking Details</h1>
                <button onClick={() => closeModal("details")} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {/* <p className="color-black font-size-16 mb-2">Nikah Dress</p>
        <p className="color-grey font-size-14">Description text goes here.. Description text goes here..Description text goes here..Description text goes here</p> */}
                <div className="d-flex gap-3 mb-3">
                  <div><LayoutList stroke="var(--color4)" /></div>
                  <div>
                    <p className="color-grey font-size-14 mb-1">Event</p>
                    <div className="d-flex flex-column gap-1 align-items-start">
                      <span className="fw-semibold font-szie-14">{userDetail?.user_event?.event_name}</span>
                      <span className="w-100" style={{ backgroundColor: `green`, height: '3px', marginTop: '-5px' }}></span>
                      <span className="color-grey font-size-12">{moment(userDetail?.user_event?.event_date)?.format("D MMM YYYY")}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 mb-3">
                  <div><Briefcase stroke="var(--color4)" /></div>
                  <div>
                    <p className="color-grey font-size-14 mb-1">Supplier Name</p>
                    <div className="d-flex gap-2">
                      <div><img className="rounded" src={userDetail?.vendor_event?.image ? `${process.env.REACT_APP_IMGURL}${userDetail?.vendor_event?.image}` : "https://via.placeholder.com/50x50"} alt="" style={{ height: '80px', width: '120px', objectFit: 'cover' }} /></div>
                      <div className="w-100">
                        <p className="color-black font-size-16 mb-0">{userDetail?.vendor_event ? userDetail?.vendor_event?.store_name : userDetail?.title}</p>
                        <p className="color-grey font-size-14 mb-0">{userDetail?.vendor_event?.event_city}</p>
                        <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase"><a href="#" className="color-white">{userDetail?.category_id?.name}</a></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 mb-3">
                  <div><List stroke="var(--color4)" /></div>
                  <div className="w-100">
                    <p className="color-grey font-size-14 mb-1">Payment Terms</p>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="color-grey font-size-14 mb-0">Booking Amount</p>
                        <p className="color-black font-size-16">PKR {userDetail?.totalPrice}</p>
                        <p className="color-grey font-size-14 mb-0">Booked on</p>
                        <p className="color-black font-size-16">{moment(userDetail?.createdAt)?.format("D MMM YYYY")}</p>
                      </div>
                      <div className="col-md-4">
                        <p className="color-grey font-size-14 mb-0">Initial Payment</p>
                        <p className="color-black font-size-16">PKR {userDetail?.initDeposit || 0}</p>
                        <div className="col-6 mb-2">
                          {!userDetail?.initialDepositPaid && userDetail?.editable == 1
                            ? (<button onClick={() => approveInitDeposit({ value: "initialDeposit", paymentRecieved: userDetail?.initialDeposit, bookingId: userDetail?._id })} className="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>) : (<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>)}
                        </div>
                        {userDetail?.finalDepositDate && (<><p className="color-grey font-size-14 mb-0">Initial Payment Due Date</p>
                          <p className="color-black font-size-16">{moment(userDetail?.initDepositDate)?.format("D MMM YYYY") || ""}</p></>)}
                      </div>
                      <div className="col-md-4">
                        <p className="color-grey font-size-14 mb-0">Final Payment</p>
                        <p className="color-black font-size-16">PKR {userDetail?.finalDeposit || 0}</p>
                        <div className="col-6 mb-2">
                          {userDetail?.initialDepositPaid && !userDetail?.finalDepositPaid && userDetail?.editable == 1 ? (<button onClick={() => approveInitDeposit({ value: "finalDeposit", paymentRecieved: userDetail?.finalDeposit, bookingId: userDetail?._id })} className="btn btn-lg wf-purple-btn wf-btn fw-bold text-uppercase py-2">Approve</button>) : userDetail?.initialDepositPaid && userDetail?.finalDepositPaid ? (<div className="fw-bold text-uppercase color-white wf-shadow-2 py-2 px-3 rounded wf-pink-btn"><p className={`font-size-14 white-color mb-0 text-uppercase`}>Paid</p></div>) : null}
                        </div>
                        {userDetail?.finalDepositDate && (<><p className="color-grey font-size-14 mb-0">Final Payment Due Date</p>
                          <p className="color-black font-size-16">{moment(userDetail?.finalDepositDate)?.format("D MMM YYYY") || ""}</p></>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Modal>) : modalType === "paymentApprove" ? (
          <>
            <div className="modal-header border-0 pb-0">
              <h1 className="font-size-36 playfair-font color-black" id="staticBackdropLabel">{t('Confirmation')}</h1>
              <button type="button" onClick={closeModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="spacer-20"></div>
            <div className="modal-body">
              <p className="color-black font-size-14 mb-2">{t('Areyousureyouwanttoapproveyourpayment')}?</p>
              <div className="modal-footer border-0 justify-content-center gap-2">
                <button className="btn wf-purple-btn wf-btn btn-lg" onClick={closeModal}><i className="far fa-cross"></i> {t('Cancel')}</button>
                <button className="btn wf-purple-btn wf-btn btn-lg" onClick={() => approveInitDeposit({ booking_id: data?._id })}><i className="fa-regular fa-floppy-disk"></i> Approve</button>
              </div>
            </div>
          </>
        ) : null}

      {location.pathname === "/dashboard" || location.pathname === "/dashboard-planner" || location.pathname === "/couples-overview" ? null : (
        <>
          <div className="row mb-4">
            <div className="col-6">
              <h2 className="font-size-26 dm-font purple-color mb-0">{t('Bookings')}</h2>
            </div>
            {myEvents?.length > 0 && (
              <div className="col-6 d-flex justify-content-end gap-3 align-items-center">
                <button type="button" onClick={() => viewModal({ type: "create" })} className="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">
                  {t('Add')} {t('Booking')}
                </button>
              </div>
            )}
          </div>

          {!queryParams.get("couple") && !queryParams.get("events") && (
            <>
              <div className="row mb-4">
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="wf-shadow-2 white-bg rounded p-3 d-flex flex-column justify-content-between h-100">
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <p className="color-grey font-size-20 mb-0">No. of Bookings</p>
                        <p className="purple-color font-size-20 mb-0 fw-bold">{bookings?.length}</p>
                      </div>
                      <img width="75" className="" alt="icon" src="../assets/images/bookings-icon-wf.svg" />
                    </div>
                    <div className="mb-3">
                      <p className="color-grey font-size-20 mb-0">Worth</p>
                      <p className="purple-color font-size-20 mb-0 fw-bold">PKR {bookings?.reduce((accumulator, currentValue) => accumulator + currentValue.totalPrice, 0)?.toLocaleString('en-US')}</p>
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={{ lineHeight: "normal" }} className="font-size-20 pink-color mb-1 text-start">
                          <b>{t('Paid')}</b> <br /> PKR {bookings?.reduce((accumulator, currentValue) => accumulator + currentValue.paid, 0)?.toLocaleString('en-US')}
                        </p>
                        <p style={{ lineHeight: "normal" }} className="font-size-20 purple-color mb-1 text-end">
                          <b>{t('Due')}</b> <br /> PKR {bookings?.reduce((accumulator, currentValue) => accumulator + currentValue.due, 0)?.toLocaleString('en-US')}
                        </p>
                      </div>
                      <div className="progress" style={{ height: "12px", width: "100%" }}>
                        <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{
                          width: `${(bookings?.reduce((accumulator, currentValue) => ((accumulator + currentValue.paid) / (accumulator + currentValue.totalPrice)) * 100, 0))}%`,
                          backgroundColor: "var(--color4)"
                        }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="wf-shadow-2 white-bg rounded p-2 h-100">
                    <div className="row gx-2">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <p className="purple-color font-size-20 mb-0 dm-font">Booked Events</p>
                        <PieChart
                          data={graphdata}
                          animate={true}
                          labelStyle={{
                            fontSize: '10px',
                            fontFamily: 'Arial, sans-serif',
                            fontWeight: 'bold',
                          }}
                          style={{
                            width: '100%',
                            height: '350px',
                          }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="d-flex gap-2 flex-column">
                          {eventlistdata?.length > 0 ? eventlistdata?.map((event, index) => (
                            <div key={index} className="col-md-12">
                              <div className="wf-shadow-2 rounded white-bg p-2">
                                <div className="row">
                                  <div className="col-md-4 align-self-center">
                                    <h4 className="font-size-18 dm-font mb-1 text-capitalize d-flex flex-column align-items-start">
                                      {event?.event_name}
                                      <span className="d-block mt-1" style={{ backgroundColor: `${event?.event_color}`, height: '3px', width: '60px' }}></span>
                                    </h4>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p style={{ lineHeight: "normal" }} className="font-size-14 pink-color mb-1 text-start" >
                                          <b>{t('Paid')}</b> <br /> PKR {event?.paid?.toLocaleString('en-US')}
                                        </p>
                                        <p style={{ lineHeight: "normal" }} className="font-size-14 purple-color mb-1 text-end" >
                                          <b>{t('Due')}</b> <br /> PKR {event?.due?.toLocaleString('en-US')}
                                        </p>
                                      </div>
                                      <div className="progress" style={{ height: "12px", width: "100%" }}>
                                        <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${((event.paid / event.totalPrice) * 100)}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <p className="purple-color font-size-20 mb-0 dm-font">Categories</p>
                </div>
              </div>








              <div className="wf-shadow-2 rounded white-bg p-3 mb-3">
                <div className="row gx-2">
                  {categorylistdata?.length > 0 ? categorylistdata?.map((c, index) => (
                    <div key={index} className="col-md-3">
                      <div className="wf-shadow-2 rounded white-bg p-2">
                        <div className="d-flex justify-content-between mb-2">
                          <img style={{ height: 'auto', width: '56px', objectFit: 'contain' }} className="" alt="icon" src={`${process.env.REACT_APP_IMGURL}${c.imageName}`} />
                          <div className="text-end">
                            <p className="color-grey font-size-16 mb-0 text-end">{c?.length} {c?.length < 2 ? "Booking" : "Bookings"}</p>
                            <p className="purple-color font-size-20 mb-0 fw-bold d-flex align-items-center gap-1 text-end">
                              <span className="d-block color-grey font-size-14 fw-medium">Total</span>
                              PKR {c?.totalPrice?.toLocaleString('en-US')}
                            </p>
                            <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase">
                              <a className="color-white">{c?.name}</a>
                            </span>
                          </div>
                        </div>
                        <div className="progress" style={{ height: "12px" }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${(c?.totalPaid / c?.totalPrice) * 100}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  )) : null}
                </div>
              </div>

              <div className="row mb-3 gy-3  d-flex justify-content-md-end gap-3 align-items-center">
                <div className="col-md-6 d-flex gap-4 align-items-center">
                  <div className="flex-grow-1 wf-search-chats wf-search-chats-2">
                    <input onChange={(e) => setsearch(e.target.value)} type="text" className="form-control wf-search-chats-field" placeholder="Search Bookings.." />
                    <Search width="18" height="18" className="wf-search-chats-icon" stroke="var(--color3)" />
                  </div>
                  <div onClick={() => viewModal({ type: "filter" })} className="d-flex align-items-center gap-2 cursor-pointer">
                    <ListFilter size={22} stroke="var(--color3)" />
                    <p className="font-size-16 purple-color mb-0 fw-medium">{t('Filters')}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}


      {/* <div className="dropdown wf-list-dropdown">
              <a className="d-flex align-items-center gap-2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <ArrowUpDown size={22} stroke="var(--color4)" />
                <p className="font-size-16 pink-color mb-0 fw-medium">Sort By</p>
              </a>

              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div> */}



      {filteredData?.length < 1 ?
        <NoEventUI name={"Bookings"} />
        : filteredData?.slice(0, isDashboard ? 4 : filteredData?.length)
          ?.map((b) => (
            <>
              <div
                key={b}
                className="wf-task-group-list mb-4">
                <div className="d-flex flex-column flex-xl-row align-items-xl-center wf-shadow-2 white-bg rounded mx-1 mx-md-0 mb-3">

                  <div className="wf-bookingimgcontaner align-self-stretch">
                    <a className='linkedimge' href="#">
                      <img alt='' src={b.vendor_event?.image ? `${process.env.REACT_APP_IMGURL}${b?.vendor_event?.image}` : "https://via.placeholder.com/50x50"} className="wf-bookingimgcontaner-image" style={{ borderRadius: "6px 0 0 6px" }} />
                    </a>
                    <div className="d-flex flex-xl-column justify-content-center align-items-center bottom-box">
                      <span className="w-100 font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-center text-break text-uppercase"><a href="#" className="color-white">{b?.category_id?.name}</a></span>
                    </div>
                  </div>

                  <div className="w-100 px-3 py-3">
                    <div className="d-flex align-items-md-center justify-content-between gap-3 mb-1">
                      <p className="font-size-16 color-black mb-2 fw-bold text-capitalize  text-break">
                        {b?.vendor_event ? b?.vendor_event?.store_name : b?.title}
                      </p>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="d-flex gap-2 align-items-center">
                          <p className="font-size-16 purple-color mb-0">{b.status === 0 && (<>{t('Cancelled')}</>)}
                            {b.status === 1 && (<>{t('Confirmed')}</>)}
                            {b.status === 2 && (<>{t('Pending')}</>)}
                            {b.status === 3 && (<>{t('Completed')}</>)}</p>
                        </div>
                        {/* {location.pathname!=="/dashboard"||location.pathname!=="/dashboard-planner"||location.pathname=="/couples-overview"&&(<div className="dropdown wf-list-dropdown"> */}
                        <div className="dropdown wf-list-dropdown">
                          <a className="color-grey font-size-22" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-three-dots-vertical"></i>
                          </a>

                          <ul className="dropdown-menu">
                            <li><a onClick={() => viewModal({ type: "details", obj: b })} className="dropdown-item">Details</a></li>
                          </ul>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap flex-md-row flex-column align-items-md-center justify-content-between gap-2">
                      <p className="font-size-16 mb-1 text-capitalize d-flex flex-column align-items-start mb-0" style={{ width: "170px" }}>
                        {b.user_event.event_name}
                        <span className="d-block mt-1" style={{ backgroundColor: `${b.user_event.event_color}`, height: '3px', width: '60px' }}></span>
                      </p>
                      <div className="col-md-6">
                        <div className="w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <p
                              style={{ lineHeight: "normal" }}
                              className="font-size-14 color-grey mb-1 text-start"
                            >
                              <b>{t('Paid')}</b> <br /> PKR{" "}
                              {b?.paid?.toLocaleString('en-US')}
                            </p>
                            <p
                              style={{ lineHeight: "normal" }}
                              className="font-size-14 pink-color mb-1 text-end"
                            >
                              <b>{t('Due')}</b> <br /> PKR{" "}
                              {b?.due?.toLocaleString('en-US')}{" "}
                            </p>
                          </div>
                          <div
                            className="progress"
                            style={{ height: "12px", width: "100%" }}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-label="Example 20px high"
                              style={{
                                width: `${(b.paid /
                                  b.totalPrice) *
                                  100
                                  }%`,
                                backgroundColor: "#FF74B8",
                              }}
                              aria-valuenow={`80%`}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '100px' }}>
                        {b.user_event.event_date <= currentDate && b.editable == 0 && (<div>
                          <a onClick={() => navigate('/booking-review', { state: b })} className="font-size-16 pink-color mb-1 cursor-pointer"><u>{t('GiveReview')}</u></a>
                        </div>)}
                      </div>
                      {/* <div>
            <p className="font-size-14 color-black mb-0">
            {b.status === 0 ? "Cancelled" : 
            b.status === 1 ? "Confirmed" : 
            b.status === 2 ? "Pending" : "Completed"}
          </p>
            </div> */}
                      <div>
                        <p className="font-size-14 color-black mb-0 text-end">Total Amount</p>
                        <p className="font-size-16 purple-color mb-1 fw-semibold text-uppercase text-end">
                          PKR {b?.totalPrice?.toLocaleString('en-US')}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </>
          ))}
    </div>
  )
}

export default BookingsContent