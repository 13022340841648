import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getEventList, userLogout } from "../../store/slices/userSlice"
import Budget from '../UserDashboard/Budget';
import NoEventUI from '../UserDashboard/noEventUI';

const Budget_Dashboard = () => {

  const myEvents = useSelector(getEventList);
  const [filteredData, setfilteredData] = useState([]);


  const handleSetData = (e) => {
    console.log('evvv', e)
    const mergedEvents = [];

    e?.forEach(event => {

      let modifiedEvent = {
        ...event,
        Event: [event.Event],
        coupleIds: [event._id]
      };

      const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id == event?.person1?._id && mergedEvent?.person2?._id == event?.person2?._id
      );

      if (existingEventIndex !== -1) {

        mergedEvents[existingEventIndex].Event.push(event.Event);
        mergedEvents[existingEventIndex].coupleIds.push(event._id);
      } else {

        mergedEvents.push(modifiedEvent);
      }
    });


    // setactualmyEvents(mergedEvents);
    setfilteredData(mergedEvents);
  };

  useEffect(() => {
    handleSetData(myEvents)
  }, [myEvents]);

  console.log('filteredDatssa', filteredData)
  return (
    <>
      <div className='row mb-4'>
        <div className='col-12'>
          <div class="d-flex gap-4 align-items-end mb-2">
            <h2 class="font-size-30 dm-font purple-color mb-0">Budget</h2>
            {myEvents?.some(event => event?.Event && event?.Event?.eventBudget) && (<a class="font-size-14 pink-color mb-0" href="budget"><u>View All</u></a>)}
          </div>
        </div>
      </div>

      {myEvents?.some(event => event?.Event && event?.Event?.eventBudget) ? (
        <>
          {/* <div className='row mb-4'>
              <div className='col-12'>
                <div class="d-flex gap-4 align-items-end mb-2">
                  <h2 class="font-size-30 dm-font purple-color mb-0">Budget</h2>
                  {myEvents?.length>0&&(<a class="font-size-14 pink-color mb-0" href="#"><u>View All</u></a>)}
                </div>
              </div>
            </div> */}

          <div className='row mb-4'>

            {filteredData?.length > 0 && filteredData?.filter(item => Boolean(item?.event_id))?.map((e) => (<div className='col-12'>
              <div className='wf-shadow-2 white-bg rounded px-3 py-3 mb-3'>
                <div className='row align-items-center'>
                  <div className='col-md-2'>
                    <div className='wf-up-avatar d-flex mb-2 mb-md-0'>
                      <div className='avatar-couple-first'>
                        <img width="57" height="57" alt="" src={e.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${e.person1?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                      </div>
                      <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                        <img width="57" height="57" alt="" src={e.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${e.person2?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <h3 className="font-size-16 fw-bold purple-color mb-2">{e?.person1?.name}<span className='font-size-12 fw-normal color-grey ms-1'>{e?.person1?.user_purpose}</span></h3>
                    <h3 className="font-size-16 fw-bold pink-color mb-2 mb-md-0">{e?.person2?.name || "partner"}<span className='font-size-12 fw-normal color-grey ms-1'>{e?.person2?.user_purpose || e?.person1?.user_purpose == "bride" ? "groom" : e?.person1?.user_purpose == "groom" ? "bride" : ""}</span></h3>
                  </div>
                  <div className='col-md-6'>
                    <div className='wf-payment-progress'>
                      <p className="font-size-14 text-end purple-color mb-2">Total <span className='fw-bold'>PKR {e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total, 0)}</span></p>
                      <div className='d-flex justify-content-between align-items-center gap-5 mb-1'>
                        <h4 className="font-size-14 fw-normal  pink-color mb-0">Spent  <span className='fw-bold'>PKR {e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total - obj.eventBudget?.event_total_available, 0)}</span></h4>
                        <h4 className="font-size-14 fw-normal text-end color-black mb-0">Remaining <span className='fw-bold'>PKR {e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total_available, 0)}</span></h4>
                      </div>
                      <div className='d-flex justify-content-between align-items-center gap-2 mb-1'>
                        <h4 className="font-size-14 fw-normal  pink-color mb-0">{((e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total - obj.eventBudget?.event_total_available, 0) / e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total, 0) * 100 || 0).toFixed(0))}%</h4>
                        {/* <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div> */}
                        <div className="progress" style={{ height: "12px", width: "75%" }}>
                          <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${((e?.Event?.reduce((acc, obj) => acc + (obj.eventBudget?.event_total - obj.eventBudget?.event_total_available), 0) / e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total, 0)) * 100 || 0).toFixed(0)}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <h4 className="font-size-14 fw-normal  color-black mb-0">PKR {((e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total_available, 0) / e?.Event?.reduce((acc, obj) => acc + obj.eventBudget?.event_total, 0) * 100 || 0).toFixed(0))}%</h4>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>))}

          </div>
        </>) : (<NoEventUI name={"Budget"} />)}
    </>
  )
}

export default Budget_Dashboard