import React from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <>
    <Helmet>
        <title>Privacy Policy - Shadi Tayari</title>
        <meta name="description" content="This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website www.shaditayari.pk, use our services, or interact with us."/>
        <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/privacy`}/>
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">Privacy</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <p className="font-size-18">ShadiTayari.pk ("we", "our", "us") values your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website www.shaditayari.pk, use our services, or interact with us. By using our services, you agree to the terms of this Privacy Policy.</p>

    <h2 className='font-size-30 fw-semibold'>Information We Collect</h2>
    <p className="font-size-18">We collect various types of information to provide and improve our services to you:</p>
    <p className="font-size-18"><strong>1. Personal Information:</strong> When you register for an account, list your services, or make a booking, we may collect personal information, including your name, email address, phone number, and payment details.</p>
    <p className="font-size-18"><strong>2. Usage Data:</strong> We automatically collect information about your interaction with our website, such as your IP address, browser type, pages visited, and the time and date of your visit.</p>
    <p className="font-size-18"><strong>3. Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor activity on our website and hold certain information. Cookies are files with a small amount of data that are sent to your browser from a website and stored on your device.</p>

    <h2 className='font-size-30 fw-semibold'>How We Use Your Information</h2>
    <p className="font-size-18">We use the collected information for various purposes, including:</p>
    <p className="font-size-18">To provide and maintain our services: Ensuring our platform functions correctly and offering you the best user experience.</p>
    <p className="font-size-18">To process transactions: Managing your listings, bookings, and payments.</p>
    <p className="font-size-18">To communicate with you: Sending updates, newsletters, marketing materials, and other information that may be of interest to you.</p>
    <p className="font-size-18">To improve our services: Analyzing data to understand how our users interact with our website and improve our services.</p>
    <p className="font-size-18">To ensure security: Monitoring and analyzing usage and activity trends to enhance the safety and security of our website.</p>

    <h2 className='font-size-30 fw-semibold'>How We Share Your Information</h2>
    <p className="font-size-18">We may share your information in the following situations:</p>
    <p className="font-size-18"><strong>With Service Providers:</strong> We may employ third-party companies and individuals to facilitate our services, provide the service on our behalf, perform service-related tasks, or assist us in analyzing how our services are used.</p>
    <p className="font-size-18"><strong>For Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your personal information may be transferred.</p>
    <p className="font-size-18"><strong>With Your Consent:</strong> We will share your information with other parties only with your consent.</p>

    <h2 className='font-size-30 fw-semibold'>Data Security</h2>
    <p className="font-size-18">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

    <h2 className='font-size-30 fw-semibold'>Your Data Protection Rights</h2>
    <p className="font-size-18">Depending on your location, you may have the following rights regarding your personal information:</p>
    <p className="font-size-18"><strong>Access:</strong> The right to access and request a copy of the personal information we hold about you.</p>
    <p className="font-size-18"><strong>Correction:</strong> The right to request that we correct any information you believe is inaccurate or incomplete.</p>
    <p className="font-size-18"><strong>Deletion:</strong> The right to request that we delete your personal information under certain conditions.</p>
    <p className="font-size-18"><strong>Restriction of Processing:</strong> The right to request that we restrict the processing of your personal information under certain conditions.</p>
    <p className="font-size-18"><strong>Objection to Processing:</strong> The right to object to our processing of your personal information.</p>
    <p className="font-size-18"><strong>Data Portability:</strong> The right to request that we transfer the data that we have collected to another organization or directly to you under certain conditions.</p>
    <p className="font-size-18">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href="mailto:privacy@shaditayari.pk">privacy@shaditayari.pk</a>.</p>

    <h2 className='font-size-30 fw-semibold'>Changes to This Privacy Policy</h2>
    <p className="font-size-18">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

    <h2 className='font-size-30 fw-semibold'>Contact Us</h2>
    <p className="font-size-18">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
    <p className="font-size-18">Email: <a href="mailto:privacy@shaditayari.pk">privacy@shaditayari.pk</a></p>
    <p className="font-size-18">Address:<br/>
    ShadiTayari.pk</p>

    <p className="font-size-18">Thank you for trusting ShadiTayari.pk with your personal information. We are committed to ensuring your privacy and security as you plan your special day.</p>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default Privacy