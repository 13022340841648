import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getProfile } from "../../store/slices/userSlice";
import SignUpStep1 from "./SignUpStep1";
import SignUpStep2 from "./SignUpStep2";
import SignUpStep3 from "./SignUpStep3";
import SignUpStep4 from "./SignUpStep4";
import SignUpStep5 from "./SignUpStep5";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/Layout/LanguageSwitcher";
import i18n from "../../components/i18n";


const SignUpForm = () => {
  const { t } = useTranslation();

  const profile = useSelector(getProfile);
  const navigate = useNavigate();


  console.log("profile", profile);

  const [formData, setFormData] = useState({
    page: 0,
    isquestion: false,
    event_name: "",
    event_city:"",
    show_city:"",
    event_color: '#ffca3a',
    event_date: "1",
    event_prefered_day: "1",
    event_prefered_season: "1",
    event_prefered_year: "1",
    no_of_guests: "",
    user_budget: "",
    user_budget_currency: "SAR",
    prefered_religion: "",
    prefered_services: [],
    sub_services: [],
    prefered_ethnics: "",
    prefered_themes: "",
  });

  const handleFormUpdate = (data) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };

  const PageDisplay = () => {
    if (formData.page === 0) {
      return (
        <SignUpStep1 formData={formData} handleFormUpdate={handleFormUpdate} />
      );
    }
    else if (formData.page === 1) {
      return (
        <SignUpStep2 formData={formData} handleFormUpdate={handleFormUpdate} />
      );
    } else if (formData.page === 2) {
      return (
        <SignUpStep3 formData={formData} handleFormUpdate={handleFormUpdate} />
      );
    } else if (formData.page === 3) {
      return (
        <SignUpStep4 formData={formData} handleFormUpdate={handleFormUpdate} />
      );
    }  else if (formData.page === 4) {
      return (
        <SignUpStep5 formData={formData} handleFormUpdate={handleFormUpdate} />
      );
    } 
  };

  console.log("formData", formData);

  const currentLanguage = i18n.language;

  return (

        <section class="authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-2-strong border-0 rounded" >
                <div class="card-body pt-5 px-md-5 px-4">
                <img onClick={()=>navigate("/")} className="mx-auto d-block auth-logo mb-5 wf-logo-card" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="ShadiTayari" width={currentLanguage === 'ar' ? '240px' : '270px'} />
          {PageDisplay()}
          <p className="d-none"> <LanguageSwitcher /> </p>
          </div>
          <div class="card-footer wf-auth-card-footer">
            <div className="text-center">
            <a><b className="pink-color cursor-pointer" onClick={() => navigate("/dashboard")}>{t('SkiptoDashboard')}</b></a>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default SignUpForm;
