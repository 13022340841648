import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UAParser } from 'ua-parser-js';
import { Helmet } from "react-helmet-async";

const DownloadApp = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const deviceType = result.os.name;
    
        if (deviceType === 'iOS') {
          window.location.href = 'https://apps.apple.com/pk/app/shadi-tayari/id6504075843';
        } else if (deviceType === 'Android') {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.shaditayari.app';
        } else {
          window.location.href = process.env.REACT_APP_FrontURL; // Fallback URL
        }
      }, []);
      
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Redirecting...</h1>
    </div>
  )
}

export default DownloadApp