import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';
import { getProfile } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const questions = [
    {
      id: 1,
      question: 'What is the budget management feature in ShadiTayari?',
      answer: 'This feature helps you create, track, and manage your wedding budget. It offers tools for setting up a budget, logging expenses, and viewing financial summaries to keep your wedding spending on track.',
    },
    {
        id: 2,
        question: 'How do I set up my wedding budget in the app?',
        answer: 'Start by selecting a budget template or creating a custom one. Input your total budget, then allocate amounts to different categories like venue, catering, attire, etc. The app will guide you through the process.',
    },
    {
      id: 3,
      question: 'Can I track real-time expenses?',
      answer: 'Yes, you can log expenses as they occur. The app updates your budget in real time, showing you how much you’ve spent and how much remains in each category.',
    },
    {
        id: 4,
        question: 'Is it possible to share the budget with my partner or wedding planner?',
        answer: 'Absolutely. ShadiTayari allows you to share your budget with others involved in the wedding planning. They can view, add, or modify expenses, ensuring everyone is informed about the budget status.',
    },
    {
        id: 5,
        question: 'Does the app offer expense tracking and receipt uploading?',
        answer: 'Yes, you can track all your expenses and upload digital copies of receipts for record-keeping. This feature helps you maintain an organized log of all wedding-related spending.',
    },
    {
        id: 6,
        question: 'How does the app help with staying within budget?',
        answer: 'The app provides visual representations of your spending, alerts you when you`re approaching category limits, and offers suggestions to reallocate funds if necessary, helping you stay within your overall budget.',
    },
    {
        id: 7,
        question: 'Can I access exclusive vendor discounts through ShadiTayari?',
        answer: 'Users of ShadiTayari often have access to exclusive discounts and offers from various wedding vendors, which can be applied directly to your budget within the app.',
    },
    {
        id: 8,
        question: 'Is it possible to adjust the budget after initial setup?',
        answer: 'Yes, the budget is fully customizable throughout your planning process. You can adjust the total budget, reallocate funds between categories, and add or remove categories as needed.',
    },
    {
        id: 9,
        question: 'Will I receive notifications if I`m close to exceeding my budget?',
        answer: 'The app sends notifications if you’re nearing or exceeding your budget limits in any category, helping you make timely adjustments to your spending.',
    },
    {
        id: 10,
        question: 'How secure is my financial information in the app?',
        answer: 'ShadiTayari prioritizes user data security. Your financial information is encrypted and securely stored, ensuring that your budget details remain private and protected.',
    },
  ]
  
  


const BudgetManagementFeature = () => {
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  
  const [dotScrollPercentage, setDotScrollPercentage] = useState(1);
  const [timelineOffsetTop, setTimelineOffsetTop] = useState(0);
  const [visibleBadgeIndex, setVisibleBadgeIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;

      // Calculate the distance of the timeline div from the top of the document
      const newTimelineOffsetTop = document.querySelector('.timeline').offsetTop;
      setTimelineOffsetTop(newTimelineOffsetTop);

      // Calculate the scroll percentage relative to the position of the timeline div
      const scrollPercentage = Math.max(0, (window.scrollY - timelineOffsetTop) / documentHeight) * 100;

      // You can adjust the multiplier to control the speed of the dot
      const dotMultiplier = 3; // Adjust as needed

      // Ensure the dot doesn't go beyond a certain point (e.g., 100%)
      const maxDotPercentage = 93.5;
      const newDotScrollPercentage = Math.min(maxDotPercentage, Math.max(1, scrollPercentage * dotMultiplier));
      setDotScrollPercentage(newDotScrollPercentage);

      // Get the bounding rectangle of the dot
      const dotRect = document.querySelector('.timeline .dot').getBoundingClientRect();

      // Get the bounding rectangles of all rows
      const rowElements = document.querySelectorAll('.timeline .row');
      let newVisibleBadgeIndex = -1;

      rowElements.forEach((rowElement, index) => {
        const rowRect = rowElement.getBoundingClientRect();

        // Check if the top of the dot is below the middle of the row
        if (dotRect.top >= rowRect.top + rowRect.height / 2) {
          newVisibleBadgeIndex = index;
        }
      });

      setVisibleBadgeIndex(newVisibleBadgeIndex);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timelineOffsetTop]);

  return (
<>
<Helmet>
    <title>Budget Management - Feature</title>
    <meta name="description" content="Explore the journey of ShadiTayari, the innovative wedding planning app. Get to know our team, our values, and our dedication to simplifying your wedding planning experience." />
    <meta name="keywords" content="ShadiTayari, wedding planning" />
</Helmet>

<section class="features-hero feature-bg">
<Header theme="light" />
  <div class="container">
    <div class="features-hero-body">
    <div className="row gy-4">
    <div className="col-lg-5 col-md-12">
    <h1 class="font-size-40 color-white playfair-font mb-3">{currentLanguage === 'ar' ? 'قم بإدارة ميزانية زفافك مع إدارة الميزانية المتقدمة من شادي تياري!' : 'Effortless Wedding Planning at Your Fingertips with ShadiTayari!'}</h1>
    <p className="font-size-20 color-white mb-5">{currentLanguage === 'ar' ? 'تتبع، خطط، وحسّن مصاريف حفل زفافك بسهولة.' : 'Efforlessly Track, Plan, and Optimize Your Wedding Expenses.'}</p>
    <div className="d-flex flex-wrap gap-3 mt-3">
      <button class="btn btn-lg wf-purple-btn wf-btn text-uppercase px-4 wf-shadow" onClick={(e)=>{e.preventDefault(); navigate(profile&&profile?.role=="customer"?'/budget':'/login')}}>{t('StartPlanning')}</button>
      <button class="btn btn-lg wf-white-btn wf-btn text-uppercase px-4 wf-shadow" onClick={()=> navigate('/features/task-management')}>{t('ExploreOtherFeatures')}</button>
    </div>
    </div>
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-hero-image.png`} alt="" style={{marginBottom:'-30px'}} className="features-hero-image w-100" />
    </div>
    </div>
    </div>
  </div>
</section>
<section className="features-section-1 py-5">
<div className="container">
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center pt-4">{currentLanguage === 'ar' ? '' : "Step into the real of stress-free financial planning for your wedding with Shadi Tayari! Our Advanced Budget Management feature is designed to make your dream wedding financially attainable, offering easy tracking, smart planning, and real-time adjustments."}</p>
<h2 className="font-size-30 playfair-font mb-3 w-75 mx-auto text-center gradient-color">{currentLanguage === 'ar' ? 'كيف يعزز شادي تياري تجربتك في تنظيم حفل الزفاف' : "Why ShadiTayari Enhances Your Wedding Planning Experience" }</h2> 
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center">{currentLanguage === 'ar' ? 'يقدم ShadiTayari نهجًا حديثًا وفعّالًا وتعاونيًا لتنظيم حفل الزفاف، مما يجعل العملية بأكملها أكثر سلاسة ومتعة للجميع المعنيين.' 
  : "ShadiTayari offers a modern, efficient, and collaborative approach to organizing a wedding, making the entire process smoother and more enjoyable for everyone involved." }</p>
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-6 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-image-1.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>
    <div className="col-lg-6 col-md-12 align-self-center">
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
        <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-1.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'مزودي حفلات الزفاف المحليين الموثوقين ' : "Verified and Reliable Local Wedding Suppliers" }</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'اكتشف قائمة مختارة من مزودي حفلات الزفاف المحليين، حيث تم فحص كل واحد منهم بدقة لضمان الجودة والموثوقية، مما يضمن لك الاتصال بأفضل الخيارات ليومك الخاص. استفد من شبكة من الموردين ذوي التقييم العالي، من الزهور إلى المصورين، والذين تم التحقق منهم جميعًا من قبل ShadiTayari بسبب تميزهم واحترافيتهم.' 
      : "Discover a curated list of local wedding suppliers, each rigorously vetted for quality and reliability, ensuring you connect with only the best for your special day. Access a network of top-rated vendors, from florists to photographers, all verified by ShadiTayari for their excellence and professionalism." }</p>
    </div>
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-2.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'خصومات حصرية ومقارنات الأسعار' : "Exclusive Discounts and Price Comparisons" }</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'تمتع بخصومات حصرية وعروض خاصة من مجموعة متنوعة من مزودي حفلات الزفاف، متاحة فقط لمستخدمي شادي تياري، مما يساعدك في استخدام ميزانيتك بأفضل طريقة. قارن الأسعار والخدمات عبر مزودين مختلفين مباشرة داخل التطبيق، مما يضمن لك الحصول على أفضل العروض أثناء تخطيط حفل الزفاف الخاص بك.' 
      : "Enjoy exclusive discounts and special offers from a range of wedding suppliers, available only to ShadiTayari users,  helping you make the most of your budget. Compare prices and services across different vendors directly within the app, ensuring you get the best deals while  planning your dream wedding." }</p>
    </div>
    <div className="">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-3.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">{currentLanguage === 'ar' ? 'تنسيق الوقت مع التاريخ ' : 'Real Time Coordination with Complete History'}</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">{currentLanguage === 'ar' ? 'استمتع بتنسيق فوري وسلس مع المزودين وفريق حفل الزفاف الخاص بك، مع توفير تاريخ شامل لجميع التفاعلات والتحديثات. ابقَ منظمًا وعلى علم بجميع الخطوات التي اتخذتها في تخطيط حفل زفافك، من الاستفسارات الأولية إلى الترتيبات النهائية، كل ذلك داخل التطبيق.' 
      : "Experience seamless real-time coordination with vendors and your wedding party, complete with a comprehensive  history of all interactions and updates. Stay organized and informed with a complete chronological record of your wedding planning steps, from initial  inquiries to final arrangements, all within the app."}</p>
    </div>
    </div>     
  </div>
</div>
</section>
<section className="features-section-2 py-5">
<div className="container">
    <h2 className="font-size-46 playfair-font mb-4 text-center gradient-color">{t('FeaturesHighlight')}</h2>
    <p className="font-size-18 purple-color mb-5 w-50 mx-auto text-center">{currentLanguage === 'ar' ? 'ميزة إدارة الميزانية الابتكارية لدينا مصممة لتبسيط الجانب المالي لتخطيط حفل زفافك، حيث تقدم أدوات بديهية لتتبع النفقات، وتخصيص الميزانيات، وضمان أن تظل على هدفك في الإنفاق.' : 'Our innovative budget management feature is designed to simplify the financial aspect of your wedding planning, offering intuitive tools for tracking expenses, customizing budgets, and ensuring you stay on target with your spending.'}</p>
  <div class="timeline">
  <div className="line"></div>
  <div className="dot" style={{ top: `${dotScrollPercentage}%` }}></div>
  <div className="row gy-4 px-md-5 gx-5 mb-4">
  {visibleBadgeIndex === 0 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-1.png`} className="features-img w-100" alt="" /> 
        </ScrollAnimation>
        </div>
        
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-1.png`} className="features-icon" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'إنشاء ميزانية مبتكرة' : 'Innovative Budget Creation'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'ابدأ بقوالب الميزانية المعرفة مسبقًا أو قم بإنشاء ميزانيتك الشخصية. ضبط ميزانيتك بتصنيفات تتناسب مع خطط حفل الزفاف الفريدة لديك.' : 'Begin with pre-defined budget templates or create your own custom budget. Tailor your budget with categories that match your unique wedding plans.'}</p>
        </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 1 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
      <ScrollAnimation animateIn="animate__zoomIn">
      <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-2.png`} className="features-icon mb-2" alt="" />
        <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تتبع النفقات بشكل ذكي' : 'Smart Expense Tracking'}</h2>
        <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'سجل النفقات على الفور واطلع على التحديثات الفورية حول حالة ميزانيتك. حافظ على جميع إيصالات النفقات في مكان واحد للوصول والرجوع إليه بسهولة.' : 'Log expenses as they happen and see instant updates on your budget status. Keep all your expense receipts in one place for easy access and reference.'}</p>
        </ScrollAnimation>
      </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-2.png`} className="features-img w-100" alt="" />
          </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 2 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-3.png`} className="features-img w-100" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-3.png`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'نظرة شاملة على الأوضاع المالية' : 'Insightful Finacial Overview'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'افهم إنفاقك بلمحة باستخدام الرسوم البيانية والمخططات سهلة الاستخدام. اطلع على تفاصيل مفصلة حول وجهات إنفاقك، مما يساعدك في اتخاذ قرارات مستنيرة.' : 'Understand your spending at a glance with user-friendly charts and graphs. View detailed insights into where your money is going, helping you make informed decisions.'}</p>
          </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 3 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-4.png`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تخطيط الميزانية بتعاون' : 'Collaborative Budgeting'}</h2>
          <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'ادعو شريكك أو مخطط حفل الزفاف لإدارة الميزانية معًا. يظهر أي تحديث أو تغيير يتم بشكل فوري لجميع المستخدمين المشتركين.' : 'Invite your partner or wedding planner to manage the budget together. Any update or changes made are instantly visible to all shared users.'}</p>
          </ScrollAnimation>
        </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`}>
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-4.png`} className="features-img w-100" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 4 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-5.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`}>
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-5.png`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'تقليل التوتر' : 'Stress Reduction'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'ادعو شريكك أو مخطط حفل الزفاف لإدارة الميزانية معًا. يظهر أي تحديث أو تغيير يتم بشكل فوري لجميع المستخدمين المشتركين.' : 'Invite your partner or wedding planner to manage the budget together. Any update or changes made are instantly visible to all shared users.'}</p>
            </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 5 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-6.png`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'عروض حصرية وتوفير' : 'Exclusive Deals and Savings'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'احصل على خصومات حصرية وعروض من مجموعة واسعة من مزودي خدمات حفل الزفاف. قارن الأسعار والخدمات لضمان الحصول على أفضل الصفقات.' : 'Access exclusive discounts and offers from a wide range of wedding vendors. Compare prices and services to ensure you’re getting the best deals.'}</p>
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 order-md-2 order-1 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-6.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-5">
    {visibleBadgeIndex === 6 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-image-7.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className={`col-md-5 align-self-center px-md-5 order-md-2 ${currentLanguage === 'ar' ? 'offset-md-1' : 'offset-md-2'}`} >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-highlight-icon-7.png`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26 playfair-font pink-color mb-2">{currentLanguage === 'ar' ? 'إمكانية الوصول عبر الهاتف المحمول' : 'Mobile Accessibility'}</h2>
            <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'قم بالوصول إلى قائمة المراجعة وإدارة المهام أثناء التنقل، أينما كنت. ابقَ على تحكم في خطط زفافك من خلال جوالك.' : 'Access your checklist and task management on the go, wherever you are. Stay in control of your wedding plans from the palm of your hand.'}</p>
            </ScrollAnimation>
        </div>
        
    </div>
  </div>
    
    
    {/* <p className="font-size-30 purple-color mb-2 w-75 mx-auto text-center">Join ShadiTayari and experience the future of wedding planning with our AI-powered Task Management and Checklist features.</p>
    <p className="font-size-16 pink-color w-50 mx-auto text-center">Let us handle the logistics while you enjoy the journey to your perfect day.</p> */}
</div>
</section>
<section className="features-section-3 py-5">
<div className="container">
<h2 className="font-size-36 playfair-font gradient-color mb-3 text-center w-75 mx-auto">{currentLanguage === 'ar' ? 'حقائق مدهشة تسلط الضوء على فعالية إدارة الميزانية' : 'Surprising Facts That Highlight the Power of Budget Management'}</h2>
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-4 col-md-12 align-self-center order-lg-1 order-2">
    <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'التنقل في الجوانب المالية لتخطيط حفل الزفاف يمكن أن يكون معقدًا بنفس قدرته على أن يكون حاسمًا. فيما يلي بعض الحقائق المدهشة حول الأمور المالية لحفل الزفاف والدور المحوري لأدوات إدارة الميزانية الفعّالة مثل ShadiTayari..' : 'Navigating the financial aspects of wedding planning can be as complex as it is critical. Below are some eye-opening facts about wedding finances and the pivotal role of effective budgeting tools like ShadiTayari..'}</p>
    <div className="row">
      <div className="col-md-6 light-pink-bg py-4">
      <p className="font-size-26 gradient-color playfair-font mb-0 text-center">SAR</p>
      <h3 className="font-size-40 gradient-color playfair-font mb-3 text-center mt-0">120,000+</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'الحد الأدنى لتكلفة الزفاف في الإمارات العربية المتحدة' : 'Minimum average wedding cost in UAE'}</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">50%</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'من إجمالي ميزانية الزفاف يتم إنفاقها على حجز المكان وتقديم الطعام وحده.' : 'of the total wedding budget is spent on booking venue and catering alone.'}</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">50%</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'ينتهي الأمر بالأزواج بإنفاق أكثر مما كان مخططا له في البداية.' : 'Couples end up spending more than initially planned'}</p>
      </div>
      <div className="col-md-6 light-purple-bg py-4">
      <h3 className="font-size-50 gradient-color playfair-font mb-3 text-center">30%</h3>
      <p className="font-size-14 color-black mb-0 text-center">{currentLanguage === 'ar' ? 'من الأزواج أكثر عرضة للبقاء ضمن ميزانيتهم المخطط لها باستخدام ShadiTayari.' : 'couples are more likely to stay within their planned budget with ShadiTayari'}</p>
      </div>
    </div>
  </div>  
    <div className="col-lg-8 col-md-12 order-lg-2 order-1">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-facts-image.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>  
  </div>
</div>
</section>
<section className="features-section-4 py-5">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-4 col-md-12 align-self-center">
    <img src={`${process.env.REACT_APP_FrontURL}/assets/images/budget-guide-image.png`} className="features-img w-100" alt="" />
  </div>  
    <div className="col-lg-8 col-md-12">
    <p className="font-size-18 purple-color mb-0">{t('HowtoGuide')}</p>
    <h2 className="font-size-32 playfair-font mb-4 pink-color">{currentLanguage === 'ar' ? 'دليل سريع لإدارة الميزانية' : 'Quick Guide to Budget Management'}</h2> 
    <p className="font-size-18 color-black mb-3">{currentLanguage === 'ar' ? 'قم بإدارة حجوزات حفل زفافك وإنفاقك بكل سهولة مع ميزة إدارة الميزانية في ShadiTayari. فيما يلي نهج بسيط وسهل لإدارة الأمور المالية لحفل الزفاف بكفاءة.' : 'Effortlessly manage your wedding related booking and spendings with ShadiTayari’s budget management feature. Here’s a simple easy-to-follow approach for efficiently managing wedding finances.'}</p>
      
      <div className="mb-4 light-pink-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'إعداد ميزانية زفافك' : 'Set Up Your Wedding Budget'}</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="pink-bg rounded-circle d-flex align-items-center justify-content-center mt-2" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'يمكنك اختيار قوالب ميزانية قابلة للتخصيص أو إنشاء ميزانيتك الخاصة من البداية، حيث تدخل الميزانية الإجمالية وتخصص الأموال لفئات مختلفة مثل المكان، والتموين، والزي، وما إلى ذلك.' : 'You can choose from customizable budget templates or create your own from scratch, inputting your total budget and allocating funds to different categories like venue, catering, attire, etc.'}</p>
        </div>
        </div>
        </div>
      </div>

      <div className="mb-4 light-purple-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'تتبع النفقات' : 'Track Your Expenses'}</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="purple-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'عند حجز الخدمات، قم بتسجيل هذه النفقات تحت الفئات المعنية في التطبيق. تقوم أداة إدارة الميزانية في ShadiTayari بالتحديث في الوقت الحقيقي، مما يظهر لك كيف يتناسب إنفاقك مع ميزانيتك.' : 'As you book services, log these expenses under the respective categories in the app. ShadiTayari’s budget management tool updates in real-time, showing you how your spending aligns with your budget.'}</p>
        </div>
        </div>
        </div>
      </div>
      <div className="light-black-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">{currentLanguage === 'ar' ? 'مراقبة وتعديل الميزانية' : 'Monitor and Adjust Your Budget'}</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="black-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">{currentLanguage === 'ar' ? 'يوفر ShadiTayari رؤى بصرية حول إنفاقك، مما يظهر لك الفئات التي تتجاوز أو تقل عن الميزانية. يمكنك إعادة تخصيص الأموال من فئة واحدة إلى أخرى، مما يضمن توازن الميزانية الإجمالي.' : 'ShadiTayari provides visual insights into your spending, showing you which categories are under or over the budget. You can reallocate funds from one category to another, ensuring that your overall budget remains balanced.'}</p>
        </div>
        </div>
        </div>
      </div>
    </div>  
  </div>
</div>
</section>
<section className="features-section-5 py-5">
<div className="container">
    <h2 className="font-size-46 playfair-font gradient-color mb-5 text-center">{currentLanguage === 'ar' ? '' : ''}Frequently Asked Questions</h2>
    <div className="faq-grid-system">
    {questions.map((data)=>(
    <div className="" key={data}>
        <h3 className="font-size-18 playfair-font color-black mb-2">
        <img style={{marginRight:'10px'}} src="../assets/images/w-pink.svg" width="35" alt="" className="features-icon-img"/> 
        {data.question}</h3>
        <p className="font-size-16 color-black mb-4"  dangerouslySetInnerHTML={{ __html: data.answer }}></p>
    </div>
    ))}
    </div>
    <p className="font-size-16 color-black mb-0 text-center">For any additional queries, contact ShadiTayari’s Support Team.</p>
</div>
</section>
<section className="features-section-6 py-5 dowload-app-bg">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/feature-download-image.png`} className="features-img w-100" alt="" />
    </div>
    <div className="col-lg-5 col-md-12 align-self-center">
      <h2 className="font-size-36 playfair-font mb-2 pink-color">{currentLanguage === 'ar' ? 'ابدأ تخطيط حفل زفافك بدون أي عناء اليوم!' : 'Master Your Wedding Finances with Our Innovative Budget Management!'}</h2> 
      <p className="font-size-16 color-black mb-4">{currentLanguage === 'ar' ? 'قل وداعًا لإجهاد تخطيط الزفاف ومرحبًا بـ ويدفايد! تطبيقنا الكامل لتخطيط الزفاف متوفر الآن لتحول رحلتك إلى المذبح إلى تجربة سلسة وممتعة. مع ShadiTayari، يكون يومك المثالي على بُعد نقرة واحدة.' 
      : 'Embrace the power of smart budgeting at your fingertips and make informed decisions that align with your vision of the perfect wedding. Download ShadiTayari now and take the first step towards a financially savvy and beautifully organized wedding!'}</p>
      <div className="d-flex align-items-center gap-3 mb-4">
      <img width={170} class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt=""/>
        <img width={170} class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt=""/>
      </div>
      <div className="d-flex align-items-center gap-3">
      <img class="" width="70" src={`${process.env.REACT_APP_FrontURL}/assets/images/dowload-ShadiTayari-icon.svg`} alt=""/>
      <div>
      <p className="font-size-18 color-black mb-0">{t('StartPlanningYourDreamWeddingToday')}!</p>
      <p className="font-size-18 mb-0 fw-semibold"><a className='pink-color'>{t('DownloadShadiTayariforFree')}! <i class="bi bi-arrow-right"></i></a></p>
      </div>
      </div>
    </div>    
  </div>
</div>
</section>
<section className="features-section-7 py-5 subscribe-bg">
<div className="container">
<p className="font-size-18 color-black text-center mb-3 mt-5">{t('ExclusiveUpdates')}</p>
    <h3 className="font-size-36 playfair-font pink-color mb-5 text-center">{t('JointheShadiTayariInsiderCommunity')}:<br/> {t('StayUpdatedontheLatestinWeddingPlanning')}!</h3>
    <div class="input-group wf-input-group w-50 mx-auto mb-1">
      <div class="form-floating wf-input">
        <input type="email" class="form-control" id="email-address" placeholder="Email Address"/>
        <label for="email-address">{t('EmailAddress')}</label>
      </div>
      <button class="btn wf-pink-btn wf-btn">{t('SubscribeNow')}</button>
    </div>
    <p className="font-size-12 color-black text-center mb-5">{currentLanguage === 'ar' ? 'كن على يقين، عنوان بريدك الإلكتروني في أمان معنا. سيتم استخدامه حصريًا لإرسال تحديثات شادي تياري ولن يتم مشاركته أبدًا مع أطراف أخرى.' : 'Rest assured, your email address is safe with us. It will be exclusively used to send you ShadiTayari updates and will never be shared with third parties.'}</p>
</div>
</section>
  
<Footer />
    </>
  )
}

export default BudgetManagementFeature