import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import NoEventUI from './noEventUI';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Heart, ListFilter } from 'lucide-react';
import {
  getProfile,
  getEventList,
  getFavourite,
} from "../../store/slices/userSlice";
import VendorCardSkeletonHome from "../LoadingSkeleton/VendorCardSkeletonHome";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "../../components/i18n";
const customStyles = {
  content: {
    // "--bs-modal-width" : "35%",
    display: "block",
  },
};
Modal.setAppElement("#root");


const Shortlist = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const myEvents = useSelector(getEventList);
  const profile = useSelector(getProfile);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [filtertoggle, setfiltertoggle] = useState(true);
  const location=useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple")?JSON.parse(queryParams.get("couple")):[]);
  const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events")?JSON.parse(queryParams.get("events")):[]);
  const [actualmyEvents, setactualmyEvents] = useState([]);
  const [selectedCouple, setselectedCouple] = useState("");


  const [favourites, setFavourites] = useState([]);
  const [totalfavourites, setTotalFavourites] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  
  
  //filters states
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [filtercat, setfiltercat] = useState([]);
  const [filterevent, setfilterevent] = useState(queryParams.get("events")?JSON.parse(queryParams.get("events")):[]);
  const [load, setload] = useState(true);


  const viewModal = async (type) => {

    setIsOpen(true);
    setModalType(type);

    }
  
  
  function closeModal() {
    setIsOpen(false);
  }

  const EventList = myEvents?.map((ev) => {
    return {
      couple_id: ev?._id,
      id: ev?.Event?._id,
      name: ev?.Event?.event_name,
      color: ev?.Event?.event_color,
      date: ev?.Event?.event_date,
    };
  });


  const handlePin = async (iddd, i, j) => {
    try {
      const abc = await axios.post("/vendorpage/favourite", { id: iddd, type: "shortlistPage" });
      
      if (abc) {
        closeModal();
        toast.success(abc?.data?.message);
  
        setFilteredFavorites(prevFavorites => {
          const newFilteredFavorites = [...prevFavorites];
          if (newFilteredFavorites[i] && newFilteredFavorites[i].favorites[j]) {
            newFilteredFavorites[i] = {
              ...newFilteredFavorites[i],
              favorites: newFilteredFavorites[i].favorites.filter((item, index) => index !== j)
            };
          }
          return newFilteredFavorites;
        });
      }
    } catch (error) {
      // Handle error
      console.error("Error occurred:", error);
    }
  };
  



  const getAllFavourites = async () => {
    try {
      
      setload(true);
      setFavourites(null);

      const eventIdss= []

      for (const events of myEvents) {
        if (events.Event && events.Event._id) {
        eventIdss.push(events.Event._id);
      }
      }
      
      const response = await dispatch(
        getFavourite({ event_ids: eventIdss })
      ).unwrap();

      console.log('response.dataaaaa', response.data)
      setTotalFavourites(response?.data?.flatMap(obj => obj.favorites))
      setFavourites(response?.data);
      setFilteredFavorites(response?.data);
      
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const getCategories = async () => {
    try {
      setload(true);
      const response = await axios.get(`user/getservices`);
      console.log("responsexx", response);
      setAllCategories(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {}
  };



  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllFavourites();
      getCategories()
    }
    return () => {
      mount = false;
    };
  }, [myEvents]);

  useEffect(() => {

   
    getFilteredFavorites();
    
  }, [favourites]);


  const handleSetData = (e) => {

    const mergedEvents = [];


    e?.forEach(event => {
    const modifiedEvent = {
        ...event,
        Event: [event.Event]
    };

    const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
    );

    if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
    } else {
        mergedEvents.push(modifiedEvent);
    }
});

setactualmyEvents(mergedEvents)
// setfilteredData(mergedEvents)
}

useEffect(() => {
  handleSetData(myEvents)
}, [myEvents]);


 



  const getFilteredFavorites = () => {

    console.log('ssss',favourites)

    if (filtercat.length === 0 && filterevent.length === 0) {
    
      return favourites; 
    }
  
    const newFilteredFavorites = favourites?.filter((obj) => {
      const filteredObjFavorites = obj?.favorites?.filter((fav) => {
        const catFilterCondition = filtercat?.length === 0 || filtercat.includes(fav.store_id.prefered_services[0]);
        const eventFilterCondition = filterevent?.length === 0 || filterevent.includes(fav.event_id._id);
        const coupleMatches = coupleURLFilter?.length == "" || coupleURLFilter.includes(fav.user_id);
        const coupleFilterMatches = selectedCouple=="" || selectedCouple==fav.user_id;
  
        return catFilterCondition && eventFilterCondition && coupleMatches && coupleFilterMatches;
      });

      return filteredObjFavorites.length > 0;
    });
  
    return newFilteredFavorites;
  };
  
  
  const IncrementView = async (store_id,type) => {
    try {
      console.log("store_id",store_id)
      await axios.post(`vendorpage/incrementview`, { store_id: store_id, type: type });
    } catch (error) {
      console.error("Error incrementing view:", error);
    }
  }
  



  const handleChange1 = async (e) => {
    console.log('eeeeeeeee', e);
    if (!filtercat.includes(e)) {
      setfiltercat([...filtercat, e.toString()]);
    } else {
      setfiltercat(filtercat.filter((service) => service !== e));
    }
  };

    const handleChange3 = async (e) => {
    console.log('eeeeeeeee', e)
    if (!filterevent.includes(e)) {
      setfilterevent([...filterevent, e.toString()]);
    } else {
      setfilterevent(
        filterevent?.filter((service) => service !== e)
      );
    }
  };

  useEffect(() => {
   
    const newCoupleURLFilter = queryParams.get("couple") || [];
  
    setcoupleURLFilter(newCoupleURLFilter);
   
    const newcoupleEventURLFilter = queryParams.get("events") || [];
  
    setfilterevent(newcoupleEventURLFilter)
  
    setfiltertoggle(true)
  }, [location.search]);


  useEffect(() => {
    getFilteredFavorites();
  }, [filtertoggle]);
 

console.log('coupleURLFilter', coupleURLFilter)
console.log('myEvents', myEvents)
console.log('allCategories', allCategories)

console.log('filteredFavorites', filteredFavorites)




const currentLanguage = i18n.language;


  return (
    <>

<Modal
    closeTimeoutMS={500}
    isOpen={modalIsOpen}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Add Task"
    className={`modal fade show wf-modal tasks ${
      modalType !== "delete" ? "" : "wf-delete-modal"
    }`}
  >
    <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
     
    {modalType == "filter" ? (<div className="modal-content">
    <div class="modal-header border-0 mb-2">
            <h1
              class="font-size-22 dm-font purple-color"
              id="staticBackdropLabel"
            >
              Filters
            </h1>
            <button
              type="button"
              onClick={closeModal}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
     
    <div className={`wf-filter-toggle ${filtertoggle&&"show"}`}>

        {coupleURLFilter?.length<1&&profile?.role=="planner"&&(<>
        <div class="mb-4 row">
          <label
            for="staticEmail"
            class="col-12 font-size-14 align-self-center col-form-label"
          >
            {t("Couples")}
          </label>
          <div class="col-12 d-flex gap-2 flex-wrap">
            {load ? (
              new Array(5)
                .fill(null)
                .map((_, index) => <ButtonSkeleton key={index} />)
            ) : (
              <>
                <input
                  checked={!selectedCouple}
                  onChange={() => {setselectedCouple("");setfilterevent([])}}
                  type="checkbox"
                  name="event-select"
                  id={554}
                  class="btn-check"
                />
                <label class="btn wf-select-btn" for={554}>
                  {t('All')}
                </label>

                {actualmyEvents?.map((e) => (
                  <>
                    <input
                      checked={selectedCouple==e._id}
                      onChange={() => {setselectedCouple(e._id);setfilterevent([])}}
                      type="checkbox"
                      name="event-select"
                      id={e._id}
                      class="btn-check"
                    />
                    <label
                      class="btn wf-select-btn"
                      for={e._id}
                      // style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                    >
                    {`${e?.person1?.name||""} ${e?.person2?.name?"&":""} ${e?.person2?.name||""}`}
                    </label>
                  </>
                ))}
              </>
            )}
          </div>
        </div> </>)}

        {profile?.role!=="planner"&&(<><div class="mb-4 row">
          <label
            for="staticEmail"
            class="col-12 font-size-14 align-self-center col-form-label"
          >
            {t("Events")}
          </label>
          <div class="col-12 d-flex gap-2 flex-wrap">
            {load ? (
              new Array(5)
                .fill(null)
                .map((_, index) => <ButtonSkeleton key={index} />)
            ) : (
              <>
                <input
                  checked={filterevent?.length < 1 ? true : false}
                  onChange={() => setfilterevent([])}
                  type="checkbox"
                  name="event-select"
                  id={56}
                  class="btn-check"
                />
                <label class="btn wf-select-btn" for={56}>
                  {t('All')}
                </label>

                {EventList?.map((e) => (
                  <>
                    <input
                      checked={filterevent.includes(e.id)}
                      onChange={() => handleChange3(e.id)}
                      type="checkbox"
                      name="event-select"
                      id={e.id}
                      class="btn-check"
                    />
                    <label
                      class="btn wf-select-btn"
                      for={e.id}
                      style={{ "--bs-btn-active-bg": ` ${e.color}` }}
                    >
                      {e.name}
                    </label>
                  </>
                ))}
              </>
            )}
          </div>
        </div></>)}

        {profile?.role=="planner"&&coupleURLFilter?.length<1&&selectedCouple!==""&&(
          <><div class="mb-4 row">
          <label
            for="staticEmail"
            class="col-12 font-size-14 align-self-center col-form-label"
          >
            {t("Events")}
          </label>
          <div class="col-12 d-flex gap-2 flex-wrap">
            {load ? (
              new Array(5)
                .fill(null)
                .map((_, index) => <ButtonSkeleton key={index} />)
            ) : (
              <>
                <input
                  checked={filterevent?.length < 1 ? true : false}
                  onChange={() => setfilterevent([])}
                  type="checkbox"
                  name="event-select"
                  id={56}
                  class="btn-check"
                />
                <label class="btn wf-select-btn" for={56}>
                  {t('All')}
                </label>

                {actualmyEvents?.filter((f)=> f._id==selectedCouple && f?.event_id)[0]?.Event?.map((e) => {
                      console.log('eeeeeeeeeeee', e)
                      return(
    
                  <>
                    <input
                      checked={filterevent.includes(e._id)}
                      onChange={() => handleChange3(e._id)}
                      type="checkbox"
                      name="event-select"
                      id={e._id}
                      class="btn-check"
                    />
                    <label
                      class="btn wf-select-btn"
                      for={e._id}
                      style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                    >
                      {e.event_name}
                    </label>
                  </>
                )})}
              </>
            )}
          </div>
        </div></>)}
        
        <div class="mb-5 row">
          <label for="staticEmail" class="col-12 font-size-14 align-self-start col-form-label">{t('Category')}</label>
          <div class="col-12 d-flex gap-2 flex-wrap">
          {load? new Array(22).fill(null).map((_, index) => (
            <CaregoriesButtonSkeleton key={index} />
          )):allCategories?.map((c)=>(
                <>
                <input onClick={()=>handleChange1(c._id)} type="checkbox" name="cat-select" id={c.name} class="btn-check" />
                <label class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{width:'11%', lineHeight:'1.2'}} for={c.name}>
                  <img src={`${process.env.REACT_APP_IMGURL}${c.imageName}`} className="mx-auto d-block color" height="35" />
                  <img src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`} className="mx-auto d-block white" height="35" />
                {c.name}</label>
                </>
                ))}
          </div>
        </div>
        </div>
        
    <div class="modal-footer border-0 justify-content-center gap-2">
      <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>Close</button>
      <button onClick={()=>{setfiltertoggle(false); closeModal()}} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Apply Filter</button>
    </div>

    </div>) : null}
    </div>
  </Modal>
   
    <section className="wf-shortlist wf-background px-5 pb-5 pt-2">
        <div className="">
        <h2 className="font-size-26 dm-font purple-color mb-4">{t('Shortlist')}</h2>
       
      
         {/* <h3 className="font-size-26 dm-font color-black mb-2">{t('All')}</h3> */}
         <div className="col-md-12 d-flex justify-content-end mb-3">
    {filteredFavorites?.length>0&&(<div onClick={() => viewModal("filter")} className="d-flex gap-2 align-items-center cursor-pointer">
    <ListFilter size={22} stroke="var(--color3)" />
      <p className="font-size-16 purple-color mb-0 fw-medium">Filters</p>
    </div>)}
      </div>

        {load ? new Array(22).fill(null).map((_, index) => (
            <div className="shimmer w-100 mb-3 rounded" key={index} style={{height:'255px'}}></div>
          )) :
          !load&&filteredFavorites?.length>0?filteredFavorites?.map((c,i)=>(<div className="wf-shadow-2 white-bg rounded py-3 px-4 mb-3">
          <div className="row gy-3">
          <div className="col-lg-3 col-md-5">
          <div className="gradient-bg wf-shadow-2 h-100 rounded-3 py-3 px-4 d-flex flex-column  gap-2">
          <img width="55" src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`} className="mb-2" />
          <h2 className="font-size-22 dm-font color-white mb-2 text-uppercase">{c.name}</h2>
          <div class="border-top mt-2 mb-2"></div>
          <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-size-22 dm-font color-white mb-0 text-uppercase">{c?.favorites?.length<10?"0":""}{c?.favorites?.length}</h3>
          <p className="font-size-16 color-white mb-0 text-uppercase">{t('Shortlisted')}</p>
          </div>
          </div>
          </div>
          {c?.favorites?.length>0?(
          <div className="col-lg-9 col-md-7">
            <div className="wf-filter-category-slider" style={{marginBottom:'-10px'}}>
              <Swiper
              modules={[Navigation, A11y]}
              spaceBetween={15}
              // slidesPerView={3}
              navigation
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}
              breakpoints={{
                468: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
             {load ? (<div className="row">
              {new Array(4).fill(null).map((_, index) => (
                <VendorCardSkeletonHome key={index} />
              ))}
            </div>
          ) :c?.favorites.map((f,j)=>(<>
              <SwiperSlide>
        <div key={f.store_id._id} className="vendor-card vendor-card-dashboard">
      <div className="vendor-card-body">
        <a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${f?.store_id?.slug}`} onClick={()=>IncrementView(c?.categoryId,"profile")} target="_Blank">
          <img alt={f.store_id.image} src={`${process.env.REACT_APP_IMGURL}${f?.store_id?.image}`} className="vendor-image w-100"/>
        </a>
        <div className="d-flex justify-content-between align-items-center top-box">
          <span className="font-size-10 rounded-1 wf-purple-btn py-1 px-2 text-uppercase">{t('Featured')}</span>
          <div className="heart-icon" onClick={() => handlePin(f._id,i,j)}>
          <Heart fill="#EE0E7B" stroke="#EE0E7B" />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center bottom-box">
          <span className="font-size-10 rounded-1 py-1 px-2 text-uppercase" style={{backgroundColor: f?.event_id?.event_color}}><a>{f?.event_id?.event_name}</a></span>
        </div>
      </div>
      <div className="vendor-card-footer">
        <h3 className="font-size-16 mb-1 dm-font" ><a href={`${process.env.REACT_APP_FrontURL}/supplier-details/${f.store_id.slug}`} onClick={()=>IncrementView(c.categoryId,"profile")} target="_Blank" className="color-black">{f?.store_id?.store_name}</a></h3>
          <p className="font-size-12 color-black mb-1"><i class="bi bi-geo-alt-fill pink-color"></i> {`${f.store_id?.cities[0]?.name}, ${f?.store_id?.country[0]?.name}`}</p>
        <div className="d-flex justify-content-between align-items-center">
          <p className="font-size-12 color-black mb-0"><i class="bi bi-star-fill purple-color"></i> <span className="fw-medium">{f?.store_id?.reviews?.average}</span> ({f?.store_id?.reviews?.total})</p>
        </div>
      </div> 
        </div>
        </SwiperSlide>
        </>))}
              </Swiper>
            </div>
          </div>
          ) : (<div className="col-lg-9 col-md-7 align-self-center">
          <p className="font-size-18 color-grey mb-0 fw-light text-center">{t('You haven’t shortlisted for this category.')}</p>
          <p className="font-size-14 pink-color mb-0 fw-semibold text-center"><a className="pink-color" href={`${process.env.REACT_APP_FrontURL}/suppliers/${c?.name.replace(/\s/g, '-').replace(/&/g, "and").toLowerCase()}`} target="_Blank"><u>{t('Let’s Explore something exciting!')}</u></a> <i className={`bi ${currentLanguage === 'ar' ? "bi-arrow-left" : "bi-arrow-right"}`}></i></p>
          </div>)}
          </div>
        </div>)):!load&&filteredFavorites?.length>0?(<NoEventUI name={"Short List"}/>):<NoEventUI name={"Short List"}/>} 
       
        </div>
      </section>
      </>
  )
}

export default Shortlist