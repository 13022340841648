import React from 'react'

const BlogCardSkeleton = () => {
  return (
    <>
        <div className="col-lg-4 col-md-6 blog-card" >
          <div className="blog-card-body">
          <div  className="blog-image w-100 shimmer"></div>
          </div>
          <div className="blog-card-footer">
          <div className="row mb-2">
          <div className="col-md-6 d-flex align-items-center gap-2">
          <div class="rounded-circle profile-image-shimmer shimmer"></div>
          <div className="text-shimmer shimmer rounded"></div>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end gap-4">
          <div className="text-shimmer shimmer rounded"></div>
          <div className="text-shimmer shimmer rounded"></div>
          </div>
          </div>
          <div style={{width:'100%', height:'22px'}} className="shimmer rounded mb-1"></div>
          <div style={{width:'100%', height:'22px'}} className="shimmer rounded mb-1"></div>
            <div className="d-flex align-items-center justify-content-between">
            <div style={{width:'115px', height:'22px'}} className="shimmer rounded"></div>
            <div style={{width:'115px', height:'22px'}} className="shimmer rounded"></div>
            </div>
          </div>
        </div>
    </>
  )
}

export default BlogCardSkeleton