import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "../reducer/userReducer";
import { io } from "socket.io-client";

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
console.log(user);
axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;
const initialState = {
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  submitStatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  user: user,
  event_list: null,
  terms: null,
  privacy: null,
  dashboard: null,
  user_events_tasks: null,
  unread_messages: [],
  dashboard: null,
  socket: null
};

export const signinUser = createAsyncThunk(
  "user/signin",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/signin`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const signupUser = createAsyncThunk(
  "user/signup",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/signup`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "user/verify-account",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/verify-account`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "user/resendotp",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/resendotp`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "user/forgetpassword",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/forgetpassword`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const newPassword = createAsyncThunk(
  "user/newpassword",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/newpassword`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const socialLogin = createAsyncThunk(
  "user/sociallogin",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/sociallogin`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);






export const getBlogs = createAsyncThunk(
  "vendorpage/getblogs",
  async (bodyData, { rejectWithValue }) => { 

    try {
      const response = await axios.get(`vendorpage/getblogs`, {
        params: {
          page: bodyData,
        },
      });
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChats = createAsyncThunk(
  "user/chat-list",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.get(`user/chat-list`);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approvePayment = createAsyncThunk(
  "/userdashboard/approvepayment",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/userdashboard/approvepayment`,bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReviews = createAsyncThunk(
  "vendordashboard/getreviews",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`vendordashboard/getreviews`,bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestBlogs = createAsyncThunk(
  "vendorpage/getlatestblogs",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.get(`vendorpage/getlatestblogs`);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBlogsDetails = createAsyncThunk(
  "vendorpage/getblogsdetails",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.post(`vendorpage/getblogsdetails`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewsLetter = createAsyncThunk(
  "vendorpage/addnewsletter",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.post(`vendorpage/addnewsletter`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCoupleEvent = createAsyncThunk(
  "userdashboard/addcoupleevent",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.post(`userdashboard/addcoupleevent`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const PinUnPin = createAsyncThunk(
  "vendorpage/pinunpin",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.post(`vendorpage/pinunpin`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBlogCat = createAsyncThunk(
  "user/getallblogcat",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`user/getallblogcat`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllVenues = createAsyncThunk(
  "vendorpage/getAllVenues",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`vendorpage/getAllVenues`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const Likes = createAsyncThunk(
  "vendorpage/likes",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.post(`vendorpage/likes`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AddComments = createAsyncThunk(
  "vendorpage/comments",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ",bodyData);

    try {
      const response = await axios.post(`vendorpage/comments`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTopBlogs = createAsyncThunk(
  "vendorpage/gettopblogs",
  async (bodyData, { rejectWithValue }) => {
    console.log("rsasa ");

    try {
      const response = await axios.get(`vendorpage/gettopblogs`);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllBookings = createAsyncThunk(
  "userdashboard/getAllBookings",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/getAllBookings`,bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const completeSignUp = createAsyncThunk(
  "user/completesignup",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/completesignup`, bodyData);
      console.log("response :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const addManualBookings = createAsyncThunk(
  "userdashboard/addBooking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/addBooking`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCouple = createAsyncThunk(
  "userdashboard/addcouple",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/addcouple`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editBooking = createAsyncThunk(
  "userdashboard/editBooking",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/editBooking`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allRead = createAsyncThunk(
  "userdashboard/allread",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/allread`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAttachment = createAsyncThunk(
  "userdashboard/saveattachment",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('bodyData', bodyData)
      const response = await axios.post(`userdashboard/saveattachment`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectOffer = createAsyncThunk(
  "userdashboard/rejectoffer",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/rejectoffer`, bodyData);
      console.log("response of ADD EVENT :>> ", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/changepassword",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`user/changepassword`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCities = createAsyncThunk(
  "admin/getallcities",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcities`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getAllAreas = createAsyncThunk(
  "admin/getallareas",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallareas`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getServices = createAsyncThunk(
  "user/getservices",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`user/getservices`, { ques: bodyData });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuestions = createAsyncThunk(
  "user/getquestions",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`user/getquestions`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuestionsWeb = createAsyncThunk(
  "user/getquestionsweb",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.post(`user/getquestionsweb`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getServiceTypes = createAsyncThunk(
  "user/getsubcategories",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData", bodyData);
      const response = await axios.post(`user/getsubcategories`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAttribute = createAsyncThunk(
  "user/getattributes",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData", bodyData);
      const response = await axios.post(`user/getattributes`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSubAttribute = createAsyncThunk(
  "user/getsubattributes",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("bodyData", bodyData);
      const response = await axios.post(`user/getsubattributes`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getCities = createAsyncThunk(
  "user/getcities",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get("user/getcities");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEthnics = createAsyncThunk(
  "user/getethnics",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`user/getethnics`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addTask = createAsyncThunk(
  "userdashboard/addtask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`userdashboard/addtask`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editTask = createAsyncThunk(
  "userdashboard/edittask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(
        `userdashboard/edittask/${data._id}`,
        data
      );
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "userdashboard/deletetask",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/deletetask/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTask = createAsyncThunk( //doubt
  "userdashboard/gettask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("ddddddddddddddata :>> ", data);
      const response = await axios.post(`userdashboard/gettask`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getUserEventsTasks = createAsyncThunk(
  "userdashboard/getusereventstask",
  async (data, { rejectWithValue }) => {
    try {
      console.log("IDSSSSSSSSSSSSSSSS :>> ", data);
      const response = await axios.post(`userdashboard/getusereventstask`, data);
      console.log("response.data of tasks :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const editStoreFront = createAsyncThunk(
  "vendordashboard/editstorefront",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`vendordashboard/editstorefront`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubTask = createAsyncThunk( //doubt
  "userdashboard/deletesubtask",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/deletesubtask/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const addGuest = createAsyncThunk(
  "userdashboard/addguest",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`userdashboard/addguest`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editGuest = createAsyncThunk(
  "userdashboard/editguest",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`userdashboard/editguest`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGuest = createAsyncThunk(
  "userdashboard/deleteguest",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/deleteguest/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getFavourite = createAsyncThunk(
  "userdashboard/getfavourites1",
  async (data, { rejectWithValue }) => {
    try {
      console.log("aba",data)
      const response = await axios.post(`userdashboard/getfavourites1`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const dashboard = createAsyncThunk(
  "vendordashboard/getdashboard",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('dataaaaaaa34',bodyData)
      const response = await axios.post(`vendordashboard/getdashboard`,bodyData);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const graphData = createAsyncThunk(
  "vendordashboard/graphdata",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('dataaaaaaa34',bodyData)
      const response = await axios.post(`vendordashboard/graphdata`,bodyData);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReviewLink = createAsyncThunk(
  "vendordashboard/getreviewlink",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('dataaaaaaa34',bodyData)
      const response = await axios.post(`vendordashboard/getreviewlink`,bodyData);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SendReviewMail = createAsyncThunk(
  "vendordashboard/sendreviewmail",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('dataaaaaaa34',bodyData)
      const response = await axios.post(`vendordashboard/sendreviewmail`,bodyData);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AddReview = createAsyncThunk(
  "vendordashboard/addreview",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log('dataaaaaaa34',bodyData)
      const response = await axios.post(`vendordashboard/addreview`,bodyData);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getUserEventList = createAsyncThunk(
  "userdashboard/getusereventlist",
  async (category, { rejectWithValue }) => {
    try {
      const response = await axios.get(`userdashboard/getusereventlist`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStoreList = createAsyncThunk(
  "vendordashboard/getstorelist",
  async (category, { rejectWithValue }) => {
    try {
      const response = await axios.get(`vendordashboard/getstorelist`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCitiesWithCountry = createAsyncThunk(
  "user/getcitieswithcountry",
  async (category, { rejectWithValue }) => {
    try {
      console.log('categoryassa', category)
      const response = await axios.get(`user/getcitieswithcountry`, {
        params: {
          lang: category,
          countryType: "pakistan"
        },
      });
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getReligions = createAsyncThunk(
  "user/getreligions",
  async (category, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getreligions`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getThemes = createAsyncThunk(
  "user/getthemes",
  async (category, { rejectWithValue }) => {
    try {
      const response = await axios.get(`user/getthemes`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);





export const Firstlogin = createAsyncThunk(
  "user/firstlogin",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaaaaaaasssssvvv :>> ", data);
      const response = await axios.post(`user/firstlogin`);
      console.log("response.dataxyz :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const getMyBlogs = createAsyncThunk(
  "userdashboard/getmyblogs",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.get(`userdashboard/getmyblogs`);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getEventBudget = createAsyncThunk(
  "userdashboard/geteventbudget",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`userdashboard/geteventbudget`,data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const removeFromPin = createAsyncThunk( //doubt
  "userdashboard/removefrompin",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasssss :>> ", data);
      const response = await axios.post(`userdashboard/removefrompin`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEvent = createAsyncThunk(
  "userdashboard/addevent",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaa :>> ", data);
      const response = await axios.post(`userdashboard/addevent`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEvent = createAsyncThunk(
  "userdashboard/editevent",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/editevent`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRSVP = createAsyncThunk( //doubt
  "userdashboard/updatersvp",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/updatersvp`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editEventBudget = createAsyncThunk(
  "userdashboard/editeventbudget",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/editeventbudget`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBudgetCategory = createAsyncThunk(
  "userdashboard/addbudgetcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/addbudgetcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editBudgetCategory = createAsyncThunk(
  "userdashboard/editBudgetCategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/editBudgetCategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBudgetCategory = createAsyncThunk(
  "userdashboard/deletebudgetcategory",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/deletebudgetcategory`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBudgetById = createAsyncThunk( //doubt
  "userdashboard/getbudgetbyId",
  async (data, { rejectWithValue }) => {
    try {
      console.log("dataaaasaaaaaaaaa :>> ");
      const response = await axios.post(`userdashboard/getbudgetbyId`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);







export const updateUserProfile = createAsyncThunk(
  "userdashboard/updateprofile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/updateprofile`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGuest = createAsyncThunk(
  "userdashboard/getguest1",
  async (data, { rejectWithValue }) => {
    try {
      console.log("aba", data);
      const response = await axios.post(`userdashboard/getguest1`, data);
      console.log("response.data :>> ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const generateInvitationLink = createAsyncThunk(
  "userdashboard/generateinvitationlink",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/generateinvitationlink`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteGuest = createAsyncThunk(
  "userdashboard/inviteguest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/inviteguest`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateprofile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/updateprofile`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const deleteEvent = createAsyncThunk( 
  "userdashboard/deleteevent",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`userdashboard/deleteevent/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getChatMembers = createAsyncThunk(
  "user/getchatmembers",
  async (data, { rejectWithValue }) => {
    try {
      console.log("datasassaas", data);
      const response = await axios.post(`user/getchatmembers`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addChatMember = createAsyncThunk(
  "user/addchatmember",
  async (data, { rejectWithValue }) => {
    try {
      console.log("datasassaas", data);
      const response = await axios.post(`user/addchatmember`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);




export const getvendorquestionsadmin = createAsyncThunk(
  "admin/getvendorquestionsadmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/getvendorquestionsadmin`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "user/deleteaccount",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/deleteaccount`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllCountries = createAsyncThunk(
  "admin/getallcountries",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallcountries`,{
        params:{lang: bodyData}
      });
      console.log("sasasasasa", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getAllStores = createAsyncThunk(
  "admin/getallstores",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log(bodyData);
      const response = await axios.get(`admin/getallstores`);
      console.log("sasasasasa",response);     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategoriesWithQuestions = createAsyncThunk(
  "userdashboard/allcategorieswithquestions",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("sasasasasasasasasasa");
      const response = await axios.get(
        `userdashboard/allcategorieswithquestions`
      );
      console.log("sasasasasa", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const rejectRequest = createAsyncThunk(
  "userdashboard/rejectrequest",
  async (bodyData, { rejectWithValue }) => {
    try {
      console.log("sasasasasasasasasasa");
      const response = await axios.post(
        `userdashboard/rejectrequest`,bodyData
      );
      console.log("sasasasasa", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTerms = createAsyncThunk(
  "terms_and_conditions",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatePrivacy = createAsyncThunk(
  "privacy_policy",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`tcandpp/content/getTcPp`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/signout",
  async (bodyData = null, { rejectWithValue }) => {
    try {
      const response = await axios.post(`user/signout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const terms = createAsyncThunk(
  "tcandpp/content/getTcPp",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const privacy = createAsyncThunk(
  "tcandpp/content/getTcPp1",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`tcandpp/content/TcPp`);
      console.log(response.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    token: (state) => {
      // const socket = io(process.env.REACT_APP_APIURL);
      // if (socket) {
      //   state.socket = socket;
      // }
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        state.user = user;
      }
    },
    setMessages: (state, action) => {
      console.log('actionssssssss', action)
      state.unread_messages = action.payload?.length<2?action.payload:action.payload.data;
    },
  },
  extraReducers
});

export const { setMessages } = userSlice.actions;

export const getSocket = (state) => state?.users?.socket;
export const getUserStatus = (state) => state?.users?.submitStatus;
export const getUserError = (state) => state?.users?.error;
export const getUsertoken = (state) => state?.users?.user?.token;
export const getProfile = (state) => state?.users?.user;
export const getTerms = (state) => state?.users?.terms;
export const getPrivacy = (state) => state?.users?.privacy;
export const getAllCourses = (state) => state?.users?.courses;
export const getCourseDetails = (state) => state?.users?.courses;
export const getAllReviews = (state) => state?.users?.reviews;
export const getAllCharges = (state) => state?.users?.charges;
export const getDashboard = (state) => state?.users?.dashboard;
export const getEventList = (state) => state?.users?.event_list;
export const getUnreadMessagesNotification = (state) => state?.users?.unread_messages;
export const getEventTasks = (state) => state?.users?.user_events_tasks;
export const getareaChart = (state) => state?.users?.areaChart;
export const getlineChart = (state) => state?.users?.lineChart;
export const getcampaigns = (state) => state?.users?.campaigns;

export const { token } = userSlice.actions;

export default userSlice.reducer;
