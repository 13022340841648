import React from 'react'

const LocationCardSkeleton = () => {
  return (
    <>
        <div className="location-card col-md-2" >
            <div className="location-img shimmer"></div>
            <div className="location-text">
                <div className="text-shimmer shimmer rounded"></div>
            </div>
          </div>
    </>
  )
}

export default LocationCardSkeleton