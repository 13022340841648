import React from 'react'

const EventsCardSkeleton = () => {
  return (
    <>
    <div className="wf-event-tab wf-shadow-2 white-bg rounded p-3 mb-2">
              <div className="row gy-4">
              <div className="col-md-3 px-4 py-1">
              <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="shimmer rounded" style={{height:'26px',width:'100px'}}></div>
              <div className="shimmer rounded" style={{height:'24px',width:'40px'}}></div>
              </div>
              {/* <div className="shimmer rounded mb-1" style={{height:'22px',width:'50px'}}></div> */}
              

              <div className="d-flex gap-3 align-items-center mb-2">
                  <div className="bride-image-name">
                  <div className="shimmer rounded-circle mb-1" style={{height:'80px',width:'80px'}}></div>
                  <div className="shimmer rounded mx-auto d-block" style={{height:'20px',width:'50px'}}></div>
                  </div>
                  <div className="bride-groom-text mb-4">
                  <div className="shimmer rounded" style={{height:'25px',width:'41px'}}></div>
                  </div>
                  <div className="groom-image-name">
                  <div className="shimmer rounded-circle mb-1" style={{height:'80px',width:'80px'}}></div>
                  <div className="shimmer rounded mx-auto d-block" style={{height:'20px',width:'50px'}}></div>
                  </div>
                </div>
                <div className="countdown">
                <ul className="mb-0 p-0">
                <li>
                <span className="shimmer rounded mb-1" style={{height:'20px',width:'15px'}} id="months"></span><span className="shimmer rounded d-block" style={{height:'16px',width:'35px'}} id="months"></span>
                </li>
                <li>
                <span className="shimmer rounded mb-1" style={{height:'20px',width:'15px'}} id="weeks"></span><span className="shimmer rounded d-block" style={{height:'16px',width:'35px'}} id="months"></span>
                </li>
                <li>
                <span className="shimmer rounded mb-1" style={{height:'20px',width:'15px'}} id="days"></span><span className="shimmer rounded d-block" style={{height:'16px',width:'35px'}} id="months"></span>
                </li>
                </ul>
                </div>
              </div>
              <div className="col-md-9">
              <div className="row gy-3 h-100">
              <div className="col-md-3">
                <div style={{height:'199px',width:'217px'}} className="shimmer rounded-3"></div>
              </div>
              <div className="col-md-3">
              <div style={{height:'199px',width:'217px'}} className="shimmer rounded-3"></div>
              </div>
              <div className="col-md-3">
              <div style={{height:'199px',width:'217px'}} className="shimmer rounded-3"></div>
              </div>
              <div className="col-md-3">
              <div style={{height:'199px',width:'217px'}} className="shimmer rounded-3"></div>
              </div>
              </div>
              </div>
              </div>           
          </div>
          </>
  )
}

export default EventsCardSkeleton