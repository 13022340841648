import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import axios from "axios";
import Footer from './Footer'
import { ArrowRight, AtSign, Phone } from 'lucide-react';
import { Contactus } from "../../store/slices/userSlice";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

const ContactUs = () => {

  const { t } = useTranslation();
    const [tc, settc] = useState(false);
    const [name, setname] = useState("");
    const [email, setemail] = useState("");
    const [role, setrole] = useState("");
    const [reason, setreason] = useState("");
    const [message, setmessage] = useState("");
    const [resmessage, setresmessage] = useState("");

    const categories = [
      "General Inquiries",
      "Supplier Listings Information",
      "Technical Support",
      "Feedback and Suggestions",
      "Advertising Opportunities",
      "Collaboration Requests",
      "App Download and Usage Assistance",
      "Wedding Planning Tips and Advice",
      "Blog Contributions",
      "Partnership Opportunities"
    ]

    
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0})
    }, []);


      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('first')
        try {
          if(!tc){
            toast.error("Please accept all terms and conditions")
          }
          else{
            const response = await axios.post(`vendorpage/contactus`, { name: name, email: email, role:role ? role : "Bride/Groom", reason:reason, message:message });
            if(response){
              console.log('response', response)
                setresmessage(response?.data?.message)
                setname("")
                setemail("")
                setrole("")
                setreason("")
                setmessage("")
                settc(false)
            }
          }
        } catch (rejectedValueOrSerializedError) {
          toast.error(rejectedValueOrSerializedError.response?.data?.message);
        }
      };



return (
<>
<Helmet>
<title>Contact Shadi Tayari</title>
<meta name="description" content="We’re here to help you every step of the way in planning your dream wedding. Whether you have questions about our app, need assistance with finding the perfect suppliers, or have any other inquiries, our team is ready to assist you"/>
<link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/contact`}/>
</Helmet>
<section class="contact-hero contact-bg">
<Header theme="light" />
  <div className="container">
    <div class="contact-hero-body">
      <h1 class="font-size-46 dm-font color-white">{t('ContactUs')}</h1>
    </div>
  </div>
  <div className="contact-hero-overlay"></div>
</section>  
<section className="contact-section py-5">
  <div className="container">
    <div className="row gy-4">
    <div className="col-lg-5 col-md-12">
      <img src="../assets/images/contact-shadi-tayari-img.jpg" className="contact-us-img w-100 rounded-4 mb-4" alt="" />
      <p className="font-size-16 color-black">We’re here to help you every step of the way in planning your dream wedding. Whether you have questions about our app, need assistance with finding the perfect suppliers, or have any other inquiries, our team is ready to assist you. Please fill out the contact form below, and we’ll get back to you as soon as possible. Your journey to a perfect wedding starts with Shadi Tayari!</p>
      <div className="row gx-5 gy-2">
      <div className="col-md-6">
        <div className="d-flex align-items-center gap-3">
        <AtSign stroke="#ED257D" width={24} />
          <div>
          <p className="font-size-16 purple-color mb-0 fw-semibold">Email Address</p>
          <p className="font-size-14 color-black mb-0">hello@shaditayari.pk</p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="d-flex align-items-center gap-3">
        <Phone stroke="#ED257D" width={24} />
          <div>
          <p className="font-size-16 purple-color mb-0 fw-semibold">Contact Number</p>
          <p className="font-size-14 color-black mb-0">+92 336 9tayari (0336 9829274)</p>
          </div>
        </div>
      </div>
  </div>
    </div>
    <div className="col-lg-7 col-md-12">
      <div className="contact-form">
      <h2 className="font-size-26 playfair-font pink-color mb-3">Send Us A Message</h2>
      <div className="spacer-20"></div>
      <form onSubmit={handleSubmit}>

        <div class="form-floating wf-input wf-input-101 mb-3">
        <input type="text" class="form-control" id="name" placeholder="name" value={name ? name : ""} onChange={(e)=>setname(e.target.value)} autocomplete="name" required />
        <label for="name">Name</label>
      </div>
      <div class="form-floating wf-input wf-input-101 mb-3">
        <input type="email" class="form-control" id="email" placeholder="name@example.com" value={email ? email : ""} onChange={(e)=>setemail(e.target.value)} autocomplete="email" required />
        <label for="email">Email Address</label>
      </div>
      <div class="row  gx-2 gy-3 mb-4">
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("Bride/Groom")} checked={role=="Bride/Groom"||role==""?true:false} name="role" id="bride-groom" class="btn-check" />
        <label class="btn wf-select-btn" for="bride-groom">Bride / Groom</label>
        </div>
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("planner")} checked={role=="planner"?true:false} name="role" id="planner" class="btn-check" />
        <label class="btn wf-select-btn" for="planner">Planner</label>
        </div>
        <div class="col-md-4 col-sm-4 col-6 d-grid">
        <input type="radio" onClick={()=>setrole("business")} checked={role=="business"?true:false} name="role" id="business" class="btn-check" />
        <label class="btn wf-select-btn" for="business">Business</label>
        </div>
      </div>
          <div class="form-floating mb-3 wf-select wf-select-101">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example" value={reason?reason:""} onChange={(e)=>setreason(e.target.value)}>
            {categories?.map((category) => 
            <option value={category}>{category}</option>
            )}
            </select>
            <label for="floatingSelect">Choose a Reason</label>
          </div>
          <div class="form-floating mb-3 wf-textarea wf-textarea-101">
            <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{"height": "150px"}} value={message?message:""} onChange={(e)=>setmessage(e.target.value)}></textarea>
            <label for="floatingTextarea2">Comment/Message</label>
          </div>
          <div class="form-check wf-signup-checkbox mb-3">
        <input class="form-check-input wf-shadow-2" type="checkbox" value="" id="flexCheckDefault" checked={tc} onChange={()=>settc(!tc)} />
        <label class="form-check-label" for="flexCheckDefault">
        I Accept Shadi Tayari <a className="white-color link-underline" href='/terms-and-condition'>Terms & Conditions</a> and <a className="white-color link-underline" href='/privacy'>Privacy & Policy</a>
        </label>
        </div>


        <button type="submit" className="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2 wf-shadow-2"> Send Message</button>
        </form>
        {resmessage && <div className="purple-bg p-3 mt-3 rounded-3">
          <p className="mb-0 color-white">{resmessage}</p>
        </div>}
        </div>
    </div>
    </div>
  </div>
</section>
    
        <Footer />
        </>
      )
}

export default ContactUs