// socketSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { io } from "socket.io-client";
const initialState = {
    socket: null
};
const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setSocket: (state) => {
            var socket = io(process.env.REACT_APP_SOCKET_URL);
            if (socket) {
                state.socket = socket
            }
        },
    },
});
export const getSocket = (state) => state?.socket?.socket;
export const { setSocket } = socketSlice.actions;
export default socketSlice.reducer;