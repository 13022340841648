import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getChats, getProfile } from "../../store/slices/userSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Chatlist_Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const [chat, setChat] = useState([]);
  const [chattype, setchattype] = useState("conversation");
  const [messagetoshow, setmessagetoshow] = useState("all");
  const [searchclick, setsearchclick] = useState(false);
  const [searchtext, setsearchtext] = useState("");
  const [filteredData, setfilteredData] = useState();
  // const [prevfilteredData, setprevfilteredData] = useState();

  const getAllChats = async () => {
    try {
      setChat(null);
      const response = await dispatch(getChats()).unwrap();
      const filters = response?.data?.filter((i) => {
        const eventTypeMatches = i?.group_event == null;
        return eventTypeMatches;
      });
      setfilteredData(filters);
      // setprevfilteredData(filters);
      // setfilteredData(response?.data)
      setChat(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleFilters = (b, c) => {
    if (!searchtext) {
      if (b == "all" && c == "conversation") {
        setfilteredData(chat?.filter((x) => !x?.group_event?._id));
      }

      if (b == "all" && c == "booking") {
        setfilteredData(chat?.filter((x) => x?.group_event?._id));
      }

      if (b == "unread" && c == "conversation") {
        console.log("aaaaaa");
        setfilteredData(
          chat?.filter((x) => !x?.group_event?._id && x?.chatStatusCount > 0)
        );
      }

      if (b == "unread" && c == "booking") {
        setfilteredData(
          chat?.filter((x) => x?.group_event?._id && x?.chatStatusCount > 0)
        );
      }
    } else {
      if (b == "all" && c == "conversation") {
        setfilteredData(
          chat?.filter(
            (x) =>
              !x?.group_event?._id &&
              x?.sender_id?.name?.toLowerCase().includes(searchtext?.toLowerCase())
          )
        );
      }

      if (b == "all" && c == "booking") {
        setfilteredData(
          chat?.filter(
            (x) =>
              x?.group_event?._id &&
              x?.sender_id?.name?.toLowerCase().includes(searchtext?.toLowerCase())
          )
        );
      }

      if (b == "unread" && c == "conversation") {
        setfilteredData(
          chat?.filter(
            (x) =>
              !x?.group_event?._id &&
              x?.chatStatusCount > 0 &&
              x?.sender_id?.name?.toLowerCase().includes(searchtext?.toLowerCase())
          )
        );
      }

      if (b == "unread" && c == "booking") {
        setfilteredData(
          chat?.filter(
            (x) =>
              x?.group_event?._id &&
              x?.chatStatusCount > 0 &&
              x?.sender_id?.name?.toLowerCase().includes(searchtext?.toLowerCase())
          )
        );
      }
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllChats();
    }
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    handleFilters(messagetoshow, chattype);
  }, [messagetoshow, chattype, searchclick]);

  console.log("filteredData", filteredData);
  console.log("chats", chat);

  return (
    <div className="wf-recent-messages mb-3">
      <div className="d-flex gap-4 align-items-end mb-2">
        <h2 className="font-size-26 dm-font color-black mb-0">
          {t('ChatMessages')}
        </h2>
        <a className="font-size-14 pink-color mb-0" href="/chat">
          <u>{t('ViewAll')}</u>
        </a>
      </div>
      <div className="d-flex gap-2 wf-select-btn mb-3">
        <input
          onChange={(e) => setmessagetoshow(e.target.value)}
          type="radio"
          checked={messagetoshow=="all"}
          value="all"
          name="chat-filter-select"
          class="btn-check"
          id="all-messages"
        />
        <label class="btn wf-select-btn" for="all-messages">
          {t('AllMessages')}
        </label>
        <input
          onChange={(e) => setmessagetoshow(e.target.value)}
          type="radio"
          checked={messagetoshow=="unread"}
          value="unread"
          name="chat-filter-select"
          class="btn-check"
          id="Unread"
        />
        <label class="btn wf-select-btn" for="Unread">
          {t('Unread')}
        </label>
      </div>
      <div className="recent-messages-listing-container wf-shadow-2 white-bg p-3 rounded">
        <div class="input-group recent-messages-search mb-1 wf-shadow-2 rounded">
          <input
            onChange={(e) => setsearchtext(e.target.value)}
            type="text"
            class="form-control"
            placeholder={t('Searchconversations')}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setsearchclick(!searchclick);
              }
            }}
          />
          <button
            onClick={() => setsearchclick(!searchclick)}
            class="input-group-text"
            id="basic-addon1"
          >
            <i class="bi bi-search"></i>
          </button>
        </div>
        <div className="recent-messages-listing-height">
          <ul class="nav mb-3 wf-tab-pills" id="pills-tab" role="tablist">
            <li
              onClick={() => setchattype("conversation")}
              class="nav-item"
              role="presentation"
            >
              <a
                className={`nav-link ${
                  chattype === "conversation" && "active"
                }`}
                id="pills-conversations-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-conversations"
                role="tab"
                aria-controls="pills-conversations"
                aria-selected="true"
              >
                {t('Conversations')}
              </a>
            </li>
            <li
              onClick={() => setchattype("booking")}
              class="nav-item"
              role="presentation"
            >
              <a
                className={`nav-link ${chattype === "booking" && "active"}`}
                id="`ills-bookings-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-bookings"
                role="tab"
                aria-controls="pills-bookings"
                aria-selected="false"
              >
                {t('Bookings')}
              </a>
            </li>
          </ul>
          <div class="tab-content">
          <div class="">
          {filteredData?.length > 0 ? (filteredData?.map((c, i) => 
                (
                    <div onClick={() => navigate("/chat", { state: { data: c, chattype: c?.group_event?._id ? "booking":"conversation" } })} className="cursor-pointer recent-messages-listing d-flex gap-2 mb-3">
                    <img src={
                           c?.vendor_store?.image? `${process.env.REACT_APP_IMGURL}${c?.vendor_store?.image}`: "https://via.placeholder.com/50x50"
                          } alt="" className="rounded-circle recent-listing-img" />
                        <div className="d-flex w-100">
                        <div className="recent-messages-text col-9 align-self-center">
                          <p className="font-size-14 purple-color mb-0 fw-bold">{                           
                            c?.group_event?.event_name
                              ? `${c?.vendor_store?.store_name} (${c?.group_event?.event_name})`
                              : !c?.group_event
                              ? `${c?.vendor_store?.store_name}`
                              : ""}</p>
                          <p className="font-size-14 color-black mb-0">
                          {c?.message ? (
                              c?.message.length < 25 ? (
                                c?.message
                              ) : (
                                <>{c?.message.substring(0, 25)}...</>
                              )
                            ) : c?.images?.length > 0 ? (
                              <>
                                <i class="fa-solid fa-paperclip"></i> Attachment
                              </>
                            ) : (
                              <>
                                <i class="fa-solid fa-handshake"></i>{" "}
                                Request/Offer
                              </>
                            )}{" "}
                          </p>
                        </div>
                        <div className="col-3 text-end">
                        <p className="font-size-12 grey-color mb-0"> {moment(c?.createdAt).format("HH:mm")}</p>
                        {c?.chatStatusCount > 0&&c?.sender_id!==profile?._id ? (<div class="recent-messages-number">{c?.chatStatusCount}</div>) : ("")}
                        </div>
                      </div>
                  </div>
                )))
                : (
                  <p className="text-center mb-5 mt-5 dm-font font-size-22 purple-color">No chats available.</p>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatlist_Dashboard;
