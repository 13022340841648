import React, {useEffect,useState} from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../../components/i18n';
import { getProfile } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const questions = [
    {
      id: 1,
      question: 'What is the guest list management feature in ShadiTayari?',
      answer: 'This feature allows you to create, organize, and manage your wedding guest list within the app. It includes adding guest details, tracking RSVPs, sending invitations, and managing guest-specific needs.',
    },
      {
      id: 2,
      question: 'How do I add guests to my list in the app?',
      answer: 'You can add guests manually, import from your contacts, or upload a spreadsheet. The app also allows you to categorize guests (e.g., family, friends) for better organization.',
    },
      {
      id: 3,
      question: 'Can I send digital invitations through ShadiTayari?',
      answer: 'Yes, ShadiTayari enables you to send customized digital invitations directly from the app. You can track when guests receive and respond to the invitations.',
    },
        {
      id: 4,
      question: 'How does RSVP tracking work in ShadiTayari?',
      answer: 'When guests respond to your digital invitations, their RSVP status (Accepted, Declined, Pending) is automatically updated in the app, allowing you to track who will be attending in real time.',
    },
    {
      id: 5,
      question: 'Is it possible to communicate with guests through the app?',
      answer: 'Absolutely. You can send messages, updates, or reminders to all or selected guests, facilitating direct and organized communication.',
    },
    {
      id: 6,
      question: 'Can I manage plus-ones and children with this feature?',
      answer: 'Yes, the guest list management feature includes options to handle plus-ones and children, ensuring you have accurate headcounts for your event.',
    },
    {
      id: 7,
      question: 'How does the app handle special guest requirements?',
      answer: 'You can note special requirements such as dietary restrictions, accessibility needs, or seating preferences for each guest, helping you cater to their individual needs.',
    },
    {
      id: 8,
      question: 'Is there a limit to the number of guests I can add?',
      answer: 'ShadiTayari is designed to handle guest lists of any size, from intimate gatherings to large-scale weddings.',
    },
    {
      id: 9,
      question: 'Can I use this feature to help with seating arrangements?',
      answer: 'Yes, once your RSVPs are in, you can use the guest list to assist in creating seating arrangements, aligning with guest preferences and relationships.',
    },
    {
      id: 10,
      question: 'Is it possible to export the guest list from the app?',
      answer: 'You can export your guest list to a spreadsheet or other formats for printing or external use, providing flexibility in how you manage and share your list.',
    },
  ]
  
  


const GuestManagementFeature = () => {
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const { t } = useTranslation();  
  const currentLanguage = i18n.language;
  const [dotScrollPercentage, setDotScrollPercentage] = useState(1);
  const [timelineOffsetTop, setTimelineOffsetTop] = useState(0);
  const [visibleBadgeIndex, setVisibleBadgeIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;

      // Calculate the distance of the timeline div from the top of the document
      const newTimelineOffsetTop = document.querySelector('.timeline').offsetTop;
      setTimelineOffsetTop(newTimelineOffsetTop);

      // Calculate the scroll percentage relative to the position of the timeline div
      const scrollPercentage = Math.max(0, (window.scrollY - timelineOffsetTop) / documentHeight) * 100;

      // You can adjust the multiplier to control the speed of the dot
      const dotMultiplier = 3; // Adjust as needed

      // Ensure the dot doesn't go beyond a certain point (e.g., 100%)
      const maxDotPercentage = 93.5;
      const newDotScrollPercentage = Math.min(maxDotPercentage, Math.max(1, scrollPercentage * dotMultiplier));
      setDotScrollPercentage(newDotScrollPercentage);

      // Get the bounding rectangle of the dot
      const dotRect = document.querySelector('.timeline .dot').getBoundingClientRect();

      // Get the bounding rectangles of all rows
      const rowElements = document.querySelectorAll('.timeline .row');
      let newVisibleBadgeIndex = -1;

      rowElements.forEach((rowElement, index) => {
        const rowRect = rowElement.getBoundingClientRect();

        // Check if the top of the dot is below the middle of the row
        if (dotRect.top >= rowRect.top + rowRect.height / 2) {
          newVisibleBadgeIndex = index;
        }
      });

      setVisibleBadgeIndex(newVisibleBadgeIndex);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [timelineOffsetTop]);

  return (
<>
<Helmet>
    <title>Guest Management - Feature</title>
    <meta name="description" content="Explore the journey of ShadiTayari, the innovative wedding planning app. Get to know our team, our values, and our dedication to simplifying your wedding planning experience." />
    <meta name="keywords" content="ShadiTayari, wedding planning" />
</Helmet>

<section class="features-hero feature-bg">
<Header theme="light" />
  <div class="container">
    <div class="features-hero-body">
    <div className="row gy-4">
    <div className="col-lg-5 col-md-12">
    <h1 class="font-size-40  color-white playfair-font mb-3">Effortlessly Manage Your Wedding Guests with ShadiTayari</h1>
    <p className="font-size-20 color-white mb-5">Organize, Track, and Communicate with Your Guests in Just a Few Clicks.</p>
    <div className="d-flex flex-wrap gap-3 mt-3">
      <button class="btn btn-lg wf-purple-btn wf-btn  text-uppercase px-4 wf-shadow" onClick={(e)=>{e.preventDefault(); navigate(profile&&profile?.role=="customer"?'/guest-list':'/login')}}>Start Planning</button>
      <button class="btn btn-lg wf-white-btn wf-btn  text-uppercase px-4 wf-shadow" onClick={()=> navigate('/features/budget-management')}>Explore Other Features</button>
    </div>
    </div>
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-hero-image.png`} alt="" style={{marginBottom:'-30px'}} className="features-hero-image w-100" />
    </div>
    </div>
    </div>
  </div>
</section>
<section className="features-section-1 py-5">
<div className="container">
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center pt-4">Welcome to the Ultimate solution for managing your wedding guest list with ShadiTayari. Designed with simplicity and efficiency in mind, our guest list management feature takes the hassle out of one of the most challenging aspects of wedding planning.</p>
  <h2 className="font-size-30  playfair-font mb-3 w-75 mx-auto text-center gradient-color">Why ShadiTayari Enhances Your Wedding Planning Experience</h2> 
  <p className="font-size-16 color-black mb-5 w-50 mx-auto text-center">ShadiTayari offers a modern, efficient, and collaborative approach to organizing a wedding, making the entire process smoother and more enjoyable for everyone involved.</p>
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-6 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/task-image-1.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>
    <div className="col-lg-6 col-md-12 align-self-center">
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
        <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-1.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">Verified and Reliable Local Wedding Suppliers</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">Discover a curated list of local wedding suppliers, each rigorously vetted for quality and reliability, ensuring you connect with only the best for your special day.
        Access a network of top-rated vendors, from florists to photographers, all verified by ShadiTayari for their excellence and professionalism.</p>
    </div>
    <div className="mb-4">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-2.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">Exclusive Discounts and Price Comparisons</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">Enjoy exclusive discounts and special offers from a range of wedding suppliers, available only to ShadiTayari users, 
      helping you make the most of your budget. Compare prices and services across different vendors directly within the app, ensuring you get the best deals while 
      planning your dream wedding.</p>
    </div>
    <div className="">
    <div className="d-flex gap-3 align-items-center mb-2">
      <img height={43} src={`${process.env.REACT_APP_FrontURL}/assets/images/task-icon-3.png`} className="features-icon" alt="" />
        <div>
        <h2 className="font-size-18 fw-semibold purple-color mb-0">Real Time Coordination with Complete History</h2>
        </div>
      </div>
      <p className="font-size-16 color-black mb-0">Experience seamless real-time coordination with vendors and your wedding party, complete with a comprehensive 
      history of all interactions and updates. Stay organized and informed with a complete chronological record of your wedding planning steps, from initial 
      inquiries to final arrangements, all within the app.</p>
    </div>
    </div>    
  </div>
</div>
</section>
<section className="features-section-2 py-5">
<div className="container">
    <h2 className="font-size-46  playfair-font mb-4 text-center gradient-color">Features Highlight</h2>
    <p className="font-size-18 purple-color mb-5 w-50 mx-auto text-center">Here’s how our Guest List Management feature can make your wedding planning a breeze:</p>
  <div class="timeline">
  <div className="line"></div>
  <div className="dot" style={{ top: `${dotScrollPercentage}%` }}></div>
  <div className="row gy-4 px-md-5 gx-5 mb-4">
  {visibleBadgeIndex === 0 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className="col-md-5 order-md-1 " >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-1.png`} className="features-img w-100" alt="" /> 
        </ScrollAnimation>
        </div>
        
        <div className="col-md-5 offset-md-2 order-md-2 align-self-center" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-1.svg`} className="features-icon" alt="" />
          <h2 className="font-size-26  playfair-font pink-color mb-2">Seamless Guest Addition</h2>
          <p className="font-size-16 color-black mb-4">Quickly add guests individually or in bulk, and categorize them into groups like family, friends, or colleagues. Effortlessly import guest details from your contacts or a spreadsheet, saving you time and effort.</p>
        </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 1 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
      <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
      <ScrollAnimation animateIn="animate__zoomIn">
      <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-2.svg`} className="features-icon mb-2" alt="" />
        <h2 className="font-size-26  playfair-font pink-color mb-2">RSVP Tracking</h2>
        <p className="font-size-16 color-black mb-4">Send digital invitations and track responses in real time directly through the app. Keep an eye on who has confirmed, declined, or not yet responded, all in one place.</p>
        </ScrollAnimation>
      </div>
        <div className="col-md-5 offset-md-2 order-md-2 order-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-2.png`} className="features-img w-auto mx-auto" alt="" />
          </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 2 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-3.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className="col-md-5 offset-md-2 align-self-center px-md-5 order-md-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-3.svg`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26  playfair-font pink-color mb-2">Personalized Communication</h2>
          <p className="font-size-16 color-black mb-4">Send personalized messages, Updates, or reminders to your guests. Communicate directly with guests through app, keeping all correspondence organized.</p>
          </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 3 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-4.svg`} className="features-icon mb-2" alt="" />
          <h2 className="font-size-26  playfair-font pink-color mb-2">Detailed Guest Information</h2>
          <p className="font-size-16 color-black mb-4">Note any special requirement like dietary restrictions, accessibility needs, or seating preferences. Easily manage plus-ones and families, ensuring everyone is accounted for.</p>
          </ScrollAnimation>
        </div>
        <div className="col-md-5 offset-md-2 order-md-2 order-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-4.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 4 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-5.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className="col-md-5 offset-md-2 align-self-center px-md-5 order-md-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-5.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26  playfair-font pink-color mb-2">Stress Reduction</h2>
            <p className="font-size-16 color-black mb-4">With ShadiTayari, there's no need to stress over the details. Our AI keeps everything organized, so you can focus on what truly matters - the love you're celebrating.</p>
            </ScrollAnimation>
        </div>
        
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-4">
    {visibleBadgeIndex === 5 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 align-self-center px-md-5 order-md-1 order-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-6.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26  playfair-font pink-color mb-2">Real-Time Synchronization</h2>
            <p className="font-size-16 color-black mb-4">Any changes or additions to your guest list are updated instantly across all devices. Share access with your partner or wedding planner for a syncronized planning</p>
            </ScrollAnimation>
        </div>
        <div className="col-md-5 offset-md-2 order-md-2 order-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-6.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
    </div>
    <div className="row gy-4 px-md-5 gx-5 mb-5">
    {visibleBadgeIndex === 6 && <div className={`timeline-badge ${dotScrollPercentage >= 50 ? 'visible' : ''}`}></div>}
        <div className="col-md-5 order-md-1" >
        <ScrollAnimation animateIn="animate__zoomIn">
            <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-image-7.png`} className="features-img w-auto mx-auto" alt="" />
            </ScrollAnimation>
        </div>
        <div className="col-md-5 offset-md-2 align-self-center px-md-5 order-md-2" >
        <ScrollAnimation animateIn="animate__zoomIn">
        <img width={62} src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-highlight-icon-7.svg`} className="features-icon mb-2" alt="" />
            <h2 className="font-size-26  playfair-font pink-color mb-2">Mobile Accessibility</h2>
            <p className="font-size-16 color-black mb-4">Access your checklist and task management on the go, wherever you are. Stay in control of your wedding plans from the palm of your hand.</p>
            </ScrollAnimation>
        </div>
        
    </div>
  </div>
    
    
    {/* <p className="font-size-30 purple-color mb-2 w-75  mx-auto text-center">Join ShadiTayari and experience the future of wedding planning with our AI-powered Task Management and Checklist features.</p>
    <p className="font-size-16 pink-color w-50  mx-auto text-center">Let us handle the logistics while you enjoy the journey to your perfect day.</p> */}
</div>
</section>
<section className="features-section-3 py-5">
<div className="container">
<h2 className="font-size-36  playfair-font gradient-color mb-3 text-center w-75 mx-auto">Surprising Facts That Highlight the Power of Guest List Management</h2>
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-4 col-md-12 align-self-center order-lg-1 order-2">
    <p className="font-size-16 color-black mb-4">When it comes to wedding planning, managing your guest list is more than just counting heads. It’s about creating memorable experiences for each attendee and ensuring seamless communication.</p>
    <div className="row">
      <div className="col-md-6 light-pink-bg py-4">
      <h3 className="font-size-50  gradient-color playfair-font mb-3 text-center">120</h3>
      <p className="font-size-14 color-black mb-0 text-center">guest in average wedding event</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50  gradient-color playfair-font mb-3 text-center">20%</h3>
      <p className="font-size-14 color-black mb-0 text-center">of the invited guests on average don’t attend the wedding</p>
      </div>
      <div className="col-md-6 py-4">
      <h3 className="font-size-50  gradient-color playfair-font mb-3 text-center">50%</h3>
      <p className="font-size-14 color-black mb-0 text-center">couple find organized communication with guests as a challenging aspect</p>
      </div>
      <div className="col-md-6 light-purple-bg py-4">
      <h3 className="font-size-50  gradient-color playfair-font mb-3 text-center">75%</h3>
      <p className="font-size-14 color-black mb-0 text-center">couples wish to send thank-you messages after the wedding</p>
      </div>
    </div>
  </div>  
    <div className="col-lg-8 col-md-12 order-lg-2 order-1">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-facts-image.png`} className="features-img w-100" alt="" />
        {/* <div className="h-100 w-100 rounded light-purple-bg"></div> */}
    </div>  
  </div>
</div>
</section>
<section className="features-section-4 py-5">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
  <div className="col-lg-4 col-md-12 align-self-center">
    <img src={`${process.env.REACT_APP_FrontURL}/assets/images/guest-guide-image.png`} className="features-img w-100" alt="" />
  </div>  
    <div className="col-lg-8 col-md-12">
    <p className="font-size-18 purple-color mb-0">How to Guide</p>
    <h2 className="font-size-32  playfair-font mb-4 pink-color">Quick Guide to Guest List Management</h2> 
    <p className="font-size-18 color-black mb-3">Effortlessly manage your wedding planning with ShadiTayari's guest list management feature. Make the most out of guest list management feature, ensuring a well-organized and smoothly run guest management process for your wedding.</p>
      
      <div className="mb-4 light-pink-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">Create and Organize Your Guest List</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="pink-bg rounded-circle d-flex align-items-center justify-content-center mt-2" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">You can enter details manually, import from contacts, or upload a spreadsheet. Group guests into categories like family, friends, and colleagues for better organization.</p>
        </div>
        </div>
        </div>
      </div>

      <div className="mb-4 light-purple-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">Send Invitations and Track RSVP’s</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="purple-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">Send out digital invitations directly to your guests’ emails or phones. Monitor who has accepted, declined, or not yet responded in real time, helping you keep an accurate count and adjust plans accordingly.</p>
        </div>
        </div>
        </div>
      </div>
      <div className="light-black-bg p-3 rounded-3 wf-shadow">
        <h2 className="font-size-18 fw-semibold pink-color mb-2">Communicate and Finalize Arrangements</h2>
        <div className="row">
        <div className="col-md-12 d-flex align-items-start gap-3">
        <div className="black-bg rounded-circle d-flex align-items-center justify-content-center mt-1" style={{height:'17px', width:'100%', maxWidth:'17px'}}>
          <i class="bi bi-check2-circle font-size-14 color-white d-flex"></i>
        </div>
        <div>
          <p className="font-size-14 color-black mb-0">Send updates, reminders, or personalized messages to your guests through the app. Make any last-minute adjustments to accommodate your guests’ needs and preferences.</p>
        </div>
        </div>
        </div>
      </div>
    </div>  
  </div>
</div>
</section>
<section className="features-section-5 py-5">
<div className="container">
    <h2 className="font-size-46  playfair-font gradient-color mb-5 text-center">Frequently Asked Questions</h2>
    <div className="faq-grid-system">
    {questions.map((data)=>(
    <div className="" key={data}>
        <h3 className="font-size-18  playfair-font color-black mb-2">
        <img style={{marginRight:'10px'}} src="../assets/images/w-pink.svg" width="35" alt="" className="features-icon-img"/> 
        {data.question}</h3>
        <p className="font-size-16 color-black mb-4"  dangerouslySetInnerHTML={{ __html: data.answer }}></p>
    </div>
    ))}
    </div>
    <p className="font-size-16 color-black mb-0 text-center">For any additional queries, contact ShadiTayari’s Support Team.</p>
</div>
</section>
<section className="features-section-6 py-5 dowload-app-bg">
<div className="container">
  <div className="row gy-4 mb-4 pt-3 gx-5">
    <div className="col-lg-7 col-md-12">
        <img src={`${process.env.REACT_APP_FrontURL}/assets/images/feature-download-image.png`} className="features-img w-100" alt="" />
    </div>
    <div className="col-lg-5 col-md-12 align-self-center">
      <h2 className="font-size-36  playfair-font mb-2 pink-color">Seamlessly Organize Your Wedding Guests with ShadiTayari!</h2> 
      <p className="font-size-16 color-black mb-4">Say goodbye to the hassles of traditional guest tracking methods and embrace a smarter way to manage your invitees. From sending out elegant digital invitations to real-time RSVP tracking and personalized guest communications, ShadiTayari simplifies every aspect of guest list management.</p>
      <div className="d-flex align-items-center gap-3 mb-4">
      <img width={170} class="google-store-img img-fluid " src={`${process.env.REACT_APP_FrontURL}/assets/images/play-store-img.svg`} alt=""/>
        <img width={170} class="apple-store-img img-fluid" src={`${process.env.REACT_APP_FrontURL}/assets/images/app-store-img.svg`} alt=""/>
      </div>
      <div className="d-flex align-items-center gap-3">
      <img class="" width="70" src={`${process.env.REACT_APP_FrontURL}/assets/images/dowload-ShadiTayari-icon.svg`} alt=""/>
      <div>
      <p className="font-size-18 color-black mb-0">Start Planning Your Dream Wedding Today!</p>
      <p className="font-size-18 mb-0 fw-semibold"><a className='pink-color'>Download ShadiTayari for Free! <i class="bi bi-arrow-right"></i></a></p>
      </div>
      </div>
    </div>    
  </div>
</div>
</section>
<section className="features-section-7 py-5 subscribe-bg">
<div className="container">
<p className="font-size-18 color-black text-center mb-3 mt-5">Exclusive Updates</p>
    <h3 className="font-size-36  playfair-font pink-color mb-5 text-center">Join the ShadiTayari Insider Community:<br/> Stay Updated on the Latest in Wedding Planning!</h3>
    <div class="input-group wf-input-group w-50 mx-auto mb-1">
      <div class="form-floating wf-input">
        <input type="email" class="form-control" id="email-address" placeholder="Email Address"/>
        <label for="email-address">Email Address</label>
      </div>
      <button class="btn wf-pink-btn wf-btn ">Subscribe Now</button>
    </div>
    <p className="font-size-12 color-black text-center mb-5">Rest assured, your email address is safe with us. It will be exclusively used to send you ShadiTayari updates and will never be shared with third parties.</p>
</div>
</section>
  
<Footer />
    </>
  )
}

export default GuestManagementFeature