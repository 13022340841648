import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { token, getProfile } from './store/slices/userSlice';
import { getUserEventList, userLogout } from '../src/store/slices/userSlice';
import Layout from './components/Layout/Layout';
import ContextProvider from './context/context';
import { requestForToken, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import { setSocket } from './store/slices/socketSlice';
import toast, { Toaster } from 'react-hot-toast';
import ConfettiFlags from './components/Layout/ConfettiFlags';
import { Helmet, HelmetProvider } from 'react-helmet-async';


 
function App() { 
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);

  const GetEventNames = async () => {
    try {
      await dispatch(getUserEventList()).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    try {
      dispatch(token());
      dispatch(setSocket());
      requestForToken();
      onMessage(messaging, (payload) => {
        toast(payload.notification.body);
        console.log('foreground', payload);
      });
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    GetEventNames();
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (!selectedLanguage) {
      localStorage.setItem('selectedLanguage', 'en');
    }
  }, []);

//   useEffect(() => {
//     const handleBeforeUnload = () => {
//       localStorage.setItem('isReturning', 'true');
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, []);

//   const handleLogout=async()=>{
//     try {
   
//       console.log("first")

//         // await new Promise((resolve) => setTimeout(resolve, 1000));
//         await dispatch(userLogout()).unwrap()
//         localStorage.removeItem('user');
//         localStorage.removeItem('pageUnloading');
//         localStorage.removeItem('isReturning');
//         // navigate(profile?.role=="customer"?"/login":"/vendor/login")
      
//       } catch (rejectedValueOrSerializedError) {
//         console.log(rejectedValueOrSerializedError)
//       }
// }

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (!document.hidden) {
//         const isReturning = localStorage.getItem('isReturning');
//         console.log('isReturning', isReturning)
     
//         const loggedInUser = localStorage.getItem('loggedInUser');
       
//         if (isReturning&&loggedInUser === 'true' || loggedInUser === true) {
     
//           console.log('First visit or page refresh');
//           localStorage.removeItem('isReturning');
//         } 
//         else {
         
//           console.log('User was not logged in last time');
//             handleLogout();
//         }
//       }
//     };

//     document.addEventListener('visibilitychange', handleVisibilityChange);
//     window.addEventListener('pagehide', handleLogout);

//     return () => {
//       document.removeEventListener('visibilitychange', handleVisibilityChange);
//       window.removeEventListener('pagehide', handleLogout);
//     };
//   }, []);

function isAugust14() {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth(); // Months are 0-indexed: January is 0, February is 1, etc.

  // Check if today is August 14th
  return day === 14 && month === 7; // August is month 7
}
  return (
    <HelmetProvider>
    <ContextProvider >
    <Helmet>
    <title>Shadi Tayari - Pakistan's Digital Wedding Planner</title>
    <meta name="description" content="Discover the future of wedding planning with Shadi Tayari, the Pakistan's premier AI-powered wedding planning app with thousands of curated vendor selections and premium wedding venues." />
    </Helmet>
      {isAugust14() && <ConfettiFlags />}
      <Layout />
    </ContextProvider>
    </HelmetProvider>
  );
}

export default App;

