import React from 'react'

const TextSkeleton = () => {
  return (
    <>
        <div style={{width:'47%'}} className="text-shimmer shimmer m-1 rounded-1"></div>
    </>
  )
}

export default TextSkeleton