import React from 'react'
import Header from './HeaderLight'
import Footer from './Footer'
import { Helmet } from "react-helmet-async";

const Marketing = () => {
  return (
    <>
    <Helmet>
      <title>Marketing - Shadi Tayari</title>
      <meta name="description" content="Welcome to ShadiTayari.pk, Pakistan’s premier online wedding planning platform, powered by ShadiTayari."/>
      <link rel="canonical" href={`${process.env.REACT_APP_FrontURL}/marketing`}/>
      </Helmet>
      <Header theme="dark" />
    <section className="faqs-section" >
      <div className="container">
      <div class="border-top  mb-4 mt-3"></div>
          <h1 className="h1-title dm-font color-black text-center">Marketing</h1>
          <div class="border-top  mb-3 mt-4"></div>
          <p className="font-size-18">Welcome to ShadiTayari.pk, Pakistan’s premier online wedding planning platform, powered by ShadiTayari. Whether you're a bride-to-be, groom-to-be, or a wedding planner, we’re here to make your wedding journey seamless and unforgettable. From finding the perfect venue to selecting the best suppliers, ShadiTayari.pk has everything you need to plan the wedding of your dreams.</p>

    <h2 className='font-size-30 fw-semibold'>Why Choose ShadiTayari.pk?</h2>
    <p className="font-size-18">Comprehensive Listings: Explore a wide range of wedding suppliers and venues across Pakistan. From stunning banquet halls and scenic outdoor venues to talented photographers, caterers, and decorators, we’ve got you covered.</p>
    <p className="font-size-18">Verified Reviews: Make informed decisions with confidence. Our platform features genuine reviews and ratings from real customers, ensuring you choose the best services for your special day.</p>
    <p className="font-size-18">User-Friendly Platform: Our intuitive website makes wedding planning easy and stress-free. Use advanced search filters to find exactly what you’re looking for, and book services directly through our secure platform.</p>
    <p className="font-size-18">Wedding Inspiration: Need ideas for your big day? Check out our blog for the latest wedding trends, tips, and inspiration. From décor ideas to fashion advice, we provide all the inspiration you need to make your wedding uniquely yours.</p>
    <p className="font-size-18">Dedicated Support: Our customer support team is here to assist you at every step. Whether you have questions about a listing or need help with a booking, we’re just an email away.</p>

    <h2 className='font-size-30 fw-semibold'>For Wedding Suppliers and Venues</h2>
    <p className="font-size-18">Increase Visibility: Showcase your services to thousands of couples planning their weddings. Our platform attracts a high volume of traffic, ensuring your business gets the exposure it deserves.</p>
    <p className="font-size-18">Easy Management: Manage your listings, bookings, and inquiries effortlessly with our user-friendly dashboard. Keep your calendar updated and respond to potential clients quickly and efficiently.</p>
    <p className="font-size-18">Grow Your Business: Benefit from our marketing expertise. We provide promotional opportunities and support to help you reach your target audience and grow your wedding business.</p>

    <h2 className='font-size-30 fw-semibold'>Join Us Today!</h2>
    <p className="font-size-18">Experience the ease and joy of wedding planning with ShadiTayari.pk. Whether you’re just starting your journey or finalizing the details, we’re here to help you every step of the way. Visit <a href="http://www.shaditayari.pk">www.shaditayari.pk</a> and start planning your perfect wedding today.</p>
      </div>
    </section>

    <Footer />
    </>
  )
}

export default Marketing