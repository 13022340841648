import React, { useState,useContext, useEffect } from 'react'
import {  useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/Layout/LanguageSwitcher';
import i18n from '../../components/i18n';




const Invitation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [attendingDetailsVisible, setAttendingDetailsVisible] = useState(false);
    const [load, setload] = useState(false);
    const [guestData, setguestData] = useState([]);
    const [eventData, seteventData] = useState([]);
    const [datetoshow, setdatetoshow] = useState("");

    const [male_count, setMale_Count] = useState(0);
    const [female_count, setFemale_Count] = useState(0);
    const [child_count, setChild_Count] = useState(0);
    
    const [rsvp, setrsvp] = useState("");


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        if (token) {
          
          try {
           
    
            const decodedToken = jwtDecode(token);
            console.log('decodedToken', decodedToken)
            getData(decodedToken?.event_id,decodedToken?.guest_id);
          } catch (error) {
            console.error('Error decoding token:', error);
          }
        }
      }, []);
      
      
      
      const getData=async(eventId,guestId)=>{

          try {
              
              setguestData([])
                seteventData([])
                setload(true);
        
              const response = await axios.get(`vendorpage/getinvitationdata`,{
                params: {
                    eventId: eventId,
                    guestId: guestId
                }
              });
              
              console.log('response?.data?.guestId', response?.data)
              
              
              setguestData(response?.data?.data?.guestData);
              seteventData(response?.data?.data?.eventData);
              setMale_Count(response?.data?.data?.guestData?.male_count)
              setFemale_Count(response?.data?.data?.guestData?.female_count)
              setChild_Count(response?.data?.data?.guestData?.child_count)
              
              
              
              
              
              setload(false);
            
            } catch (rejectedValueOrSerializedError) {
                console.log(rejectedValueOrSerializedError);
            }

        };
        
        useEffect(() => {
            if(eventData){
                subtractDates(eventData?.event_id?.event_date)
            }
          }, [eventData]);

       
        
          function subtractDates(providedDate) {
           
            const providedDateTime = new Date(providedDate);
            
            const currentDate = new Date();
        
           
            const differenceMs = providedDateTime - currentDate;
           
            const millisecondsInADay = 1000 * 60 * 60 * 24;
            const differenceDays = Math.floor(differenceMs / millisecondsInADay);

            
            if(differenceDays>10){
                setdatetoshow(providedDateTime.setDate(providedDateTime.getDate() - 10));
                        
            }
            else if(differenceDays<10&&differenceDays>0){
                setdatetoshow(providedDateTime.setDate(providedDateTime.getDate() - 1));
            }

            else{
                setdatetoshow(providedDateTime)
            }
        
            console.log('differenceDays:', differenceDays);
        }


        const handleAttendingClick = () => {
            setAttendingDetailsVisible(!attendingDetailsVisible);
          };
        
       
        
          const handleSubmit = async (rsvp,e) => {
            e.preventDefault();
            try {
             
                const updateInvitation=await axios.post(`vendorpage/updateinvitation`,{ eventId: eventData?.event_id?._id, guestId: guestData?._id, rsvp: rsvp, maleCount: male_count, femaleCount: female_count, childCount: child_count})
                

                if(updateInvitation){
                    toast.success(updateInvitation?.data?.message)
                    navigate("/")
                }
                
             
            } catch (rejectedValueOrSerializedError) {
              console.log(rejectedValueOrSerializedError);
            }
          } 
       
    
    

console.log('data', eventData,guestData)
      


const currentLanguage = i18n.language;
   

  return (
    <>
    <section className="wf-rsvp-invitation">
        <div style={{zIndex:'2'}} className="container position-relative">
        <h1 className="font-size-50 color-black mb-5 dm-font text-center">{t('Invitation')}</h1>
        <div className="d-flex gap-4 align-items-center mb-5 justify-content-center">
            <div className="groom-image-name">
            <h3 className="font-size-30 pink-color dm-font mb-2 text-center">{guestData?.user_purpose==eventData?.person1?.user_purpose?eventData?.person1?.name:eventData?.person2?eventData?.person2?.name:"Partner"}</h3>
            <img style={{width:'135px', height:'135px'}} src={guestData?.user_purpose==eventData?.person1?.user_purpose&& eventData?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${eventData?.person1?.imageName}` : eventData?.person2 && eventData?.person2?.imageName? `${process.env.REACT_APP_IMGURL}${eventData?.person2?.imageName}` :"https://via.placeholder.com/100x100"} class="rounded-circle groom-bride-image mx-auto d-block" />
            </div>
            <div className="bride-groom-text mt-4">
            <h3 className="font-size-46 dm-font mb-0">&</h3>
            </div>
            <div className="bride-image-name">
            <h3 className="font-size-30 purple-color dm-font mb-2 text-center">{guestData?.user_purpose==eventData?.person1?.user_purpose?eventData?.person2?.name:eventData?.person1?eventData?.person1?.name:"Partner"}</h3>
            <img style={{width:'135px', height:'135px'}} src={guestData?.user_purpose==eventData?.person1?.user_purpose&&eventData?.person2?.imageName?`${process.env.REACT_APP_IMGURL}${eventData?.person2?.imageName}`:eventData?.person1&&eventData?.person1?.imageName?`${process.env.REACT_APP_IMGURL}${eventData?.person1?.imageName}`:"https://via.placeholder.com/100x100"} className="rounded-circle groom-bride-image mx-auto d-block" />
            </div>
        </div>
        <p className="font-size-18 purple-color text-center">{`Together with their families are delighted to invite you to their ${eventData?.event_id?.event_name} Event`}</p>
        <div className="row">
            <div className={`col-md-4 ${currentLanguage === 'ar' ? "offset-md-1" : "offset-md-2"}`}>
            <h3 className="font-size-22 color-black mb-3 dm-font text-center">{t('When')}</h3>
            <p className="font-size-18 purple-color text-center">{moment(eventData?.event_id?.event_date).format("D MMMM YYYY")}</p>
            </div>
            <div className="col-md-4">
            <h3 className="font-size-22 color-black mb-3 dm-font text-center">{t('Where')}</h3>
            <p className="font-size-18 purple-color text-center">{eventData?.event_id?.event_city?.name}, {eventData?.event_id?.event_city?.country_id?.name}</p>
            </div>
        </div>
        <div>
        <h3 className="font-size-22 color-black mb-3 dm-font text-center">{t('RSVP')}</h3>
        <p className="font-size-18 purple-color text-center">{t('Kindly confirm your attendance by')} <span className="fw-semibold">{moment(datetoshow).format("D MMMM YYYY")}</span>.</p>
        <div className="d-flex justify-content-center gap-3 mb-4 flex-wrap">
        <button onClick={handleAttendingClick} class="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-4">{t('Attending')}</button>
        
        <>
        <button onClick={(e) => handleSubmit(0,e)} class={`btn wf-btn-2 ${attendingDetailsVisible?"wf-grey-btn":"wf-pink-btn"} py-2 px-4 rounded-4`}>{t('Not Attending')}</button>
        <button onClick={(e) => handleSubmit(3,e)} class={`btn wf-btn-2 ${attendingDetailsVisible?"wf-grey-btn":"wf-yellow-btn"} py-2 px-4 rounded-4`}>{t('Maybe')}</button>
        </>
      
        </div>
        {attendingDetailsVisible && (<div className="d-flex flex-column justify-content-center gap-1 align-items-center">
        <div className="row mb-3 gy-3 wf-guest-attending-row">
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-male-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" /> 
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Male')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button disabled={male_count==0?true:false} onClick={() => { setMale_Count(male_count-1); }} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input value={male_count} type="text" name="quant[2]" class="form-control input-number" min="0" max="100"/>
        <span class="input-group-btn">
            <button onClick={() => { setMale_Count(male_count+1); }} type="button"  class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-female-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" />
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Female')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button disabled={female_count==0?true:false} onClick={() => { setFemale_Count(female_count-1); }} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input value={female_count} type="text" name="quant[2]" class="form-control input-number"  min="0" max="100"/>
        <span class="input-group-btn">
            <button onClick={() => { setFemale_Count(female_count+1); }} type="button" class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        <div className="col-md-4">
        <div className="d-flex gap-2 wf-count-input align-items-center gap-1">
        <div>
        <img height="23" src="../assets/images/wf-child-guests-icon.svg" alt="" className="guests-icon mx-auto d-block mb-1" />
        <p className="font-size-10 color-black mb-0 fw-semibold text-center">{t('Kids')}</p>
        </div>
        <div class="input-group">
        <span class="input-group-btn">
            <button disabled={child_count==0?true:false} onClick={() => { setChild_Count(child_count-1); }} type="button" class="btn btn-number wf-shadow-2"  data-type="minus" data-field="quant[2]">
            <i class="fas fa-minus"></i>
            </button>
        </span>
        <input value={child_count} type="text" name="quant[2]" class="form-control input-number"  min="0" max="100"/>
        <span class="input-group-btn">
            <button onClick={() => { setChild_Count(child_count+1); }} type="button" class="btn btn-number wf-shadow-2" data-type="plus" data-field="quant[2]">
            <i class="fas fa-plus"></i>
            </button>
        </span>
    </div>
        </div>
        </div>
        </div>
        <button onClick={(e) => handleSubmit(1,e)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">{t('Confirm')} {t('Attending')}</button>
        </div>)}
        </div>
        <div style={{marginTop: attendingDetailsVisible ? '15px' : '80px',}}>
        <p className="font-size-18 purple-color text-center">{t('We hope to share this special day with you and create memories that last a lifetime')}<br/>
        {t('With love')}, {guestData?.user_purpose==eventData?.person1?.user_purpose?eventData?.person1?.name:eventData?.person2?eventData?.person2?.name:"Partner"} & {guestData?.user_purpose==eventData?.person1?.user_purpose?eventData?.person2?.name:eventData?.person1?eventData?.person1?.name:"Partner"}</p>
        <div className="d-flex justify-content-center gap-2 align-items-center flex-wrap">
        <p className="font-size-14 purple-color mb-0">{t('Digital invitations are sent and managed by')} </p>
        <a href="/"><img className="" src={`${process.env.REACT_APP_FrontURL}/assets/images/${currentLanguage === 'ar' ? 'logo-arabic.png' : 'logo.svg'}`} alt="ShadiTayari" width="115px" /></a>
        </div>
        </div>
        </div>
        <img src="../assets/images/rsvp-invitation-image-1.svg" className="rsvp-image-1" alt="" />
        <img src="../assets/images/rsvp-invitation-image-2.svg" className="rsvp-image-2" alt="" />
        <img src="../assets/images/rsvp-invitation-image-3.svg" className="rsvp-image-3" alt="" />
        <img src="../assets/images/rsvp-invitation-image-4.svg" className="rsvp-image-4" alt="" />
        <img src="../assets/images/rsvp-invitation-image-5.svg" className="rsvp-image-5" alt="" />
    </section>
    <p className="d-none"> <LanguageSwitcher /> </p>
    </>
  )
}

export default Invitation