import React, { useEffect } from 'react';

const AdSense = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2825367626708661";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    const handleAds = () => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('Adsense error', e);
      }
    };

    script.onload = handleAds;
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ins className="adsbygoogle"
         style={{ display: "block", height:'290px' }}
         data-ad-client="ca-pub-2825367626708661"
         data-ad-slot="8617889179"
         data-ad-format="square"
         data-full-width-responsive="true"></ins>
  );
};

export default AdSense;