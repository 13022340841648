import React from 'react'
import { Link } from 'react-router-dom'

const CustomAd = ({height, width, link, image, name}) => {
  return (
    <>
    {/* <div className="st-custom-add position-relative rounded overflow-hidden">
        <Link to={link}><img src={image} alt={name} style={{objectFit:"cover"}} height={height} width={width} /></Link>
    </div> */}
    </>
  )
}

export default CustomAd